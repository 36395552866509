import React from 'react';
import moment from 'moment';
import './SendConfirmationModal.css';
import DatePicker from "react-date-picker";
import { FaTimes, FaCalendarAlt } from "react-icons/fa";
import Store from "../../../Common/Store";
import APICall from '../../../Common/APICall';
import CSLTable from '../../Common/CSLTable';
import EllipsisMenu from '../../Common/EllipsisMenu';
import YesNoBox from '../../Common/YesNoBox';
import SpinningLoader from '../../Common/SpinningLoader';
import AlertBox from '../../Common/AlertBox';

class SendConfirmationModal extends React.Component{

    state = {
        to_send_trans_conf : [],
        to_send_hold_conf : [],
        trans_from_date : null,
        trans_to_date : null,
        trans_due_date : null,
        holdingFromDate : null,
        holdingToDate : null,
        holdingDueDate : null,
        alertParam : null,
        show_alert : false,
        deactivate_assignment : false,
        show_spinning_loader : false,
        alert_param : null
    }

    assignConfirmation = async (event) =>{
        event.preventDefault();
        console.log("Statte ==>",this.state)
        let to_send_trans_conf = JSON.parse(JSON.stringify(this.state.to_send_trans_conf));
        let toSendHoldConf = JSON.parse(JSON.stringify(this.state.to_send_hold_conf));

        let trans=[];
        to_send_trans_conf?.forEach(element => {
            if(element.exclude !== true) trans.push(element.id);
        });

        let holdings=[];
        toSendHoldConf?.forEach(element => {
            if(element.exclude !== true) holdings.push(element.id);
        });
        let trans_due_date = this.state.trans_due_date;
        let trans_from_date = this.state.trans_from_date;
        let trans_to_date = this.state.trans_to_date;
        let holdingDueDate = this.state.holdingDueDate; 
        let holdingFromDate = this.state.holdingFromDate; 
        let holdingToDate = this.state.holdingToDate;
        let error_dates = []
        let alertParam = null;
        let show_alert = false;
        if(trans.length > 0){
            if(trans_from_date === null){
                error_dates.push(`Transactions From Date`);
            }
            if(trans_to_date === null){
                error_dates.push(`Transactions To Date`);
            }
            if(trans_due_date === null){
                error_dates.push(`Transaction Confirmation Due Date`);
            }
            if(error_dates.length !==0){
                alertParam = {
                    "header" : "Confirmation Assignment Error",
                    "subheader" : "",
                    "msg" : `${error_dates.join(', ')} cannot be blank`,
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                show_alert = true;
                this.setState({ alertParam, show_alert });
                return;
            }
        }
        if(holdings.length>0){
            if(holdingToDate === null){
                error_dates.push(`Holdings To Date`);
            }
            if(holdingDueDate === null){
                error_dates.push(`Holding Confirmation Due Date`);
            }
            if(error_dates.length !==0){
                alertParam = {
                    "header" : "Confirmation Assignment Error",
                    "subheader" : "",
                    "msg" : `${error_dates.join(', ')} cannot be blank`,
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                show_alert = true;
                this.setState({ alertParam, show_alert });
                return;
            }
        }
        this.setState({  show_spinning_loader : true });

        let postdata = { command : 'assign_confirmation',
            to_send_trans_conf : trans,
            to_send_hold_conf : holdings,
            trans_from_date : parseInt(moment(moment(trans_from_date,"DD/MM/YYYY")).format("YYYYMMDD")),
            trans_to_date : parseInt(moment(moment(trans_to_date,"DD/MM/YYYY")).format("YYYYMMDD")),
            trans_due_date : parseInt(moment(moment(trans_due_date,"DD/MM/YYYY")).format("YYYYMMDD")),
            holding_from_date : parseInt(moment(moment(holdingFromDate,"DD/MM/YYYY")).format("YYYYMMDD")), 
            holding_to_date : parseInt(moment(moment(holdingToDate,"DD/MM/YYYY")).format("YYYYMMDD")),
            holding_due_date : parseInt(moment(moment(holdingDueDate,"DD/MM/YYYY")).format("YYYYMMDD")),
            date_sent : parseInt(moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").format("YYYYMMDD")),
            pad_report_title : Store.getStoreData("module_config").pad_confirmation.report_title,
            holding_report_title : Store.getStoreData("module_config").holding_confirmation.holding_report_title
        }
        console.log("POSTDATA ===>",postdata)
        const api = new APICall();
        let result = await api.commandWithoutCallback(postdata);
        console.log("CONF result", result);
        if(result.error_code === 0){
            let alert_param = {
				title: 'Confirmation Requests Sent', message: 'All the selected Confirmation Requests have been sent to the employees', ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlertSmall, stack: { close_assignment_modal : 1 }
			}
            this.setState({  show_spinning_loader : false, alert_param });
        }
        else{
            let alert_param = {
				title: 'Confirmation Requests Sending Error', message: 'Something went wrong.', ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlertSmall, stack: {}
			}
            this.setState({  show_spinning_loader : false, alert_param });
        }
    }

    async componentDidMount(){
        const module_config = Store.getStoreData("module_config");
        console.log("From Send Confirmation : module config :",module_config);
        console.log("SELECTED RECORDS : ", this.props.selected_records);
        const payload1 = { command: "fetch_last_sent_date", type : 'transaction', ids : this.props.selected_records.transaction }
        const payload2 = { command: "fetch_last_sent_date", type : 'holding', ids : this.props.selected_records.holding }

        const api = new APICall()
        const [result1, result2] = await Promise.all([
            api.commandWithoutCallback(payload1),
            api.commandWithoutCallback(payload2)
        ]);
        //debugger;
        let transaction_last_sent = result1.result;
        let holding_last_sent = result2.result;
        console.log("DB return : ", transaction_last_sent, holding_last_sent);
        let to_send_trans_conf = [];
        let to_send_hold_conf = [];
        let to_send_broker_conf = [];
        for(let r of this.props.selected_records.transaction){
            let data = {
                id : r,
                name : (Store.getStoreData("company_users").find(item => item.user_id === r.toString())).name === "Inactive User" ? (this.props.inactive_users.find(item => item.user_id === r.toString())).name : (Store.getStoreData("company_users").find(item => item.user_id === r.toString())).name,
                last_sent : r.toString() in transaction_last_sent ? transaction_last_sent[r.toString()].max_date : null,
                exclude : false
            }
            to_send_trans_conf.push(data);
        }
        for(let r of this.props.selected_records?.holding){
            let data = {
                id : r,
                name : (Store.getStoreData("company_users")?.find(item => item.user_id === r.toString()))?.name === "Inactive User" ? (this.props.inactive_users?.find(item => item.user_id === r.toString()))?.name : (Store.getStoreData("company_users").find(item => item.user_id === r.toString()))?.name,
                last_sent : r.toString() in holding_last_sent ? holding_last_sent[r.toString()]?.max_date : null,
                exclude : false
            }
            to_send_hold_conf.push(data);
        }
        console.log("to_send_trans_conf ===>",to_send_trans_conf)
        this.setState({ to_send_trans_conf, to_send_hold_conf, to_send_broker_conf, ready : true })
    }

    handleFromDate = (e) => {
        console.log("from Date",e)
        let trans_from_date = e !== null ? moment(e).format("DD/MM/YYYY") : e;
        this.setState({ trans_from_date: moment(e).format("DD/MM/YYYY") });
        let trans_to_date = this.state.trans_to_date;
        let trans_due_date = this.state.trans_due_date;
        let alertParam = null;
        let show_alert = false;
        if(trans_to_date !== null){
            if(moment(trans_from_date,"DD/MM/YYYY").unix() > moment(trans_to_date,"DD/MM/YYYY").unix()){
                alertParam = {
                    "header" : "From Date Alert",
                    "subheader" : "",
                    "msg" : "From Date cannot be greater than To Date",
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                show_alert = true;
                trans_from_date = null;
            }
        }else if(trans_due_date !== null){
            if(moment(trans_from_date,"DD/MM/YYYY").unix() > moment(trans_due_date,"DD/MM/YYYY").unix()){
                alertParam = {
                    "header" : "From Date Alert",
                    "subheader" : "",
                    "msg" : "From Date cannot be greater than Due Date",
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                show_alert = true;
                trans_from_date = null;
            }
        }
        this.setState({trans_from_date, show_alert, alertParam});
    }

    handleToDate = (e) => {
        let trans_to_date = e !== null ? moment(e).format("DD/MM/YYYY") : e;
        let trans_from_date = this.state.trans_from_date;
        let trans_due_date = this.state.trans_due_date;
        let alertParam = null;
        let show_alert = false;
        if((trans_from_date === null || moment(trans_from_date,"DD/MM/YYYY").unix() <= moment(trans_to_date,"DD/MM/YYYY").unix()) && (trans_due_date === null || moment(trans_to_date,"DD/MM/YYYY").unix() <= moment(trans_due_date,"DD/MM/YYYY").unix()) || trans_to_date === null){
            this.setState({ trans_to_date });
            return;
        }else if( trans_from_date !== null && moment(trans_from_date,"DD/MM/YYYY").unix() > moment(trans_to_date,"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "To Date Alert",
                "subheader" : "",
                "msg" : "To Date cannot be less than From Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            show_alert = true;
        }else if( trans_due_date !== null && moment(trans_to_date,"DD/MM/YYYY").unix() > moment(trans_due_date,"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "To Date Alert",
                "subheader" : "",
                "msg" : "To Date cannot be greater than Due Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            show_alert = true;
        }
        this.setState({ alertParam, show_alert });
    }

    handleDueDate = (e) => {
        let trans_due_date = e !== null ? moment(e).format("DD/MM/YYYY") : e;
        let trans_to_date = this.state.trans_to_date;
        console.log("trans due date : ", moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").unix());
        let alertParam = null;
        let show_alert = false;
        if(trans_due_date === null || (trans_due_date !== null && moment(trans_due_date,"DD/MM/YYYY").unix() >= moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").unix() && (trans_to_date === null || moment(trans_due_date,"DD/MM/YYYY").unix() >=  moment(trans_to_date,"DD/MM/YYYY").unix()))){
            this.setState({ trans_due_date });
            return;
        }
        else if(moment(trans_due_date,"DD/MM/YYYY").unix() < moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "Due Date Alert",
                "subheader" : "",
                "msg" : "Due Date cannot be less than current date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            show_alert = true;
        }else if(trans_to_date !== null && moment(trans_due_date,"DD/MM/YYYY").unix() <  moment(trans_to_date,"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "Due Date Alert",
                "subheader" : "",
                "msg" : "Due Date cannot be less than To Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            show_alert = true;
        }
        this.setState({ alertParam, show_alert });
    }
    

    handleHoldingToDate = (e) => {
        let holdingToDate = e !== null ? moment(e).format("DD/MM/YYYY") : e;
        let holdingFromDate = this.state.holdingFromDate;
        let holdingDueDate = this.state.holdingDueDate;
        let alertParam = null;
        let showAlert = false;
        if((holdingFromDate === null || moment(holdingFromDate,"DD/MM/YYYY").unix() <= moment(holdingToDate,"DD/MM/YYYY").unix()) && (holdingDueDate === null || moment(holdingToDate,"DD/MM/YYYY").unix() <= moment(holdingDueDate,"DD/MM/YYYY").unix()) || holdingToDate === null){
            this.setState({ holdingToDate : holdingToDate });
            return;
        }else if( holdingFromDate !== null && moment(holdingFromDate,"DD/MM/YYYY").unix() > moment(holdingToDate,"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "To Date Alert",
                "subheader" : "",
                "msg" : "To Date cannot be less than From Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            showAlert = true;
        }else if( holdingDueDate !== null && moment(holdingToDate,"DD/MM/YYYY").unix() > moment(holdingDueDate,"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "To Date Alert",
                "subheader" : "",
                "msg" : "To Date cannot be greater than Due Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            showAlert = true;
        }
        this.setState({ alertParam, show_alert : showAlert });
    }

    handleHoldingDueDate = (e) => {
        let holdingDueDate = e !== null ? moment(e).format("DD/MM/YYYY") : e;
        let holdingToDate = this.state.holdingToDate;
        let alertParam = null;
        let showAlert = false;
        if(holdingDueDate === null || (moment(holdingDueDate, "DD/MM/YYYY").unix() >= moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").unix() && (holdingToDate === null || moment(holdingDueDate, "DD/MM/YYYY").unix() >= moment(holdingToDate, "DD/MM/YYYY").unix()))){
            this.setState({ holdingDueDate });
            return;
        }
        else if(moment(holdingDueDate,"DD/MM/YYYY").unix() < moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "Due Date Alert",
                "subheader" : "",
                "msg" : "Due Date cannot be less than current date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            showAlert = true;
        }else if(holdingToDate !== null && moment(holdingDueDate,"DD/MM/YYYY").unix() <  moment(holdingToDate,"DD/MM/YYYY").unix()){
            alertParam = {
                "header" : "Due Date Alert",
                "subheader" : "",
                "msg" : "Due Date cannot be less than To Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            showAlert = true;
        }
        this.setState({ alertParam, show_alert : showAlert });
    }

    performActionTrans = (id, action) => {
        let to_send_trans_conf = JSON.parse(JSON.stringify(this.state.to_send_trans_conf));
        let to_send_hold_conf = JSON.parse(JSON.stringify(this.state.to_send_hold_conf));
        for(let i=0; i<to_send_trans_conf.length; i++){
            if(to_send_trans_conf[i].id === id) to_send_trans_conf[i].exclude = true;
        }
        let total_confirmations_to_send = 0;
        for(let i=0; i<to_send_trans_conf.length; i++){
            if(to_send_trans_conf[i].exclude === false) total_confirmations_to_send++;
        }
        for(let i=0; i<to_send_hold_conf.length; i++){
            if(to_send_hold_conf[i].exclude === false) total_confirmations_to_send++;
        }
        console.log("total_confirmations_to_send",total_confirmations_to_send)
        this.setState({ to_send_trans_conf, to_send_hold_conf, deactivate_assignment : !(total_confirmations_to_send > 0) })
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    closeAlert = () => {
        this.setState({ alertParam : null, show_alert : false });
    }

    closeAlertSmall = (value, stack) => {
        this.setState({ alert_param : null});
        if("close_assignment_modal" in stack && value === true) this.props.closeModal("new");
    }

    processPadConTable = () =>{
        let ret = { data: [], columns: [] };
        const actions = [{name: "Remove", action: "remove"}];
        ret.columns = [
        { Header: "Employee", accessor: "employee", width: 500, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" }}, //, Cell: (row) => <div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}></div> },
        { Header: "Last sent", accessor: "last_sent", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
        { Header: "", width: 50, filterable: false, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px"},
            Cell: (row) => {
                let show = {remove: 1};
                return(
                    <div style={{ marginTop: "-5px"}}>
                        <EllipsisMenu show={show}  entity_id={row.original.id} actions={actions} performAction={this.performActionTrans} />
                    </div>
                )
            }
        }
        ];
        for (let record of this.state.to_send_trans_conf) {
            if(record.exclude === false){
                ret.data.push({
                    id : record.id,
                    employee : record.name,
                    last_sent : record.last_sent !== null ? moment(moment(record.last_sent.toString(),"YYYYMMDD")).format("DD/MM/YYYY") : "--/--/----"
                });
            }
        }
        return ret;
    }

    processHoldConTable = () =>{
        let ret = { data: [], columns: [] };
        const actions = [{name: "Remove", action: "remove"}];
        ret.columns = [
        { Header: "Employee", accessor: "employee", width: 500, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" }},
        { Header: "Last sent", accessor: "last_sent", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
        { Header: "", width: 50, filterable: false, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px"},
            Cell: (row) => {
                let show = {remove: 1};
                return(
                    <div style={{ marginTop: "-5px"}}>
                        <EllipsisMenu show={show}  entity_id={row.original.id} actions={actions} performAction={this.performActionTrans} />
                    </div>
                )
            }
        }
        ];
        for (let record of this.state.to_send_hold_conf) {
            if(record.exclude === false){
                ret.data.push({
                    id : record.id,
                    employee : record.name,
                    last_sent : record.last_sent !== null ? moment(moment(record.last_sent.toString(),"YYYYMMDD")).format("DD/MM/YYYY") : "--/--/----"
                });
            }
        }
        return ret;
    }

    render(){
        return(
            <>
            <div className='send-confirmation-modal-wrapper'>
                {this.state.alertParam !== null && this.state.show_alert === true && <YesNoBox alertParam={this.state.alertParam}/>}
                {this.state.show_spinning_loader === true && <SpinningLoader />}
                <div className='send-confirmation-modal-header'>
                    <div className='send-confirmation-modal-title-wrapper'>
                        <div className='send-confirmation-modal-heading'>
                            Send PAD Confirmation Requests
                        </div>
                        <div className='send-confirmation-modal-subheading'>
                            {Store.getStoreData("module_config").pad_confirmation.assignment_help_text}
                        </div>
                    </div>
                    <div className='send-confirmation-modal-close'>
                        <FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/>
                    </div>
                </div>
                <div className='send-confirmation-modal-body'>
                    {
                        (()=>{
                            if(this.props.selected_records?.transaction?.length>0) return(
                                <div className='content-container'>
                                    <div className='content-header'>Transaction Reports</div>
                                    <div className='content-helptext'>{Store.getStoreData("module_config").pad_confirmation.report_title}</div>
                                    <div className='content-date-bar'>
                                        <div className='date-field-container'>
                                            <div className='date-field-label'>From</div>
                                            <div className='date-picker-container'>
                                                <DatePicker
                                                    onChange={this.handleFromDate}
                                                    clearIcon={null}
                                                    calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                                                    locale={"en-GB"}
                                                    value={this.state.trans_from_date !== null ? moment(this.state.trans_from_date, "DD/MM/YYYY").toDate() : null}
                                                    className='date-picker'
                                                />
                                            </div>
                                        </div>
                                        <div className='date-field-container'>
                                            <div className='date-field-label'>To</div>
                                            <div className='date-picker-container'>
                                                <DatePicker
                                                    onChange={this.handleToDate}
                                                    clearIcon={null}
                                                    calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                                                    locale={"en-GB"}
                                                    value={this.state.trans_to_date !== null ? moment(this.state.trans_to_date, "DD/MM/YYYY").toDate() : null}
                                                    className='date-picker'
                                                />
                                            </div>
                                        </div>
                                        <div className='date-field-container'>
                                            <div className='date-field-label'>Due</div>
                                            <div className='date-picker-container'>
                                                <DatePicker
                                                    onChange={this.handleDueDate}
                                                    clearIcon={null}
                                                    calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                                                    locale={"en-GB"}
                                                    value={this.state.trans_due_date !== null ? moment(this.state.trans_due_date, "DD/MM/YYYY").toDate() : null}
                                                    className='date-picker'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id='content-table-container'>
                                        <CSLTable add={false} processData={this.processPadConTable} zebra={false} tableRows={5}/>
                                    </div>
                                </div>
                            )
                        })()
                    }
                    {
                        (()=>{
                            if(this.props.selected_records?.holding?.length>0) return(
                                <div className='content-container'>
                                    <div className='content-header'>Holdings Reports</div>
                                    <div className='content-helptext'>{Store.getStoreData("module_config")?.holding_confirmation?.holding_report_title}</div>
                                    <div className='content-date-bar'>
                                        <div className='date-field-container'>
                                            <div className='date-field-label'>To</div>
                                            <div className='date-picker-container'>
                                                <DatePicker 
                                                    onChange={this.handleHoldingToDate}
                                                    clearIcon={null}
                                                    calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                                                    locale={"en-GB"}
                                                    value={this.state.holdingToDate !== null ? moment(this.state.holdingToDate, "DD/MM/YYYY").toDate() : null}
                                                    className='date-picker'
                                                />
                                            </div>
                                        </div>
                                        <div className='date-field-container'>
                                            <div className='date-field-label'>Due</div>
                                            <div className='date-picker-container'>
                                                <DatePicker 
                                                    onChange={this.handleHoldingDueDate}
                                                    clearIcon={null}
                                                    calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                                                    locale={"en-GB"}
                                                    value={this.state.holdingDueDate !== null ? moment(this.state.holdingDueDate, "DD/MM/YYYY").toDate() : null}
                                                    className='date-picker'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id='content-table-container'>
                                        <CSLTable add={false} processData={this.processHoldConTable} zebra={false} tableRows={5}/>
                                    </div>
                                </div>
                            )
                        })()
                    }
                </div>
                <div className='send-confirmation-modal-footer'>
                <div className='send-confirmation-modal-footer-button-container'>
                        <button className='send-confirmation-modal-footer-button close-button' onClick={this.closeModal} >Cancel</button>
                        {
                            (()=>{
                                if(this.state.deactivate_assignment === false){
                                    return <button className='send-confirmation-modal-footer-button save-button' onClick={this.assignConfirmation}>Assign</button>
                                }else{
                                    return <button className='send-confirmation-modal-footer-inactive-button'>Assign</button>
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
            <AlertBox alertParam={this.state.alert_param} />
            </>
        )
    }
}

export default SendConfirmationModal;
