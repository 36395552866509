import React from "react";
import styled from "styled-components";
import CslSimpleDropDown from "../../../Common/CslSimpleDropDown";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";

const GeneralContainer = styled.div`
  width: 90%;
  background-color: #ffffff;
`;
const GeneralInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const GeneralLabelLarge = styled.div`
  font-weight: 600;
  color: #595959;
  margin: 10px 0px 5px 0px;
  font-size : 13px;

`;

const RadioBlock = styled.div`
  margin-top: 5px;
`;

const FloatingSection = styled.div`
  float: left;
  box-sizing: border-box;
`;

const BoldLabel = styled.div`
  font-weight: 600;
`;

const Clearfix = styled.div`
  clear: both;
`;

const TextArea = styled.textarea`
  resize: none;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 10px;
  box-sizing: border-box;
  margin-top : 10px;
  font-size : 13px;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`;

const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;

const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

const Separator = styled.div`
  border : 1px solid rgb(200,200,200);
  background-color: #ffffff;
  margin : 5px auto;
  width : 95%;
`;

class PADConfirmation extends React.Component{

    state = {
        active_section_not_ready : true,
        pad_confirmation : {},
        selected_confirmation_frequency: { id : "0", name : ""},
        holdingConfirmation : {},
        selectedHoldingConfirmationFrequency: { id : "0", name : ""},
        brokerConfirmation : {},
        newBrokerConfirmation : {}
    }

    componentDidMount(){
        console.log("PADConfirmation Props : ", this.props);
        let pad_confirmation = JSON.parse(JSON.stringify(this.props.pad_confirmation));
        let selected_confirmation_frequency = pad_confirmation.confirmation_frequency.find(item => item.id === pad_confirmation.selected_confirmation_frequency)
        let holdingConfirmation = JSON.parse(JSON.stringify(this.props.holding_confirmation));
        let selectedHoldingConfirmationFrequency = holdingConfirmation?.holding_confirmation_frequency?.find(item => item.id === holdingConfirmation?.selected_holding_confirmation_frequency)
        let brokerConfirmation = JSON.parse(JSON.stringify(this.props.broker_confirmation));
        let newBrokerConfirmation = JSON.parse(JSON.stringify(this.props.new_broker_confirmation));
        this.setState({ pad_confirmation, selected_confirmation_frequency,  holdingConfirmation, selectedHoldingConfirmationFrequency, brokerConfirmation, newBrokerConfirmation, active_section_not_ready : false });
    }

    changeTextValue = (key) => (event) => {
        event.preventDefault();
        let pad_confirmation = JSON.parse(JSON.stringify(this.state.pad_confirmation));
        let holdingConfirmation = JSON.parse(JSON.stringify(this.state.holdingConfirmation));
        let brokerConfirmation = JSON.parse(JSON.stringify(this.state.brokerConfirmation));
        let newBrokerConfirmation = JSON.parse(JSON.stringify(this.state.newBrokerConfirmation));
        if(key in pad_confirmation) pad_confirmation[key] =  event.target.value;
        else if(key in holdingConfirmation) holdingConfirmation[key] =  event.target.value;
        else if(key in brokerConfirmation) brokerConfirmation[key] =  event.target.value;
        else if(key in newBrokerConfirmation) newBrokerConfirmation[key] =  event.target.value;
        this.props.updatePADConfirmation(pad_confirmation, holdingConfirmation, brokerConfirmation, newBrokerConfirmation);
        this.setState({ pad_confirmation, holdingConfirmation, brokerConfirmation, newBrokerConfirmation });
    }

    setSelection = (item_id) => {
        let pad_confirmation = JSON.parse(JSON.stringify(this.state.pad_confirmation));
        let selected_confirmation_frequency = pad_confirmation.confirmation_frequency.find(item => item.id === item_id);
        pad_confirmation.selected_confirmation_frequency = item_id;
        this.props.updatePADConfirmation(pad_confirmation, this.state.holdingConfirmation, this.state.brokerConfirmation, this.state.newBrokerConfirmation);
        this.setState({selected_confirmation_frequency, pad_confirmation});
    }

    setHoldingFreqSelection = (item_id) => {
      let holdingConfirmation = JSON.parse(JSON.stringify(this.state.holdingConfirmation));
      let selectedHoldingConfirmationFrequency = holdingConfirmation.holding_confirmation_frequency.find(item => item.id === item_id);
      holdingConfirmation.selected_holding_confirmation_frequency = item_id;
      this.props.updatePADConfirmation(this.state.pad_confirmation, holdingConfirmation, this.state.brokerConfirmation, this.state.newBrokerConfirmation);
      this.setState({selectedHoldingConfirmationFrequency, holdingConfirmation});
    }

    render(){
        console.log("PAD Confirmation State ==> ", this.state);
        if(this.state.active_section_not_ready === true) {
           return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
        }
        return(
            <GeneralContainer>
                <GeneralInnerContainer>
                    <GeneralLabel style={{ marginTop: "20px" }}>Assignment Help Text</GeneralLabel>
                    <TextArea rows="3" value={this.state.pad_confirmation.assignment_help_text} onChange={this.changeTextValue("assignment_help_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabelLarge>Transaction Reports</GeneralLabelLarge>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Report Title</GeneralLabel>
                    <TextArea rows="1" value={this.state.pad_confirmation.report_title} onChange={this.changeTextValue("report_title")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Transaction Report Frequency</GeneralLabel>
                    <div style={{ paddingTop: "10px", width: "40%" }}>
                        <CslSimpleDropDown options={this.state.pad_confirmation.confirmation_frequency} selected={this.state.selected_confirmation_frequency} setSelection={this.setSelection} />
                    </div>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Assignee Help Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.pad_confirmation.assignee_help_text} onChange={this.changeTextValue("assignee_help_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Attestation Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.pad_confirmation.attestation_text} onChange={this.changeTextValue("attestation_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Nil Transactions Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.pad_confirmation.nil_transactions_text} onChange={this.changeTextValue("nil_transactions_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Guidance Notes when Rejected</GeneralLabel>
                    <TextArea rows="4" value={this.state.pad_confirmation.rejection_guidance} onChange={this.changeTextValue("rejection_guidance")}></TextArea>
                </GeneralInnerContainer>

                <Separator />

                <GeneralInnerContainer>
                    <GeneralLabelLarge>New Broker Accounts Confirmation</GeneralLabelLarge>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Section Title</GeneralLabel>
                    <TextArea rows="1" value={this.state.newBrokerConfirmation?.new_broker_section_title} onChange={this.changeTextValue("new_broker_section_title")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>New Broker Attestation Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.newBrokerConfirmation?.new_broker_attestation_text} onChange={this.changeTextValue("new_broker_attestation_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Nil Brokers Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.newBrokerConfirmation?.nil_new_brokers_text} onChange={this.changeTextValue("nil_new_brokers_text")}></TextArea>
                </GeneralInnerContainer>

                <Separator />
                
                <GeneralInnerContainer>
                    <GeneralLabelLarge>Holding Reports</GeneralLabelLarge>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Report Title</GeneralLabel>
                    <TextArea rows="1" value={this.state.holdingConfirmation?.holding_report_title} onChange={this.changeTextValue("holding_report_title")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Holding Report Frequency</GeneralLabel>
                    <div style={{ paddingTop: "10px", width: "40%" }}>
                        <CslSimpleDropDown options={this.state.holdingConfirmation?.holding_confirmation_frequency} selected={this.state.selectedHoldingConfirmationFrequency} setSelection={this.setHoldingFreqSelection} />
                    </div>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Assignee Help Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.holdingConfirmation?.holding_assignee_help_text} onChange={this.changeTextValue("holding_assignee_help_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Attestation Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.holdingConfirmation?.holding_attestation_text} onChange={this.changeTextValue("holding_attestation_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Nil Holdings Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.holdingConfirmation?.nil_holdings_text} onChange={this.changeTextValue("nil_holdings_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Guidance Notes when Rejected</GeneralLabel>
                    <TextArea rows="4" value={this.state.holdingConfirmation?.holding_rejection_guidance} onChange={this.changeTextValue("holding_rejection_guidance")}></TextArea>
                </GeneralInnerContainer>

                <Separator />

                <GeneralInnerContainer>
                    <GeneralLabelLarge>Broker Accounts Confirmation</GeneralLabelLarge>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Section Title</GeneralLabel>
                    <TextArea rows="1" value={this.state.brokerConfirmation?.broker_section_title} onChange={this.changeTextValue("broker_section_title")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Broker Account Attestation Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.brokerConfirmation?.broker_attestation_text} onChange={this.changeTextValue("broker_attestation_text")}></TextArea>
                </GeneralInnerContainer>
                <GeneralInnerContainer>
                    <GeneralLabel>Nil Brokers Text</GeneralLabel>
                    <TextArea rows="4" value={this.state.brokerConfirmation?.nil_brokers_text} onChange={this.changeTextValue("nil_brokers_text")}></TextArea>
                </GeneralInnerContainer>
            </GeneralContainer>
        )
    }
}

export default PADConfirmation;