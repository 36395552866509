import React from "react";
import styled from "styled-components";
import ClearBoth from '../Common/ClearBoth';
import CSLTable from '../Common/CSLTable';
import { FaTimes, FaUserLock, FaExclamationTriangle } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import CslSimpleDropDown from '../Common/CslSimpleDropDown';
import OutsideClickHandler from "react-outside-click-handler";

const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 70%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 100px;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 100px;
  width: calc(100% - 10px);
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ada8;
  border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #143151;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-right: 20px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 200;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: rgb(243, 247, 251);
  padding: 25px;
  border-radius: 4px;
`;
const MRModalLabel = styled.div`
  color: #030303;
  font-weight: 600;
  margin-top: 15px;
  font-size: 15px;
  margin-bottom: 15px;
`;

const ExportMenu = styled.div`
  height : 30px;
  width : 100px;
  border : 1px solid #bbbbbb;
  text-align : center;
  color : #656565;
  font-size : 14px;
  font-weight : bold;
  position : relative;
  line-height : 30px;
  background-color : white;
  z-index : 2;
  top : 25px;
  right : 10px;
  cursor : pointer;
  box-shadow : 0px 0px 2px #656565;
`
const HoldingEllipsis = styled.div`
  paddingTop: 7px;
  font-size: 20px; 
  cursor: pointer;
  position : absolute;
  top : 0px;
  right : 5px;
`

class HoldingModal extends React.Component {

  state = {securities: {}, selected_contact_obj: null, ready: false, show_export : false, export_clicked : false};

  closeView = () => this.props.closeModal();

  componentDidMount() {
    const api = new APICall();
    const postdata = {command: "get_user_holding", requester_id: Store.getStoreData("loggedin_contact_id")};
    api.command(postdata, this.processData);
  }

  processData = (result) => {
    const company_users = Store.getStoreData("company_users");
    // const selected_contact_id = Store.getStoreData("loggedin_contact_id");
    const my_users = Store.getStoreData("my_users");
    let contacts = [];
    let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
    for (let user_id of my_users) {
      if (user_id.toString() in company_users_obj === true) contacts.push({id: company_users_obj[user_id.toString()].user_id, name: company_users_obj[user_id.toString()].name})
    }
    const selected_contact_obj = {id: company_users_obj[Store.getStoreData("loggedin_contact_id").toString()].user_id, name: company_users_obj[Store.getStoreData("loggedin_contact_id").toString()].name};
    this.setState({contacts, selected_contact_obj, securities: result, ready: true});
  }

  changeUser = (data) => {
		this.selected_contact_obj = this.state.contacts.find(item => item.id === data);
		const postdata = {command: "get_user_holding", requester_id: parseInt(this.selected_contact_obj.id)};
    const api = new APICall();
    api.command(postdata, this.afterUserChange);
	}

  afterUserChange = (result) => {
    this.setState({securities: result, selected_contact_obj: this.selected_contact_obj})
  }

  tableData = () => {
    let ret = { data: [], columns: [] };

    ret.columns = [
      {Header: "", width: 30, headerStyle: { textAlign: "center" }, filterable: false,
                          Cell: (row) => (
                            
                            <>
                              {row.original.source === 'private' && <FaUserLock style={{color: "#59C2EF", marginTop: "2px"}} />}
                              {row.original.is_restricted === true && <FaExclamationTriangle style={{color: "red", marginTop: "2px"}} />}
                            </>
                            // if (row.original.is_restricted === true) {
                            //   return <FaExclamationTriangle />
                            // }
                          )
      },
      { Header: "Name", accessor: "name", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Type", accessor: "type", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Identifier", accessor: "identifier", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Holding", accessor: "holding", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
    ];

    const module_config = Store.getStoreData('module_config');
    const securities = module_config.security_register;
    const security_types = module_config.security_types;

    if (Object.keys(this.state.securities).length !== 0) {
      for (let security_id in this.state.securities) {
        const name = securities[security_id.toString()].security_name;
        const type = security_types[securities[security_id.toString()].security.toString()].name;
        const identifier_types = securities[security_id.toString()].identifier_types;
        let identifier = [];
        for (let key in identifier_types) {
          identifier.push(identifier_types[key]);
        }
        identifier = identifier.join();
        const holding = this.state.securities[security_id].volume;
        const source = securities[security_id.toString()].source;
        ret.data.push({name, type, identifier, holding, source});
      }
    }
    
    return ret;
  };

  toggleMenu = (e) => {
    e.preventDefault();
    let show_export = this.state.show_export;
    show_export = show_export === true ? false : true;
    let export_clicked = this.state.export_clicked;
    export_clicked = false;
    this.setState({ show_export, export_clicked });
  }

  clickOutsideHandle = (e) => {
    e.preventDefault();
    let show_export = this.state.show_export;
    show_export = false;
    this.setState({ show_export });
  }

  exportClicked = (e) => {
    e.preventDefault();
    let export_clicked = this.state.export_clicked;
    export_clicked = true;
    this.setState({export_clicked})
  }

  preventExport = () => {
    this.setState({export_clicked: false});
  }

  render() {
    if (this.state.ready === false) {
      return (<div>Loading...</div>);
    }
    return (
      <MRModalContainer>
        <MRModalHeader>
          <MRModalHeaderText>{Store.getStoreData("role") !== "team" ? "Holding List" : "My Holdings"}</MRModalHeaderText>
          <MRModalHeaderCloseBtn onClick={this.closeView}>
            <FaTimes />
          </MRModalHeaderCloseBtn>
          <ClearBoth />
        </MRModalHeader>
        <MRModalBody>
          <div style={{display : "flex", flexDirection : "row", justifyContent : "flex-end", position : "relative", height : "30px"}}>
            <HoldingEllipsis id="pad_ellipsis" onClick={this.toggleMenu}>
              <FiMoreVertical />
            </HoldingEllipsis>
            {this.state.show_export === true && <OutsideClickHandler onOutsideClick={this.clickOutsideHandle}> <ExportMenu onClick={this.exportClicked}>Export</ExportMenu> </OutsideClickHandler>}
          </div>
          {Store.getStoreData("role") !== "team" && <MRModalLabel style={{ marginTop: "30px", width: "100%" }}>Select User</MRModalLabel>}
          {Store.getStoreData("role") !== "team" && <CslSimpleDropDown options={this.state.contacts} selected={this.state.selected_contact_obj} setSelection={this.changeUser} />}
          <div style={{marginTop: "20px"}}>
            <CSLTable add={false} processData={this.tableData} tableRows={5} exportClicked={this.state.export_clicked} preventExport={this.preventExport}/>
          </div>
        </MRModalBody>
      </MRModalContainer>
    );
  }
}

export default HoldingModal;
