import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { plusCircle } from 'react-icons-kit/fa/plusCircle';
import SingleCustomField from '../molecules/forms/SingleCustomField';
// import Utils from '../../Common/Utils';

const MainContainer = styled.div`
	width: 90%;
`;
const InnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const TitleBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;

class CustomFields extends React.Component
{
	static propTypes = {
        /** Initial Data */
        custom_fields: PropTypes.object,
        /** callback: custom field */
		updateCustomFields: PropTypes.func,
    };

	state = {custom_fields: null};

	componentDidMount(){
		this.setState( {custom_fields: this.props.custom_fields} );
	}

	componentDidUpdate(prevProps){
		if (this.props !== prevProps)
			this.setState({custom_fields: this.props.custom_fields});
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[key] = custom_fields[key] ? false : true;
		// this.setState({custom_fields});
		this.props.updateCustomFields(custom_fields);
	}

	addAdditionalField = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		// const randtxt = Utils.genKey(10);
		const randtxt = Math.floor(Math.random() * 9000000000) + 1000000000; // random 10 digits
		custom_fields.additional_fields.push({
			name: randtxt,
			nickname: "Untitled Field",
			type: "Alpha",
			dropdown_options: [],
			required: false,
			other: false
		});
		this.props.updateCustomFields(custom_fields);
	}

	render()
	{
		if (this.state.custom_fields === null)
			return (<div>Loading...</div>);

		return (
			<div>
				<MainContainer>
					<InnerContainer>
						<TitleBoldLabel>Enable Custom Fields</TitleBoldLabel>
						<SubHeading>Configure customisable fields to be utilised within this Module</SubHeading>
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('enabled')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('enabled')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return (
										<div>
											{ this.state.custom_fields.additional_fields.map((item, index) =>
												<SingleCustomField
													key={index}
													data={this.state.custom_fields}
													outer_data_key="additional_fields"
													inner_data={item}
													removable={true}
													updateCustomFields={this.props.updateCustomFields} /> )
											}
											<div style={{marginTop: "20px"}}>
												<FloatingSection><Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={plusCircle} size={20} onClick={this.addAdditionalField} /></FloatingSection>
												<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel style={{paddingTop: "1px"}}>Additional Custom Field</BoldLabel></FloatingSection>
												<Clearfix></Clearfix>
											</div>
										</div>
									);
								}
							})()
						}
					</InnerContainer>
				</MainContainer>
			</div>
		);
	}
}

export default CustomFields;
