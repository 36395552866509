import React from "react";
import styled from "styled-components";
import { FiMoreVertical } from "react-icons/fi";
import Store from "../../Common/Store";
import EventBus from "../../Common/EventBus";

const Container = styled.div`
    margin-left: 15px;
    font-size: 21px;
    font-weight: 600;
    color: #7c7a7a;
    position: fixed;
    background-color: #ffffff;
    padding: 0px 10px;
    width : 100px; 
    box-sizing : border-box;
    box-shadow: 0px 0px 5px rgb(167,166,166); 
    z-index: "1";
`;

class DropdownOptions extends React.Component {

    state = {menu_spec : {}}

    componentDidMount() {
        EventBus.registerEvent("show_dropdown", "menu_spec", this.showMenu);
        console.log("Menu Spec in Options : ", this.state.menu_spec);
    }

    closeDropdown = (event) => {
        event.preventDefault();
        // this.props.closeDropdown();
        EventBus.raiseEvent("show_dropdown", {});
        EventBus.raiseEvent("close_dropdown",false);
    }
    
    performAction = (entity_id, action_type) => (event) => {
        event.preventDefault();
        this.props.performAction(entity_id, action_type);
    }

    showMenu = (menu_spec) =>
    {
        this.setState({menu_spec});
    }
    render () {
        // console.log("In Options");
        //const module_config = Store.getStoreData("module_config");
        console.log("Menu Spec in Options in render : ", this.state.menu_spec);
        if(Object.keys(this.state.menu_spec).length === 0) return <div></div>;
        return (
            <Container onMouseLeave={this.closeDropdown} style={{ left : this.state.menu_spec.x-120, top : this.state.menu_spec.y}}>
                {
                    this.state.menu_spec.actions.map((item, index) => {
                        // console.log("index:", index);
                        if (this.state.menu_spec.show[item.action] === 1) {
                            return (
                                <div
                                    style={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                        paddingTop: index === 0 ? "10px" : "none",
                                        paddingBottom: "10px"
                                    }}
                                    key={index}
                                    onClick={this.performAction(this.state.menu_spec.entity_id, item.action)}
                                >
                                    {item.name}
                                </div>
                            )
                        }
                    })
                }
            </Container>  
        );
    }
}

export default DropdownOptions;