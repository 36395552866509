import React from 'react';
import styled from "styled-components";
import { FaTimes, FaPlusCircle, FaPencilAlt } from "react-icons/fa";
import CSLTable from "../CSLTable/CSLTable";
import APICall from "../../../../Common/APICall";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import ManageRestrictedRegister from './modals/ManageRestrictedRegister';
import SearchableSelect from 'react-select';
import ToggleSwitch from "./../../../Common/ToggleSwitch";
import Store from "./../../../../Common/Store";
import './SearchableSelect.css';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from './../../../../Common/XcelLoader';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 70%;
	position: absolute;
	z-index: 1001;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 100px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 33px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 33px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F0F6FC;
	padding: 20px;
	border-radius: 4px;
`;
const ClearBoth = styled.div`
	clear: both;
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalFooter = styled.div`
	padding: 17px;
	background-color: white;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
  	border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    padding: 10px 20px;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
	color: #2d2c2c;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	padding: 10px 20px;
	font-weight: 600;
`;

const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const EditButton = styled.div`
    width: 30px;
    height: 30px;
    margin: 0px;
    padding-left: 10px;
    font-size: 16px;
    cursor: pointer;
    float: left;
`
const SectionNameInput = styled.input`
    height: 18px;
    width: 60%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #282828;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 5px;
    padding: 6px;    
    margin-top: -8px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
class ManageSecurityRegister extends React.Component {
    state = {
        ready: false,        
        tableHeaderText: 'Default List, applicable to all employees',
        filters: null,
        security_register: null,
        type: {},
        show_modal: null,
        is_editable: false,
        is_editing_name: false,
        employees_list: [],
        selected_employees: [],
        isActive: 1,
        employee_object: {},
        is_data_add: false,
        res_id: 0,
        ischange: false,
        curid: null
    }

    constructor() {
      super();      
      this.api = new APICall();
    }

    componentDidMount(){ 
        let filters = this.props.filters
        let security_register = this.props.security_register;
        let tableHeaderText = 'Default List, applicable to all employees';
        let mRestrictedActiveInactive = this.props.mRestrictedActiveInactive
        let cur_active_users = Store.getStoreData('cur_active_users');
        console.log("Cur_id===>", this.props.curid)
        let is_editable = false;
        let selected_employees = [];
        let employee_object = {}
        let isActive = 1;
        let res_id = 0
        let ischange = false
        if(this.props.curid !== null){
          if(this.props.curid !== '0'){
            is_editable = true;
            let selected_mRestrictedlist = mRestrictedActiveInactive[this.props.curid.toString()]
            let employee_ids = selected_mRestrictedlist.employee_ids; 
            employee_object = employee_ids           
            for(let k in employee_ids){
              selected_employees.push({ 'value': k, 'label': cur_active_users[k.toString()] , "fontWeight": "600"})
            }
            tableHeaderText = selected_mRestrictedlist.name;
            isActive = selected_mRestrictedlist.is_active;
            res_id = this.props.curid
          }else{
            res_id = this.props.curid
          }          
        }else{          
          // let new_cout = 1
          // for(let k in mRestrictedActiveInactive){
          //   if(mRestrictedActiveInactive[k.toString()].is_active === 1){
          //     let name_text = mRestrictedActiveInactive[k].name;
          //     if(name_text.match("New Restricted List")){
          //       new_cout++
          //     }
          //   }
          // }
          // tableHeaderText = 'New Restricted List #'+ new_cout;
          let mResLength = Object.keys(mRestrictedActiveInactive).length + 1
          tableHeaderText = 'New Restricted List #'+ mResLength;
          is_editable = true;
          ischange = true
        }
        console.log("is_editable===>", is_editable)        
        let employees_list = [];
        for(let k in cur_active_users){
          employees_list.push({ 'value': k, 'label': cur_active_users[k.toString()] })
        }
        employees_list.sort(function(a,b) {return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);} );

        this.setState({ready: true, 
          filters, 
          security_register, 
          tableHeaderText, 
          is_editable, 
          employees_list, 
          selected_employees, 
          employee_object, 
          isActive, 
          res_id, 
          curid: this.props.curid,
          ischange
        })
    }   

    closeView = () => {
      if(this.state.ischange){
        this.props.refreshAndCloseModal();
      }else{
        this.props.closeModal();
      }        
    };

    submitView = () => {      
      const postData = {};
      postData['id'] = this.state.curid
      postData['list_name'] = this.state.tableHeaderText
      postData['is_active'] = this.state.isActive
      postData['employees'] = this.state.selected_employees
      if(this.state.curid !== null){
        if(this.state.curid !== '0'){
          let mRestrictedActiveInactive = this.props.mRestrictedActiveInactive
          let selected_mRestrictedlist = mRestrictedActiveInactive[this.state.curid.toString()]
          let employee_ids = selected_mRestrictedlist.employee_ids; 
          console.log("updt_emply==>", this.state.employee_object)
          
          let up_cur_res = {}
          for(let c in this.state.employee_object){
            if(c.toString() in employee_ids && this.state.employee_object[c] === 1){
              up_cur_res[c] = {'id': c, 'status': 'no_change'}
            }
            if(c.toString() in employee_ids && this.state.employee_object[c] === 0){
              up_cur_res[c] = {'id': c, 'status': 'update'}
            }
            if(!(c.toString() in employee_ids) && this.state.employee_object[c] === 1){
              up_cur_res[c] = {'id': c, 'status': 'insert'}
            }
          }
          console.log("up_cur_res===>", up_cur_res)
          postData['cur_emp_ids'] = up_cur_res;
          postData['command'] = "update_restricted_list";
          postData['restricted_list_id'] = this.state.curid;   
		      console.log("postData===>", postData)
          this.api.command(postData, this.afterUpdateRestrictedList);
        }                
      }else{
        //create
        postData['command'] = "save_restricted_list";
        console.log("postData", postData)
        this.api.command(postData, this.afterSaveRestrictedList);
      }      
    }

    afterSaveRestrictedList = (result) => {
    	console.log("result", result)   
      alert(result.error_msg); 
      this.setState({is_data_add: true, res_id: result.res_id, curid: result.res_id})
      
      // this.props.refreshManageRestricted(); 	
    }
    afterUpdateRestrictedList = (result) => {
    	console.log("result", result)   
      alert(result.error_msg); 
      // this.props.refreshManageRestricted(); 	
    }

    performAction = (role_id, action_type) => {
        console.log("role_id:", role_id);
        console.log("action_type:", action_type);
        if (action_type === "edit") {
          this.openModal(role_id);
        }
        if (action_type === "derestrict") {
          // this.derestrictSecurity(role_id);
          const postData = {command: "de_restricted_security", selected_security_id: role_id, selected_mrestricted_id: this.props.curid};
          console.log("postData===>", postData)
          this.api.command(postData, this.afterDeRestrictedValue);
        }
        if (action_type === "delete") {
          if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
            const postData = {
              command: "delete_lookup",
              lookup_id: "security_register",
              check_key: "security_id",
              id: role_id              
            };
            console.log("postData:", postData);
            this.api.command(postData, this.afterRemove);
          }
        }
        if(action_type === "export"){
          let wb = {SheetNames:[], Sheets:{}};        
          let open_tasks = Store.getStoreData('table_cur_data') === null ? [] : Store.getStoreData('table_cur_data'); 
          wb.SheetNames.push("Restricted Lists"); 
          wb.Sheets["Restricted Lists"] = XLSX.utils.json_to_sheet(XcelLoader.restricted_lists(open_tasks, this.state.filters)); 
          const format = "DD-MM-YYYY HH:mm:ss"
          let tm = moment().format(format);  
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], {type: fileType});
          FileSaver.saveAs(data, 'Restricted_list' + '-' + tm + fileExtension);
        }
    }

    openModal = (id) => {
        // event.preventDefault();
        let type = this.state.security_register[id.toString()];
        let t = {};
        t['id'] = id;
        t['status'] = type.is_active;
        t['name'] = type.name;
        t['desc'] = type.description;
        t['lookup_json'] = JSON.parse(type.lookup_json);
        this.setState({ show_modal: 'roletype', type: t });
      };

    afterRemove = (result) => {
        console.log(result);
        if (result.error_msg === "exists") {
          alert("This item cannot be removed as it is associated with tasks");
        } else {
          let security_register = JSON.parse(JSON.stringify(this.state.security_register));
          delete security_register[result.result.toString()];
          this.setState({security_register});
          console.log("sourav", security_register)
          this.props.updateSecurityRegister(security_register)
        }
      }

      afterDeRestrictedValue = (result) => {
        console.log(result);
        alert(result.error_msg); 
        let security_register = JSON.parse(JSON.stringify(this.state.security_register));
        delete security_register[result.result.toString()];
        this.setState({security_register, ischange: true});
        console.log("sourav", security_register)
        // this.props.updateSecurityRegister(security_register)
      }

    derestrictSecurity = (sec_id) => {
        let security = this.state.security_register[sec_id.toString()];
        console.log("security:", security);
        const postData = { command: "save_lookup"};
        postData['lookup_json'] = JSON.parse(security.lookup_json);
        postData['lookup_json']['is_restricted'] = false;
        postData['name'] = security.name;
        postData['status'] = security.is_active;
        postData['description'] = security.desc;
        postData['lookup_id'] = 'security_register';
        postData['id'] = sec_id;
        console.log("postData", postData)
        this.api.command(postData, this.refreshParent);
      }

      refreshParent = () => {
        this.setState({ show_modal: null });
        const postData = { command: "get_security_lookup_and_restricted_lists"};
        postData['lookup_id'] = this.props.lookup_id;
        postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
        console.log("postData===>", postData)
        this.api.command(postData, this.getroleTypes);
      }

    tableData = () => {        
        let ret = {data: [], columns: []};
        const actionsExport = [{name: "Export", action: "export"}];
        const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}, {name: "Derestrict", action: "derestrict"}];
        ret.columns =[                        
                        {Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left'}},					
                        {Header: 'Type', accessor: 'type', minWidth: 80, headerStyle: {textAlign: 'left'}},
                        {Header: 'Currency', accessor: 'currency', minWidth: 80, headerStyle: {textAlign: 'left'}}                        
                    ];
  
        for(let key in this.state.filters){
            if(this.state.filters[key].lookup_id === "security_identifier_types"){
                let addColums = {Header: this.state.filters[key].name , accessor: key, minWidth: 80, headerStyle: {textAlign: 'left'}} 
                ret.columns.push(addColums)
            }
        }
        ret.columns.push({Header: 'Reason', accessor: 'reason', minWidth: 200, headerStyle: {textAlign: 'left'}});
    
        
        let c2 = {
          Header: <EllipsisMenuModConfig entity_id={0} actions={actionsExport} performAction={this.performAction} />,
          Cell: (row) => (
            <div style={{marginTop: "-8px"}}><EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
          ),
          width: 55,
          headerStyle: { textAlign: "center" },
          filterable: false,
          sortable: false
        };
        ret.columns.push(c2)
        
        let sl = 0 
        console.log("this.props.curid===>", this.state.curid)
        let curid = this.state.curid;
        for(let key in this.state.security_register){
            let lookup_json = JSON.parse(this.state.security_register[key].lookup_json)
            if (this.state.security_register[key].is_restricted === true && curid !== null && curid.toString() in this.state.security_register[key].restricted_list_ids) {
              console.log("lookup_json:", lookup_json);
              sl++;            
              let elem = {
                  slno: sl, 
                  id: key, 
                  name: this.state.security_register[key].name, 
                  type: ('security_type_id' in lookup_json) ? this.state.filters[lookup_json.security_type_id].name : "", 
                  currency: ('currency_id' in lookup_json) ? this.state.filters[lookup_json.currency_id].name : "", 
                  status: this.state.security_register[key].is_active,
                  status_value : this.state.security_register[key].is_active === 1 ? "Active" : "In-Active",
                  reason: "reason" in lookup_json === true ? lookup_json.reason : ""
              };
    
              if(Object.keys(lookup_json.identifier_types).length !== 0){
                  let identifierTypes = lookup_json.identifier_types;
                  console.log("identifierTypes===>", identifierTypes)
                  for(let k in identifierTypes){
                      elem[k.toString()] = identifierTypes[k.toString()]
                  }
              }
    
              ret.data.push(elem);
            }
        }   
        Store.updateStore('table_cur_data', ret.data)     
        return ret;
    }

    closeModal = (event) => {    
        this.setState({ show_modal: null });
    }; 

    getroleTypes = (result) => {
        console.log("result", result)
        let filters = result.filter;
        let security_register = result.security_register;
        this.setState({filters:filters, security_register: security_register, ready : true, show_modal: false, ischange: true})
        //this.props.updateSecurityRegister(security_register)
    }

    addType = (event) => {
        event.preventDefault();
        let type = {};
        type['id'] = 0
        type['status'] = 1
        type['name'] = ""
        type['desc'] = ""
        type['lookup_json'] = {};
        type['lookup_json']['identifier_types'] = {};
        this.setState({show_modal:'roletype', type: type});
    };

    addTypeAlert = (event) => {
      event.preventDefault();
      alert("Please add the Manage Restricted list first then you can add the restricted list.");
    }

    editSectionName = () => {  
      this.setState({is_editing_name: true})
  }

  changeNickName = (event) => {    
    this.setState({tableHeaderText: event.target.value, ischange: true})
  }

  // limit(event)
  // {
  //     var max_chars = 2;
          
  //     if(element.value.length > max_chars) {
  //         element.value = element.value.substr(0, max_chars);
  //     }
  // }

  handleSelectEmplyee = (data) => {        
    console.log("data==>", data)
    let se_obj = {}
    for(let d of data){
      se_obj[d.value.toString()] = 1
    }

    console.log("se_obj==>", se_obj)
    let updt_emply = {}
    for(let k in this.state.employee_object){
      if(k.toString() in se_obj){
        updt_emply[k.toString()] = 1
      }else{
        updt_emply[k.toString()] = 0
      }
    }

    let new_emp = {}
    for(let k in se_obj){
      if(!(k in updt_emply)){
        new_emp[k.toString()] = 1
      }
    }
    let merged = {...updt_emply, ...new_emp};
    this.setState({selected_employees: data, employee_object: merged, ischange: true})
  }

  toggleStatus = () => {   
    let isActive = this.state.isActive === 1 ? 0 : 1; 
    this.setState({isActive, ischange: true})
  }

    render () {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }	
		return (
			<div>                
				<ModalWraper>
                {
                    (() => {
                    if(this.state.show_modal){
                        return <ManageRestrictedRegister closeModal={this.closeModal} type = {this.state.type} filters = {this.state.filters} refreshParent={this.refreshParent} changeWidgh={true} res_id={this.state.res_id} isManageRes={true}/>
                    }
                    })()
                }
	                <MRModalContainer>
	                    <MRModalHeader>
							<MRModalHeaderText>Manage Restricted Securities List</MRModalHeaderText>
							<MRModalHeaderCloseBtn onClick={this.closeView}>
					            <FaTimes />
					        </MRModalHeaderCloseBtn>
					        <ClearBoth />					        
						</MRModalHeader>
	                    <MRModalBody>
                            <TypesContainer>          
                                <TypesInnerContainer> 
                                    <div style={{width: "100%", marginTop: "1%"}}>
                                        <div style={{ display: "inline-block", width: "90%", float: "left"}}>  
                                            {
                                              (() => {
                                                if(this.state.is_editable){
                                                  if(this.state.is_editing_name){
                                                    return(<div>
                                                      <SectionNameInput value={this.state.tableHeaderText} onChange={this.changeNickName} maxLength={150}/>                                                      
                                                    </div>)
                                                  }else{
                                                    return(<div>
                                                      <TypesBoldLabel style={{float: "left", marginTop: "2px", width: "60%", wordWrap: "break-word", marginBottom: "10px"}}>{this.state.tableHeaderText}</TypesBoldLabel>
                                                      <EditButton title='Edit name' onClick={() => this.editSectionName()}><FaPencilAlt /></EditButton>
                                                    </div>)
                                                  }
                                                }else{
                                                  return(<TypesBoldLabel style={{float: "left", marginTop: "2px"}}>{this.state.tableHeaderText}</TypesBoldLabel>)
                                                }
                                              })()
                                            }
                                            
                                        </div>
                                        {
                                          (() => {
                                            if(this.props.curid !== null){                                              
                                                return(<div style={{ display: "inline-block", width: "10%"}}>
                                                          <div onClick={this.addType} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}><FaPlusCircle /></div>
                                                  </div>)                                                        
                                            }else{  
                                              if(this.state.is_data_add){
                                                return(<div style={{ display: "inline-block", width: "10%"}}>
                                                          <div onClick={this.addType} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}><FaPlusCircle /></div>
                                                  </div>)
                                              }else{
                                                return(<div style={{ display: "inline-block", width: "10%"}}>
                                                  <div onClick={this.addTypeAlert} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}><FaPlusCircle /></div>
                                                </div>)
                                              }                                              
                                            }
                                          })()
                                        }                                        
                                        
                                    </div>
                                    {
                                      (()=> {
                                        if(this.state.is_editable){
                                          return(<div>
                                              <div style={{width: "100%", marginTop: "10px"}}>
                                                  <div style={{ display: "inline-block", width: "80%", float: "left"}}> 
                                                    <MRModalLabel>Select the employees this Restricted List applies to</MRModalLabel>
                                                  </div>                                        
                                                  <div style={{ display: "inline-block", width: "20%"}}>
                                                    <div style={{ float:"right"}}><ToggleSwitch checked={this.state.isActive === 1 ? true : false} onClick={this.toggleStatus}/></div>
                                                    <div style={{ float:"right", marginRight: "10px"}}>Active</div>
                                                                                                 
                                                  </div>
                                                  
                                              </div> 
                                              <div style={{width: "100%", marginTop: "5px"}}>
                                              <SearchableSelect isMulti options={this.state.employees_list} placeholder="Select employees" value={this.state.selected_employees} onChange={this.handleSelectEmplyee} />
                                              </div>
                                          </div>)
                                        }
                                      })()
                                    }   
                                </TypesInnerContainer>
                                
                                <CSLTable add={false} processData={this.tableData} tableRows={10} />
                            </TypesContainer>
	                    </MRModalBody>
	                    <MRModalFooter>
                          {
                            (() => {
                              if(this.state.is_editable){
                                return <MRModalNextBtn style={{float: "right"}} onClick={this.submitView}>SUBMIT</MRModalNextBtn>
                              }
                            })()
                          }	                        
	                        <CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeView}>CLOSE</CloseButton>
	                        <ClearBoth />
	                    </MRModalFooter>
	                </MRModalContainer>
	            </ModalWraper>
            </div>
        )
    }
}
export default ManageSecurityRegister;