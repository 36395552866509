let automatic_rejection_advices = {
        introductory_text_pef : {
          identifier: "introductory_text_pef",
          header: "Introductory text – PAD Entry Form",
          text: "Based on the information you have provided your PAD request may be declined for the following reason(s):",
          included: false,
          pos: 0,
          show_on_entry_form: true
        },
        introductory_text_paf : {
          identifier: "introductory_text_paf",
          header: "Introductory text – PAD Approval Form",
          text: "Based on the information provided the PAD request may be declined for the following reason(s):",
          included: false,
          pos: 1,
          show_on_entry_form: true
        },
        short_trade : {
          identifier: "short_trade",
          header: "Short Trade",
          text: "It appears this trade could be a ‘short’ trade which is against the company policy",
          included: false,
          pos: 2,
          show_on_entry_form: true
        },
        breaches_confirmation : {
          identifier: "breaches_confirmation",
          header: "Breaches Confirmation",
          text: "Confirmation has not been provided that this trade does not breach any regulatory rules or company policy",
          included: false,
          pos: 3,
          show_on_entry_form: true
        },
        restricted_security : {
          identifier: "restricted_security",
          header: "Restricted Security",
          text: "The security is on the company’s Restricted List.",
          included: false,
          pos: 4,
          show_on_entry_form: true
        },
        already_tread_hp : {
          identifier: "already_tread_hp",
          header: "Already traded within the Holding Period",
          text: "A PAD request for the same security was raised within the Holding Period.",
          included: false,
          pos: 5,
          show_on_entry_form: true
        },
        past_trade_date : {
          identifier: "past_trade_date",
          header: "Past Trade Date",
          text: "The trade date is in the past and PAD requests can only be submitted prior to trading.",
          included: false,
          pos: 6,
          show_on_entry_form: true
        },
        approval_validity_window_exceeded : {
          identifier: "approval_validity_window_exceeded",
          header: "Approval Validity Window exceeded",
          text: "The trade date was executed outside of the approval validity window.",
          included: false,
          pos: 7,
          show_on_entry_form: true
        }
}
      
module.exports = automatic_rejection_advices