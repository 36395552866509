import React from 'react';
import moment from 'moment';
import "./AddNewBrokerModal.css";
import CSLToggle from '../module_config/CSLToggle';
import APICall from './../../../Common/APICall';
import DatePicker from "react-date-picker";
import { FaTimes, FaCalendarAlt } from "react-icons/fa";
import { faL } from '@fortawesome/pro-solid-svg-icons';
import YesNoBox from '../../Common/YesNoBox';

class AddNewBrokerModal extends React.Component{

    state = {
        ac_opening_date : moment().format("DD/MM/YYYY"),
        ac_closing_date : null,
        broker_data : {},
        ready : false,
        show_alert : false,
        alertParam : null
    }

    componentDidMount(){
        console.log("Add new broker props : ", this.props);
        let broker_data = this.props.broker_data;
        let ac_opening_date = broker_data.open_date;
        let ac_closing_date = broker_data.close_date;
        this.setState({ broker_data, broker_data_from_prop : JSON.parse(JSON.stringify(broker_data)), ac_opening_date, ac_closing_date, ready : true });
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    handleTextChange = (event) => {
        let broker_data = JSON.parse(JSON.stringify(this.state.broker_data));
        broker_data[event.target.name] = event.target.value;
        this.setState({ broker_data });
    }

    toggleStatus = () => {
        //event.preventDefault();
        let broker_data = JSON.parse(JSON.stringify(this.state.broker_data));
        broker_data["active"] = broker_data["active"] === true ? false : true
        this.setState({ broker_data })
    }

    handleOpeningDate = (e) => {
        let ac_opening_date = moment(e).format("DD/MM/YYYY");
        this.setState({ ac_opening_date: moment(e).format("DD/MM/YYYY") });
        let ac_closing_date = this.state.ac_closing_date;
        let alertParam = null;
        let show_alert = false;
        if(ac_closing_date !== null){
            if(moment(ac_opening_date,"DD/MM/YYYY").unix() > moment(ac_closing_date,"DD/MM/YYYY").unix()){
                ac_closing_date = null;
                
                alertParam = {
                    "header" : "Closing Date Alert",
                    "subheader" : "",
                    "msg" : "Account Closing Date cannot be less than Account Opening Date",
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                // this.setState({ show_alert : true, alertParam});
                //alert("Mandatory fields are marked with asterisk. Please fill in the fields.");
                //return false;
                show_alert = true;
            }
        }
        //console.log("AC_CLOSE_DATE : ", ac_closing_date);
        this.setState({ac_opening_date, ac_closing_date, show_alert, alertParam});
    }

    handleClosingDate = (e) => {
        //let ac_opening_date = moment(e).format("DD/MM/YYYY");
        //this.setState({ ac_opening_date: moment(e).format("DD/MM/YYYY") });
        let ac_closing_date = moment(e).format("DD/MM/YYYY");
        let ac_opening_date = this.state.ac_opening_date;
        let alertParam = null;
        if(moment(ac_opening_date,"DD/MM/YYYY").unix() > moment(ac_closing_date,"DD/MM/YYYY").unix()){
            
            alertParam = {
                "header" : "Closing Date Alert",
                "subheader" : "",
                "msg" : "Account Closing Date cannot be less than Account Opening Date",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            this.setState({ show_alert : true, alertParam});
            return;
            //alert("Mandatory fields are marked with asterisk. Please fill in the fields.");
            //return false;
            
        }
        this.setState({ ac_closing_date });
        //console.log("AC_CLOSE_DATE : ", ac_closing_date);
    }

    closeAlert = () => {
		//console.log("Save and Close Alert Closed for Registration")
		this.setState({ show_alert : false, alertParam : null })
	}

    saveBrokerData = (event) => {
        event.preventDefault();
        let broker_data = JSON.parse(JSON.stringify(this.state.broker_data));
        if(broker_data.broker_name.trim() === "") {
            let alertParam = {
                "header" : "Broker Data Save Error",
                "subheader" : "",
                "msg" : "Broker Name cannot be blank",
                "closefunc" : this.closeAlert,
                "buttons" : {
                    "1" : {
                        "caption" : "OK",
                        "bgcolor" : "#4472C4",
                        "color" : "white",
                        "callback" : this.closeAlert
                    },
                }
            }
            this.setState({ show_alert : true, alertParam});
            return;
        }
        broker_data.open_date = moment(this.state.ac_opening_date, "DD/MM/YYYY").unix();
        broker_data.close_date = this.state.ac_closing_date === null ? -1 : moment(this.state.ac_closing_date, "DD/MM/YYYY").unix();
        broker_data.active = broker_data.active === true ? 1 : 0;
        let action = "insert";
        if(broker_data.id !== 0){
            action="update"
            let broker_data_from_prop = JSON.parse(JSON.stringify(this.state.broker_data_from_prop));
            //console.log("Broker Data : ", broker_data);
            //console.log("Broker Data from Prop : ", broker_data_from_prop);
            broker_data_from_prop.close_date = broker_data_from_prop.close_date === null ? -1 : moment(broker_data_from_prop.close_date, "DD/MM/YYYY").unix()
            broker_data_from_prop.active = broker_data_from_prop.active === true ? 1 : 0;
            //debugger;
            // console.log(broker_data.broker_name.trim() === broker_data_from_prop.broker_name.trim());
            // console.log(broker_data.account_type.trim() === broker_data_from_prop.account_type.trim());
            // console.log(broker_data.account_name.trim() === broker_data_from_prop.account_name.trim());
            // console.log(broker_data.account_id.trim() === broker_data_from_prop.account_id.trim());
            // console.log(broker_data.open_date === moment(broker_data_from_prop.open_date, "DD/MM/YYYY").unix());
            // console.log(broker_data.close_date === broker_data_from_prop.close_date);
            // console.log(broker_data.active === broker_data_from_prop.active);
            if( broker_data.broker_name.trim() === broker_data_from_prop.broker_name.trim() &&
                broker_data.account_type.trim() === broker_data_from_prop.account_type.trim() &&
                broker_data.account_name.trim() === broker_data_from_prop.account_name.trim() &&
                broker_data.account_id.trim() === broker_data_from_prop.account_id.trim() &&
                broker_data.open_date === moment(broker_data_from_prop.open_date, "DD/MM/YYYY").unix() &&
                broker_data.close_date === broker_data_from_prop.close_date &&
                broker_data.active === broker_data_from_prop.active ) {
                    action="no change";
            }   
        }
        this.props.saveBrokerData(broker_data,action);
    }

    render(){
        console.log("Add New Broker State : ", this.state)
        if(this.state.ready === false) return(<div></div>);
        return(
            <div className='add-new-broker-modal-wrapper'>
                {this.state.alertParam !== null && this.state.show_alert === true && <YesNoBox alertParam={this.state.alertParam}/>}
                <div className='add-new-broker-modal-header'>
                    <div className='add-new-broker-modal-title-wrapper'>
                        <div className='add-new-broker-modal-heading'>
                            Broker List
                        </div>
                        <div className='add-new-broker-modal-subheading'>
                            Use this form to add or update your registered Broker Accounts
                        </div>
                    </div>
                    <div className='add-new-broker-modal-close'>
                        <FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/>
                    </div>
                </div>
                <div className='add-new-broker-modal-body'>
                    <div className='label'>Broker Name<span className='mandatory-asterisk'>*</span></div>
                    <input type='text' className='text-input' name='broker_name' onChange={this.handleTextChange} value={this.state.broker_data.broker_name} />
                    <div className='label'>Account Type</div>
                    <input type='text' className='text-input' name='account_type' onChange={this.handleTextChange} value={this.state.broker_data.account_type}/>
                    <div className='label'>Account Name at Broker</div>
                    <input type='text' className='text-input' name='account_name' onChange={this.handleTextChange} value={this.state.broker_data.account_name}/>
                    <div className='label'>Account ID at Broker</div>
                    <input type='text' className='text-input' name='account_id' onChange={this.handleTextChange} value={this.state.broker_data.account_id}/>
                    <div className='label'>Account Opening Date</div>
                    <DatePicker 
                        // onChange={(e) => {
                        //     console.log("DATE : ", e)
                        //     this.setState({ ac_opening_date: moment(e).format("DD/MM/YYYY") }); //, ac_closing_date: moment(this.state.ac_closing_date).format("DD/MM/YYYY")
                        // }}
                        onChange={this.handleOpeningDate}
                        clearIcon={null}
                        calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                        locale={"en-GB"}
                        value={moment(this.state.ac_opening_date, "DD/MM/YYYY").toDate()}
                        className='date-picker'
                    />
                    <div className='label'>Account Closing Date</div>
                    <DatePicker 
                        // onChange={(e) => {
                        //     this.setState({ ac_closing_date: moment(e).format("DD/MM/YYYY")}); //, ac_opening_date: moment(this.state.ac_opening_date).format("DD/MM/YYYY")
                        // }}
                        onChange={this.handleClosingDate}
                        clearIcon={null}
                        calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                        locale={"en-GB"}
                        value={this.state.ac_closing_date !== null ? moment(this.state.ac_closing_date, "DD/MM/YYYY").toDate() : null}
                        className='date-picker'
                    />
                    <div className='label'>Active</div>
                    <div className='toggle-container'>
                        <CSLToggle id='broker-active' checked={this.state.broker_data.active} onClick={this.toggleStatus} />
                    </div>
                </div>
                <div className='add-new-broker-modal-footer'>
                <div className='add-new-broker-modal-footer-button-container'>
                        <button className='add-new-broker-modal-footer-button close-button' onClick={this.closeModal} >Close</button>
                        <button className='add-new-broker-modal-footer-button save-button' onClick={this.saveBrokerData}>Save</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddNewBrokerModal;