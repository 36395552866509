import React from "react";
import styled from "styled-components";
import APICall from "../../../../Common/APICall";
import { FaPlusCircle } from "react-icons/fa";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";
import { Container, Draggable } from 'react-smooth-dnd';
import approval_options from './approval_options.js'

const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const HelpTextDiv = styled.div`
  background-color: #ffffff;
  border: 1px solid #d3d5d7;
  border-radius: 4px;
  padding: 17px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12px;
  color: #6e6e6e;
  line-height: 20px;
`;

const TableMain = styled.table`
  width: 100%;  
  border: 1px solid #CCC;
  border-collapse: collapse;
`;
const TableHeaderTr = styled.tr`
  height: 45px;
  background-color: #E8EAF6;
  border: none;
`;
const TableTr = styled.tr`
  height: 45px;
  border: none;
`;
const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`;

const RoleSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 35px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #c4c4c4;

  // box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;

class ApprovalRulesTable extends React.Component {  

  state = {
    ready: false,
    array_approval_rule: [],
    selected_decision_option: null,
    selected_role_type: null,
    role_types: {}    
  };

  constructor() {
    super();    
    this.api = new APICall();
  }

  componentDidMount() {   
    const postData = { command: "get_approval_rules"};    
    this.api.command(postData, this.getApprovalRules);    
  }

  getApprovalRules = (result) => {
    console.log("result###", result)
    let role_types = this.props.role_types
    this.setState({ready: true, array_approval_rule: result.result, role_types})   
  }

  componentDidUpdate(prevProps) {
    if(this.props === prevProps) return  
    let role_types = this.props.role_types
    this.setState({ready: true, role_types})
  } 

  performAction = (curid, action_type) => {
    console.log("role_id:", curid);
    console.log("action_type:", action_type); 

    let array_approval_rule = this.state.array_approval_rule;
    let update_array_approval_rule = [];    
    for(let d of array_approval_rule){
      let obj = d;
      if(parseInt(d.id) !== parseInt(curid)){
        update_array_approval_rule.push(obj);
      }      
    }
    
    this.setState({array_approval_rule: update_array_approval_rule})

    this.deleteDragApprovalRules(this.factory(update_array_approval_rule), curid);    
  }  

  deleteDragApprovalRules = (value, curid) => {    
    const postData = {command: "delete_app_rule", id: curid, value: value};
    this.api.command(postData, this.afterDeleteApprovalRules);    
  }

  afterDeleteApprovalRules = (result) => {
    console.log("result==>", result)    
  }

  applyDrag = (arr, dragResult) => {
      console.log("arr===>", arr);
      console.log("dragResult===>", dragResult);
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
          itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
          result.splice(addedIndex, 0, itemToAdd);
      }

      console.log("result==>", result)
      console.log("this.factory(result)==>", this.factory(result))      
      this.updateDragApprovalRules(this.factory(result));		
      return result;
  };

  updateDragApprovalRules = (value) => {
    const postData = {command: "update_drag_app", value: value};
    this.api.command(postData, this.afterSetDragApprovalRules);  
  }

  afterSetDragApprovalRules = (result) => {
    console.log("result==>", result)
  }

  factory = (a) => {
      //debugger;
      const obj = {}
      let c = a.forEach((q, i) => {
          obj[i + 1] = q
      })
      return obj;
  }

  handleAllChange = (evt) => { 
    console.log("value", evt.target.value)
    console.log("id", evt.target.id)
    console.log("id", evt.target.name)
    let array_approval_rule = this.state.array_approval_rule;
    let update_array_approval_rule = [];
    let updateValue = {}
    for(let d of array_approval_rule){
      let obj = d;
      if(parseInt(d.id) === parseInt(evt.target.id)){
        obj[evt.target.name] = evt.target.value
        updateValue = obj
      }
      update_array_approval_rule.push(obj);
    }
    console.log("update_array_approval_rule==>", update_array_approval_rule)
    this.setState({array_approval_rule: update_array_approval_rule})

    // const postData = {command: "update_app_rule", approval_rule: update_array_approval_rule};
    const postData = {command: "update_app_rule", id: updateValue.id, updateValue: updateValue};
    this.api.command(postData, this.afterSetDragApprovalRules);  
  }

  addApprovalRule = (event) => {
    event.preventDefault();
    let order_no = this.state.array_approval_rule.length + 1;
    const postData = {command: "insert_app_rule", order_no: order_no};
    this.api.command(postData, this.afterInsertApprovalRules);  
  };
  
  afterInsertApprovalRules = (result) => {
    console.log("result==>", result)
    this.setState({ready: true, array_approval_rule: result.result})
  }  

  render() {
    if(!this.state.ready){
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }
    const actions = [{name: "Delete", action: "delete"}];

    let update_role_types = []    
    // console.log("role_types==>", this.state.role_types)
    for(let k in this.state.role_types){
      if(this.state.role_types[k].isActive === 1){
        let objrole = this.state.role_types[k]
        objrole['id'] = k
        update_role_types.push(objrole)
      }
    }
    // console.log("update_role_types==>", update_role_types)
    update_role_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    return (<div style={{ width: "90%" }}>
            <TypesContainer>            
              <TypesInnerContainer>
                <div style={{ display: "flow-root", paddingBottom: "16px" }}>
                  <div style={{ float: "left" }}><TypesBoldLabel style={{ display: "inline-block" }}>Approval Rules Table</TypesBoldLabel></div>
                  <div onClick={this.addApprovalRule} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}><FaPlusCircle /></div>
                </div>   
                <HelpTextDiv>
                  Use this table to configure a series of rule against a PAD Request is tested in sequence. When a PAD Request matches all the criteria on a row, that decision is applied to request and that workflow followed. A dash ("-") indicates that any PAD Request value is a match for that test.
                </HelpTextDiv> 

                <TableMain>
                  <TableHeaderTr>
                    <th style={{width: "5%"}}></th>
                    <th style={{width: "5%"}}>#</th>
                    <th style={{textAlign: "left", paddingLeft: "10px", width: "45%"}}>Role Type</th>
                    <th style={{textAlign: "left", paddingLeft: "10px", width: "40%"}}>Decision</th>
                    <th style={{width: "5%"}}></th>
                  </TableHeaderTr>
                </TableMain>      
                  <Container dragHandleSelector=".column-drag-handle" onDrop={e => this.setState({ array_approval_rule: this.applyDrag(this.state.array_approval_rule, e) })}>
                    {(this.state.array_approval_rule).map((q, i) => {
                      return (
                        <Draggable key={q.id}>                          
                          <TableMain>
                          <TableTr>
                            <td style={{width: "5%"}}><span className="column-drag-handle" style={{float:'left', padding:'0 10px', cursor: "move"}}>&#x2630;</span></td>
                            <td style={{width: "5%", textAlign: "center"}}>{i + 1}</td>
                            <td style={{width: "45%", textAlign: "left", paddingLeft: "10px"}}>
                              <RoleSelect value={q.role_type_id} name="role_type_id" selected={this.state.selected_role_type} key={i} id={q.id} onChange={this.handleAllChange}>
                              <option key={0} value={0}>-</option>
                              {                                               
                                update_role_types.map((d) => {
                                    return (<option key={d.id} value={d.id}>{d.name}</option>);
                                })
                              }
                              </RoleSelect>
                            </td>
                            <td style={{textAlign: "left", paddingLeft: "10px"}}>
                              <RoleSelect value={q.decision} name="decision" selected={this.state.selected_decision_option} key={i} id={q.id} onChange={this.handleAllChange}>
                                {approval_options.map((role) => (
                                  <option key={role.id} value={role.id}>
                                    {role.name}
                                  </option>
                                ))}
                              </RoleSelect>
                            </td>
                            <td style={{width: "5%"}}><EllipsisMenuModConfig entity_id={q.id} actions={actions} performAction={this.performAction} /></td>
                          </TableTr>
                          </TableMain>
                        </Draggable>
                      )
                    })}
                  </Container>   
              </TypesInnerContainer>              
            </TypesContainer>
          </div>)
  }
    
}

export default ApprovalRulesTable;
