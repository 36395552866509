import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import OutsideClickHandler from 'react-outside-click-handler';
// import {chevronDown} from 'react-icons-kit/ionicons/chevronDown';
// import {chevronUp} from 'react-icons-kit/ionicons/chevronUp';

import { FaSort } from "react-icons/fa";

const DropContainer = styled.div`
	min-height: 40px;
	width: 100%;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 6px 10px;
	background-color: #ffffff;
	cursor: pointer;
`;
const Section85 = styled.div`
	float: left;
	width: 85%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section15 = styled.div`
	float: left;
	width: 15%;
	box-sizing: border-box;
`;
const ColorBox = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 4px;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 5px;
    // height: 250px;
    // overflow-y: auto;
`;
const SingleOption = styled.div`
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #DBDBDB;
	cursor: pointer;
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;

class CslSimpleDropDown extends React.Component
{
	// Demo data structure
	// options: [
	// 	{id: "1", name: "Alpha"},
	// 	{id: "2", name: "Calendar"},
	// 	{id: "3", name: "Numeric"},
	// 	{id: "4", name: "Dropdown"}
	// ],
	// selected_option: {id: "1", name: "Alpha"}

	state = {show_options: false};

	toggleOptions = (event) => {
		event.preventDefault();
		const show_options = this.state.show_options ? false : true;
		this.setState({show_options});
	}

	makeSelected = (data) => (event) => {
		event.preventDefault();
		this.props.setSelection(data);
		this.setState({show_options: false});
	}

	render()
	{
		return (
			<OutsideClickHandler onOutsideClick={() => {this.setState({show_options: false})}}>
				<div style={{position: "relative"}}>
					<DropContainer onClick={this.toggleOptions}>
						<Section85 style={{paddingTop: "5px"}}>{this.props.selected.name}</Section85>
						<Section15 style={{textAlign: "right", paddingTop: "3px"}}>
							<FaSort style={{fontSize: "20px"}} />
						</Section15>
						<div style={{clear: "both"}}></div>
					</DropContainer>
					{
						(() => {
							if (this.state.show_options) {
								return (
									<OptionsContainer>
										{
											this.props.options.map((item) => {
												return (<SingleOption key={item.id} onClick={this.makeSelected(item.id)}>{item.name}</SingleOption>);
											})
										}
									</OptionsContainer>
								);
							}
						})()
					}
				</div>
			</OutsideClickHandler>
		);
	}
}

export default CslSimpleDropDown;