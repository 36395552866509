import Store from './Store';

class XcelLoader {
	manage_restricted_lists(tasks){		
		let j = 0;
		let results = [];		
		for(let d of tasks) {
			j++;		  
		    let elem = {		            
		            'Restricted List Name': d.restricted_list_name,
		            'Securities': d.securites,
		            'Employees' : d.employees,
		            'Status' : d.id !== '0' ? d.active === 1 ? 'Active' : 'Inactive' : ''											
		           };					    
		    results.push(elem)
		}
		return results;
	}

	restricted_lists(tasks, filter){		
		let j = 0;
		let results = [];		
		for(let d of tasks) {
			j++;		  
		    let elem = {		            
		            'Name': d.name,		            									
		            'Type': d.type,		            									
		            'Currency': d.currency,		            									
		           };
			for(let key in filter){
				if(filter[key].lookup_id === "security_identifier_types"){					
					elem[filter[key].name] = d[key.toString()]
				}
			}
			elem['Reason'] = d.reason
			results.push(elem)
		}
		return results;
	}
}
export default new XcelLoader();