import React from "react";
import styled from "styled-components";
// import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
// import { Icon } from "react-icons-kit";
// import { basic_trashcan } from "react-icons-kit/linea/basic_trashcan";
// import { software_pencil } from "react-icons-kit/linea/software_pencil";
// import { basic_floppydisk } from "react-icons-kit/linea/basic_floppydisk";

// import { checkboxChecked } from "react-icons-kit/icomoon/checkboxChecked";
// import { checkboxUnchecked } from "react-icons-kit/icomoon/checkboxUnchecked";


import { FaTimes , FaRegCheckSquare , FaRegSquare} from "react-icons/fa";
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const GeneralContainer = styled.div`
  width: 90%;
`;
const GeneralInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 10px;
  margin-bottom:10px;
  border: 2px solid
  float: left
`;
// const Label = styled.div`
//   font-weight: 600;
//   color: #595959;
//   margin-top: 10px;
//   margin-bottom:10px;
//   border: 2px solid
//   float: right
// `;
// const GeneralsubLabel = styled.div`
//   font-weight: 400;
//   color: #595959;
//   margin-top: 5px;
// `;
const RadioBlock = styled.div`
  margin-top: 5px;
`;
const RadioBlockapproval= styled.div`
margin-top: -5px;
`;
// const ResponseDropdown = styled.select`
//   box-sizing: border-box;
//   height: 40px;
//   width: 100%;
//   background-color: #fff;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   color: #b5b5b5;
// `;
const FloatingSection = styled.div`
  float: left;
  box-sizing: border-box;
`;

const BoldLabel = styled.div`
  font-weight: 600;
`;
const Clearfix = styled.div`
  clear: both;
`;
const ModalWraper = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 70%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  margin-left: 15%;
  padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 110%;
  // position: absolute;
  // top: 38px;
  // z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  // margin-bottom: 10px;
  // margin-left: 30%;
`;
const MRModalHeader = styled.div`
background-color: white;
display: block;
padding-top: 5px;
font-size: 14px;
font-weight: 700;
height: 50px;
width: calc(100% - 10px);
border-width: 1px 1px 1px 10px;
border-left: 9px solid #04ada8;
border-bottom: 1px solid #dfdfdf;
`;
const ClearBoth = styled.div`
  clear: both;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 21px;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 200;
  color: #656565;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #f1f6f7;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
`;
// const TextBox = styled.textarea`
//   height: 40px;
//   width: 98%;
//   rows:4;
//   cols:40;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   padding: 5px;
//   color: ${process.env.DEFAULT_TEXT_COLOR};
//   font-size: 15px;
//   font-family: "Montserrat", sans-serif;
//   &:focus {
//     outline-width: 0;
//   }
// `;

const TextArea = styled.textarea`
  resize: none;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  padding: 5px 10px;
  box-sizing: border-box;
`;
class Sla extends React.Component {
  state = {
    general: null,
    request_options: [
      {id: "notification", name: "Notification"},
      {id: "approval", name: "Approval"}
    ],
    selected_request_option: null,
    validity_options: null,
    holding_options: null,
    selected_validity_option: null,
    selected_holding_option: "approval"
  };

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {

    // let selected_request_option = this.state.request_options.find(item => item.id === this.props.general.request_type);
    // let validity_options = [];
    // for (let i=1; i<=24; i++) {
    // validity_options.push({id: i, name: i});
    // }
    // console.log("validityy",validity_options)
    // let selected_validity_option = validity_options.find(item => parseInt(item.id) === parseInt(this.props.general.validity_window_hours));
    // let holding_options = [];
    // for (let i=1; i<=30; i++) {
    //   holding_options.push({id: i, name: i});
    // }
    // let selected_holding_option = holding_options.find(item => parseInt(item.id) === parseInt(this.props.general.holding_period_days));
    // this.setState({general: this.props.general, selected_request_option, validity_options, holding_options, selected_validity_option, selected_holding_option});
  }

  setSelection = (item_id) => {
    // console.log("this.state.general:::::::::",this.state.general);
    // let general = JSON.parse(JSON.stringify(this.state.general));
    // // console.log("general:::",general)
    // let selected_request_option = this.state.request_options.find(item => item.id === item_id);
    // console.log("item_id:",item_id)
    // general.request_type = item_id;
    console.log("set selection")
    // this.props.updateGeneral(general);
    // console.log("this.props.updateGeneral(general);:::::",this.props.updateGeneral(general))
    // this.setState({selected_request_option, general});
  }

  setValidity = (item_id) => {
  
  }

  setHolding = (item_id) => {

  }

  switchRadio = (key) => (event) => {

  };
  advicerSwitch = (key,index) => (event) => {
  
  
    
  };

  changeAdvice = (identifier) => (event) => {
  
   
 
  }


  render() {
    // console.log("Gen ",this.state);
    // if (this.state.general === null) {
    //   return <div>Loading...</div>;
    // }

    return (
       
      <GeneralContainer>
        {/* <GeneralInnerContainer>
          <GeneralLabel>Request Type</GeneralLabel>
          <div style={{ paddingTop: "10px", width: "40%" }}>
            <CslSimpleDropDown options={this.state.request_options} selected={this.state.selected_request_option} setSelection={this.setSelection} />
          </div>
        </GeneralInnerContainer> */}
  <ModalWraper>
    
      
    <MRModalContainer>
    <MRModalHeader>
  
  <MRModalHeaderCloseBtn>
    <FaTimes />
  </MRModalHeaderCloseBtn>
  <ClearBoth />

</MRModalHeader>


<MRModalBody>
      
    <div>
        {

         
                <GeneralInnerContainer>
                  <GeneralLabel>Require Confirmation by Manager</GeneralLabel>
                
                   
                      
                        <RadioBlock>
                          <SelectedRadio>Yes</SelectedRadio>
                          <UnselectedRadio onClick={this.switchRadio("require_confirmation_by_manager")}>No</UnselectedRadio>
                        </RadioBlock>
                    
                  <GeneralLabel>Track Employee Position</GeneralLabel>
                   
                     
                        <RadioBlock>
                          <UnselectedRadio onClick={this.switchRadio("require_confirmation_by_manager")}>Yes</UnselectedRadio>
                          <SelectedRadio>No</SelectedRadio>
                        </RadioBlock>
                  <GeneralLabel>Enable internal Approver Comments </GeneralLabel>
                   
                     
                        <RadioBlock>
                          <UnselectedRadio onClick={this.switchRadio("require_confirmation_by_manager")}>Yes</UnselectedRadio>
                          <SelectedRadio>No</SelectedRadio>
                        </RadioBlock>
                    
                
                </GeneralInnerContainer>
            
        }

        {/* {
          this.state.general.request_type === "approval" &&
          <GeneralInnerContainer>
            <GeneralLabel>Confirm trade information </GeneralLabel>
            {(() => {
               console.log("this.state.general.confirm_trade_information:::",this.state.general.confirm_trade_information)
              if (this.state.general.confirm_trade_information === true) {
                return (
                  <RadioBlock>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.switchRadio("confirm_trade_information")}>No</UnselectedRadio>
                  </RadioBlock>
                );
              } else {
                return (
                  <RadioBlock>
                    <UnselectedRadio onClick={this.switchRadio("confirm_trade_information")}>Yes</UnselectedRadio>
                    <SelectedRadio>No</SelectedRadio>
                  </RadioBlock>
                );
              }
            })()}
          </GeneralInnerContainer>
        } */}

        {/* <GeneralInnerContainer>
          <GeneralLabel>Track Employee Position </GeneralLabel>
          {(() => {
            if (this.state.general.track_employee_position === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("track_employee_position")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("track_employee_position")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer> */}

        {/* <GeneralInnerContainer>
          <GeneralLabel>Add Mandatory CheckBoxes</GeneralLabel>
          {(() => {
            if (this.state.general.add_mandatory_checkboxes === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("add_mandatory_checkboxes")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("add_mandatory_checkboxes")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer> */}

        <GeneralInnerContainer>
          <GeneralLabel>Add Mandatory CheckBoxes</GeneralLabel>
          <FloatingSection>
            
               <FaRegCheckSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_short")} />
             
               <FaRegSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_short")} />
            
         
          </FloatingSection>
          <FloatingSection style={{ paddingLeft: "7px" }}>
            <BoldLabel>I confirm the trade does not take the benificiary owner short</BoldLabel>
          </FloatingSection>
          <Clearfix></Clearfix>
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <FloatingSection>
           
            
               <FaRegCheckSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_breach")} />
             
               <FaRegSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_breach")} />
           
         
          </FloatingSection>
          <FloatingSection style={{ paddingLeft: "7px" }}>
            <BoldLabel>I confirm the trade does not breach any Regulator or Company Rules or Policies</BoldLabel>
          </FloatingSection>
          <Clearfix></Clearfix>
        </GeneralInnerContainer>

       
            
                {/* <GeneralInnerContainer>
                  <GeneralLabel>Validity Windows(hrs)</GeneralLabel>
                  <div style={{ paddingTop: "10px", width: "20%" }}>
                    <CslSimpleDropDown options={this.state.validity_options} selected={this.state.selected_validity_option} setSelection={this.setValidity} />
                  </div>
                </GeneralInnerContainer> */}
{/* 
                <GeneralInnerContainer>
                  <GeneralLabel>Holding Period(days)</GeneralLabel>
                  <div style={{ paddingTop: "10px", width: "20%" }}>
                    <CslSimpleDropDown  selected={this.state.selected_holding_option} setSelection={this.setHolding} />
                  </div>
                </GeneralInnerContainer> */}
                
               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Introductory text – PAD Entry Form
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio> */}
                  
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  > Based on the information you have provided your PAD request may be declined for the following reason(s):</TextArea>
                  </div> */}
        




                  
               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Introductory text – PAD Approval Form
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                   */}
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  > Based on the information provided the PAD request may be declined for the following reason(s):</TextArea>
                  </div>
         */}




               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Short Trade
             
               <div style={{float:"right"}} >Show on Entry Form 
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio> */}
                  
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  > It appears this trade could be a ‘short’ trade which is against the company policy</TextArea>
                  </div>
         */}




               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Breaches Confirmation
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                   */}
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  > Confirmation has not been provided that this trade does not breach any regulatory rules or company policy</TextArea>
                  </div>
         */}




               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Restricted Security
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio> */}
                  
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  >The security is on the company’s Restricted List.</TextArea>
                  </div> */}
        




               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Already traded within the Holding Period
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                   */}
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  >A PAD request for the same security was raised within the Holding Period.</TextArea>
                  </div>
         */}




               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Past Trade Date
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio> */}
                  
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  > The trade date is in the past and PAD requests can only be submitted prior to trading.</TextArea>
                  </div>
         */}




               {/* <div style={{ paddingTop: "10px"}} >

               <GeneralLabel>Approval Validity Window exceeded (included)
             
               <div style={{float:"right"}} >Show on Entry Form (included)
                        
              <div style={{float:"right" ,paddingLeft:"10px"}}>

                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio> */}
                  
                    {/* <UnselectedRadio onClick={this.advicerSwitch("show_on_entry_form","automatic_rejection_advices","identifier")}>No</UnselectedRadio> */}
                    {/* <UnselectedRadio> No</UnselectedRadio>
                 
                  </RadioBlockapproval>
              </div>
                  </div>
                 
                  </GeneralLabel> 
                  <TextArea rows="5" cols="120"  >The trade date was executed outside of the approval validity window.</TextArea>
                  </div> */}
        





                {/* <GeneralInnerContainer>
                  {
                    this.state.general.automatic_rejection_advices.map((item, i) => {
                      return (

                          
                        <div style={{ paddingTop: "10px"}} key={i}> 
                            
                           <GeneralLabel> {item.header} 
                           <div style={{float:"right" }}>
                           <div style={{float:"left",paddingRight:"5px"}} >Show on Entry Form 
                           </div>
                           
                          <div style={{float:"right",paddingRight:"5px"}}>
                          {(() => {
               //console.log("this.state.general.confirm_trade_information:::",this.state.general.automatic_rejection_advices[i].show_on_entry_form ,"  ","another one",this.state.general.automatic_rejection_advices[i].identifier)
              if (this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "introductory_text_pef") {
                console.log("this.state.general.confirm_trade_information:::","  :",i)
                return (
                
                 
                );
              } else if(this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "introductory_text_paf")
              {
                return (
                  <RadioBlockapproval>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                </RadioBlockapproval>
                );
              } else if(this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "short_trade")
              {
                return (
                  <RadioBlockapproval>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                </RadioBlockapproval>
                );
              } else if (this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "breaches_confirmation")
              {
                return (
                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                  </RadioBlockapproval>
                );

              }
              else if (this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "restricted_security")
              {
                return (
                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                  </RadioBlockapproval>
                );

              }
              else if (this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "already_tread_hp")
              {
                return (
                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                  </RadioBlockapproval>
                );

              }
              else if (this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "past_trade_date")
              {
                return (
                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                  </RadioBlockapproval>
                );

              }
              else if (this.state.general.automatic_rejection_advices[i].show_on_entry_form === true && this.state.general.automatic_rejection_advices[i].identifier === "approval_validity_window_exceeded")
              {
                return (
                  <RadioBlockapproval>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>No</UnselectedRadio>
                  </RadioBlockapproval>
                );

              }
            else { 
              return (
                 <RadioBlockapproval>
                    <UnselectedRadio onClick={this.advicerSwitch("automatic_rejection_advices",i)}>Yes</UnselectedRadio>
                    <SelectedRadio>No</SelectedRadio>
                 </RadioBlockapproval>
               ) }
               
            })()
   
            }
                          </div>
                          </div>
                       

                            </GeneralLabel>
                                            
                          <div style={{ paddingTop: "5px", width: "100%" }}>

                            <TextArea rows="5" cols="120" value={item.text} onChange={this.changeAdvice(item.identifier)}></TextArea>
                          </div>
                        </div>
                      )
                    })
                  }
                </GeneralInnerContainer> */}
                 </div>
              </MRModalBody> 
      </MRModalContainer>
      </ModalWraper>
           
          
        
      </GeneralContainer>
    
    );
  }
}

export default Sla;
