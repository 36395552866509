import React from "react";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import CSLTable from "../CSLTable/CSLTable";
import CSLTableUserRole from "../CSLTable/CSLTableUserRole";
import CSLToggle from "../CSLToggle";
// import Utils from "../../../../Common/Utils";
import Store from "../../../../Common/Store";
import APICall from "../../../../Common/APICall";
import MangeRoletype from "./modals/MangeRoletype";
//import Dropdown from "../../../Common/Dropdown";
import ToggleSwitch from "../../../Common/ToggleSwitch";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";

import approval_options from './approval_options.js'
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";
import CSLLoader from './../../../Common/CSLLoader';


const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const RoleSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;

  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`
const Flexbox = styled.button`
    display: flex;
    width: 100%;
    padding: 0px;
    border: none;
`;
const SectionTab = styled.div`
  float: left;
  width: 143px;
  height: 16px;
  cursor: pointer;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #c4c4c4;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  // margin-left: 4px;
  border: 1px solid #D9D9D9;
`;

const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    margin-top: 15px;
`;

class RoleTypes extends React.Component {

  state = {
    role_types: null,
    prefered_columns: null,
    show_modal: null,
    type:{},
    selected_approval_option: null,
    active_section_not_ready : true,
    is_role_types: 1,
    is_users: 0,
    current_view: "role_types",
    userLists: [],
    selected_role_type: null,
    users_role_type: {},
    isLoadedIcon: false,
    rowchecked: false,
    commonRoleTypeValue: '',
    userListsAll: []
  };

  constructor() {
    super();
    this.api = new APICall();
  }

  componentDidMount() {
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    console.log("postData", postData)
    this.api.command(postData, this.getroleTypes);
  }

  getroleTypes = (result) => {
    console.log(result.result)
    let prefered_columns = {};
    prefered_columns['name'] = 'Type / Question';
    prefered_columns['status_value'] = 'Status';
    prefered_columns['approver_level'] = 'Approval Required';
    let role_types = result.result
    for(let rid in role_types) {
      let l_json = JSON.parse(role_types[rid].lookup_json)
      role_types[rid].approver_level = 'approver_level' in l_json ? l_json.approver_level : 'ar'
    }
    this.role_types = role_types;
    let cur_active_users = Store.getStoreData('cur_active_users')
    let userLists = []
    let users_role_type= this.props.users_role_type
    console.log("users_role_type==>", users_role_type)
    console.log("cur_active_users==>", cur_active_users)
    for(let k in cur_active_users){
      if(k.toString() in users_role_type){
        let userObject = {}
        userObject.id = k
        userObject.name = cur_active_users[k]
        userObject.select = false
        userObject.roleType = users_role_type[k.toString()].role_id
        userLists.push(userObject)
      }else{
        let userObject = {}
        userObject.id = k
        userObject.name = cur_active_users[k]
        userObject.select = false
        userObject.roleType = ''
        userLists.push(userObject)
      }
    }
    this.setState({role_types, prefered_columns: prefered_columns, active_section_not_ready : false, userLists, userListsAll: userLists, users_role_type})
  }

  toggleStatus = (id) => {
    // console.log("is_active:", is_active);
    console.log("id:", id);
    let role_types = JSON.parse(JSON.stringify(this.state.role_types));
    role_types[id.toString()].is_active = role_types[id.toString()].is_active === 0 ? 1 : 0;
    // role_types[id.toString()].is_active = is_active === true ? 1 : 0;
    const postData = { command: "toggle_status"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['is_active'] = role_types[id.toString()].is_active;
    // postData['is_active'] = is_active ? 1 : 0;
    postData['id'] = id;
    this.role_types = role_types;
    this.api.command(postData, this.toggleroleType);
  }

  toggleroleType = (result) => {
    alert(result.error_msg);
    this.setState({role_types: this.role_types});

    let users_role_type = this.state.users_role_type
    let role_types = this.state.role_types;
    let update_user_role_type = {}
    for(let k in users_role_type){
      if(users_role_type[k.toString()].role_id in role_types && role_types[users_role_type[k.toString()].role_id].is_active === 1){
        update_user_role_type[k.toString()] = {"approver_level": role_types[users_role_type[k.toString()].role_id].approver_level, "role_id": role_types[users_role_type[k.toString()].role_id].id, "role_name": role_types[users_role_type[k.toString()].role_id].name}
      }
    }
    let usersLiust = this.state.userLists
    let update_usersList = []
    for(let d of usersLiust){
      if(d.id in update_user_role_type){
        d['roleType'] = update_user_role_type[d.id.toString()].role_id
      }else{
        d['roleType'] = ''
      }
      update_usersList.push(d)
    }
    console.log("update_usersList===>", update_usersList)
    this.setState({userLists: update_usersList, users_role_type: update_user_role_type})
    this.props.updateUserRoleValue(update_user_role_type)
  }

  addType = (event) => {
    event.preventDefault();
    let type = {};
    type['id'] = 0
    type['status'] = 1
    type['name'] = ""
    type['approver_level'] = "nr"
    this.setState({show_modal:'roletype', type: type});
  };


  openModal = (id) => {
    // event.preventDefault();
    let type = this.state.role_types[id.toString()];
    let t = {};
    t['id'] = id;
    t['status'] = type.is_active;
    t['name'] = type.name;
    t['approver_level'] = type.approver_level;
    this.setState({ show_modal: 'roletype', type: t });
  };

  // deleteType = (id) => (event) => {
  //   let type = this.state.role_types[id.toString()];
  //   if (window.confirm(`Are you sure you want to delete this ${type['name']} from the list?`)) {
  //    const postData = { command: "delete_lookup"};
  //    postData['lookup_id'] = this.props.lookup_id;
  //    postData['id'] = id;
  //    this.api.command(postData, this.removeroleType);
  //   }
  // };

  // removeroleType = (result) => {
  //   let msg = "Role type successfully deleted";
  //   alert(msg);
  //   const postData = { command: "list_lookups"};
  //   postData['lookup_id'] = this.props.lookup_id;
  //   this.api.command(postData, this.getroleTypes);
  // }

  closeModal = (event) => {
    this.setState({ show_modal: null });
  };
  refreshParent = () => {
    this.setState({ show_modal: null });
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    console.log("postData", postData)
    this.api.command(postData, this.getroleTypes);
  }
  
  editRole = (role_id) => {
    console.log(role_id);
  }
  
  deleteRole = (role_id) => {
    console.log(role_id);
  }
  
  performAction = (role_id, action_type) => {
    console.log("role_id:", role_id);
    console.log("action_type:", action_type);
    if (action_type === "edit") {
      this.openModal(role_id);
    }
    if (action_type === "delete") {
      if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
        const postData = {
          command: "delete_lookup",
          lookup_id: this.props.lookup_id,
          check_key: "role_type_id",
          id: role_id
        };
        console.log("postData:", postData);
        this.api.command(postData, this.afterRemove);
      }
    }
  }

  afterRemove = (result) => {
    console.log(result);
    if (result.error_msg === "exists") {
      alert("This item cannot be removed as it is associated with tasks");
    } else {
      alert("This item is successfully removed");
      let role_types = JSON.parse(JSON.stringify(this.state.role_types));
      console.log("role_types:", role_types);
      delete role_types[result.result.toString()];
      this.setState({role_types});
    }
  }
  
  saveroleTypes = (result) => {
    alert('Approval Requirement has been successfully updated.')
    let users_role_type = this.state.users_role_type
    let role_types = this.state.role_types;
    let update_user_role_type = {}
    for(let k in users_role_type){
      if(users_role_type[k.toString()].role_id in role_types && role_types[users_role_type[k.toString()].role_id].is_active === 1){
        update_user_role_type[k.toString()] = {"approver_level": role_types[users_role_type[k.toString()].role_id].approver_level, "role_id": role_types[users_role_type[k.toString()].role_id].id, "role_name": role_types[users_role_type[k.toString()].role_id].name}
      }
    }
    
    let usersLiust = this.state.userLists
    let update_usersList = []
    for(let d of usersLiust){
      if(d.id in update_user_role_type){
        d['roleType'] = update_user_role_type[d.id.toString()].role_id
      }else{
        d['roleType'] = ''
      }
      update_usersList.push(d)
    }
    this.setState({userLists: update_usersList, users_role_type: update_user_role_type})
    this.props.updateUserRoleValue(update_user_role_type)
  }

  handleUserLevelChange = (id) => (event) => {
    event.preventDefault()
    let usersLiust = this.state.userLists
    let users_role_type = this.state.users_role_type
    let role_types = this.state.role_types
    let update_usersList = []
    console.log("event.target.value==>", event.target.value)
    for(let d of usersLiust){
      if(d.id === id){
        d['roleType'] = event.target.value
        if(event.target.value !== ''){          
          let value = {'role_id': role_types[event.target.value].id, 'role_name': role_types[event.target.value].name,'approver_level': role_types[event.target.value].approver_level}
          users_role_type[d.id.toString()] = value
        }else{
          delete users_role_type[d.id.toString()];
        }
      }
      update_usersList.push(d)
    }
    console.log("users_role_type===>", users_role_type)
    this.setState({userLists: update_usersList, users_role_type, isLoadedIcon: true})
    this.props.updateUserRoleValue(users_role_type)
  }
  
  handleLevelChange = (id) => (e) => {

    e.preventDefault()
    let role_types = this.state.role_types
    role_types[id].approver_level = e.target.value
    this.setState({role_types})
    console.log("role_types", role_types)
    const postData = { command: "save_lookup"};
    let lookup_json = {};
    lookup_json['approver_level'] = role_types[id].approver_level
    lookup_json['name'] = role_types[id]["name"];
    postData['lookup_json'] = lookup_json;
    postData['name'] = role_types[id]["name"];
    postData['status'] = role_types[id]["is_active"];
    
    postData['description'] = '';
    postData['lookup_id'] = 'role_types';
    postData['id'] = id;
    console.log("postData", postData)
    this.api.command(postData, this.saveroleTypes);
  }

  selectDocument = (event) => {
    let index = event.target.value;
    let userLists = this.state.userLists;
    userLists[index].select = userLists[index].select ? false : true;
    this.setState({ userLists });
  };

  selectAll = (event) => {
    let rowchecked = this.state.rowchecked;
    rowchecked = rowchecked ? false : true;
    let usersList = this.state.userLists
    let update_usersList = []
    let filter_object = {}
    for(let d of usersList){
      if(rowchecked){
        d.select = true
        update_usersList.push(d)
      }else{
        d.select = false
        update_usersList.push(d)
      }
      filter_object[d.id.toString()] = d.select
    }
    let userAll = []
    for(let usr of this.state.userListsAll){
      usr.select = filter_object[usr.id.toString()]
      userAll.push(usr)
    }
    this.setState({ usersList: update_usersList, userListsAll: userAll,rowchecked: rowchecked });
  };

  handleCommonUserLevelChange = (evt) => {
    let usersList = this.state.userLists
    let update_usersList = []
    let users_role_type = this.state.users_role_type
    let role_types = this.state.role_types
    let is_update = false
    for(let d of usersList){
      if(d.select){
        d.roleType = evt.target.value
        if(evt.target.value !== ''){
          let value = {'role_id': role_types[evt.target.value].id, 'role_name': role_types[evt.target.value].name,'approver_level': role_types[evt.target.value].approver_level}
          users_role_type[d.id.toString()] = value
        }else{
          delete users_role_type[d.id.toString()];
        }
        is_update = true
      }
      update_usersList.push(d)
    }
    let user_array = []
    if(is_update){
      for(let d of update_usersList){
        d.select = false
        user_array.push(d)
      }
    }else{
      user_array = update_usersList
    }


    this.setState({[evt.target.name]: evt.target.value, usersList: user_array, users_role_type, rowchecked: false});
    if(is_update){
      this.props.updateUserRoleValue(users_role_type)
    }
  }

  searchUsers = (event) => {
		event.preventDefault();
    let search_string = event.target.value;
		let userListsAll = this.state.userListsAll;
    if (search_string === "") {
      let userLists = userListsAll;
      this.setState({userLists});
    }else {
      let all_value = userListsAll;
      let filtered_values = [];
      for(let d of all_value){
        if (d.name.toLowerCase().includes(search_string.toLowerCase())) {
          filtered_values.push(d);
        }
      }
      let userLists = filtered_values;
      this.setState({userLists});
    }
	}

  tableUserData = () => {
    let table = { data: [], columns: [] };
    let update_role_types = []
    // console.log("this.state.role_types==>", this.state.role_types)
    for(let k in this.state.role_types){
      if(this.state.role_types[k].is_active === 1){
        update_role_types.push(this.state.role_types[k])
      }
    }
    update_role_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    console.log("update_role_types==>", update_role_types)
    table.columns = [
      {
        Header: (
          <div>
            <input type="checkbox" checked={this.state.rowchecked} onClick={this.selectAll} disabled = {this.state.disabled}  />
          </div>
        ),
        Cell: (row) => (
          <div>
            <input type="checkbox" checked={row.original.select} value={row.original.index} onClick={this.selectDocument} disabled={this.state.disabled} />
          </div>
        ),
        width: 50,
        headerStyle: { textAlign: "left" },
        sortable: false,filterable:false,
      },
      { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      {
        Header: "Role",
        accessor: "role_type",
        headerStyle: { textAlign: "left" },
        filterable: false,
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          return <RoleSelect style={{width: "98%"}} value={row.original.role_type} selected={this.state.selected_role_type} key={row.original.index} onChange={this.handleUserLevelChange(row.original.id)}>
            <option key={0} value={''}>Not Assigned</option>
            {update_role_types.map((val) => (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
            ))}
          </RoleSelect>
          },
        width: 250,
        // headerStyle: { textAlign: "center" },
        // filterable: false
      },
    ]

    let data = this.state.userLists;
    data = data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    let i = 0;
    for(let d of data){
      let elem = {'index' : i,
              'id':d.id,
              'name': d.name,
              'select': d.select,
              'role_type': d.roleType
             };
      table.data.push(elem);
      i++;
    }
    return table;
  }
  
  tableData = () => {
    let table = { data: [], columns: [] };
    const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}];

    table.columns = [
      { Header: "#", accessor: "index", width: 30, headerStyle: { textAlign: "center" }, filterable: false, style: { fontFamily: "monospace", fontSize: "14px", textAlign: "right" } },
      { Header: "Type / Question", accessor: "name", headerStyle: { textAlign: "left" }, filterable: false, style: { whiteSpace: "unset" } },
      {
        Header: "Approval Required",
        accessor: "approver_level",
        headerStyle: { textAlign: "left" },
        filterable: false,
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          return <RoleSelect style={{width: "98%"}} value={row.original.approver_level} selected={this.state.selected_approval_option} key={row.original.index} onChange={this.handleLevelChange(row.original.key)}>
            {approval_options.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </RoleSelect>
          },
        width: 250,
      },

      { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => {
        //console.log("row:", row);
        return <ToggleSwitch id={row.original.id} checked={parseInt(row.original.status) === 1 ? true : false} onClick={this.toggleStatus}/>
        //return <CSLToggle id={row.original.id} checked={parseInt(row.original.status) === 1 ? true : false} onClick={this.toggleStatus} />
      }, style: { display: "flex", alignItems: "center", justifyContent: "center" } },
      {
        Header: "",
        Cell: (row) => (
            // <div style={{marginTop : "10px"}}><Dropdown  entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
            <div><EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
        ),
        width: 55,
        headerStyle: { textAlign: "center" },
        filterable: false
      },
    ];
    let data = this.state.role_types;
    let i = 0;
    for(let key in data){
      i++;
      let elem = {'index' : i,
              'key': key,
              'name': data[key].name,
              'id': data[key].id,
              'status': data[key].is_active,
              'approver_level': data[key].approver_level,
              'status_value' : data[key].is_active === 1 ? "Active" : "In-Active"
             };
            // console.log('elem', elem)
      table.data.push(elem);
    }
    return table;
  };

  handleButton = (event) => {
    event.preventDefault();
    let is_role_types = 0;
    let is_users = 0;
    var current_view = null;
    if ([event.target.id] == "role_types") {
      is_role_types = 1;
      current_view = "role_types";
    }
    if ([event.target.id] == "users") {
      is_users = 1;
      current_view = "users";
    }
    this.setState({is_role_types, is_users, current_view})
  }



  render() {
    // console.log("role_types in render:", this.state.role_types);
    if(this.state.active_section_not_ready === true) {
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }

    let update_role_types = []
    // console.log("this.state.role_types==>", this.state.role_types)
    for(let k in this.state.role_types){
      if(this.state.role_types[k].is_active === 1){
        update_role_types.push(this.state.role_types[k])
      }
    }
    update_role_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    
    return (
      <div>
        <div style={{ width: "90%" }}>
          {
            (() => {
              if(this.state.isLoadedIcon){
                return <div><CSLLoader style={{position: 'absolute'}}/></div>
              }
            })()
          }
          <TypesContainer>
          {
            (() => {
              if(this.state.show_modal){
                return <MangeRoletype closeModal={this.closeModal} type = {this.state.type} refreshParent={this.refreshParent}/>
              }
            })()
          }
            <TypesInnerContainer>
              <div style={{ display: "flow-root", paddingBottom: "4px" }}>
                {/* <div style={{ float: "left" }}><TypesBoldLabel style={{ display: "inline-block" }}>{this.props.sectionName}</TypesBoldLabel></div> */}
                <div style={{ float: "left" }}>
                  <Flexbox style={{ width: "100%", backgroundColor: "#ffffff"}}>
                    <SectionTab id="role_types" style={{color: this.state.is_role_types === 1 ? "#464646" : "#BFBFBF"}} onClick={this.handleButton}>
                      {this.props.sectionName}
                    </SectionTab>
                    <SectionTab id="users" style={{color: this.state.is_users === 1 ? "#464646" : "#BFBFBF", marginLeft: "4px"}} onClick={this.handleButton}>
                      Users
                    </SectionTab>
                  </Flexbox>
                </div>
                <div onClick={this.addType} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                  <FaPlusCircle />
                </div>
              </div>
              {
                (() => {
                  if(this.state.current_view === 'role_types'){
                    return(<CSLTable add={false} processData={this.tableData} tableRows={10} />)
                  }else if(this.state.current_view === 'users'){
                    return(<div>
                      <div style={{marginBottom: "5px"}}>
                          <div style={{display: "inline-block", fontWeight: "600", color: "#464646", width: "40%"}}>
                              Assign this role to all selected employees
                          </div>
                          <div style={{display: "inline-block", width: "35%"}}>
                            <RoleSelect style={{width: "98%", height: "35px"}} name="commonRoleTypeValue" value={this.state.commonRoleTypeValue} onChange={this.handleCommonUserLevelChange}>
                              <option key={0} value={''}>Not Assigned</option>
                              {update_role_types.map((val) => (
                                  <option key={val.id} value={val.id}>
                                    {val.name}
                                  </option>
                              ))}
                            </RoleSelect>
                          </div>
                      </div>
                      <SearchInput placeholder="Search..." onChange={this.searchUsers} />
                    <CSLTableUserRole add={false} processData={this.tableUserData} tableRows={10}/>
                    </div>)
                  }
                })()
              }

            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
    );
  }
}

export default RoleTypes;
