import React from 'react';
import moment from 'moment';
import './ConfirmationReportModal.css';
import { FaTimes, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import Store from "../../../Common/Store";
import APICall from '../../../Common/APICall';
import CSLTable from '../../Common/CSLTable';
import SpinningLoader from '../../Common/SpinningLoader';
import AlertBox from '../../Common/AlertBox';
import UserCommentModal from './UserCommentModal';

class HoldingConfirmationReportModal extends React.Component{
    state = {
        ready : false,
        alertParam : null,
        contactId : null,
        reportTitle : null,
        fromDate : null,
        toDate :  null,
        enableEmployeeBrokerAc : Store.getStoreData("module_config")?.general?.enable_employee_broker_ac,
        holdingConfirmChecked : false,
        brokerConfirmChecked : false,
        showSpinningLoader : false,
        showRejectionCauseModal : false,
        holdingIdsToConfirm : [], 
        holdingsToConfirm : [],
        holdingReportToConfirm : {},
        brokers : [],
        rowsInView : 5,
        brokerRowsInView : 5,
        showConfirmDialog: false
    }

    componentDidMount(){
        let fromDate = this.props.selected_report?.holding?.from_date;
        let toDate = this.props.selected_report?.holding?.to_date;
        let contactId = parseInt(this.props.selected_report?.holding?.contact_id);
        let reportTitle = this.props.selected_report?.holding?.report_title
        this.setState({ fromDate, toDate, contactId, reportTitle, showSpinningLoader : true, holdingReportToConfirm : this.props.selected_report?.holding})
        this.intializeState(contactId, fromDate, toDate);
    }

    intializeState = async (contactId, fromDate, toDate) => {
        let holdingsToConfirm = [];
        let holdingIdsToConfirm = [];
        const postData = { command: "list_holdings_for_period", requester_id : parseInt(contactId), from : moment(fromDate.toString(),"YYYYMMDD").valueOf(), to : moment(toDate.toString(),"YYYYMMDD").valueOf()};
        const api = new APICall();
        const apiResult = await api.commandWithoutCallback(postData);
        const moduleConfig = Store.getStoreData('module_config');
        const holdings = apiResult?.result?.holdings;
        const brokers = apiResult?.result?.broker_list;
        if(holdings?.length > 0){
            for(let r of holdings){
                holdingIdsToConfirm.push(r.id);
                let security = moduleConfig?.security_register[r.security_id] !== undefined ? moduleConfig?.security_register[r.security_id] : null;
                let securityName = security !== null ? security?.security_name : "-";
                let securityType = moduleConfig?.security_types[security.security] !== undefined ? moduleConfig?.security_types[security.security]?.name : null;
                let securityIdentifiers = [];
                let currency = moduleConfig?.currencies[security?.currency]?.name;
                for(let keys of Object.keys(security?.identifier_types)) securityIdentifiers.push(security?.identifier_types[keys]);
                let data = {
                    id : r?.id,
                    securityName : securityName,
                    securityId : securityIdentifiers?.length > 0 ? securityIdentifiers[0] : '',
                    securityType : securityType !== null && securityType !== undefined ? securityType : '',
                    volume : r?.current_status,
                    price : r?.price !== null && r?.price !== undefined ? r?.price : "-",
                    currency : currency !== undefined ? currency : "-",
                }
                holdingsToConfirm.push(data);
                
            }
        }
        let rowsInView = 5;
        if( holdingsToConfirm?.length > 5 ){
            rowsInView = holdingsToConfirm?.length <20 ? holdingsToConfirm?.length : 20;
        }
        let brokerRowsInView = 5;
        if(brokers > 5){
            brokerRowsInView = brokers?.length <20 ? brokers?.length : 20;
        }
        this.setState({ holdingIdsToConfirm, holdingsToConfirm, brokers, showSpinningLoader : false, rowsInView, brokerRowsInView, ready : true});
    }

    toggleCheckHolding = (e) => {
        e.preventDefault();
        this.setState({ holdingConfirmChecked : !this.state.holdingConfirmChecked });
    } 

    toggleCheckBroker = (e) => {
        e.preventDefault();
        this.setState({ brokerConfirmChecked : !this.state.brokerConfirmChecked });
    } 

    closeModal = (e) =>{
        e.preventDefault();
        this.props.closeModal();
    }

    showConfirmationDialog = (e) => {
        e.preventDefault();
        this.setState({showConfirmDialog: true});
    }

    closeConfirmationDialog = () => {
        this.setState({showConfirmDialog: false})
    }
    
    showRejectionCauseModal = (e) =>{
        e.preventDefault();
        this.setState({ showRejectionCauseModal : true });
    }

    closeRejectionCauseModal = (e) =>{
        this.setState({ showRejectionCauseModal : false });
    }

    confirmRejectHoldings = async (userInput, action) => {
        let alertParam = null;
        this.setState({ alertParam, showSpinningLoader : true })
        let holdingReport = this.state.holdingReportToConfirm;
        let holdings = this.state.holdingsToConfirm;
        
        let actionData = holdingReport.action_data;
        actionData.push({
            "action": action === 'hold_confirm' ? "HOLD_CON_CONFIRMED" : "HOLD_CON_REJECTED",
            "actiondate": moment().valueOf(),
            "cur_lane": action === 'hold_confirm' ? "HOLD_CON_CONFIRMED" : "HOLD_CON_REJECTED",
            "performed_by": this.state.contactId
        })

        let postData = { 
            command : 'confirm_reject_holding_report', 
            involved_holdings : holdings,
            involved_track_holding_ids : this.state.holdingIdsToConfirm,
            resp_date : parseInt(moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").format("YYYYMMDD")),
            resp_time : moment().format('HH:mm:ss'),
            report_id : holdingReport?.id,
            //optional_comments: optionalComments.trim(),
            action : action,
            action_data : actionData
        }

        if(action === 'hold_confirm'){
            postData["optional_comments"] = userInput?.trim();
        }else{
            postData["rejection_cause"] = userInput?.trim();
        }

        if(this.state.enableEmployeeBrokerAc === true){
            let brokersToConfirm = this.state.brokers;
            let involvedBrokerIds = [];
            for(let b of this.state.brokers) {
                involvedBrokerIds.push(b.id);
            }
            postData["involved_brokers"] = brokersToConfirm;
            postData["involved_broker_ids"] = involvedBrokerIds;
        }

        const api = new APICall();
        const apiResult = await api.commandWithoutCallback(postData);
        if(apiResult.error_code !== 0){
            alertParam = {
                title: 'Error', message: `Something went wrong. Try again after sometime`, ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlertSmall, stack: {}
            }
            this.setState({ alertParam, showConfirmationDialog : false, showRejectionCauseModal : false, showSpinningLoader : false });
        }else{
            this.setState({ alertParam, showConfirmationDialog : false, showRejectionCauseModal : false, showSpinningLoader : false })
            this.props.closeModal('reload');
        }
    }

    closeAlertSmall = (value, stack) => {
        this.setState({ alertParam : null});
    }

    processDataTable = () => {
        const ret = { data: [], columns: [] };
        let tableData = [];
        ret.columns = [
            { Header: "Security Name", accessor: "securityName", width: 435, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Security ID", accessor: "securityId", width: 330, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Security Type", accessor: "securityType", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Volume", accessor: "volume", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Currency", accessor: "currency", width: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        for(let r of this.state.holdingsToConfirm){
            let data = {
                id : r?.id,
                securityName : r?.securityName,
                securityId : r?.securityId,
                securityType : r?.securityType,
                volume : r?.volume,
                currency : r?.currency
            }
            tableData.push(data);
        }
        ret.data = tableData;
        return ret;
    }

    processDataBrokerTable = () => {
        const ret = { data: [], columns: [] };
        let tableData = [];
        ret.columns = [
            { Header: "Name of Broker/Dealer or Bank", accessor: "brokerName", width: 500, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Account Title (Your Reference)", accessor: "accountName", width: 380, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Account Opened", accessor: "acOpeningDate", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Account Closed", accessor: "acCloseDate", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        for(let r of this.state.brokers){
            let data = {
                id : r?.id,
                brokerName : r?.broker_name,
                accountName : r?.account_name,
                acOpeningDate : moment.unix(r.ac_opening_date).format('DD/MM/YYYY'),
                acCloseDate : r?.ac_close_date !== -1 ? moment.unix(r?.ac_close_date).format('DD/MM/YYYY') : '--/--/----'
            }
            tableData.push(data);
        }
        ret.data = tableData;
        return ret;
    }
    formatDate(date){
        return moment(date.toString(), "YYYYMMDD").format("DD/MM/YYYY");
    }
    

    render(){
        if(this.state.ready !== true){ 
            return(<SpinningLoader />);
        }
        return(
            <>
            <div className='confirmation-report-modal-wrapper'>
                {this.state.showSpinningLoader === true && <SpinningLoader />}
                <div className='confirmation-report-modal-header'>
                    <div className='confirmation-report-modal-title-wrapper'>
                        <div className='confirmation-report-modal-heading'>
                            { this.state.reportTitle === null ? Store.getStoreData("module_config")?.holding_confirmation?.holding_report_title : this.state.reportTitle}
                        </div>
                        <div className='confirmation-report-modal-subheading'>
                            {this.props.view === 'admin' ? (Store.getStoreData("company_users")?.find(item => parseInt(item.user_id) === this.state.contactId)).name + " - " : ""}
                            {
                                this.state.fromDate === 0 ? 
                                    this.formatDate(this.state.toDate):
                                `${this.formatDate(this.state.fromDate)} to ${this.formatDate(this.state.toDate)}`
                            }
                        </div>
                    </div>
                    <div className='confirmation-report-modal-close'>
                        <FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/>
                    </div>
                </div>
                <div className='confirmation-report-modal-body'>
                    {
                        this.props.view === 'requester' && 
                        <div className='confirmation-report-modal-help-text'>
                            {Store.getStoreData("module_config")?.holding_confirmation?.holding_assignee_help_text}
                        </div>
                    }
                    <div className='confirmation-report-modal-table-container'>
                        <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={this.state.rowsInView}/>
                    </div>
                    {
                        this.props.view === 'requester' && 
                        <div className='confirmation-report-modal-confirmation-text-container'>
                            {
                                (()=>{
                                
                                    if(this.state.holdingConfirmChecked === false) return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheckHolding}><FaRegSquare /></div>;
                                    return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheckHolding}><FaRegCheckSquare /></div>;            
                                    
                                })()
                            }
                            {
                                (()=>{
                                
                                    if(this.state.holdingsToConfirm?.length > 0) return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config")?.holding_confirmation?.holding_attestation_text}</div>;
                                    return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config")?.holding_confirmation?.nil_holdings_text}</div>;            
                                    
                                })()
                            }
                        </div>
                    }
                    {
                        this.state.enableEmployeeBrokerAc &&
                        <div className='tr-report-view-broker-table-label'>{Store.getStoreData("module_config")?.broker_confirmation?.broker_section_title}</div>
                    }
                    {
                        this.state.enableEmployeeBrokerAc &&
                        <div className='confirmation-report-modal-table-container'>
                            <CSLTable add={false} processData={this.processDataBrokerTable} zebra={false} tableRows={this.state.brokerRowsInView}/>
                        </div>
                    }
                    {
                        this.props.view === 'requester' && this.state.enableEmployeeBrokerAc &&
                        <div className='confirmation-report-modal-confirmation-text-container'>
                            {
                                (()=>{
                                
                                    if(this.state.brokerConfirmChecked === false){ 
                                        return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheckBroker}><FaRegSquare /></div>;
                                    }
                                    return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheckBroker}><FaRegCheckSquare /></div>;            
                                    
                                })()
                            }
                            {
                                (()=>{
                                
                                    if(this.state.brokers?.length > 0){ 
                                        return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config")?.broker_confirmation?.broker_attestation_text}</div>;
                                    }
                                    return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config")?.broker_confirmation?.nil_brokers_text}</div>;            
                                    
                                })()
                            }
                        </div>
                    }
                </div>
                
                <div className='confirmation-report-modal-footer'>
                    <div className='confirmation-report-modal-footer-button-container'>
                        {
                            this.props.view === 'admin' && <button className='confirmation-report-modal-footer-button confirmation-report-close-button' onClick={this.closeModal} >Close</button>
                        }
                        {
                            this.props.view === 'requester' && <button className='confirmation-report-modal-footer-button confirmation-report-close-button' onClick={this.closeModal} >CANCEL</button>
                        }
                        <div className='confirmation-report-modal-footer-button-subcontainer'>
                            {
                                this.props.view === 'requester' && <button className='confirmation-report-modal-footer-button confirmation-report-reject-button' onClick={this.showRejectionCauseModal}>REJECT</button>
                            }
                            {   
                                (()=>{
                                    if(this.props.view === 'requester' && this.state.holdingConfirmChecked === true && ((this.state.enableEmployeeBrokerAc === true && this.state.brokerConfirmChecked === true) || this.state.enableEmployeeBrokerAc === false)){
                                        return <button className='confirmation-report-modal-footer-button confirmation-report-save-button' onClick={this.showConfirmationDialog}>CONFIRM</button>
                                    }else if(this.props.view === 'requester' && (this.state.holdingConfirmChecked === false || (this.state.enableEmployeeBrokerAc === true && this.state.brokerConfirmChecked === false))){
                                        return <button className='confirmation-report-modal-footer-inactive-button'>CONFIRM</button>
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.showRejectionCauseModal && 
                    <div className='tr-rejection-cause-modal-overlay'>
                        <UserCommentModal action={"hold_reject"} reportTitle = {this.state.reportTitle} from_date={this.state.fromDate} to_date={this.state.toDate} closeModal={this.closeRejectionCauseModal} confirmReject={this.confirmRejectHoldings}/>
                    </div>
                }
                {
                    this.state.showConfirmDialog === true &&
                    <div className='tr-rejection-cause-modal-overlay'>
                        <UserCommentModal action={"hold_confirm"} closeModal={this.closeConfirmationDialog} confirmReject={this.confirmRejectHoldings} />
                    </div>
                }
            </div>
            <AlertBox alertParam={this.state.alertParam} />
            </>
        )
    }
}

export default HoldingConfirmationReportModal;
