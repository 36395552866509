import React from 'react';
import styled from "styled-components";
import { FaTimes, FaCalendarAlt, FaPlusCircle, FaRegCheckSquare, FaRegSquare, FaExclamationTriangle } from "react-icons/fa";
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';
import TableDropdown from '../../Common/TableDropdown';
import Utils from './../../../Common/Utils';
import Store from "./../../../Common/Store";
import moment from 'moment';
import APICall from './../../../Common/APICall';
import DatePicker from "react-date-picker";
import NoteModal from './NoteModal';
import CreateSecurityRegisterModal from './CreateSecurityRegisterModal';
import CustomField from '../../Common/CustomField';
import Dropzone from "../../Dropzone/Dropzone.js";
// import AlertBox from "../../Common/AlertBox.js";
import YesNoBox from "../../Common/YesNoBox.js";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { MdClear } from "react-icons/md";
import SpinningLoader from '../../Common/SpinningLoader';
// const moment = require("moment");
const last_action_time = moment().unix();

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 40%;
	position: absolute;
	top: 38px;
	z-index: 1001;
	margin-left: 30%;
	padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	// width: 40%;
	// position: absolute;
	// top: 38px;
	// z-index: 1001;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	// margin-bottom: 10px;
	// margin-left: 30%;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 100px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	float: left;
	margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const ClearBoth = styled.div`
	clear: both;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const HR = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgb(208 209 210);
	margin: 35px 0px;
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const InfoDiv = styled.div`
	width: 100%;
	background-color: #d6e6f3;
	border: 1px solid #adb1b5;
	border-radius: 3px;
	padding: 10px 10px 10px 10px;
	box-sizing: border-box;
`;
const MRModalFooter = styled.div`
  padding: 17px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	border-radius: 4px;
	font-size: 13px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
  	padding: 9px;
`;
const CSLDateCover = styled.div`
  width: 30%;
  box-sizing: border-box;
  height: 40px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  // font-size: 14px;
  // font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  & > div {
    padding: 3px 4px;
    border: none;
    & > div > button {
      padding-top: 9px;
    }
  }
`;
const Section50 = styled.div`
	width: 50%;
	float: left;
	box-sizing: border-box;
`;
const Section25 = styled.div`
	width: 25%;
	float: left;
	box-sizing: border-box;
`;
const Section30 = styled.div`
	width: 30%;
	float: left;
	box-sizing: border-box;
`;
const Section70 = styled.div`
	width: 70%;
	float: left;
	box-sizing: border-box;
`;
const Clearfix = styled.div`
	clear: both;
`;
const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;
const TextArea = styled.textarea`
	resize: none;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	max-width: 100%;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
`;
const NoteTableHeaderBar = styled.div`
	background-color: #11ABA6;
    border: 1px solid #11ABA6;
    box-sizing: border-box;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 6px 15px;
`;
const NoteWrapper = styled.div`
	position: fixed;
    height: 100vh;
    width: calc(100vw - 320px);
    left: 300px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1002;
`;
const NoteTableHeadBodyWrapper = styled.div`
	box-sizing: border-box;
    border-left: 1px solid rgb(203 204 206);
    border-right: 1px solid rgb(203 204 206);
    border-bottom: 1px solid rgb(203 204 206);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`;
const NoteTableHeader = styled.div`
	box-sizing: border-box;
`;
const NoteTableBody = styled.div`
	box-sizing: border-box;
	background-color: #ffffff;
`;
const Cell30 = styled.div`
	width: 30%;
	float: left;
	box-sizing: border-box;
	padding: 10px 0px 10px 20px;
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;
const Cell70 = styled.div`
	width: 70%;
	float: left;
	box-sizing: border-box;
	padding: 10px 0px 10px 20px;
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;
const MRAlertContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	margin: 0 auto;
	margin-top: 30px;
	background-color: #eccaca;
	border: 1px solid #b75050;
	border-radius: 5px;
	padding: 5px 10px;
`;

const RadioBlock = styled.div`
  margin-top: 5px;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const TradeDetailsContainer = styled.div`
  display : grid;
  grid-template-columns : 20% 47% 27%;
  grid-template-rows : minmax(25px, auto) 50px;
  grid-column-gap : 3%;
  margin-top : 20px;
`

// const table_data: {
// 	header: [
// 		{accessor: "icon", display: "z", width: "10", color: "transparent"},
// 		{accessor: "name", display: "Name", width: "30", color: "#030303"},
// 		{accessor: "identifiers", display: "Identifier(s)", width: "30", color: "#030303"},
// 		{accessor: "holding", display: "Holding", width: "30", color: "#030303"}
// 	],
// 	data: [
// 		{id: "1", name: "Alliance Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "none"},
// 		{id: "2", name: "Alliance1 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "none"},
// 		{id: "3", name: "Alliance2 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "none"},
// 		{id: "4", name: "Alliance3 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "5", name: "Alliance4 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "6", name: "Alliance5 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "7", name: "Alliance6 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "8", name: "Alliance7 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "9", name: "Alliance8 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "10", name: "Alliance9 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "11", name: "Alliance10 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"}
// 	],
// 	selected: {id: "1", name: "Alliance Boosts Ltd.", identifiers: "AB:LN", holding: "1,000"}
// }



class CSLAtteachmentViewer extends React.Component {
  state = { ready: false, files: null, task_id: null, registering: false };

  setComponentState = () => {
    console.log("this.props", this.props);
    if (this.props.task_id) {
      this.setState({ ready: true, files: this.props.files, task_id: this.props.task_id });
    }
  };
  componentDidMount = () => {
    this.setComponentState();
  };
  componentDidUpdate = (prevprops) => {
    if (this.props === prevprops) return;
    this.setComponentState();
  };

  

  handleDownload(image_id, task_id) {
    let api = new APICall();
    let postData = { command: "download_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData);
    api.command(postData, this.processDownloadFile);
  }
  handleRemove(image_id) {
    let bin_files = this.state.files;
    let fls = bin_files.filter( x => x.uid !== image_id );
    console.log("bin_files", fls);
    this.setState({files: fls})
    this.props.updateAttachments(fls)
  }

  processDownloadFile = (result) => {
    console.log("Attachment", result);
    let dbinfile = result.result;
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  };



  imageUrl(file) {
    const file_parts = file.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOC";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      default:
        ext = "PDF";
        break;
    }
    console.log("ext", ext);
    let file_icon_var = ext === "DOCX" ? "DOC" : ext;
    file_icon_var = ext === "XLSX" ? "XLS" : ext;
    const file_icon = `https://cmpplus.co.uk/common-resource/Resource/Dropzone/${ext}.png`;
    return file_icon;
  }

  render() {
    if (!this.state.ready) return <div></div>;
    return (
      <div style={{ width: "100%" }}>
      		{
      			this.state.files.map((f, index) => {
      				return (
      				  <div key={index} style={{ width: "50%", boxSizing: "border-box", padding: index === 0 || index % 2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px" }}>
      				    <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
      				      <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
      				        <img src={this.imageUrl(f.name)} style={{ height: "100px", width: "auto" }} />
      				      </div>
      				      <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
      				        <div>
      				          <strong title={f.name} style={{ color: "#000000" }}>
      				            {f.name.length > 20 ? f.name.substring(0, 20) : f.name}
      				          </strong>
      				          <br />
      				          <br />
      				        </div>
      				        <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(f.uid, this.state.task_id)}>
      				          <FiDownload /> Download
      				        </div>
      				        <div style={{ cursor: "pointer", marginTop: "10px" }} onClick={() => this.handleRemove(f.uid)}>
      				          <MdClear /> Remove
      				        </div>
      				      </div>
      				      <div style={{ clear: "both" }}></div>
      				    </div>
      				  </div>
      				);
      			})
      		}
      </div>
    );
  }
}







class AddPadModal extends React.Component {

	state = {
		task_id: 0,
		task: null,
		options: [
			{id: "1", name: "Alpha"},
			{id: "2", name: "Calendar"},
			{id: "3", name: "Numeric"},
			{id: "4", name: "Dropdown"}
		],
		selected_option: {id: "1", name: "Alpha"},
		table_data: {},
		contacts: null,
		requester_obj: {},
		roles: null,
		selected_role_obj: {},
		instruction_types: null,
		selected_instruction_type: {},
		transaction_types: null,
		selected_transaction_type: {},
		counterparties: null,
		selected_counterparty: {},
		beneficiaries: null,
		selected_beneficiary: {},
		security_types: null,
		selected_security_type: {},
		transaction_date: moment().format("DD/MM/YYYY"),
		securities: null,
		selected_security: null,
		security_typedisp: null,
		selected_security_typedisp: {},
		currencies: null,
		selected_currency: {},
		trade_details: {},
		not_short: false,
		not_breach: false,
		comments: "",
		notes: {},
		show_note_modal: false,
		show_security_modal: false,
		custom_fields: null,
		ready: false,
		binFiles: [],
		cur_files_json: null,
		addnew: true,
		alert_param: 1,
		alertParam : null,
		saving: false,
		attachments: [],
		is_new_security : false,
		private_security_name : "",
		show_security_info : false,
		show_spinner : false,
		show_save_and_close_alert : false,
		register_or_save : "",
		show_api_security_note : false, 
		api_security_has_no_type : false,
		api_security_has_no_currency : false,
		api_price_not_retrievable : false,
		broker_list : [],  // employee-broker-ac change 20-03-2023
		active_brokers : [],
		no_of_active_brokers : 0,
		selected_broker_obj : { id : 0, name : "No Active Broker listed. Please add one."}
	}

	async componentDidMount(){
		// let contactsData = Store.getStoreData("company_users");
		let task = this.props.task === null ? null : JSON.parse(JSON.stringify(this.props.task.parenttask));
		// let task_json = this.props.task === null ? null : JSON.parse(task.task_json);
		const company_users = Store.getStoreData("company_users");
		const my_users = Store.getStoreData("my_users");
		const loggedin_contact_id = Store.getStoreData("loggedin_contact_id");
		let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
		let contacts = [];
		let requester_obj = {};
		for (let user_id of my_users) {
			if (user_id.toString() in company_users_obj) {
				if (company_users_obj[user_id.toString()].role !== "no_access") {
					if(task === null && company_users_obj[user_id.toString()].name !== "Inactive User"){
						contacts.push({id: company_users_obj[user_id.toString()].user_id, name: company_users_obj[user_id.toString()].name});
					} else if(task !== null){
						contacts.push({id: company_users_obj[user_id.toString()].user_id, name: company_users_obj[user_id.toString()].name});
					}	
				}
			}
		}
		requester_obj = {id: company_users_obj[loggedin_contact_id.toString()].user_id, name: company_users_obj[loggedin_contact_id.toString()].name};
		if (task !== null) requester_obj = {id: company_users_obj[task.task_json.object_data.requester_id.toString()].user_id, name: company_users_obj[task.task_json.object_data.requester_id.toString()].name};
		console.log("requester_obj:", requester_obj);

		//Employee Broker List changes
		let broker_id = 0;
		let broker_list = [];
		let selected_broker_obj = {};
		if(Store.getStoreData("module_config").general.enable_employee_broker_ac === true){
			await this.getBrokerList(requester_obj.id);
			selected_broker_obj = JSON.parse(JSON.stringify(this.state.selected_broker_obj));
			broker_list = this.state.broker_list;
			if(task !== null){
				if("broker_id" in  task.task_json.object_data.trade_details){
					broker_id = task.task_json.object_data.trade_details.broker_id;
				}
				if(broker_id !==0 ){
					for(let broker of broker_list){
						if(broker.id === broker_id){
							selected_broker_obj = {id : broker.id, name : broker.broker_name}
							if(broker.is_active === 0) selected_broker_obj.name = selected_broker_obj.name + " (Inactive)";
						}
					}
				}
			}
		}

		console.log("Broker List : ", broker_list);
		console.log("Selected Broker Obj : ", selected_broker_obj);
		//Employee Broker List changes upto here

		let security_types = [{id: "1", name: "I want to trade a Security new to me"}, {id: "2", name: "I want to trade a Security I have reported before"}];
		let selected_security_type = security_types[0];

		let custom_fields = Store.getStoreData("module_config")["custom_fields"];
		custom_fields.additional_fields.forEach(item => {
			item.value = "";
			if (item.type === "Dropdown") item.other_value = "";
		})
		if (task !== null) custom_fields = task.task_json.object_data.custom_fields;

		let rolesData = Store.getStoreData("module_config")["role_types"];
		let roles = [];
		for(let key in rolesData){
			if(rolesData[key].isActive === 1){
				roles.push({id: key, name: rolesData[key].title})
			}
		}	
		let selected_role_obj = roles[0];
		if (task !== null) selected_role_obj = roles.find(item => item.id === task.task_json.object_data.role_type_id);

		let instruction_types_data = Store.getStoreData('module_config').instruction_types;
		let instruction_types = [];
		for (let key in instruction_types_data) {
			if (instruction_types_data[key].isActive === 1) {
				instruction_types.push({id: key, name: instruction_types_data[key].title});
			}
		}
		let selected_instruction_type = instruction_types[0];
		if (task !== null) selected_instruction_type = instruction_types.find(item => item.id === task.task_json.object_data.instruction_type_id);

		let transaction_types_data = Store.getStoreData('module_config').transaction_types;
		let transaction_types = [];
		for (let key in transaction_types_data) {
			if (transaction_types_data[key].isActive === 1) {
				transaction_types.push({id: key, name: transaction_types_data[key].title});
			}
		}
		let selected_transaction_type = transaction_types[0];
		if (task !== null) selected_transaction_type = transaction_types.find(item => item.id === task.task_json.object_data.transaction_type_id);

		let counterparties_data = Store.getStoreData('module_config').counterparties;
		let counterparties = [];
		for (let key in counterparties_data) {
			if (counterparties_data[key].isActive === 1) {
				counterparties.push({id: key, name: counterparties_data[key].title});
			}
		}
		let selected_counterparty = counterparties[0];
		if (task !== null) selected_counterparty = counterparties.find(item => item.id === task.task_json.object_data.trade_details.counterparty_id);

		let beneficiaries_data = Store.getStoreData('module_config').beneficiaries;
		let beneficiaries = [];
		for (let key in beneficiaries_data) {
			if (beneficiaries_data[key].isActive === 1) {
				beneficiaries.push({id: key, name: beneficiaries_data[key].title});
			}
		}
		let selected_beneficiary = beneficiaries[0];
		
		let security_typedisp_data = Store.getStoreData('module_config').security_types;
		let security_typedisp = [];
		for (let key in security_typedisp_data) {
			if (security_typedisp_data[key].isActive === 1) {
				security_typedisp.push({id: key, name: security_typedisp_data[key].title});
			}
		}
		
		
		if (task !== null) selected_beneficiary = beneficiaries.find(item => item.id === task.task_json.object_data.trade_details.beneficiary_id);

		let not_short = this.state.not_short;
		let not_breach = this.state.not_breach;
		if (task !== null) not_short = task.task_json.object_data.not_short;
		if (task !== null) not_breach = task.task_json.object_data.not_breach;

		let company_securities = Store.getStoreData('module_config').security_register;
		let securities = [];
		let identifiers = [];
		const my_holdings = Store.getStoreData('holdings');
		const requester_holdings = this.props.task === null ? null : JSON.parse(JSON.stringify(this.props.task.holdings));
		//console.log("Requester Task : ", task);
		//console.log("My Holdings : ", my_holdings);
		console.log("Requester Holdings : ", requester_holdings);
		// console.log("company_securities:", company_securities);
		let from_holding = false;
		if(task!== null && task.cur_lane === "COMP_PAD_SAVED"){
			if((task.task_json.object_data.trade_details.security_id in requester_holdings) === true ){
				console.log("YES SECURITY IS FROM HOLDING");
				from_holding = true;
				//securities = requester_holdings;
				for (let key in requester_holdings) {
					identifiers = [];
					let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
					if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
						icon = "none";
					}
					for (let sub_key in company_securities[key].identifier_types) {
						if (company_securities[key].identifier_types[sub_key] !== "") {
							identifiers.push(company_securities[key].identifier_types[sub_key]);
						}
					}
					let identifiers_str = identifiers.join();
					identifiers_str = identifiers_str.replace(/,+/g,", ");
					securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, holding: requester_holdings[key].volume, icon: icon});
				}
			} else {
				for (let key in company_securities) {
					// if (key.toString() in my_holdings === false) {
						//change arnab
						if(Store.getStoreData("role") === "team" && company_securities[key.toString()].contact_id !== Store.getStoreData("logged_contact_id") && company_securities[key.toString()].contact_id !== 0 && Store.getStoreData('module_config').general.hide_employee_holdings === true) continue;
						if(Store.getStoreData("role") === "team" && company_securities[key.toString()].contact_id !== Store.getStoreData("logged_contact_id") && company_securities[key.toString()].source === 'private') continue;
						if(key.toString() in my_holdings === true) continue;
						identifiers = [];
						let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
						if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
							icon = "none";
						}
						for (let sub_key in company_securities[key].identifier_types) {
							if (company_securities[key].identifier_types[sub_key] !== "") {
								identifiers.push(company_securities[key].identifier_types[sub_key]);
							}
						}
						let identifiers_str = identifiers.join();
						identifiers_str = identifiers_str.replace(/,+/g,", ");
						securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, icon: icon});
					// }
				}
			}
		} else {
			if (Object.keys(company_securities).length !== 0 && (Store.getStoreData("role") !== "team" || Store.getStoreData("role") === "team" && Store.getStoreData('module_config').general.use_security_register === true)) {
				console.log("company_securities:", company_securities);
				for (let key in company_securities) {
					// if (key.toString() in my_holdings === false) {
						//change arnab
						if(Store.getStoreData("role") === "team" && company_securities[key.toString()].contact_id !== Store.getStoreData("logged_contact_id") && company_securities[key.toString()].contact_id !== 0 && Store.getStoreData('module_config').general.hide_employee_holdings === true) continue;
						if(Store.getStoreData("role") === "team" && company_securities[key.toString()].contact_id !== Store.getStoreData("logged_contact_id") && company_securities[key.toString()].source === 'private') continue;
						if(key.toString() in my_holdings === true) continue;
						identifiers = [];
						let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
						if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
							icon = "none";
						}
						for (let sub_key in company_securities[key].identifier_types) {
							if (company_securities[key].identifier_types[sub_key] !== "") {
								identifiers.push(company_securities[key].identifier_types[sub_key]);
							}
						}
						let identifiers_str = identifiers.join();
						identifiers_str = identifiers_str.replace(/,+/g,", ");
						securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, icon: icon});
					// }
				}
			} 
		}
		
		let all_securities = [];
		for (let key in company_securities) {
				let all_identifiers = [];
				let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
				if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
					icon = "none";
				}
				for (let sub_key in company_securities[key].identifier_types) {
					if (company_securities[key].identifier_types[sub_key] !== "") {
						all_identifiers.push(company_securities[key].identifier_types[sub_key]);
					}
				}
				let identifiers_str = all_identifiers.join();
				identifiers_str = identifiers_str.replace(/,+/g,", ");
				all_securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, icon: icon});
			// }
		}
		
		console.log("securities:", securities);
		let selected_security = null;
		if (task !== null) selected_security = all_securities.find(item => parseInt(item.id) === parseInt(task.task_json.object_data.trade_details.security_id));
		console.log("selected_security:", selected_security);

		let table_data = {
			header: [
				{accessor: "icon", display: "z", width: "10", color: "transparent"},
				{accessor: "name", display: "Name", width: "30", color: "#030303"},
				{accessor: "identifiers", display: "Identifier(s)", width: "30", color: "#030303"},
				// {accessor: "holding", display: "Holding", width: "30", color: "#030303"}
			],
			data: securities,
			selected: ""
		}
		//change for saved securities or registered security by team
		if (task !== null && (task.cur_lane === "COMP_PAD_SAVED" || Store.getStoreData("role") === "team" && task.cur_lane === "COMP_PAD_FOR_APPROVAL") && securities !== null) {
			if(from_holding===true) table_data.header.push({accessor: "holding", display: "Holding", width: "30", color: "#030303"});
			table_data.selected = selected_security;
			selected_security_type = security_types[1];
		}

		let company_currencies = Store.getStoreData('module_config').currencies;
		let currencies = [];
		for (let key in company_currencies) {
			if (company_currencies[key].isActive === 1) {
				currencies.push({id: key, name: company_currencies[key].title});
			}
		}
		let selected_currency = selected_security === null ? currencies[0] : currencies.find(item => item.id === company_securities[selected_security.id].currency);
		console.log("selected_currency:", selected_currency);
		if (task !== null) selected_currency = currencies.find(item => item.id === task.task_json.object_data.trade_details.currency_id);
		let trade_details = {
			broker_id: broker_id,
			security_id: selected_security === null ? 0 : selected_security.id,
			volume: "00",
			currency_id: selected_currency.id,
			price: 0.00,
			counterparty_id: selected_counterparty.id,
			beneficiary_id: selected_beneficiary.id,
			cash_consideration: 0.00
		}
		if (task !== null) {
			trade_details.volume = task.task_json.object_data.trade_details.volume;
			trade_details.price = parseFloat(task.task_json.object_data.trade_details.price);
			trade_details.cash_consideration = parseFloat(task.task_json.object_data.trade_details.cash_consideration);
		}
		let comments = task !== null ? task.task_json.object_data.requester_comments : this.state.comments;
		let notes = task !== null ? task.task_json.object_data.notes : this.state.notes;
		let attachments = task !== null ? task.task_json.object_data.attachments : this.state.attachments;

		let transaction_date = task === null ? moment().format("DD/MM/YYYY") : moment(task.task_json.object_data.transaction_date, 'DD/MM/YYYY').format('DD/MM/YYYY');
		
		console.log(beneficiaries_data);
		this.setState({
			task_id: task === null ? 0 : task.id,
			task,
			contacts,
			requester_obj,
			security_types,
			selected_security_type,
			security_typedisp,
			roles,
			selected_role_obj,
			instruction_types,
			selected_instruction_type,
			transaction_types,
			selected_transaction_type,
			counterparties,
			selected_counterparty,
			beneficiaries,
			selected_beneficiary,
			table_data,
			selected_security,
			trade_details,
			currencies,
			selected_currency,
			custom_fields,
			not_short,
			not_breach,
			comments,
			notes,
			attachments,
			transaction_date,
			selected_broker_obj,
			ready: true
		})
	}

	closeView = () => this.props.closeModal();

	setSelection = (data) => {
		let selected_option = this.state.options.find(item => item.id === data);
		this.setState({selected_option});
	}

	changeRequester = async (data) => {
		let requester_obj = this.state.contacts.find(item => item.id === data);
		this.requester_obj = requester_obj;
		if(Store.getStoreData("module_config").general.enable_employee_broker_ac === true) await this.getBrokerList(requester_obj.id); // employee-broker-ac change 20-03-2023
		const postData = {command: "get_user_holding", requester_id: requester_obj.id};
		const api = new APICall();
		api.command(postData, this.afterChangeRequester);
		// this.setState({requester_obj});
	}

	afterChangeRequester = (result) => {
		Store.updateStore("holdings", result);
		let company_securities = Store.getStoreData('module_config').security_register;
		let securities = [];
		let identifiers = [];
		const my_holdings = result;
		if (Object.keys(company_securities).length !== 0) {
			for (let key in company_securities) {
				if (key.toString() in my_holdings === false) {
					identifiers = [];
					let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
					if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
						icon = "none";
					}
					for (let sub_key in company_securities[key].identifier_types) {
						if (company_securities[key].identifier_types[sub_key] !== "") {
							identifiers.push(company_securities[key].identifier_types[sub_key]);
						}
					}
					let identifiers_str = identifiers.join();
					identifiers_str = identifiers_str.replace(/,+/g,", ");
					securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, icon: icon});
				}
			}
		}
		let selected_security = null;
		let table_data = {
			header: [
				{accessor: "icon", display: "z", width: "10", color: "transparent"},
				{accessor: "name", display: "Name", width: "30", color: "#030303"},
				{accessor: "identifiers", display: "Identifier(s)", width: "30", color: "#030303"},
				// {accessor: "holding", display: "Holding", width: "30", color: "#030303"}
			],
			data: securities,
			selected: ""
		}
		let selected_security_type = this.state.security_types[0];
		this.sec_type = selected_security_type.id;
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.security_id = selected_security === null ? 0 : selected_security.id;
		this.setState({requester_obj: this.requester_obj, securities, table_data, selected_security_type, selected_security, trade_details});
	}

	//to get list of brokers for selected requester : version employee-broker-ac
	getBrokerList = async (contact_id) => {
		//debugger;
        console.log("Contact_id : ", contact_id)
        const postData = {command: "list_brokers", requester_id: contact_id};
		const api = new APICall();
		let result = await api.commandWithoutCallback(postData);
        console.log("Broker Result : ", result);
        let broker_list = result.result;
		let active_brokers = [];
		let no_of_active_brokers = 0
		if(broker_list.length  > 0){
			for(let broker of broker_list){
				if(broker.is_active === 1) {
					no_of_active_brokers++;
					if(no_of_active_brokers === 1) active_brokers.push({ id : 0, name : "Select"});
					active_brokers.push({id : broker.id, name : broker.broker_name});
				}
			}
		}		
		console.log("Active Brokers : ", active_brokers);
		let selected_broker_obj = { id : 0, name : "No Active Broker listed. Please add one."};
		if(no_of_active_brokers > 0){
			selected_broker_obj = active_brokers[0];
		}
        this.setState({ broker_list, active_brokers, selected_broker_obj, no_of_active_brokers });
    }

	changeRole = (data) => {
		let selected_role_obj = this.state.roles.find(item => item.id === data);
		this.setState({selected_role_obj});
	}

	changeInstructionType = (data) => {
		let selected_instruction_type = this.state.instruction_types.find(item => item.id === data);
		this.setState({selected_instruction_type});
	}

	changeTransactionType = (data) => {
		let selected_transaction_type = this.state.transaction_types.find(item => item.id === data);
		this.setState({selected_transaction_type});
	}

	changeBroker = (data) => {
		//if(data === 0) return;
		let selected_broker_obj = this.state.active_brokers.find(item => item.id === data);
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.broker_id = selected_broker_obj.id;
		this.setState({selected_broker_obj, trade_details});
	}

	changeCounterparty = (data) => {
		let selected_counterparty = this.state.counterparties.find(item => item.id === data);
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.counterparty_id = selected_counterparty.id;
		this.setState({selected_counterparty, trade_details});
	}

	changeBeneficiary = (data) => {
		let selected_beneficiary = this.state.beneficiaries.find(item => item.id === data);
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.beneficiary_id = selected_beneficiary.id;
		this.setState({selected_beneficiary, trade_details});
	}

	changeSecurityType = (data) => {
		this.sec_type = data;
		const postData = {command: "get_user_holding", requester_id: this.state.requester_obj.id};
		const api = new APICall();
		api.command(postData, this.afterSecurityTypeChange);
	}

	afterSecurityTypeChange = (result) => {
		const my_holdings = result;
		Store.updateStore("holdings", result);
		let company_securities = Store.getStoreData('module_config').security_register;
		let securities = [];
		let identifiers = [];
		let selected_security = null;
		let table_data = null;
		//console.log("security type change : ", this.sec_type);
		if (parseInt(this.sec_type) === 1) {
			if (Object.keys(company_securities).length !== 0 && (Store.getStoreData("role") !== "team" || Store.getStoreData("role") === "team" && Store.getStoreData('module_config').general.use_security_register === true)) {
				for (let key in company_securities) {
					//change arnab
					//if(Store.getStoreData("role") === "team" && Store.getStoreData('module_config').use_security_register === false) continue;
					if(Store.getStoreData("role") === "team" && company_securities[key.toString()].contact_id !== Store.getStoreData("logged_contact_id") && company_securities[key.toString()].contact_id !== 0 && Store.getStoreData('module_config').general.hide_employee_holdings === true) continue;
					if(Store.getStoreData("role") === "team" && company_securities[key.toString()].contact_id !== Store.getStoreData("logged_contact_id") && company_securities[key.toString()].source === 'private') continue;
					if (key.toString() in my_holdings === false) {
						identifiers = [];
						let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
						if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
							icon = "none";
						}
						for (let sub_key in company_securities[key].identifier_types) {
							if (company_securities[key].identifier_types[sub_key] !== "") {
								identifiers.push(company_securities[key].identifier_types[sub_key]);
							}
						}
						let identifiers_str = identifiers.join();
						identifiers_str = identifiers_str.replace(/,+/g,", ");
						securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, icon: icon});
					}
				}
			}
		} else {
			if (Object.keys(my_holdings).length !== 0) {
				for (let key in my_holdings) {
					identifiers = [];
					let icon = company_securities[key.toString()].is_restricted === true ? "Warning" : "none";
					if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
						icon = "none";
					}
					for (let sub_key in company_securities[key].identifier_types) {
						if (company_securities[key].identifier_types[sub_key] !== "") {
							identifiers.push(company_securities[key].identifier_types[sub_key]);
						}
					}
					let identifiers_str = identifiers.join();
					identifiers_str = identifiers_str.replace(/,+/g,", ");
					securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers_str, holding: my_holdings[key].volume, icon: icon});
				}
			}
		}
		selected_security = null;
		table_data = {
			header: [
				{accessor: "icon", display: "z", width: "10", color: "transparent"},
				{accessor: "name", display: "Name", width: "30", color: "#030303"},
				{accessor: "identifiers", display: "Identifier(s)", width: "30", color: "#030303"}
			],
			data: securities,
			selected: selected_security
		}
		if (parseInt(this.sec_type) !== 1) {
			table_data.header.push({accessor: "holding", display: "Holding", width: "30", color: "#030303"});
			table_data.selected = "";
		}
		let selected_security_type = this.state.security_types.find(item => item.id === this.sec_type);
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.security_id = selected_security === null ? 0 : selected_security.id;
		this.setState({selected_security_type, securities, selected_security, table_data, trade_details});
	}

	//to select any security from database
	changeSecurity = (data) => {
		let table_data = JSON.parse(JSON.stringify(this.state.table_data));
		table_data.selected = data;
		let trade_details = {
			broker_id : this.state.selected_broker_obj.id,
			security_id: data.id,
			volume: "00",
			currency_id: Store.getStoreData('module_config').security_register[data.id].currency,
			price: 0.00,
			counterparty_id: this.state.selected_counterparty.id,
			beneficiary_id: this.state.selected_beneficiary.id
		}
		let selected_currency = this.state.currencies.find(item => item.id === Store.getStoreData('module_config').security_register[data.id].currency);
		this.setState({table_data, selected_security: data, trade_details, selected_currency, show_security_info : true, show_api_security_note : false});
	}

	//to select any security from api
	setApiSecurity = (data) => {
		let trade_details = {};
		let selected_currency = {};
		let currencies = [];
		let api_security_has_no_type = true;
		let api_security_has_no_currency = true;
		let api_price_not_retrievable = false;
		console.log("DATA : ", data);
		console.log("CURRENCIES : ", currencies);
		let selected_service = Store.getStoreData('module_config').general.security_hot_lookup.selected_service;
		switch(selected_service){
			case "yahoo_fin" :
				selected_currency = {}
				trade_details = {
					broker_id : this.state.selected_broker_obj.id,
					security_id: data.id,
					volume: "00",
					currency_id: this.state.currencies.find(item => item.name === "GBP").id,
					price: 0.00,
					counterparty_id: this.state.selected_counterparty.id,
					beneficiary_id: this.state.selected_beneficiary.id,
					from_api: true
				}
				selected_currency = this.state.currencies.find(item => item.name === "GBP");
				break;
			case "alpha" :
				selected_currency = {}
				currencies = JSON.parse(JSON.stringify(this.state.currencies));
				let s_currency = currencies.find(item => item.name === data.currency);
				let currency_id = 0;
				currency_id = s_currency !== undefined ? s_currency.id : undefined;
				if(currency_id === null || currency_id === undefined) {
					currency_id = Utils.genKey(8);
					selected_currency = {id : currency_id, name : data.currency};
					currencies.push(selected_currency);
					//this.setState({currencies, selected_currency});
				}
				else{
					selected_currency = this.state.currencies.find(item => item.name === data.currency);
					//this.setState({selected_currency});
				}
				trade_details = {
					broker_id : this.state.selected_broker_obj.id,
					security_id: data.id,
					volume: "00",
					currency_id: selected_currency.id,
					//currency : data.currency,
					price: 0.00,
					counterparty_id: this.state.selected_counterparty.id,
					beneficiary_id: this.state.selected_beneficiary.id,
					from_api: true
				}
				break;
			case "market_stack" :
				selected_currency = {}
				api_security_has_no_type = true;
				api_security_has_no_currency = true;
				if(data.has_eod === false) api_price_not_retrievable = true;
				currencies = JSON.parse(JSON.stringify(this.state.currencies));
				currencies.push({id: "0", name: "Select"});
				selected_currency = currencies.find(item => item.id === "0");
				let security_typedisp = [];
				security_typedisp = JSON.parse(JSON.stringify(this.state.security_typedisp));
				security_typedisp.push({id: "0", name: "Select"});
				let selected_security_typedisp = security_typedisp.find(item => item.id === "0");
				trade_details = {
					broker_id : this.state.selected_broker_obj.id,
					security_id: data.id,
					volume: "00",
					currency_id: null,
					//currency : null,
					price: 0.00,
					counterparty_id: this.state.selected_counterparty.id,
					beneficiary_id: this.state.selected_beneficiary.id,
					from_api: true
				}
				this.setState({api_security_has_no_currency, api_security_has_no_type, api_price_not_retrievable, security_typedisp, selected_security_typedisp});
				break;
			case "eodhd" :
				selected_currency = {}
				currencies = JSON.parse(JSON.stringify(this.state.currencies));
				let e_currency = currencies.find(item => item.name === data.currency);
				let e_currency_id = 0;
				e_currency_id = e_currency !== undefined ? e_currency.id : undefined;
				if(e_currency_id === null || e_currency_id === undefined) {
					e_currency_id = Utils.genKey(8);
					selected_currency = {id : e_currency_id, name : data.currency};
					currencies.push(selected_currency);
					//this.setState({currencies, selected_currency});
				}
				else{
					selected_currency = this.state.currencies.find(item => item.name === data.currency);
					//this.setState({selected_currency});
				}
				trade_details = {
					broker_id : this.state.selected_broker_obj.id,
					security_id: data.id,
					volume: "00",
					currency_id: selected_currency.id,
					//currency : null,
					price: data.price,
					counterparty_id: this.state.selected_counterparty.id,
					beneficiary_id: this.state.selected_beneficiary.id,
					from_api: true
				}
				console.log("EODHD Trade Details after setting price : ", trade_details);
				break;
		}
		//selected_currency = this.state.currencies.find(item => item.name === "GBP");
		this.setState({selected_security: data, trade_details, selected_currency, currencies, show_api_security_note : true});
		if("has_eod" in data && data.has_eod === false) return;
		if("price" in data) return;
		if (Store.getStoreData('module_config').general.security_hot_lookup.retrieve_indicative_price === true) {
			let api = new APICall();
			let postData = { command: "financial_services", action: "get_quote", symbol : data.symbol };
			console.log("postData", postData);
			api.command(postData, this.processQuote);
		}
	}

	processQuote = (result) => {
		console.log(result);
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.price = result.result.price;
		let selected_service = Store.getStoreData('module_config').general.security_hot_lookup.selected_service;
		switch(selected_service){
			case "yahoo_fin" :
				let currencies = JSON.parse(JSON.stringify(this.state.currencies));
				let selected_currency = currencies.find(item => item.name === result.result.currency);
				if (selected_currency === undefined) {
					selected_currency = {id: Utils.genKey(8), name: result.result.currency};
					currencies.push(selected_currency);
				}
				trade_details.currency_id = selected_currency.id;
				console.log("Yahoo fin Trade Details after setting price : ", trade_details);
				this.setState({trade_details, currencies, selected_currency,  show_security_info : true});
				break;
			case "alpha" :
				console.log("Alpha Vantage Trade Details after setting price : ", trade_details);
				this.setState({trade_details, show_security_info : true});
				break;
			case "market_stack" :
				console.log("Marketstack Trade Details after setting price : ", trade_details);
				this.setState({trade_details, show_security_info : true});
				break;
		}
	}

	changeCurrency = (data) => {
		let selected_currency = this.state.currencies.find(item => item.id === data);
		console.log("selected_currency:", selected_currency);
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.currency_id = selected_currency.id;
		let selected_security = JSON.parse(JSON.stringify(this.state.selected_security));
		if(this.state.api_security_has_no_currency === true){
			selected_security.currency = this.state.currencies.find(item => item.id === data).name;
		}
		console.log("Trade Details after Change currency : ", trade_details);
		console.log("Selected security after Change currency : ", selected_security);
		this.setState({selected_currency, trade_details, selected_security});
	}

	changeVolume = (event) => {
		event.preventDefault();
		if (!/^[0-9.]+$/.test(event.target.value) === false || event.target.value === '') {
			let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
			trade_details.volume = event.target.value;
			trade_details.cash_consideration = (parseFloat(trade_details.volume) * parseFloat(trade_details.price)).toFixed(2);
			this.setState({trade_details});
		}
	}

	changeSecurityTypedisp = (data) =>{
		let selected_security = JSON.parse(JSON.stringify(this.state.selected_security));
		selected_security.typeDisp = this.state.security_typedisp.find(item => item.id === data).name;
		let selected_security_typedisp = this.state.security_typedisp.find(item => item.id === data);
		console.log("Selected Security after typeDisp change : ", selected_security);
		this.setState({selected_security, selected_security_typedisp});
	}

	changePrice = (event) => {
		event.preventDefault();
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.price = event.target.value;
		trade_details.cash_consideration = (parseFloat(trade_details.volume) * parseFloat(trade_details.price)).toFixed(2);
		this.setState({trade_details});
	}

	changeCashConsideration = (event) => {
		event.preventDefault();
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		trade_details.cash_consideration = event.target.value !== "" ? event.target.value : 0.00 ;
		this.setState({trade_details});
	}

	changeCheck = (entity, status) => (event) => {
		event.preventDefault();
		this.setState({[entity]: status});
	}

	changeComments = (event) => {
		event.preventDefault();
		this.setState({comments: event.target.value});
	}

	toggleNoteModal = (status) => (event) => {
		event.preventDefault();
		this.setState({show_note_modal: status});
	}

	closeNoteModal = () => {
		this.setState({show_note_modal: false});
	}

	addNote = (obj) => {
		let notes = JSON.parse(JSON.stringify(this.state.notes));
		notes[Utils.genKey(10)] = obj;
		this.setState({notes, show_note_modal: false});
	}

	saveAndClose = (type) => (event) =>{
		event.preventDefault();
		console.log("Saving");
		let alertParam = {};
		let error = false;
		if (this.state.custom_fields.enabled === true && this.state.custom_fields.additional_fields.length !== 0 && type === "register") {
			this.state.custom_fields.additional_fields.forEach(item => {
				if (item.type === "Dropdown") {
					if (item.required === true && item.value === "" && item.other_value === "") error = true;
				} else {
					if (item.required === true && item.value === "") error = true;
				}
			})
		}
		if(this.state.api_security_has_no_type === true && this.state.selected_security_typedisp.id === "0") error = true;
		console.log("Trade Details : ", this.state.trade_details);
		if(Store.getStoreData('module_config').general.enable_employee_broker_ac === true && this.state.trade_details.broker_id === 0) error = true; //Employee_broker_ac change
		if (error === true) {
			alertParam = {
				"header" : "PAD Save & Close Error",
				"subheader" : "",
				"msg" : "Mandatory fields are marked with asterisk. Please fill in the fields.",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Mandatory fields are marked with asterisk. Please fill in the fields.");
			return false;
		}
		if (parseInt(this.state.trade_details.security_id) === 0) {
			alertParam = {
				"header" : "PAD Save & Close Error",
				"subheader" : "",
				"msg" : "Please choose a security",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Please choose a security");
			return false;
		}
		if (parseInt(this.state.selected_currency.id) === 0) {
			alertParam = {
				"header" : "PAD Save & Close Error",
				"subheader" : "",
				"msg" : "Please choose a currency",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Please choose a currency");
			return false;
		}
		// if (Store.getStoreData("module_config").general.checkbox_short === true && this.state.not_short === false) {
		// 	alertParam = {
		// 		"header" : "PAD Save & Close Error",
		// 		"subheader" : "",
		// 		"msg" : "Please confirm that this trade does not take the beneficial owner \"Short\"",
		// 		"closefunc" : this.closeSaveAndCloseAlert,
		// 		"buttons" : {
		// 			"1" : {
		// 				"caption" : "OK",
		// 				"bgcolor" : "#4472C4",
		// 				"color" : "white",
		// 				"callback" : this.closeSaveAndCloseAlert
		// 			},
		// 		}
		// 	}
		// 	this.setState({ show_save_and_close_alert : true, alertParam});
		// 	return false;
		// }
		// if (Store.getStoreData("module_config").general.checkbox_short === true && this.state.not_breach === false) {
		// 	alertParam = {
		// 		"header" : "PAD Save & Close Error",
		// 		"subheader" : "",
		// 		"msg" : "Please confirm that this trade does not breach Regulator or Company rules or policies",
		// 		"closefunc" : this.closeSaveAndCloseAlert,
		// 		"buttons" : {
		// 			"1" : {
		// 				"caption" : "OK",
		// 				"bgcolor" : "#4472C4",
		// 				"color" : "white",
		// 				"callback" : this.closeSaveAndCloseAlert
		// 			},
		// 		}
		// 	}
		// 	this.setState({ show_save_and_close_alert : true, alertParam});
		// 	return false;
		// }
		alertParam = {
			"header" : "PAD Save & Close",
			"subheader" : "",
			"msg" : "This register entry is now saved, please note your PAD entry has not been submitted. By saving this request you can submit at a later date.",
			"closefunc" : this.closeSaveAndCloseAlert,
			"buttons" : {
				"1" : {
					"caption" : "OK",
					"bgcolor" : "#4472C4",
					"color" : "white",
					"callback" : this.proceedToSaveAndClose
				},
				"2" : {
					"caption" : "Cancel",
					"bgcolor" : "#203864",
					"color" : "white",
					"callback" : this.closeSaveAndCloseAlert
				}
			}
		}
		this.setState({ show_save_and_close_alert : true, alertParam, register_or_save : type });
	}
	
	closeSaveAndCloseAlert = () => {
		console.log("Save and Close Alert Closed for Registration")
		this.setState({ show_save_and_close_alert : false })
	}

	proceedToSaveAndClose = () => {
		this.submitTask(this.state.register_or_save);
		console.log("Save and Close Alert Closed. Value of type : ", this.state.register_or_save)
		this.setState({ show_save_and_close_alert : false })
		//this.submitTask(this.state.register_or_save);
	}

	proceedToRegister = (type) => (event) => {
		event.preventDefault();
		let error = false;
		let alertParam = {};
		if (this.state.custom_fields.enabled === true && this.state.custom_fields.additional_fields.length !== 0 && type === "register") {
			this.state.custom_fields.additional_fields.forEach(item => {
				if (item.type === "Dropdown") {
					if (item.required === true && item.value === "" && item.other_value === "") error = true;
				} else {
					if (item.required === true && item.value === "") error = true;
				}
			})
		}
		if(this.state.api_security_has_no_type === true && this.state.selected_security_typedisp.id === "0") error = true;
		console.log("Trade Details : ", this.state.trade_details);
		if(Store.getStoreData('module_config').general.enable_employee_broker_ac === true && this.state.trade_details.broker_id === 0) error = true;  //Employee_broker_ac change
		if (error === true) {
			alertParam = {
				"header" : "PAD Register Error",
				"subheader" : "",
				"msg" : "Mandatory fields are marked with asterisk. Please fill in the fields.",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Mandatory fields are marked with asterisk. Please fill in the fields.");
			return false;
		}
		if (parseInt(this.state.trade_details.security_id) === 0) {
			alertParam = {
				"header" : "PAD Register Error",
				"subheader" : "",
				"msg" : "Please choose a security",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Please choose a security");
			return false;
		}
		if (parseInt(this.state.selected_currency.id) === 0) {
			alertParam = {
				"header" : "PAD Register Error",
				"subheader" : "",
				"msg" : "Please choose a currency",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Please choose a currency");
			return false;
		}
		if (!(this.state.requester_obj.id in Store.getStoreData('module_config').users_role_type)) {
			alertParam = {
				"header" : "PAD Register Error",
				"subheader" : "",
				"msg" : "Your employee account is not linked to an active role. Please contact your system administrator.",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Please choose a currency");
			return false;
		}
		// if (Store.getStoreData("module_config").general.checkbox_short === true && this.state.not_short === false) {
		// 	alertParam = {
		// 		"header" : "PAD Register Error",
		// 		"subheader" : "",
		// 		"msg" : "Please confirm that this trade does not take the beneficial owner \"Short\"",
		// 		"closefunc" : this.closeSaveAndCloseAlert,
		// 		"buttons" : {
		// 			"1" : {
		// 				"caption" : "OK",
		// 				"bgcolor" : "#4472C4",
		// 				"color" : "white",
		// 				"callback" : this.closeSaveAndCloseAlert
		// 			},
		// 		}
		// 	}
		// 	this.setState({ show_save_and_close_alert : true, alertParam});
		// 	return false;
		// }
		// if (Store.getStoreData("module_config").general.checkbox_short === true && this.state.not_breach === false) {
		// 	alertParam = {
		// 		"header" : "PAD Register Error",
		// 		"subheader" : "",
		// 		"msg" : "Please confirm that this trade does not breach Regulator or Company rules or policies",
		// 		"closefunc" : this.closeSaveAndCloseAlert,
		// 		"buttons" : {
		// 			"1" : {
		// 				"caption" : "OK",
		// 				"bgcolor" : "#4472C4",
		// 				"color" : "white",
		// 				"callback" : this.closeSaveAndCloseAlert
		// 			},
		// 		}
		// 	}
		// 	this.setState({ show_save_and_close_alert : true, alertParam});
		// 	return false;
		// }
		this.submitTask(type);	
	}

	submitTask = (type) => {
		//event.preventDefault();
		// console.log("trade_details in submit:", this.state.trade_details);
		// return false;
		this.type = type;
		// let error = false;
		// if (this.state.custom_fields.enabled === true && this.state.custom_fields.additional_fields.length !== 0 && this.type === "register") {
		// 	this.state.custom_fields.additional_fields.forEach(item => {
		// 		if (item.type === "Dropdown") {
		// 			if (item.required === true && item.value === "" && item.other_value === "") error = true;
		// 		} else {
		// 			if (item.required === true && item.value === "") error = true;
		// 		}
		// 	})
		// }
		// if (error === true) {
		// 	alert("Mandatory fields are marked with asterisk. Please fill in the fields.");
		// 	return false;
		// }
		// if (parseInt(this.state.trade_details.security_id) === 0) {
		// 	alert("Please choose a security");
		// 	return false;
		// }

		this.setState({registering: true, show_spinner : true});
		let bin_files = this.state.binFiles;
		if(bin_files.length === 0) {
			const module_config = Store.getStoreData("module_config");
			let general = JSON.parse(JSON.stringify(module_config.general));
			// delete general.email_remainder_enable;
			delete general.holding_period_days;
			delete general.track_employee_position;
			delete general.validity_window_hours;
			delete general.automatic_rejection_advices;
			let cur_lane = "COMP_PAD_REGISTERED";
			let approval_type = "none";
			let sent_mail_type = "none";

			// console.log("requester_id===>", module_config.users_role_type[this.state.requester_obj.id])

			let company_securities = JSON.parse(JSON.stringify(module_config.security_register));
			if (module_config.general.request_type === "notification") {
				if (module_config.general.require_confirmation_by_manager === false) {
					cur_lane = "COMP_PAD_COMPLETED";
				}
				// if(module_config.role_types[this.state.selected_role_obj.id].approver_level === "ad"){
				if(module_config.users_role_type[this.state.requester_obj.id].approver_level === "ad"){
					cur_lane = "COMP_PAD_REJECTED";
					// sent_mail_type = "rejected";
					sent_mail_type = "autoDeclined";
				}
				// if('id' in this.state.selected_security && this.state.selected_security.id in company_securities && company_securities[this.state.selected_security.id.toString()].is_restricted === true && 'show_auto_decline' in module_config.general && module_config.general.show_auto_decline){
				if('id' in this.state.selected_security && this.state.selected_security.id in company_securities && this.state.selected_security.icon === 'Warning' && 'show_auto_decline' in module_config.general && module_config.general.show_auto_decline){
					cur_lane = "COMP_PAD_REJECTED";
					sent_mail_type = "autoDeclined";
				}
			} else {				
				approval_type = module_config.users_role_type[this.state.requester_obj.id].approver_level;

				cur_lane = "COMP_PAD_FOR_APPROVAL";	
				sent_mail_type = this.state?.task_id === 0 ? sent_mail_type : "forApproval";
				// if (module_config.users_role_type[this.state.requester_obj.id].approver_level === "nr") {
				if (approval_type === "nr") {
					cur_lane = "COMP_PAD_COMPLETED";
					if (module_config.general.confirm_trade_information === true) {
						cur_lane = "COMP_PAD_APPROVED";
					}
				}
				
				// if(module_config.users_role_type[this.state.requester_obj.id].approver_level === "ad"){
				if(approval_type === "ad"){
					cur_lane = "COMP_PAD_REJECTED";
					sent_mail_type = "autoDeclined";
				}
				
				if('id' in this.state.selected_security && this.state.selected_security.id in company_securities && this.state.selected_security.icon === 'Warning' && 'show_auto_decline' in module_config.general && module_config.general.show_auto_decline){
					cur_lane = "COMP_PAD_REJECTED";
					sent_mail_type = "autoDeclined";					
				}
			}
			if (type === "save") cur_lane = "COMP_PAD_SAVED";
			let task_json = {		
				task_data: this.state.task_id === 0 ? { due_date: moment().format('YYYY-MM-DD') } : this.state.task.task_json.task_data,
				object_data: {},
				action_data: [],
				api_security : this.state.selected_security,
				api_currency : this.state.selected_currency
			};
			if (this.state.task_id !== 0) {
				task_json.action_data = {
					action: cur_lane,
					actiondate: Date.now(),
					assigned_to: 0,
					cur_lane: cur_lane,
					performed_by: Store.getStoreData("loggedin_contact_id")
				}
			}
			const postData = {
				command: this.state.task_id === 0 ? "create_task" : "update_task", // "create_task" or "update_task"
				task_json,
				bin_files: [],
				submit: 1,
				is_gc: false,
				gc_company_id: 3121,
				cur_lane: cur_lane,
				taskid: this.state.task_id,
				sent_mail_type
			};	
			// const status = "COMP_PADREG_REGISTERED";			

			postData.task_json.object_data = {
				"module_config": {general: general},
				"name": "",
				"description": "", 
				"requester_id": this.state.requester_obj.id,
				// "role_type_id": this.state.selected_role_obj.id,
				"role_type_id": module_config.users_role_type[this.state.requester_obj.id].role_id.toString(),
				"instruction_type_id": this.state.selected_instruction_type.id,
				"transaction_type_id": this.state.selected_transaction_type.id,
				"transaction_date": this.state.transaction_date,
				"trade_details": this.state.trade_details,
				"not_short": this.state.not_short,
				"not_breach": this.state.not_breach,
				"requester_comments": this.state.comments,
				"attachments":[],
				"notes": this.state.notes,
				"custom_fields": this.state.custom_fields,
				"approval_type": approval_type,
				"selected_security": this.state.selected_security
			};
			if (type !== "save") {
				postData.task_json.object_data.disclosure_time = moment().unix();
			}
			
			console.log("postData:", postData);	
			let api = new APICall();	
			api.command(postData, this.processData);
		}else{
			let dnld = bin_files[0].bin_file.replace(/ /g, "+");
			let binaryString = window.atob(dnld);
			var fileSize = binaryString.length;
			if(fileSize > 5000000){
			  let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
			  this.setState({ alert_param: alert_param });

			}else{
			  this.refs.dropZone.uploadFilesFromChild(bin_files);
			}
		}

	}

	processData = (result) => {
		console.log("result==>", result);
		window.location.reload();
	};

	createNewSecurity = (security) => {
		console.log(security);
		let api = new APICall();
		api.command(security, this.afterCreateSecurity);
	}

	afterCreateSecurity = (result) => {
		console.log(result);
		result = result.result;
		let module_config = Store.getStoreData('module_config');
		let company_securities = module_config.security_register;
		console.log("company_securities:", company_securities);
		const lookup_json = JSON.parse(result.lookup_json);
		const new_security = {
			[result.id.toString()]: {
				security_name: result.name,
				security: lookup_json.security_type_id,
				currency: lookup_json.currency_id,
				is_restricted: lookup_json.is_restricted,
				identifier_types: lookup_json.identifier_types
			}
		};
		company_securities[result.id.toString()] = new_security[result.id.toString()];
		module_config.company_securities = company_securities;
		Store.updateStore('module_config', module_config);
		let table_data = JSON.parse(JSON.stringify(this.state.table_data));
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
		let selected_security = JSON.parse(JSON.stringify(this.state.selected_security));
		let identifiers = [];
		for (let key in new_security) {
			let icon = new_security[key.toString()].is_restricted === true ? "Warning" : "none";
			if (Store.getStoreData("role") === "team" && Store.getStoreData("module_config").general.show_restricted_security_information === false) {
				icon = "none";
			}
			identifiers = [];
			for (let sub_key in new_security[key].identifier_types) {
				if (new_security[key].identifier_types[sub_key] !== "") {
					identifiers.push(new_security[key].identifier_types[sub_key]);
				}
			}
			let identifiers_str = identifiers.join();
			identifiers_str = identifiers_str.replace(/,+/g,", ");
			table_data.data.push({id: key, name: new_security[key].security_name, identifiers: identifiers_str, icon: icon});
			table_data.selected = {id: key, name: new_security[key].security_name, identifiers: identifiers_str, icon: icon};
			selected_security = {id: key, name: new_security[key].security_name, identifiers: identifiers_str, icon: icon};
			trade_details.security_id = key;
			// securities.push({id: key, name: company_securities[key].security_name, identifiers: identifiers.join(), holding: 1200, icon: "Warning"});
		}
		this.setState({table_data, trade_details, selected_security, show_security_modal: false});
	}

	closeSecurityModal = () => {
		this.setState({show_security_modal: false});
	}

	showCreateSecurityModal = () => {
		this.setState({show_security_modal: true});
	}
	
	// saveAndClose = (event) => {
	// 	event.preventDefault();
	// 	console.log("saveAndClose");
	// }

	changeFieldValue = (field_name, value) => {
		console.log(`${field_name} | ${value}`);
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.additional_fields.forEach(item => {
			if (item.name.toString() === field_name.toString()) {
				item.value = value;
			}
		})
		this.setState({custom_fields});
	}

	changeOtherValue = (field_name, value) => {
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.additional_fields.forEach(item => {
			if (item.name.toString() === field_name.toString()) {
				item.other_value = value;
			}
		})
		this.setState({custom_fields});
	}

	changeDropdownValue = (option_id, field_name) => {
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.additional_fields.forEach(item => {
            if (item.name.toString() === field_name.toString()) {
				if (option_id === "Select") {
                    item.value = "";
                    item.other_value = ""
                } else if (option_id === "other") {
                    item.value = "other";
                    item.other_value = "";
                } else {
                    item.value = option_id;
                }
			}
        })
		this.setState({custom_fields});
	}

	processORcancel = (result, stack) => {
	  if (!result || stack.prevent) {
	    this.setState({ alert_param: null });
	    return;
	  }
	  this.setState({ alert_param: null });
	  let bin_files = this.state.binFiles;
	  this.refs.dropZone.uploadFilesFromChild(bin_files);
	}

	filesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_contact_id");
	  let a = []
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	    // a.push({uid: files[i].uid, name: files[i].name})
	  }
	  //let document_info = this.state.document_info;
	  //document_info["file_name"] = files.length > 0 ? files[0].name : "";
	  console.log("files a", a)
	  this.setState({binFiles: files,addnew: false});
	};

	onUploadComplete = (files) => {
		// alert("hello", files)
		let alertParam = {};
		if (!(this.state.requester_obj.id in Store.getStoreData('module_config').users_role_type)) {
			alertParam = {
				"header" : "PAD Register Error",
				"subheader" : "",
				"msg" : "Your employee account is not linked to an active role. Please contact your system administrator.",
				"closefunc" : this.closeSaveAndCloseAlert,
				"buttons" : {
					"1" : {
						"caption" : "OK",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeSaveAndCloseAlert
					},
				}
			}
			this.setState({ show_save_and_close_alert : true, alertParam});
			//alert("Please choose a currency");
			return false;
		}

		let returnedFiles = JSON.parse(files);
		console.log("hello==>", returnedFiles)
		let bin_files = this.state.binFiles;
		let attachments = this.state.attachments;
		let contact_id = Store.getStoreData("loggedin_contact_id");
		if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
		bin_files.forEach((file) => {
			let fileName = file.name;
			let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
			delete file.bin_file;
			file.ref_id = refID;
			attachments.push({uid: file.uid, name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time})
		});
		}
		const module_config = Store.getStoreData("module_config");
		let general = JSON.parse(JSON.stringify(module_config.general));
		delete general.email_remainder_enable;
		delete general.holding_period_days;
		delete general.track_employee_position;
		delete general.validity_window_hours;
		delete general.automatic_rejection_advices;
		let cur_lane = "COMP_PAD_REGISTERED";
		let approval_type = "none";
		if (module_config.general.request_type === "notification") {
			if (module_config.general.require_confirmation_by_manager === false) {
				cur_lane = "COMP_PAD_COMPLETED";
			}
		} else {			
			approval_type = module_config.users_role_type[this.state.requester_obj.id].approver_level;

			cur_lane = "COMP_PAD_FOR_APPROVAL";			
			
			// if (module_config.users_role_type[this.state.requester_obj.id].approver_level === "nr") {
			if (approval_type === "nr") {
				cur_lane = "COMP_PAD_COMPLETED";
				if (module_config.general.confirm_trade_information === true) {
					cur_lane = "COMP_PAD_APPROVED";
				}
			}
		}
		if (this.type === "save") cur_lane = "COMP_PAD_SAVED";
		let task_json = {		
			task_data: this.state.task_id === 0 ? { due_date: moment().format('YYYY-MM-DD') } : this.state.task.task_json.task_data,
			object_data: {},
			action_data: [],
			api_security : this.state.selected_security,
			api_currency : this.state.selected_currency
		};
		if (this.state.task_id !== 0) {
			task_json.action_data = {
				action: cur_lane,
				actiondate: Date.now(),
				assigned_to: 0,
				cur_lane: cur_lane,
				performed_by: Store.getStoreData("loggedin_contact_id")
			}
		}
		const postData = {
			command: this.state.task_id === 0 ? "create_task" : "update_task", // "create_task" or "update_task"
			task_json,
			bin_files: attachments,
			submit: 1,
			is_gc: false,
			gc_company_id: 3121,
			cur_lane: cur_lane,
			taskid: this.state.task_id
		};		

		postData.task_json.object_data = {
			"module_config": {general: general},
			"name": "",
			"description": "", 
			"requester_id": this.state.requester_obj.id,
			// "role_type_id": this.state.selected_role_obj.id,
			"role_type_id": module_config.users_role_type[this.state.requester_obj.id].role_id.toString(),
			"instruction_type_id": this.state.selected_instruction_type.id,
			"transaction_type_id": this.state.selected_transaction_type.id,
			"transaction_date": this.state.transaction_date,
			"trade_details": this.state.trade_details,
			"not_short": this.state.not_short,
			"not_breach": this.state.not_breach,
			"requester_comments": this.state.comments,
			"attachments": attachments,
			"notes": this.state.notes,
			"custom_fields": this.state.custom_fields,
			"approval_type": approval_type
		};
		if (this.type !== "save") {
			postData.task_json.object_data.disclosure_time = moment().unix();
		}
		console.log("postData:", postData);	
		let api = new APICall();	
		api.command(postData, this.processData);
	}

	updateAttachments = (bin_files) => {
		// let attachments = this.state.attachments;
		// task['attachments'] = bin_files;
		console.log("bin_files attachments", bin_files)
		this.setState({attachments: bin_files})
	}

	callback1 = () => {
		console.log("Callback 1");
	}

	callback2 = () => {
		console.log("Callback 2");
	}

	closeYesNO = () => {
		console.log("Yes No Closed");
	}

	toggleIsNewSecurity = (event) => {
        event.preventDefault();
        //let general = JSON.parse(JSON.stringify(this.state.general));
        let is_new_security = this.state.is_new_security;
		is_new_security = is_new_security === true ? false : true;
		let show_security_info = this.state. show_security_info;
		show_security_info = show_security_info === true ? false : true;
		let trade_details = {
			security_id: this.state.private_security_name,
			volume: "00",
			currency_id: this.state.currencies.find(item => item.name === "GBP").id,
			price: 0.00,
			counterparty_id: this.state.selected_counterparty.id,
			beneficiary_id: this.state.selected_beneficiary.id,
			private: true
		}
		let selected_security = {id : Utils.genKey(8), name : this.state.private_security_name, identifiers : "", icon: "none" }
		let selected_currency = this.state.currencies.find(item => item.name === "GBP");
		this.setState({selected_security, trade_details, selected_currency, is_new_security,show_security_info});
    }

	inputPrivateSecurity = (event) => {
		event.preventDefault();
		let trade_details = JSON.parse(JSON.stringify(this.state.trade_details))
		let private_security_name = event.target.value;
		let selected_security = JSON.parse(JSON.stringify(this.state.selected_security))
		selected_security.name = private_security_name;
		trade_details.security_id = private_security_name;
		this.setState({ private_security_name, trade_details, selected_security });
		
	}

	render () {
		if(!this.state.ready){
			return(<div>Loading...</div>)
		}
		// console.log("selected_security:", this.state.selected_security);
		console.log("this.props.add_readonly:", this.props.add_readonly);
		console.log("this.state===>", this.state)
		const module_config = Store.getStoreData("module_config");
		const my_holdings = Store.getStoreData("holdings");
		const automatic_rejection_advices = {};
		for (let item of module_config.general.automatic_rejection_advices) {
			automatic_rejection_advices[item.identifier] = {text: item.text, show: item.show_on_entry_form};
		}
		if (Store.getStoreData("role") === "team") {
			automatic_rejection_advices.restricted_security.show = module_config.general.show_restricted_security_information;
		}
		console.log("automatic_rejection_advices:", automatic_rejection_advices);
		console.log("Add manadatory checkboxes : ", module_config.general.add_mandatory_checkboxes);
		console.log("Short Breach : ", module_config.general.checkbox_short, module_config.general.checkbox_breach);
		let warning = {short: false, breach: false, restrict: false, holding: false, past: false};
		if(module_config.general.checkbox_short === true || module_config.general.checkbox_breach === true) {
			module_config.general.add_mandatory_checkboxes = module_config.general.add_mandatory_checkboxes === false ? true : module_config.general.add_mandatory_checkboxes;
		}
		if (module_config.general.add_mandatory_checkboxes === true) {
			if (automatic_rejection_advices.short_trade.show === true && module_config.general.checkbox_short === true && this.state.not_short === false && automatic_rejection_advices.short_trade.show === true) warning.short = true;
			if (automatic_rejection_advices.breaches_confirmation.show === true && module_config.general.checkbox_breach === true && this.state.not_breach === false && automatic_rejection_advices.breaches_confirmation.show === true) warning.breach = true;
		}
		if (module_config.general.request_type === "approval" && automatic_rejection_advices.past_trade_date.show === true) {
			if (moment(this.state.transaction_date, 'DD/MM/YYYY').set('hour', 23).set('minute', 59).set('second', 59).unix() < moment().unix() && automatic_rejection_advices.past_trade_date.show === true) warning.past = true;
		}
		if (this.state.selected_security !== null) {
			if (this.state.selected_security.icon === "Warning" && automatic_rejection_advices.restricted_security.show === true && automatic_rejection_advices.restricted_security.show === true) warning.restrict = true;
		}
		if (this.state.selected_security !== null && automatic_rejection_advices.already_tread_hp.show === true) {
			if (this.state.selected_security.id.toString() in my_holdings === true) {
				const holding_time = my_holdings[this.state.selected_security.id.toString()].holding_time;
				const holding_period_days = module_config.general.holding_period_days;
				const holding_check_time = moment.unix(holding_time).add(holding_period_days, 'days').unix();
				if (moment().set('hour', 23).set('minute', 59).set('second', 59).unix() <= holding_check_time && automatic_rejection_advices.already_tread_hp.show === true) warning.holding = true;
			}
		}
		let error = false;
		for ( let key in warning) {
			if (warning[key] === true) error = true;
		}
		console.log("WARNING STATUS : ", warning);

		console.log("Active Brokers : ", this.state.no_of_active_brokers);

		let alertParam = {
			"header" : "Minimum Letter Search Setting 1",
			"subheader" : "",
			"msg" : "When using Third Party Security engines, it is recommended that you search only after 4 or 5 letters have been entered by the employee. This reduces the number of calls made to the service which will optimise the search and may reduce costs.<br><br>Would you like to set this value now?",
			"closefunc" : this.closeYesNO,
			"buttons" : {
				"1" : {
					"caption" : "I'll check later",
					"bgcolor" : "#4472C4",
					"color" : "white",
					"callback" : this.callback1
				},
				"2" : {
					"caption" : "Yes, please",
					"bgcolor" : "#203864",
					"color" : "white",
					"callback" : this.callback2
				}
			}
		}
		
		let minDate = new Date(0);
		let maxDate = null;
		if(module_config.instruction_types[this.state.selected_instruction_type.id].transaction_date_type === 'future_date') minDate = moment().format("DD/MM/YYYY");
		if(module_config.instruction_types[this.state.selected_instruction_type.id].transaction_date_type === 'past_date') maxDate = moment().format("DD/MM/YYYY");
		//console.log("Table data sent from AddPadModal : ", this.state.table_data);
		//console.log("Module Config : ",Store.getStoreData('module_config'));
		//console.log("Selected Instruction Type : ",this.state.selected_instruction_type);
		return (
			<ModalWraper>
				{ this.state.show_spinner === true && <SpinningLoader />}
				{this.state.alertParam !== null && this.state.show_save_and_close_alert === true && <YesNoBox alertParam={this.state.alertParam}/>}
				{/* <YesNoBox alertParam={alertParam}/> */}
				{
					this.state.show_note_modal === true &&
					<NoteWrapper>
						<NoteModal closeNoteModal={this.closeNoteModal} addNote={this.addNote} />
					</NoteWrapper>
				}
				{
					this.state.show_security_modal === true &&
					<CreateSecurityRegisterModal curid={0} modifySecurityRegister={this.createNewSecurity} closeModal={this.closeSecurityModal} requester_id={this.state.requester_obj.id} />
				}
				<MRModalContainer>

					<MRModalHeader>
						<MRModalHeaderText>Add PAD Entry</MRModalHeaderText>
						<MRModalHeaderCloseBtn onClick={this.closeView}>
				            <FaTimes />
				        </MRModalHeaderCloseBtn>
				        <ClearBoth />
				        <MRModalSubHeaderText>Complete the required fields to create a new PA Dealing record</MRModalSubHeaderText>
					</MRModalHeader>

					<MRModalBody style={{pointerEvents: this.props.add_readonly === true ? "none" : "auto"}}>
						{
							(module_config.general.enable_employee_broker_ac === true && this.state.no_of_active_brokers === 0) &&
							<Row style={{marginTop : "-10px", paddingTop : "0"}}>
								<MRAlertContainer style={{marginTop : "0px", paddingTop : "0", textAlign : "justify", paddingBottom : "0px"}}>
									<p style={{ color: "#b75050" }}><b>Please Note : </b> You will not be able to submit a PA Dealing Request until you have added a Broker to trade with. Go to 'Manage Brokers' to add a new broker.</p>
								</MRAlertContainer>
							</Row>
						}						
						{
							(!(this.state.requester_obj.id in module_config.users_role_type)) &&
							<Row style={{marginTop : "-10px", paddingTop : "0"}}>
								<MRAlertContainer style={{marginTop : "0px", paddingTop : "0", textAlign : "justify", paddingBottom : "0px"}}>
									<p style={{ color: "#b75050" }}><b>Please Note : </b> You will not be able to submit a PA Dealing Request until a role has been assigned by a System Administrator.</p>
								</MRAlertContainer>
							</Row>
						}
						{
							Store.getStoreData("role") === "admin_manager" &&
							<Row>
								<MRModalLabel>Employee Name</MRModalLabel>
								<CslSimpleDropDown options={this.state.contacts} selected={this.state.requester_obj} setSelection={this.changeRequester} />
							</Row>
						}
						{/* <Row>
							<MRModalLabel>Role</MRModalLabel>
							<CslSimpleDropDown options={this.state.roles} selected={this.state.selected_role_obj} setSelection={this.changeRole} />
						</Row> */}
						<Row>
							<MRModalLabel>Instruction</MRModalLabel>
							<CslSimpleDropDown options={this.state.instruction_types} selected={this.state.selected_instruction_type} setSelection={this.changeInstructionType} />
						</Row>
						{/* <Row>
							<MRModalLabel>Transaction Type</MRModalLabel>
							<CslSimpleDropDown options={this.state.transaction_types} selected={this.state.selected_transaction_type} setSelection={this.changeTransactionType} />
						</Row> */}
						{
							module_config.instruction_types[this.state.selected_instruction_type.id].transaction_date_type !== 'none' &&
							<Row>
								<MRModalLabel>Transaction Date</MRModalLabel>
								<CSLDateCover style={{ display: "inline-block" }}>
									<DatePicker
										onChange={(e) => {
											this.setState({ transaction_date: moment(e).format("DD/MM/YYYY") });
										}}
										clearIcon={null}
										calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
										locale={"en-GB"}
										className="csldatecontainer"
										value={moment(this.state.transaction_date, "DD/MM/YYYY").toDate()}
										maxDate={moment(maxDate, "DD/MM/YYYY").toDate()}
										minDate={moment(minDate, "DD/MM/YYYY").toDate()}
									/>
								</CSLDateCover>
							</Row>
						}
						{this.state.custom_fields.enabled === true && this.state.custom_fields.additional_fields.length !== 0 && <CustomField fields={this.state.custom_fields.additional_fields} changeFieldValue={this.changeFieldValue} changeDropdownValue={this.changeDropdownValue} changeOtherValue={this.changeOtherValue} />}
						<HR />
						<Row>
							<MRModalLabel>Security Details</MRModalLabel>
							<CslSimpleDropDown options={this.state.security_types} selected={this.state.selected_security_type} setSelection={this.changeSecurityType} />
						</Row>
						{/* arnab change */}

						{
							this.state.selected_security_type.id === "1" && Store.getStoreData('module_config').general.private_securities_enabled === true && module_config.general.private_securities_enabled === true &&
							<Row style={{ display : "flex", flexDirection : "row", alignItems : "center", gap : "10px", marginTop : "20px" }}>
								<MRModalLabel style={{padding : "0px", margin : "0px"}}>This Security is Private / Unlisted and unique to me</MRModalLabel>
								{(() => {
									if (this.state.is_new_security === true) {
										return (
											<RadioBlock  style={{padding : "0px", margin : "0px" }}>
												<SelectedRadio>Yes</SelectedRadio>
												<UnselectedRadio onClick={this.toggleIsNewSecurity}>No</UnselectedRadio>
											</RadioBlock>
										)
									} else {
										return (
											<RadioBlock  style={{padding : "0px", margin : "0px" }}>
												<UnselectedRadio onClick={this.toggleIsNewSecurity}>Yes</UnselectedRadio>
												<SelectedRadio>No</SelectedRadio>
											</RadioBlock>
										)
									}
								})()}
							</Row>
						}
						{
							this.state.is_new_security === true && this.state.selected_security_type.id === "1" &&
							<Row>
								<div style={{margin : "10px 0px 10px 0px", textAlign : "justify"}}>Enter the name of your Private / Unlisted Security here. This Security will be added to your holdings but will not be visible to other users.</div>
								<TextBox onChange={ this.inputPrivateSecurity }/>
							</Row>
						}
						{
							(this.state.selected_security_type.id === "2" || this.state.selected_security_type.id === "1" && this.state.is_new_security === false) && 
							<Row>
								<MRModalLabel>Select Security</MRModalLabel>
								{(()=>{
									if(Store.getStoreData('module_config').general.security_hot_lookup.enabled === true && Store.getStoreData('module_config').general.security_hot_lookup.selected_service === "yahoo_fin"){
										return <div style={{ marginBottom : "10px", textAlign : "justify"}}>Refining your search further, or searching by ISIN will make it more likely to find the correct security</div>
									}
								})()}
								<TableDropdown table_data={this.state.table_data} setSelected={this.changeSecurity} setApiSecurity={this.setApiSecurity} action_text={this.state.selected_security_type.id === "2" ? "" : "Security not listed?"} actionCallback={this.showCreateSecurityModal} requester_id={this.state.requester_obj.id}/>
							</Row>
						}
						
						{
							this.state.selected_security !== null && (this.state.is_new_security === true || this.state.show_security_info === true) &&
							<Row>
								<MRModalLabel style={{fontWeight: "100", fontStyle: "italic"}}>Security Information</MRModalLabel>
								<InfoDiv style={{backgroundColor: this.state.selected_security.icon === "Warning" ? "#ECCACA" : "#d6e6f3", borderColor: this.state.selected_security.icon === "Warning" ? "#b73232" : "#adb1b5", height: "40px"}}>
									{this.state.selected_security.icon === "Warning" &&
										<div style={{float: "left", width: "5%", marginTop: "1px", color: "#b73232"}}>
											<FaExclamationTriangle />
										</div>
									}
									<div style={{float: "left", width: this.state.selected_security.icon === "Warning" ? "65%" : "70%", color: this.state.selected_security.icon === "Warning" ? "#b73232" : "#495156"}}>
										{this.state.selected_security === null ? "" : this.state.selected_security.name}
									</div>
									<div title={this.state.selected_security === null ? "" : this.state.selected_security.identifiers} style={{float: "left", width: "30%", textAlign: "right", color: this.state.selected_security.icon === "Warning" ? "#b73232" : "#495156", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										{this.state.selected_security === null ? "" : this.state.selected_security.identifiers}
									</div>
									<div style={{clear: "both"}}></div>
								</InfoDiv>
							</Row>
						}
						{
							this.state.api_security_has_no_type === true &&
							<Row>
								<MRModalLabel>Security Type<span style={{color: "red", fontWeight: "600", fontSize: "21px", marginLeft: "5px"}}>*</span></MRModalLabel>
								<CslSimpleDropDown options={this.state.security_typedisp} selected={this.state.selected_security_typedisp} setSelection={this.changeSecurityTypedisp} />
							</Row>
						}
						<HR />
						<Row><MRModalLabel style={{fontSize: "15px"}}>Trade Details</MRModalLabel></Row>
						{
							this.state.show_api_security_note === true && this.state.api_price_not_retrievable === false &&
							<Row>
								<MRModalLabel style={{fontWeight: "300", fontStyle: "italic"}}>The price has been automatically retrieved. Please ensure that you check price and currency details.</MRModalLabel>
							</Row>
						}
						{
							this.state.api_price_not_retrievable === true && this.state.show_api_security_note === true &&
							<Row>
								<MRModalLabel style={{fontWeight: "300", fontStyle: "italic"}}>Price could not be retrieved for this security. Please ensure that you enter price and currency details.</MRModalLabel>
							</Row>
						}
						{/* change arnab */}
						<Row>
							<TradeDetailsContainer>
								<MRModalLabel>Volume</MRModalLabel>
								<MRModalLabel>Price</MRModalLabel>
								<MRModalLabel>Cash Consideration</MRModalLabel>
								<TextBox type="text" value={this.state.trade_details.volume} onChange={this.changeVolume} />
								<div style={{display : "flex", flexDirection : "row"}}>
									<div style={{width : "30%"}}><CslSimpleDropDown options={this.state.currencies} selected={this.state.selected_currency} setSelection={this.changeCurrency} /></div>
									<TextBox style={{width: "70%"}} type="text" value={this.state.trade_details.price} onChange={this.changePrice} />
								</div>
								<TextBox type="text" value={this.state.trade_details.cash_consideration} onChange={this.changeCashConsideration} />
							</TradeDetailsContainer>
						</Row>
						{/* change arnab */}
						{/* <Row>
							<Section25>
								<Row><MRModalLabel>Volume</MRModalLabel></Row>
								<TextBox type="text" value={this.state.trade_details.volume} onChange={this.changeVolume} />
							</Section25>
							<Section50 style={{paddingLeft: "6%"}}>
								<Row><MRModalLabel>Price</MRModalLabel></Row>
								<div style={{width: "100%", boxSizing: "border-box"}}>
									<Section30>
										<CslSimpleDropDown options={this.state.currencies} selected={this.state.selected_currency} setSelection={this.changeCurrency} />
									</Section30>
									<Section70>
										<TextBox style={{width: "83%"}} type="text" value={this.state.trade_details.price} onChange={this.changePrice} />
									</Section70>
									<Clearfix></Clearfix>
								</div>
							</Section50>
							<Section25>
								<Row><MRModalLabel>Cash Consideration</MRModalLabel></Row>
								<div><TextBox type="text" value={this.state.trade_details.cash_consideration} onChange={this.changeCashConsideration} /></div>
							</Section25>
							<Clearfix></Clearfix>
						</Row> */}
						{/* change v3.2 */}
						{/* <Row>
							<MRModalLabel>Counterparty</MRModalLabel>
							<CslSimpleDropDown options={this.state.counterparties} selected={this.state.selected_counterparty} setSelection={this.changeCounterparty} />
						</Row> */}
						{
							module_config.general.enable_employee_broker_ac === true &&
							<Row>
								<MRModalLabel>Broker <span style={{fontSize : "20px", color: "#ff0000",}}>*</span></MRModalLabel>
								<CslSimpleDropDown options={this.state.active_brokers} selected={this.state.selected_broker_obj} setSelection={this.changeBroker} />
							</Row>
						}
						<Row>
							<MRModalLabel>Beneficial Owner</MRModalLabel>
							<CslSimpleDropDown options={this.state.beneficiaries} selected={this.state.selected_beneficiary} setSelection={this.changeBeneficiary} />
						</Row>
						<HR />
						{
							module_config.general.add_mandatory_checkboxes === true &&
							<>
								{
									module_config.general.checkbox_short === true &&
									<Row style={{width: "100%", boxSizing: "border-box", marginBottom: "22px"}}>
										<div style={{float: "left", width: "10%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
											{this.state.not_short === true && <FaRegCheckSquare style={{cursor: "pointer"}} onClick={this.changeCheck("not_short", false)} />}
											{this.state.not_short === false && <FaRegSquare style={{cursor: "pointer"}} onClick={this.changeCheck("not_short", true)} />}
										</div>
										<div style={{float: "left", width: "90%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
											I confirm that this trade does not take the beneficial owner "Short"
										</div>
										<Clearfix></Clearfix>
									</Row>
								}
								{
									module_config.general.checkbox_breach === true &&
									<Row style={{width: "100%", boxSizing: "border-box", marginBottom: "20px"}}>
										<div style={{float: "left", width: "10%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
											{this.state.not_breach === true && <FaRegCheckSquare style={{cursor: "pointer"}} onClick={this.changeCheck("not_breach", false)} />}
											{this.state.not_breach === false && <FaRegSquare style={{cursor: "pointer"}} onClick={this.changeCheck("not_breach", true)} />}
										</div>
										<div style={{float: "left", width: "90%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
											I confirm that this trade does not breach Regulator or Company rules or policies
										</div>
										<Clearfix></Clearfix>
									</Row>
								}
							</>
						}
						<Row>
							<MRModalLabel>Comments</MRModalLabel>
							<TextArea rows="5" cols="120" value={this.state.comments} onChange={this.changeComments}></TextArea>
						</Row>
						<Row>
							  <MRModalLabel>Attachments</MRModalLabel>
							  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={this.state.addnew} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />
						</Row>
						{
							(() => {
						  if (this.state.task!==null && this.state.attachments.length > 0) {
						    return <Row><CSLAtteachmentViewer files={this.state.attachments} task_id={this.state.task["id"]} updateAttachments={this.updateAttachments}/></Row>;
						  }
						})()
					}
						<Row>
							<NoteTableHeaderBar>
								<Section50 style={{fontWeight: "600", color: "#ffffff", fontSize: "15px"}}>Notes</Section50>
								<Section50 style={{textAlign: "right", color: "#ffffff", fontSize: "15px", marginTop: "2px"}}>
									<FaPlusCircle style={{cursor: "pointer"}} onClick={this.toggleNoteModal(true)} />
								</Section50>
								<Clearfix></Clearfix>
							</NoteTableHeaderBar>
							<NoteTableHeadBodyWrapper>
								<NoteTableHeader>
									<Section30 style={{padding: "10px 0px 10px 20px", fontWeight: "600", fontSize: "14px"}}>Title</Section30>
									<Section70 style={{padding: "10px 0px 10px 20px", fontWeight: "600", fontSize: "14px"}}>Details</Section70>
									<Clearfix></Clearfix>
								</NoteTableHeader>
								<NoteTableBody>
									{
										Object.keys(this.state.notes).map(key => {
											return (
												<div key={key} style={{width: "100%", boxSizing: "border-box"}}>
													<Cell30>{this.state.notes[key].title}</Cell30>
													<Cell70>{this.state.notes[key].details}</Cell70>
													<Clearfix></Clearfix>
												</div>
											)
										})
									}
								</NoteTableBody>
								<div style={{borderBottom: "1px solid rgb(203 204 206)", width: "100%"}}></div>
							</NoteTableHeadBodyWrapper>
						</Row>
						{
							(module_config.general.request_type === "approval" && error === true) &&
							<Row>
								<MRAlertContainer>
									{automatic_rejection_advices.introductory_text_pef.show === true && <p style={{ color: "#a91414" }}>{automatic_rejection_advices.introductory_text_pef.text}</p>}
									{(automatic_rejection_advices.short_trade.show === true && warning.short === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.short_trade.text}</b></p>}
									{(automatic_rejection_advices.breaches_confirmation.show === true && warning.breach === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.breaches_confirmation.text}</b></p>}
									{(automatic_rejection_advices.restricted_security.show === true && warning.restrict === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.restricted_security.text}</b></p>}
									{(automatic_rejection_advices.already_tread_hp.show === true && warning.holding === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.already_tread_hp.text}</b></p>}
									{(automatic_rejection_advices.past_trade_date.show === true && warning.past === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.past_trade_date.text}</b></p>}
								</MRAlertContainer>
							</Row>
						}
					</MRModalBody>				
						             
					<MRModalFooter>
						{
							this.props.add_readonly === false &&
							<>
								<MRModalNextBtn
									onClick={this.proceedToRegister("register")}
									disabled={this.state.registering && (Store.getStoreData('module_config').general.enable_employee_broker_ac === true && this.state.no_of_active_brokers > 0 ? true : false)}
									style={{
										cursor: this.state.registering === true || (Store.getStoreData('module_config').general.enable_employee_broker_ac === true && this.state.no_of_active_brokers === 0) ? "not-allowed" : "pointer",
										filter: this.state.registering === true || (Store.getStoreData('module_config').general.enable_employee_broker_ac === true && this.state.no_of_active_brokers === 0) ? "grayscale(1)" : "none"
									}}
								>
									REGISTER
								</MRModalNextBtn>
								<MRModalNextBtn 
									style={{
										//change Arnab
										backgroundColor: ("from_api" in this.state.trade_details || "private" in this.state.trade_details) ? "#999999" : "#1A3552", 
										border: ("from_api" in this.state.trade_details || "private" in this.state.trade_details) ? "#777777" : "#1A3552", 
										color : ("from_api" in this.state.trade_details || "private" in this.state.trade_details) ? "#bbbbbb" : "white",
										cursor : ("from_api" in this.state.trade_details || "private" in this.state.trade_details) ? "not-allowed" : "pointer",
										pointerEvents : ("from_api" in this.state.trade_details || "private" in this.state.trade_details) ? "none" : "auto"
									}} onClick={this.saveAndClose("save")}
								>
									SAVE & CLOSE
								</MRModalNextBtn>
							</>
						}
						<MRModalNextBtn style={{backgroundColor: "#ffffff", color: "#222222", border: "1px solid #c7c2c2"}} onClick={this.closeView}>CANCEL</MRModalNextBtn>
						<Clearfix></Clearfix>
					</MRModalFooter>
				</MRModalContainer>
				{/* <AlertBox alertParam={this.state.alert_param} /> */}
			</ModalWraper>
		)
	}
}

export default AddPadModal;
