import React from "react";
import styled from "styled-components";
import { FiMoreVertical } from "react-icons/fi";
import Store from "../../Common/Store";
import EventBus from "../../Common/EventBus";

const Container = styled.div`
    margin-left: 15px;
    font-size: 21px;
    font-weight: 600;
    color: #7c7a7a;
    position: relative;
`;

class SimpleDropdown extends React.Component {

    state = {show_dropdown : "false"}

    constructor(props) {
        super(props);
        // this.show_dropdown = "false";
    }
    
    componentDidMount() {
        EventBus.registerEvent("close_dropdown", "menu_spec_clear", this.closeDropdown);
    }

    toggleDropdown = (event) => {
        event.preventDefault();
        console.log("Toggle Dropdown Called 1. show_dropdown = ", this.state.show_dropdown);
        let x = event.pageX;
        let y = event.pageY;
        console.log("MOUSE x : ",x," y : ",y);
        let menu_spec = {}
        // this.show_dropdown = this.show_dropdown === true ? false : true;
        // this.state.show_dropdown = "true"   ;
        let sd = this.state.show_dropdown;
        sd = sd === "false" ? "true" : "false";
        console.log("Toggle Dropdown Called 2. show_dropdown = ", sd);
        if (sd === "true") menu_spec = {x, y, actions: this.props.actions, show: this.props.show, entity_id: this.props.entity_id};
        console.log("menu_spec in SimpleDropDown:", menu_spec);
        EventBus.raiseEvent("show_dropdown", menu_spec);
        this.setState({show_dropdown: sd})
    }
    
    closeDropdown = (value) => {
        console.log(value.toString());
        this.setState({show_dropdown: value.toString()})
        // this.show_dropdown = value.toString();
        // console.log("Close Dropdown Called. show_dropdown = ", this.show_dropdown);
    }
    
    render () {
        // console.log("Dropdown state:", this.state);
        let home = false;
        if ("home" in this.props) {
            home = this.props.home;
        }
        return (
            <Container>
                <FiMoreVertical style={{cursor: "pointer"}} onClick={this.toggleDropdown} />
            </Container>
        );
    }
    
}

export default SimpleDropdown;