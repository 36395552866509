import React from 'react';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const Overlay = styled.div`
    background-color : white;
    box-sizing : border-box;
    position: fixed;
    top : 0;
    left : 0;
    height : 100%;
    width : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    z-index : 60000;
    opacity : 0.5;
`
const SpinnerContainer = styled.div`
    position: fixed;
    width : 100px;
    height : 100px;
    border-radius : 50px;
    background-color : white;
    opacity : 1;
    z-index : 600001;
`

class SpinningLoader extends React.Component {
    render(){
        return (
            <Overlay>
                <SpinnerContainer><ClipLoader color={"blue"} loading={true} size={100} /></SpinnerContainer>
            </Overlay>
        )   
    }
}

export default SpinningLoader;