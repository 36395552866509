import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import CSLTable from "../CSLTable/CSLTable";
import APICall from "../../../../Common/APICall";
import ManageRestrictedRegister from './modals/ManageRestrictedRegister';
//import Dropdown from "../../../Common/Dropdown";
import CSLToggle from "../CSLToggle";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";

const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`;

class SecurityRestrictedRegisters extends React.Component {  

  state = {
    ready: false,
    filters: null,
    security_register: null,
    show_modal: null,
    type: {},
    active_section_not_ready : true,
  };

  constructor() {
    super();    
    this.api = new APICall();
  }

  componentDidMount() {
    

    const postData = { command: "get_security_lookup"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
    this.api.command(postData, this.getroleTypes);

    // console.log("security_register", this.props.security_register)
    // let security_types = Store.getStoreData("module_config")["security_types"];
    // let currencies = Store.getStoreData("module_config")["currencies"];
    // let security_identifier_types = Store.getStoreData("module_config")["security_identifier_types"];    
    // this.setState({security_types, currencies, security_identifier_types, ready: true})
  }

  getroleTypes = (result) => {
    console.log("result", result)
    let filters = result.filter;
    let security_register = result.security_register;
    this.setState({filters:filters, security_register: security_register, active_section_not_ready : false})

  }

  componentDidUpdate(prevProps) {    
  }

  closeModal = (event) => {    
    this.setState({ show_modal: null });
  }; 

  deleteType = (id) => (event) => {
    let type = this.state.security_register[id.toString()];
    if (window.confirm(`Are you sure you want to delete this ${type['name']} from the list?`)) {
     const postData = { command: "delete_lookup"};
     postData['lookup_id'] = this.props.lookup_id;
     postData['id'] = id;
     this.api.command(postData, this.removeroleType);
    }
  };

  removeroleType = (result) => {
    let msg = "Restricted Register successfully deleted";
    alert(msg);
    const postData = { command: "get_security_lookup"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
    this.api.command(postData, this.getroleTypes);
  }
  
  performAction = (role_id, action_type) => {
    console.log("role_id:", role_id);
    console.log("action_type:", action_type);
    if (action_type === "edit") {
      this.openModal(role_id);
    }
    if (action_type === "derestrict") {
      this.derestrictSecurity(role_id);
    }
    if (action_type === "delete") {
      if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
        const postData = {
          command: "delete_lookup",
          lookup_id: "security_register",
          check_key: "security_id",
          id: role_id
        };
        console.log("postData:", postData);
        this.api.command(postData, this.afterRemove);
      }
    }
  }

  afterRemove = (result) => {
    console.log(result);
    if (result.error_msg === "exists") {
      alert("This item cannot be removed as it is associated with tasks");
    } else {
      let security_register = JSON.parse(JSON.stringify(this.state.security_register));
      delete security_register[result.result.toString()];
      this.setState({security_register});
    }
  }
  
  tableData = () => {        
      let ret = {data: [], columns: []};
      const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}, {name: "Derestrict", action: "derestrict"}];
      ret.columns =[
                      // { Header: "#", accessor: "slno", width: 30, headerStyle: { textAlign: "center" }},
                      {Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left'}},					
                      {Header: 'Type', accessor: 'type', minWidth: 80, headerStyle: {textAlign: 'left'}},
                      {Header: 'Currency', accessor: 'currency', minWidth: 80, headerStyle: {textAlign: 'left'}}                        
                  ];

      for(let key in this.state.filters){
          if(this.state.filters[key].lookup_id === "security_identifier_types"){
              let addColums = {Header: this.state.filters[key].name , accessor: key, minWidth: 80, headerStyle: {textAlign: 'left'}} 
              ret.columns.push(addColums)
          }
      }
      ret.columns.push({Header: 'Reason', accessor: 'reason', minWidth: 200, headerStyle: {textAlign: 'left'}});

  
      // let c1 = { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} />, style: { display: "flex", alignItems: "center", justifyContent: "center" } };
      // ret.columns.push(c1)
      let c2 = {
        Header: "",
        Cell: (row) => (
          <div style={{marginTop: "-8px"}}><EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
        ),
        width: 55,
        headerStyle: { textAlign: "center" },
        filterable: false
      };
      ret.columns.push(c2)
      
      let sl = 0 
      for(let key in this.state.security_register){
          let lookup_json = JSON.parse(this.state.security_register[key].lookup_json)
          if (lookup_json.is_restricted === true) {
            console.log("lookup_json:", lookup_json);
            sl++;            
            let elem = {
                slno: sl, 
                id: key, 
                name: this.state.security_register[key].name, 
                type: ('security_type_id' in lookup_json) ? this.state.filters[lookup_json.security_type_id].name : "", 
                currency: ('currency_id' in lookup_json) ? this.state.filters[lookup_json.currency_id].name : "", 
                status: this.state.security_register[key].is_active,
                status_value : this.state.security_register[key].is_active === 1 ? "Active" : "In-Active",
                reason: "reason" in lookup_json === true ? lookup_json.reason : ""
            };
  
            if(Object.keys(lookup_json.identifier_types).length !== 0){
                let identifierTypes = lookup_json.identifier_types;
                for(let k in identifierTypes){
                    elem[k.toString()] = identifierTypes[k.toString()]
                }
            }
  
            ret.data.push(elem);
          }
      }        
      return ret;
  }

    toggleStatus = (is_active, id) => {
      let security_register = this.state.security_register;
      console.log("security_register", security_register)
      security_register[id.toString()].is_active = is_active ? 1 : 0;
      const postData = { command: "toggle_status"};
      postData['lookup_id'] = this.props.lookup_id;
      postData['is_active'] = is_active ? 1 : 0;
      postData['id'] = id;
      this.api.command(postData, this.toggleroleType);
    }

    toggleroleType = (result) => {
      alert(result.error_msg);
    }

    openModal = (id) => {
      // event.preventDefault();
      let type = this.state.security_register[id.toString()];
      let t = {};
      t['id'] = id;
      t['status'] = type.is_active;
      t['name'] = type.name;
      t['desc'] = type.description;
      t['lookup_json'] = JSON.parse(type.lookup_json);
      this.setState({ show_modal: 'roletype', type: t });
    };

    addType = (event) => {
      event.preventDefault();
      let type = {};
      type['id'] = 0
      type['status'] = 1
      type['name'] = ""
      type['desc'] = ""
      type['lookup_json'] = {};
      type['lookup_json']['identifier_types'] = {};
      this.setState({show_modal:'roletype', type: type});
    };

    refreshParent = () => {
      this.setState({ show_modal: null });
      const postData = { command: "get_security_lookup"};
      postData['lookup_id'] = this.props.lookup_id;
      postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
      this.api.command(postData, this.getroleTypes);
    }
    
    derestrictSecurity = (sec_id) => {
      let security = this.state.security_register[sec_id.toString()];
      console.log("security:", security);
      const postData = { command: "save_lookup"};
      postData['lookup_json'] = JSON.parse(security.lookup_json);
      postData['lookup_json']['is_restricted'] = false;
      postData['name'] = security.name;
      postData['status'] = security.is_active;
      postData['description'] = security.desc;
      postData['lookup_id'] = 'security_register';
      postData['id'] = sec_id;
      console.log("postData", postData)
      this.api.command(postData, this.refreshParent);
    }
    changeRestriction = (status) => {
      // event.preventDefault();
      let general = JSON.parse(JSON.stringify(this.props.general));
      general.show_restricted_security_information = general.show_restricted_security_information === true ? false : true;
      this.props.updateGeneralSave(general);
    }
    changeRestrictionAutoDecline = (status) => {
      // event.preventDefault();
      let general = JSON.parse(JSON.stringify(this.props.general));
      general.show_auto_decline = general.show_auto_decline === true ? false : true;
      this.props.updateGeneralSave(general);
    }

  render() {
    // if (!this.state.ready) return <div>Loading...</div>;
    if(this.state.active_section_not_ready === true) {
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }

    return (
      <div>       
         <div style={{ width: "90%" }}>          
          <TypesContainer>
          {
            (() => {
              if(this.state.show_modal){
                return <ManageRestrictedRegister closeModal={this.closeModal} type = {this.state.type} filters = {this.state.filters} refreshParent={this.refreshParent} res_id={this.state.res_list_id_after_save} isManageRes={false}/>
              }
            })()
          }
            <TypesInnerContainer>
              <div style={{ display: "flow-root", paddingBottom: "16px" }}>
                <div style={{ float: "left" }}>
                  <TypesBoldLabel style={{ display: "inline-block" }}>{this.props.sectionName}</TypesBoldLabel>
                </div>
                <div style={{float: "left", paddingLeft: "45%"}}>
                  <div style={{display: "inline-block"}}>
                    <div style={{float: "left", marginTop: "1px", marginRight: "5px"}}>Show Restricted Security Information</div>
                    <div style={{float: "left"}}><CSLToggle checked={this.props.general.show_restricted_security_information} onClick={this.changeRestriction} /></div>
                    <div style={{float: "left", marginTop: "1px", marginRight: "5px", marginLeft: "10px"}}>Automatically Decline</div>
                    <div style={{float: "left"}}><CSLToggle checked={this.props.general.show_auto_decline} onClick={this.changeRestrictionAutoDecline} /></div>
                  </div>
                  <div style={{clear: "both"}}></div>
                </div>
                <div onClick={this.addType} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                  <FaPlusCircle />
                </div>
              </div>
              <CSLTable add={false} processData={this.tableData} tableRows={20} />
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
    );
  }
}

export default SecurityRestrictedRegisters;
