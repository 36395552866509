import React from 'react';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const Overlay = styled.div`
    background-color : white;
    box-sizing : border-box;
    position: relative;
    top : 0;
    left : 0;
    height : 100%;
    width : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
`
const SpinnerContainer = styled.div`
    position: absolute;
    width : 56px;
    height : 56px;
    border-radius : 28px;
    background-color : white;
`

class SpinningLoaderSmall extends React.Component {
    render(){
        return (
            <Overlay>
                <SpinnerContainer><ClipLoader color={"#6666ff"} loading={true} size={56} /></SpinnerContainer>
            </Overlay>
        )   
    }
}

export default SpinningLoaderSmall;