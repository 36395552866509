module.exports  = {
    general: {
      request_type: "notification",
      confirm_trade_information: true,
      track_employee_position: true,
      add_mandatory_checkboxes: true,
      checkbox_short: true,
      checkbox_breach: true,
      require_confirmation_by_manager: true,
      validity_window_hours: 24,
      holding_period_days: 30,
      show_restricted_security_information: true,
      automatic_rejection_advices: [
        {
          identifier: "introductory_text_pef",
          header: "Introductory text – PAD Entry Form",
          text: "Based on the information you have provided your PAD request may be declined for the following reason(s):",
          show_on_entry_form: true
        },
        {
          identifier: "introductory_text_paf",
          header: "Introductory text – PAD Approval Form",
          text: "Based on the information provided the PAD request may be declined for the following reason(s):",
          show_on_entry_form: true
        },
        {
          identifier: "short_trade",
          header: "Short Trade",
          text: "It appears this trade could be a ‘short’ trade which is against the company policy",
          show_on_entry_form: true
        },
        {
          identifier: "breaches_confirmation",
          header: "Breaches Confirmation",
          text: "Confirmation has not been provided that this trade does not breach any regulatory rules or company policy",
          show_on_entry_form: true
        },
        {
          identifier: "restricted_security",
          header: "Restricted Security",
          text: "The security is on the company’s Restricted List.",
          show_on_entry_form: true
        },
        {
          identifier: "already_tread_hp",
          header: "Already traded within the Holding Period",
          text: "A PAD request for the same security was raised within the Holding Period.",
          show_on_entry_form: true
        },
        {
          identifier: "past_trade_date",
          header: "Past Trade Date",
          text: "The trade date is in the past and PAD requests can only be submitted prior to trading.",
          show_on_entry_form: true
        },
        {
          identifier: "approval_validity_window_exceeded",
          header: "Approval Validity Window exceeded",
          text: "The trade date was executed outside of the approval validity window.",
          show_on_entry_form: true
        },
      ]
    },
    sla:{
      days_to_close_notification: 0,
      email_before_sla_exceeded: {
        enabled: false,
        hours_before: 24
      },
      email_after_sla_exceeded: {
        enabled: false,
        hours_after: 0
      },
      repeat_email_after_sla_exceeded: {
        enabled: false,
        frequency_in_days: 1,
        repetition_times: 28
      }
    }, 
    email: [
      {
        id: "padreg_registered_user",
        name: "New PA Dealing REGISTERED – User notification",
        enabled: true,
        subject: "Your PA Dealing submission has been created – {{PAD_ID}}",
        body: "Dear {{discloser}},\n\nYour PA Dealing submission has been received and will be responded shortly.\n\nIf further information is required, please follow up with a call to your local PA Dealing Manager quoting reference PAD-{{PAD_ID}}.\n\nYour submission can be viewed at any time in your ‘My Compliance Centre’ at www.compliancy-services.co.uk or by following this link {{PAD_Link}}\n\n\n\nYour Compliance Team",
      },
      {
        id: "padreg_registered_manager",
        name: "New PA Dealing REGISTERED – Manager notification",
        enabled: true,
        subject: "New PA Dealing submission created by {{discloser}} – {{PAD_ID}}",
        body: "A new PA Dealing submission has been created by {{discloser}} with identifier: {{PAD_ID}}\n\nIt can be viewed at any time in your ‘My Compliance Centre’ at www.compliancy-services.co.uk or by following this link: {{PAD_Link}}",
      },
      {
        id: "padreg_updated_user",
        name: "PA Dealing UPDATED –  User notification",
        enabled: true,
        subject: "Your PA Dealing has been Updated – {{PAD_ID}}",
        body: "Your PA Dealing submission {{PAD_ID}} has been updated to {{status}}. Please contact your PA Dealings Manager if you have any queries.",
      },
      {
        id: "padreg_followup_user",
        name: "PA Dealing :: New Task - Followup",
        enabled: true,
        subject: "PA Dealing - A new task has been created",
        body: "A new task has been created. Click {{PAD_Link}} to see full details.\nAlternatively, visit www.compliancy-services.co.uk and log in to your ‘My Compliance Centre’.\n\nIf you have any questions, please contact your manager or Compliancy Services customer support on:\nTel: 01462 510022 or e-mail regtech@compliancy-services.co.uk.\n\nPowered by My Compliance Centre",
      },
      {
        id: "padreg_completed_user",
        name: "PA Dealing COMPLETED –  User notification",
        enabled: true,
        subject: "Your PA Dealing registration has been completed – {{PAD_ID}}",
        body: "Dear {{discloser}},\n\nYour PA Dealing registration has been completed.\n\nIf further information is required, please follow up with a call to your local PA Dealings Manager quoting reference PAD-{{PAD_ID}}.\n\nYour PA Dealing submission can be viewed at any time in your ‘My Compliance Centre’ at www.compliancy-services.co.uk or by following this link {{PAD_Link}}\n\n\n\nYour Compliance Team",
      },
    ],
    variables: [
      {
        name: "PAD_ID",
        nickname: "PAD_ID",
      },
      {
        name: "discloser",
        nickname: "discloser",
      },
      {
        name: "PAD_Link",
        nickname: "PAD_Link",
      },
      {
        name: "status",
        nickname: "Status",
      },
    ],
    custom_fields: {
      enabled: false,
      additional_fields: [],
      available_field_types: ["Alpha", "Numeric", "Dropdown"],
    }
  };
  