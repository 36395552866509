import React from "react";
import { saveAs } from "file-saver";
import styled from "styled-components";
import Store from '../../../Common/Store';
import { FaTimes } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import APICall from '../../../Common/APICall';
import moment from "moment";
import CSLTable from '../../Common/CSLTable';
import Dropzone from "../../Dropzone/Dropzone.js";
import SpinningLoader from "../../Common/SpinningLoader";

const last_action_time = moment().unix();

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 45%;
	position: absolute;
	top: 38px;
	z-index: 1001;
	margin-left: 27%;
	padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	// width: 40%;
	// position: absolute;
	// top: 38px;
	// z-index: 1001;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	// margin-bottom: 10px;
	// margin-left: 30%;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 85px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const MRModalFooter = styled.div`
  padding: 17px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 45px;
	border-radius: 4px;
	font-size: 13px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
`;
const InfoBox = styled.div`
	background-color: #E7F0F7;
    border: 1px solid #d1d4d2;
    border-radius: 3px;
    margin-top: 20px;
`;
const Box = styled.div`
	padding: 20px;
`;
const Row = styled.div`
	box-sizing: border-box;
    width: 100%;
    margin-bottom: 15px;
`;
const Cell30 = styled.div`
	float: left;
	box-sizing: border-box;
	width: 30%;
`;
const Cell70 = styled.div`
	float: left;
    box-sizing: border-box;
    width: 70%;
    padding-left: 30px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	// margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const BoxHeader = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	font-family: 'Montserrat',sans-serif;
	margin-bottom: 20px;
`;
const HR = styled.div`
	width: 100%;
	height: 1px;
	background-color: #bbbbbb;
`;
const MRAlertContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #eccaca;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 10px;
`;
const MRTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 92%;
  margin: 0 auto;
  // top: 30px;
  margin-bottom: 10px;
  // border-radius: 5px;
  padding: 5px 10px;
`;
const NoteTableHeaderBar = styled.div`
	background-color: #11ABA6;
    border: 1px solid #11ABA6;
    box-sizing: border-box;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 6px 15px;
	margin-top: 20px;
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Section50 = styled.div`
	width: 50%;
	float: left;
	box-sizing: border-box;
`;

class CancelledModal extends React.Component {

    state = {task: null, company_users_obj: null, custom_fields: [], cur_files_json : null, binFiles: [], uploadedFiles: [], show_spinner : false, broker_list : []};

    async componentDidMount() {
		// console.log("task props:", this.props.task);
        const company_users = Store.getStoreData("company_users");
        let task = JSON.parse(JSON.stringify(this.props.task));
		let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
        let custom_fields = [];
		if ("custom_fields" in task.parenttask.task_json.object_data) {
			if (task.parenttask.task_json.object_data.custom_fields.enabled === true && task.parenttask.task_json.object_data.custom_fields.additional_fields.length !== 0) {
				task.parenttask.task_json.object_data.custom_fields.additional_fields.forEach(field => {
					if (field.type === "Dropdown") {
					if (field.value === "other") {
						custom_fields.push({name: field.name, nickname: field.nickname, value: field.other_value});
					}
					if (field.value !== "" && field.value !== "other") {
						custom_fields.push({name: field.name, nickname: field.nickname, value: field.value});
					}
					} else {
					if (field.value !== "") {
						custom_fields.push({name: field.name, nickname: field.nickname, value: field.value});
					}
					}
				})
			}
		}

		let broker_list = [];
		if(Store.getStoreData("module_config").general.enable_employee_broker_ac === true){
			const api = new APICall();
			const postData = {command: "list_brokers", requester_id : task.parenttask.contact_id};
			let result = await api.commandWithoutCallback(postData);
			broker_list = result.result;
		}
		// console.log("Complete Modal Task at mount : ", task);
        this.setState({task, company_users_obj, custom_fields, broker_list});
    }

    closeView = () => this.props.closeModal();

    handleDownload(image_id, task_id) {
    	const api = new APICall();
      	let postData = { command: "download_attachment", image_id: image_id, task_id: task_id };
      	console.log("postData", postData);
      	api.command(postData, this.processDownloadFile);
    }

    processDownloadFile = (result) => {
      console.log("Attachment", result);
      let dbinfile = result.result;
      let dnld = dbinfile.bin_file.replace(/ /g, "+");
      let binaryString = window.atob(dnld);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      var blob = new Blob([bytes]);
      saveAs(blob, dbinfile.name);
    };

	processFollowups = () => {
		const company_users = Store.getStoreData("company_users");
		let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
		const ret = { data: [], columns: [] };
		const actions = [{name: "View", action: "view"}, {name: "Remove", action: "delete"}];
		ret.columns = [
			{ Header: "Status", accessor: "status", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
			{ Header: "Task", accessor: "subject", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
			{ Header: "Assigned To", accessor: "assigned_to", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
			{ Header: "Due Date", accessor: "due_date", minWidth: 100, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center" } },
		];
		for (let task_id in this.state.task.parenttask.task_json.object_data.followups) {
			ret.data.push({
				id: task_id,
				status: this.state.task.parenttask.task_json.object_data.followups[task_id].status,
				subject: this.state.task.parenttask.task_json.object_data.followups[task_id].subject,
				assigned_to: company_users_obj[this.state.task.parenttask.task_json.object_data.followups[task_id].assigned_to.toString()].name,
				due_date: moment.unix(this.state.task.parenttask.task_json.object_data.followups[task_id].due_date).format('DD/MM/YYYY')
			});
		}
		return ret;
	}

	filesLoaded = (files) => {
		console.log("files", files)
		let contact_id = Store.getStoreData("loggedin_contact_id");
		let a = [];
		//let uploadedFiles = JSON.parse(files);
		for (let i = 0; i < files.length; i++) {
		  files[i].name = files[i].name.replace("&", "");
		  if (!("user_id" in files[i])) {
			files[i].user_id = contact_id;
			files[i].upload_date = last_action_time;
		  }
		}
		console.log("files a", a)
		this.setState({binFiles: files, addnew: false});
		console.log("TASK AFTER FILES LOADED", this.state.task.parenttask.task_json.object_data.attachments);
	  };

	  uploadFiles = (e) =>{
		e.preventDefault();
		let bin_files = this.state.binFiles;
		if(bin_files.length !== 0) {
			this.refs.dropZone.uploadFilesFromChild(bin_files)
			this.setState({show_spinner : true})
		}
	  }

	  onUploadComplete = (files) => {
		//e.preventDefault();
		//let files = this.state.uploadedFiles;
		console.log("files in onUploadComplete: ", files);
		let contact_id = Store.getStoreData("loggedin_contact_id");
		const module_config = Store.getStoreData("module_config");
		let returnedFiles = JSON.parse(files);
		//console.log("hello==>", returnedFiles)
		let bin_files = this.state.binFiles;
		let attachments = [];
		if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
		  bin_files.forEach((file) => {
			let fileName = file.name;
			let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
			delete file.bin_file;
			file.ref_id = refID;
			attachments.push({uid: file.uid, name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time})
		  });
		}
		console.log("bin_files", bin_files, "attachments", attachments)
		let task = JSON.parse(JSON.stringify(this.state.task));
		let task_json = JSON.parse(JSON.stringify(task.parenttask.task_json));
		let previous_bins = task_json.object_data.attachments;
		let new_bins = [...previous_bins, ...bin_files];
		console.log("new_bins", new_bins)
		let cur_lane = "COMP_PAD_COMPLETED";
		task_json.task_data.last_action = cur_lane;
		task_json.task_data.cur_lane = cur_lane;
		task_json.task_data.actiondate = Date.now();
		task_json.object_data.completed_attachments = task_json.object_data.completed_attachments === undefined ? [] : task_json.object_data.completed_attachments;
		for(let file of attachments)
			task_json.object_data.completed_attachments.push(file);
		task_json.action_data = {
		  action: cur_lane,
		  actiondate: Date.now(),
		  assigned_to: 0,
		  cur_lane: cur_lane,
		  performed_by: Store.getStoreData("loggedin_contact_id")
		}
		const postData = {
		  command: "update_task",
		  task_json,
		  bin_files: new_bins,
		  taskid: task.parenttask.id
		};
		console.log("postData", postData)
		let api = new APICall();
		api.command(postData, this.closeModalAndRefresh);
	  }

	  closeModalAndRefresh = (result) => {
		window.location.reload();
	  }

    render () {
        
        if (this.state.task === null) return <div></div>
        const module_config = Store.getStoreData("module_config");
        const trade_info = "actual_trade_details" in this.state.task.parenttask.task_json.object_data ? this.state.task.parenttask.task_json.object_data.actual_trade_details : this.state.task.parenttask.task_json.object_data.trade_details;
        const security_id = trade_info.security_id;
        let identifier_values_arr = [];
	    let identifier_type_arr = [];
	    for (let key in module_config.security_register[security_id.toString()].identifier_types) {
	    	if (module_config.security_register[security_id.toString()].identifier_types[key] !== "") {
	    		identifier_values_arr.push(module_config.security_register[security_id].identifier_types[key]);
	    		identifier_type_arr.push(module_config.security_identifier_types[key].title);
	    	}
	    }
	    let identifier_values = identifier_values_arr.join();
	    let identifier_types = identifier_type_arr.join();
        const currency_symbols = {
            'GBP': "&pound;",
            'EUR': "&euro;",
            'USD': "&dollar;"
        };
        const currency = module_config.currencies[trade_info.currency_id.toString()];
        const status = "Cancelled";

		//console.log("AtTAcHmeNTS : ", this.state.task.parenttask.task_json.object_data.attachments);
        
		let attachments = [];
        attachments = "attachments" in this.state.task.parenttask.task_json.object_data ? JSON.parse(JSON.stringify(this.state.task.parenttask.task_json.object_data.attachments)) : []
        if(!Array.isArray(attachments)){
        	attachments = []
        }
		if ("completed_attachments" in this.state.task.parenttask.task_json.object_data) {
			if (this.state.task.parenttask.task_json.object_data.completed_attachments.length !== 0) {
				for (let file of this.state.task.parenttask.task_json.object_data.completed_attachments) {
					attachments.push(file);
				}
			}
		}

		if ("approval_data" in this.state.task.parenttask.task_json.object_data) {
			for (let app_data of this.state.task.parenttask.task_json.object_data.approval_data) {
				if("attachments" in app_data) {
					for (let app_attach of app_data.attachments) attachments.push(app_attach);
				}
			}
		}

        let approval_attachments = ("approval_data" in this.state.task.parenttask.task_json.object_data && "attachments" in this.state.task.parenttask.task_json.object_data.approval_data[0]) ? this.state.task.parenttask.task_json.object_data.approval_data[0].attachments : []
        if(!Array.isArray(approval_attachments)){
        	approval_attachments = []
        }
		let approval_data = "approval_data" in this.state.task.parenttask.task_json.object_data ? this.state.task.parenttask.task_json.object_data.approval_data : [];
		for (let item of approval_data) {
			item.internal_approver_comments = "internal_approver_comments" in item === true ? item.internal_approver_comments : "";
		}
		
		const my_holdings = this.state.task.holdings;
		const automatic_rejection_advices = {};
		for (let item of module_config.general.automatic_rejection_advices) {
			automatic_rejection_advices[item.identifier] = {text: item.text, show: item.show_on_entry_form};
		}
		if (Store.getStoreData("role") === "team") {
			automatic_rejection_advices.restricted_security.show = module_config.general.show_restricted_security_information;
		}
		// console.log("automatic_rejection_advices:", automatic_rejection_advices);
		let warning = {validity: false};
		// let warning = {short: false, breach: false, restrict: false, holding: false, past: false, validity: false};
		// if (this.state.task.parenttask.task_json.object_data.module_config.general.add_mandatory_checkboxes === true) {
		// 	if (this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_short === true && this.state.task.parenttask.task_json.object_data.not_short === false) warning.short = true;
		// 	if (this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_breach === true && this.state.task.parenttask.task_json.object_data.not_breach === false) warning.breach = true;
		// }
		// if (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval") {
		// 	if (moment(this.state.task.parenttask.task_json.object_data.transaction_date, 'DD/MM/YYYY').set('hour', 23).set('minute', 59).set('second', 59).unix() < this.state.task.parenttask.task_json.object_data.disclosure_time) warning.past = true;
		// }
		// const trade_details = "actual_trade_details" in this.state.task.parenttask.task_json.object_data ? this.state.task.parenttask.task_json.object_data.actual_trade_details : this.state.task.parenttask.task_json.object_data.trade_details;
		// if (module_config.security_register[trade_details.security_id.toString()].is_restricted === true) {
		// 	warning.restrict = true;
		// }
		// if (trade_details.security_id.toString() in my_holdings === true) {
		// 	const holding_time = my_holdings[trade_details.security_id.toString()].holding_time;
		// 	const holding_period_days = this.state.task.parenttask.task_json.object_data.module_config.general.holding_period_days;
		// 	const holding_check_time = moment.unix(holding_time).add(holding_period_days, 'days').unix();
		// 	if (moment().set('hour', 23).set('minute', 59).set('second', 59).unix() <= holding_check_time) warning.holding = true;
		// }
		if (this.state.task.parenttask.cur_lane === "COMP_PAD_COMPLETED") {
			const action_data = this.state.task.parenttask.task_json.action_data.find(item => item.cur_lane === "COMP_PAD_COMPLETED");
			let completed_time = Math.floor(action_data.actiondate / 1000);
			if (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval") {
				if (this.state.task.parenttask.task_json.object_data.approval_type !== "nr" && this.state.task.parenttask.task_json.object_data.module_config.general.confirm_trade_information === true) {
					let check_time = moment.unix(approval_data[approval_data.length - 1].action_time).add(module_config.general.validity_window_hours, 'hours').unix();
					if (completed_time > check_time) {
						warning.validity = true;
					}
				}
			}
		}
		let error = false;
		for ( let key in warning) {
			if (warning[key] === true) error = true;
		}
        let cancellation_data = JSON.parse(JSON.stringify(this.state.task.parenttask.task_json.object_data.cancellation_data));
		//console.log("warning:", warning);
		console.log("approver level:", module_config.role_types[this.state.task.parenttask.task_json.object_data.role_type_id].approver_level);
		console.log("attachments:", attachments);
		//console.log("state in complete:", this.state);
		//console.log("task props:", this.props.task);
        return (
            <ModalWraper>
				{ this.state.show_spinner === true && <SpinningLoader />}
				<MRModalContainer>

					<MRModalHeader>
						<MRModalHeaderText>{`PAD-${this.state.task.parenttask.id}-${status}`}</MRModalHeaderText>
						<MRModalHeaderCloseBtn onClick={this.closeView}>
				            <FaTimes />
				        </MRModalHeaderCloseBtn>
				        <Clearfix />
				        {/*<MRModalSubHeaderText>Complete the required fields to create a new PA Dealing record</MRModalSubHeaderText>*/}
					</MRModalHeader>

					<MRModalBody>
						{/* <MsgBox>The PAD request has been approved. You can now enter the actual trade data.</MsgBox> */}
						<InfoBox>
							<Box>
								<Row>
									<Cell30><MRModalLabel>Name</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{this.state.company_users_obj[this.state.task.parenttask.task_json.object_data.requester_id.toString()].name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{
									(() => {
									if (parseInt(this.state.task.parenttask.task_json.object_data.requester_id) !== parseInt(Store.getStoreData("loggedin_contact_id"))) {
										return(<Row>
											<Cell30><MRModalLabel>Role</MRModalLabel></Cell30>
											<Cell70><MRModalLabel>{Store.getStoreData("module_config").role_types[this.state.task.parenttask.task_json.object_data.role_type_id.toString()].name}</MRModalLabel></Cell70>
											<Clearfix></Clearfix>
										</Row>)
									}
									})()
								}								
								<Row>
									<Cell30><MRModalLabel>Instruction</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{Store.getStoreData("module_config").instruction_types[this.state.task.parenttask.task_json.object_data.instruction_type_id.toString()].name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{/* <Row>
									<Cell30><MRModalLabel>Transaction Type</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{module_config.transaction_types[this.state.task.parenttask.task_json.object_data.transaction_type_id.toString()].name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row> */}
								<Row>
									<Cell30><MRModalLabel>Transaction Date</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.transaction_date}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{this.state.custom_fields.map(field => {
									return (
										<Row key={field.name}>
											<Cell30><MRModalLabel>{field.nickname}</MRModalLabel></Cell30>
											<Cell70><MRModalLabel>{field.value}</MRModalLabel></Cell70>
											<Clearfix></Clearfix>
										</Row>
									)
								})}
							</Box>
                            <HR />
							<Box>
                                <BoxHeader>Security Details</BoxHeader>
								<Row>
									<Cell30><MRModalLabel>Name</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{module_config.security_register[security_id.toString()].security_name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Identifier</MRModalLabel></Cell30>
									<Cell70><MRModalLabel title={identifier_values}>{identifier_values}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Identifier Type</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{identifier_types}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
							</Box>
							{
								approval_data.map((item, index) => {
									return (
										<div key={index}>
											<HR />
											<Box>
												<Row>
													<Cell30><MRModalLabel>Reviewer</MRModalLabel></Cell30>
													<Cell70><MRModalLabel>{this.state.company_users_obj[item.performed_by.toString()].name}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												<Row>
													<Cell30><MRModalLabel>Response</MRModalLabel></Cell30>
													<Cell70><MRModalLabel style={{textTransform: "capitalize", color: this.state.task.parenttask.cur_lane === "COMP_PAD_REJECTED" ? "#D95656" : "#37ADA7"}}>{item.status}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												<Row>
													<Cell30><MRModalLabel>Reviewed</MRModalLabel></Cell30>
													<Cell70><MRModalLabel>{moment.unix(item.action_time).format('DD/MM/YYYY hh:mm a')}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												<Row>
													<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
													<Cell70><MRModalLabel>{item.comments}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												{
													(() => {
														if (Store.getStoreData("role") !== "team" && module_config.general.internal_approver_comments === true) {
															return (
																<Row>
																	<Cell30><MRModalLabel>Internal Comments</MRModalLabel></Cell30>
																	<Cell70><MRModalLabel>{item.internal_approver_comments}</MRModalLabel></Cell70>
																	<Clearfix></Clearfix>
																</Row>
															)
														}
													})()
												}
												<Row>
													<Cell30><MRModalLabel>Attachments</MRModalLabel></Cell30>
													<Cell70>
														<MRModalLabel>
														{item.attachments.map(file => {
															return (
																<Row key={file.uid}>
																	{file.name}
																	<div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
																		<FiDownload /> Download
																	</div>
																</Row>
															)
														})}
														</MRModalLabel>

													</Cell70>
													<Clearfix></Clearfix>
												</Row>
											</Box>
										</div>
									)
								})
							}
                            <HR />
                            <Box>
                                <BoxHeader>Trade Details</BoxHeader>
                                <Row>
                                    <Cell30><MRModalLabel>Volume</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{trade_info.volume}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                <Row>
                                    <Cell30><MRModalLabel>Price</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel><span dangerouslySetInnerHTML={{ __html: currency_symbols[currency.title] }}></span>{trade_info.price}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                {/* <Row>
                                    <Cell30><MRModalLabel>Counterparty</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{module_config.counterparties[trade_info.counterparty_id.toString()].title}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row> */}
								{
									Store.getStoreData("module_config").general.enable_employee_broker_ac === true &&
									<Row>
										<Cell30><MRModalLabel>Broker</MRModalLabel></Cell30>
										<Cell70><MRModalLabel>
											{
												(()=>{
													let broker_name = "N/A"
													if("broker_id" in this.state.task.parenttask.task_json.object_data.trade_details && this.state.task.parenttask.task_json.object_data.trade_details.broker_id !==0){
														let broker_obj = this.state.broker_list.find(item => item.id === this.state.task.parenttask.task_json.object_data.trade_details.broker_id)
														broker_name = broker_obj.broker_name;
														if(broker_obj.is_active === 0) broker_name += " (Inactive)";
													}
													return broker_name;
												})()
											}
										</MRModalLabel></Cell70>
										<Clearfix></Clearfix>
									</Row>
								}
                                <Row>
                                    <Cell30><MRModalLabel>Beneficial Owner</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{module_config.beneficiaries[trade_info.beneficiary_id.toString()].title}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                {
                                    "execution_date_time" in trade_info === true &&
                                    <Row>
                                        <Cell30><MRModalLabel>Execution Date and Time</MRModalLabel></Cell30>
                                        <Cell70><MRModalLabel>{moment.unix(trade_info.execution_date_time).format('DD/MM/YYYY hh:mm a')}</MRModalLabel></Cell70>
                                        <Clearfix></Clearfix>
                                    </Row>
                                }
                                <Row>
                                	<Cell30><MRModalLabel>Attachments</MRModalLabel></Cell30>
                                	<Cell70>
                                		<MRModalLabel>
                                		{attachments.map(file => {
                                		  return (
                                		    <Row>
                                		      {file.name}
                                		      <div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
                                		        <FiDownload /> Download
                                		      </div>
                                		    </Row>
                                		  )
                                		})}
                                		</MRModalLabel>

                                	</Cell70>
                                	<Clearfix></Clearfix>
                                </Row>
								{
									this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "notification" &&
									<Row>
										<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
										<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.requester_comments}</MRModalLabel></Cell70>
										<Clearfix></Clearfix>
									</Row>
								}
								{
									(this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" && module_config.role_types[this.state.task.parenttask.task_json.object_data.role_type_id].approver_level === "nr") &&
									<Row>
										<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
										<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.requester_comments}</MRModalLabel></Cell70>
										<Clearfix></Clearfix>
									</Row>
								}
                            </Box>
                            <HR />
                            <Box>
                                <BoxHeader>Cancellation Details</BoxHeader>
                                <Row>
                                    <Cell30><MRModalLabel>Cancelled By</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{this.state.company_users_obj[cancellation_data.performed_by.toString()].name}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                <Row>
                                    <Cell30><MRModalLabel>Reason</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{cancellation_data.reason}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                <Row>
                                    <Cell30><MRModalLabel>Date and Time</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{moment.unix(cancellation_data.action_time).format('DD/MM/YYYY hh:mm a')}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                <Row>
                                	<Cell30><MRModalLabel>Attachments</MRModalLabel></Cell30>
                                	<Cell70>
                                		<MRModalLabel>
                                		{ 
                                            (() => {
                                                if("attachments" in cancellation_data && cancellation_data.attachments.length > 0){
                                                    return(
                                                        cancellation_data.attachments.map(file => {
                                                            return (
                                                                <Row>
                                                                    {file.name}
                                                                    <div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
                                                                        <FiDownload /> Download
                                                                    </div>
                                                                </Row>
                                                            )
                                                        })
                                                    
                                                    )
                                                }
                                            })()
                                        }
                                		</MRModalLabel>
                                	</Cell70>
                                	<Clearfix></Clearfix>
                                </Row>
                                {/* <Row>
                                    <Cell30><MRModalLabel>Beneficial Owner</MRModalLabel></Cell30>
                                    <Cell70><MRModalLabel>{module_config.beneficiaries[trade_info.beneficiary_id.toString()].title}</MRModalLabel></Cell70>
                                    <Clearfix></Clearfix>
                                </Row>
                                {
                                    "execution_date_time" in trade_info === true &&
                                    <Row>
                                        <Cell30><MRModalLabel>Execution Date and Time</MRModalLabel></Cell30>
                                        <Cell70><MRModalLabel>{moment.unix(trade_info.execution_date_time).format('DD/MM/YYYY hh:mm a')}</MRModalLabel></Cell70>
                                        <Clearfix></Clearfix>
                                    </Row>
                                }
                                
								{
									this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "notification" &&
									<Row>
										<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
										<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.requester_comments}</MRModalLabel></Cell70>
										<Clearfix></Clearfix>
									</Row>
								}
								{
									(this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" && module_config.role_types[this.state.task.parenttask.task_json.object_data.role_type_id].approver_level === "nr") &&
									<Row>
										<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
										<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.requester_comments}</MRModalLabel></Cell70>
										<Clearfix></Clearfix>
									</Row>
								} */}
                            </Box>
                        </InfoBox>
						{/* {
							(() => {
								const followups = "followups" in this.state.task.parenttask.task_json.object_data === true ? this.state.task.parenttask.task_json.object_data.followups : {};
								if (Object.keys(followups).length !== 0) {
									return (
										<>
											<NoteTableHeaderBar>
												<Section50 style={{fontWeight: "600", color: "#ffffff", fontSize: "15px"}}>Follow Up Tasks</Section50>
												<Section50 style={{textAlign: "right", color: "#ffffff", fontSize: "15px", marginTop: "2px"}}></Section50>
												<Clearfix></Clearfix>
											</NoteTableHeaderBar>
											<CSLTable add={false} processData={this.processFollowups} zebra={false} tableRows="3" />
										</>
									)
								}
							})()
						}
						{
							(this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" && error === true && this.state.task.parenttask.cur_lane !== "COMP_PAD_REJECTED" && Store.getStoreData("role") !== "team") &&
							<Row>
								<MRAlertContainer>
									{(automatic_rejection_advices.approval_validity_window_exceeded.show === true && warning.validity === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.approval_validity_window_exceeded.text}</b></p>}
								</MRAlertContainer>
							</Row>
						} */}
						{/* <Row>
							{
								(()=>{
									if (Store.getStoreData("role") === "admin_manager"){
										return(
											<>
												<MRModalLabel style={{marginTop : "10px"}}>Attachments</MRModalLabel>
												<div>You can add attachments after a PA Dealing Request has been completed. Files attached here will appear with any other files attached by the employee.</div>
												<ModalNameInputDiv>
													<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />
												</ModalNameInputDiv>
											</>
										)	
									}
								})()
								
							}
						</Row> */}
					</MRModalBody>

					<MRModalFooter>
						<MRModalNextBtn onClick={this.closeView}>CLOSE</MRModalNextBtn>
						{/* {
							(() =>{
								console.log("Number of BinFiles : ", this.state.binFiles.length);
								if (this.state.binFiles.length > 0 === true) {
									return <MRModalNextBtn onClick={this.uploadFiles}>SUBMIT</MRModalNextBtn>
								}
							})()
						} */}
						<Clearfix></Clearfix>
					</MRModalFooter>

				</MRModalContainer>
			</ModalWraper>
        )
    }
}

export default CancelledModal;