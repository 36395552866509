import React from "react";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import CSLTable from "../PAD/module_config/CSLTable/CSLTable";
import CSLToggle from "../../Components/PAD/module_config/CSLToggle";
// import Utils from "../../../../Common/Utils";
// import Store from "../../../../Common/Store";
import APICall from "../../Common/APICall";
import MangeRoletype from "../PAD/module_config/include/modals/MangeRoletype";
import Dropdown from "../Common/Dropdown";
// import CslSimpleDropDown from '../../../Common/CslSimpleDropDown';

// const SearchInput = styled.input`
//   height: 30px;
//   width: 98%;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   padding: 5px;
//   color: #222222;
//   font-size: 15px;
//   font-family: "Montserrat", sans-serif;
// `;
// const MRModalLabel = styled.div`
//   color: #212121;
//   font-weight: 600;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   display: inline-block;
// `;

const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const ModalWraper = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 70%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  margin-left: 15%;
  padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  // width: 40%;
  // position: absolute;
  // top: 38px;
  // z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  // margin-bottom: 10px;
  // margin-left: 30%;
`;
const MRModalHeader = styled.div`
background-color: white;
display: block;
padding-top: 5px;
font-size: 14px;
font-weight: 700;
height: 800px;
width: calc(100% - 10px);
border-width: 1px 1px 1px 10px;
border-left: 9px solid #04ada8;
border-bottom: 1px solid #dfdfdf;
`;


// const GeneralLabel = styled.div`
//   font-weight: 600;
//   color: #595959;
//   margin-top: 5px;
// `;
const RoleSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;

  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;
class RoleTypes extends React.Component {
  state = {
    role_types: {
      Ul8BSWXINQ: { name: "Director", approver_level: "Approver role" },
      CoiNdA4LTD: { name: "Partner", approver_level: "Approver role" },
      NMNKmINMlJ: { name: "Sales", approver_level: "Approver role" },
      NKC5KMNQEo: { name: "Marketing", approver_level: "Approver role" },
      "349OttAWhi": { name: "Customer Services", approver_level: "Approver role" },
      xFcxiTO8R8: { name: "Finance", approver_level: "Approver role" },
      oJhHowaNlN: { name: "Compliance", approver_level: "Approver role" },
      IkDfISdazY: { name: "Administration", approver_level: "Approver role" },
      XHq7SVkgid: { name: "Senior Manager", approver_level: "Approver role" },
      "2re2PbTcqo": { name: "Operations", approver_level: "Approver role" },
      wjgTUIWeH3: { name: "Manager", approver_level: "Approver role" },
      "6eiekroiaB": { name: "Team Leader", approver_level: "Approver role" },
    },
    prefered_columns: null,
    show_modal: null,
    type: {},
    approval_options: [
      { id: "Approver_Role", name: "Approver Role " },
      { id: "Line_Manager", name: "Line Manager" },
      { id: "Approver_Role_then_Line_Manager", name: "Approver Role then Line Manager" },
      { id: "Line_Manager_then_Approver_Role", name: "Line Manager then Approver Role" },
      { id: "Not_required_(Auto-approve)", name: "Not required (Auto-approve)" },
    ],
    selected_approval_option: "null",
  };


  /* tableData = () => {
    let table = { data: [], columns: [] };
    const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}];

    table.columns = [
      { Header: "#", accessor: "index", width: 30, headerStyle: { textAlign: "center"}, filterable: false, style: { fontFamily: "monospace", fontSize: "14px", textAlign: "right" } },
      { Header: "Type / Question", accessor: "name", headerStyle: { textAlign: "left" }, filterable: false, style: { whiteSpace: "unset" } },
      { Header: "Approval Required", accessor: "approver_level", headerStyle: { textAlign: "left" }, filterable: false, style: { whiteSpace: "unset" },
      Cell: (row) => (

        <RoleSelect value={this.state.role_types[row.original.key].approver_level} selected={this.state.selected_approval_option}  key={row.original.index} onChange={(e)=>this.handleChange(e,row.original.key)}>
        { this.state.approval_options.map( role =>
          <option key={role.id} value={role.name}>{role.name}</option> )
        }
      </RoleSelect>
      ),
      width: 250,
      // headerStyle: { textAlign: "center" },
      // filterable: false


      },
      { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} />, style: { display: "flex", alignItems: "center", justifyContent: "center" } },
      {
        Header: "",
        Cell: (row) => (
            <Dropdown entity_id={row.original.id} actions={actions} performAction={this.performAction} />
        ),
        width: 100,
        headerStyle: { textAlign: "center" },
        filterable: false
      },
  
    ];
    // let aktion= Object.keys(actions)
    //console.log("aktion:",actions[0].action)
    let data = this.state.role_types;
    console.log("roles::::",data)
    let i = 0;
    for(let key in data){
      i++;
      let elem = {'index' : i, 
          'key':key,
              'name': data[key].name,
              'id': data[key].id,
              //'approver_level':this.state.approval_options,
              'status': data[key].is_active,
              'status_value' : data[key].is_active === 1 ? "Active" : "In-Active"

             };
      table.data.push(elem);
      //  console.log("elem:::::::::::::::::::::",elem)
    }


    return table;
  }; */

  handleChange = (event, id) => {
   

    //  let role_types = this.state.role_types;
    // role_types[id].approver_level = event.target.value;

    //console.log("role types::::::", role_types);
  };
  tableData = () => {
    let table = { data: [], columns: [] };
    const actions = [
      { name: "Edit", action: "edit" },
      { name: "Remove", action: "delete" },
    ];

    table.columns = [
      { Header: "#", accessor: "index", width: 30, headerStyle: { textAlign: "center" }, filterable: false, style: { fontFamily: "monospace", fontSize: "14px", textAlign: "right" } },
      { Header: "Type / Question", accessor: "name", headerStyle: { textAlign: "left" }, filterable: false, style: { whiteSpace: "unset" } },
      {
        Header: "Approval Required",
        accessor: "approver_level",
        headerStyle: { textAlign: "left" },
        filterable: false,
        style: { whiteSpace: "unset" },
        Cell: (row) => (
          <RoleSelect value={this.state.role_types[row.original.key].approver_level} selected={this.state.selected_approval_option} key={row.original.index} onChange={(e) => this.handleChange(e, row.original.key)}>
            {this.state.approval_options.map((role) => (
              <option key={role.id} value={role.name}>
                {role.name}
              </option>
            ))}
          </RoleSelect>
        ),
        width: 250,
        // headerStyle: { textAlign: "center" },
        // filterable: false
      },
      { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} />, style: { display: "flex", alignItems: "center", justifyContent: "center" } },
      {
        Header: "",
        Cell: (row) => <Dropdown entity_id={row.original.id} actions={actions} performAction={this.performAction} />,
        width: 100,
        headerStyle: { textAlign: "center" },
        filterable: false,
      },
    ];
    // let aktion= Object.keys(actions)
    //console.log("aktion:",actions[0].action)
    let data = this.state.role_types;
    console.log("roles::::", data);
    let i = 0;
    for (let key in data) {
      i++;
      let elem = {
        index: i,
        key: key,
        name: data[key].name,
        id: data[key].id,
        //'approver_level':this.state.approval_options,
        status: data[key].is_active,
        status_value: data[key].is_active === 1 ? "Active" : "In-Active",
      };
      table.data.push(elem);
      //  console.log("elem:::::::::::::::::::::",elem)
    }

    return table;
  };

  render() {
    return (
      <ModalWraper>
    
      
      <MRModalContainer>
    
        <MRModalHeader> 
      <div>
        <div style={{ width: "90%" }}>
          <TypesContainer>
            <TypesInnerContainer>
              <div style={{ display: "flow-root", paddingBottom: "16px" }}>
                <div style={{ float: "left" }}>
                  <TypesBoldLabel style={{ display: "inline-block" }}>{this.props.sectionName}</TypesBoldLabel>
                </div>
              </div>
              <CSLTable add={false} processData={this.tableData} tableRows={10} />

              {/* <GeneralInnerContainer>
         
          <div style={{ paddingTop: "10px", width: "40%" }}>
            <CslSimpleDropDown options={this.state.approval_options} selected={this.state.selected_approval_option} setSelection={this.setSelection} />
          </div>
        </GeneralInnerContainer> */}
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
      </MRModalHeader>
      </MRModalContainer>
      </ModalWraper>
    );
  }
}

export default RoleTypes;
