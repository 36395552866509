import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import ClearBoth from './ClearBoth';
import CslSimpleDropDown from './CslSimpleDropDown';

const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
// const MRModalSelect = styled.select`
//     box-sizing: border-box;
//     height: 40px;
//     width: 100%;
//     background-color: #fff;
//     border: 1px solid #ffffff;
//     border-radius: 3px;
//     box-shadow: 0 0 4px #c4c4c4;
//     font-family: 'Montserrat',sans-serif;
//     color: #5F6368;
// `;
// const MRModalInput = styled.input`
//     height: 25px;
//     width: 99%;
//     box-shadow: rgb(196, 196, 196) 0px 0px 4px;
//     color: #545454;
//     font-family: 'Montserrat';
//     font-size: 14px;
//     font-weight: 600;
//     border-width: 1px;
//     border-style: solid;
//     border-color: rgb(255, 255, 255);
//     border-image: initial;
//     border-radius: 3px;
//     padding: 6px;
// `;

const MRModalInput = styled.input`
	border: 1px solid #DBDBDB;
	border-radius: 2px;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
    width: 100%;
    height: 36px;
`;

const Row = styled.div`
	margin-bottom: 10px;
`;

class CustomField extends React.Component
{
    state = {fields: null};

    componentDidMount() {
        this.setState({fields: this.props.fields});
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props !== prevProps) {
    //         this.setState({fields: this.props.fields});
    //     }
    // }

    handleChange = (field_name) => (event) => {
        event.preventDefault();
        let fields = JSON.parse(JSON.stringify(this.state.fields));
        fields.forEach(item => {
            if (item.name.toString() === field_name.toString()) {
				item.value = event.target.value;
			}
        })
        this.setState({fields});
        this.props.changeFieldValue(field_name, event.target.value);
    }

    changeOtherValue = (field_name) => (event) => {
        event.preventDefault();
        let fields = JSON.parse(JSON.stringify(this.state.fields));
        fields.forEach(item => {
            if (item.name.toString() === field_name.toString()) {
				item.other_value = event.target.value;
			}
        })
        this.setState({fields});
        this.props.changeOtherValue(field_name, event.target.value);
    }

    changeDropdownValue = (option_id, field_name) => {
        let fields = JSON.parse(JSON.stringify(this.state.fields));
        fields.forEach(item => {
            if (item.name.toString() === field_name.toString()) {
				if (option_id === "Select") {
                    item.value = "";
                    item.other_value = ""
                } else if (option_id === "other") {
                    item.value = "other";
                } else {
                    item.value = option_id;
                    item.other_value = "";
                }
			}
        })
        this.setState({fields});
        this.props.changeDropdownValue(option_id, field_name);
    }

    createAlphaField = (field) => {
        return (
            <Row key={field.name}>
                {
                    field.required === true
                    ?
                    <div>
                        <div style={{float: "left"}}><MRModalLabel>{field.nickname}</MRModalLabel></div>
                        <div style={{float: "left"}}><div style={{color: "red", fontWeight: "600", fontSize: "21px", marginLeft: "5px"}}>*</div></div>
                        <div style={{clear: "both"}}></div>
                    </div>
                    :
                    <MRModalLabel>{field.nickname}</MRModalLabel>
                }
                <MRModalInput type="text" value={field.value} onChange={this.handleChange(field.name)} />
            </Row>
        )
    }

    createNumericField = (field) => {
        return (
            <Row key={field.name}>
                {
                    field.required === true
                    ?
                    <div>
                        <div style={{float: "left"}}><MRModalLabel>{field.nickname}</MRModalLabel></div>
                        <div style={{float: "left"}}><div style={{color: "red", fontWeight: "600", fontSize: "21px", marginLeft: "5px"}}>*</div></div>
                        <div style={{clear: "both"}}></div>
                    </div>
                    :
                    <MRModalLabel>{field.nickname}</MRModalLabel>
                }
                <MRModalInput type="number" value={field.value} onChange={this.handleChange(field.name)} />
            </Row>
        )
    }

    createDropdownField = (field) => {
        let options = [{id: "Select", name: "Select"}];
        field.dropdown_options.forEach(option => {
            options.push({id: option.toString(), name: option.toString()});
        })
        if (field.other === true) options.push({id: "other", name: "Other"});
        let selected = {id: "Select", name: "Select"};
        if (field.value !== "") selected = {id: field.value, name: field.name};
        let other_value = "";
        if (field.value === "other") {
            other_value = field.other_value;
            selected = {id: "other", name: "Other"};
        }
        if (field.value !== "" && field.value !== "other" && field.value !== "Select") selected = {id: field.value, name: field.value};
        return (
            <Row key={field.name}>
                {
                    field.required === true
                    ?
                    <div>
                        <div style={{float: "left"}}><MRModalLabel>{field.nickname}</MRModalLabel></div>
                        <div style={{float: "left"}}><div style={{color: "red", fontWeight: "600", fontSize: "21px", marginLeft: "5px"}}>*</div></div>
                        <div style={{clear: "both"}}></div>
                    </div>
                    :
                    <MRModalLabel>{field.nickname}</MRModalLabel>
                }
                <CslSimpleDropDown options={options} selected={selected} entity={field.name} setSelection={this.changeDropdownValue} />
                {field.value === "other" && <MRModalInput style={{marginTop: "10px"}} type="text" value={field.other_value} onChange={this.changeOtherValue(field.name)} />}
            </Row>
        )
    }

    render() {
        if (this.state.fields === null) return <div></div>
        console.log("fields:", this.state.fields);
        return (
            <>
                {this.state.fields.map(field => {
                    if (field.type === "Alpha") {
                        return this.createAlphaField(field);
                    }
                    if (field.type === "Numeric") {
                        return this.createNumericField(field);
                    }
                    if (field.type === "Dropdown") {
                        return this.createDropdownField(field);
                    }
                })}
            </>
        )
    }
}

export default CustomField;
