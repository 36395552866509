import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import AlertBox from './Components/Common/AlertBox';
import ModuleConfigHOC from "./Components/PAD/module_config/ModuleConfigHOC";
import Index from "./Components/Index";
import ManageConfirmation from "./Components/PAD/manage_confirmation/ManageConfirmation";
import MyConfirmation from "./Components/PAD/manage_confirmation/MyConfirmation";
import APICall from './Common/APICall'
import APIContact from './Common/APIContact';
import CSLLoader from './Components/Common/CSLLoader'
import Store from './Common/Store';
import Utils from './Common/Utils';
import ComDecom from './Common/ComDecom.js';
import UIElements from "./Components/UIElements/UIElements";
import authService from 'mcc-front-aux/dist/authService';

import Reload from './Common/Reload.js';
import EventBus from "./Common/EventBus";

class AppContent extends React.Component {
  state = {
    OverlayHeight: null,
    cardata: null,
    ready: false,
    OverlayTaskHeight: null,
    role: "noperm",
    refreshCount: 0,
    alert_param: null,
  };

  constructor(props) {
    super(props);
    console.log("constructor");
    Utils.setEnv("padreg");
    this.callContact();
  }

  callContact() {
      let postData = { command: "contactlist"};
      let api = new APIContact();
      console.log("calling callContact");
      let j = api.command(postData, this.processContactData);
      //console.log("return true", j)
  }

  // processContactData = (result) => {
  //     localStorage.setItem("contactlist", JSON.stringify(result));
  // }
  processContactData = (result) => {
      console.log("after callContact");
      let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
      result.result['contactlist'] = cl
      localStorage.setItem("contactlist", JSON.stringify(result));
  }

  componentDidMount() {
    console.log("initail");
    EventBus.registerEvent('system_err','AppContent', this.system_err)
    const postData = { command: "index" };
    const api = new APICall();
    api.command(postData, this.processIndex);
  }

  system_err = (error) => {
    let alert_param = {
      title : 'System Error',
      message : error,
      ok_text : 'OK',
      confirm : false,
      alertHandler : this.alertHandler,
      stack : {id : 0}
    }
    this.setState({ ready : true, alert_param, task_loading : false})
  }

  processIndex = (result) => {
    console.log("result", result);
    if (result.error_code === 0) {
      if (Store.getStoreData("is_admin")) {
      } else {
        // let holdings = {};
        // for (let item of result.result.holdings) {
        //   holdings[item.user_id.toString()] = item.securities;
        // }
        // console.log("holdings:", holdings);
        // let has_gc = result.result.gc_company.length > 1 ? true : false;
        // let cts = localStorage.getItem("contactlist");
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        console.log('cts==>', cts);
        let company_users = [];
        let user_roles = {};
        let cur_active_users = {}
        if('contactlist' in cts.result && 'user_roles' in cts.result) {
          user_roles = cts.result.user_roles['padreg'];
        }
        for(let k in cts.result.contactlist){
          company_users.push({
            user_id: k,
            name: cts.result.contactlist[k.toString()].IsActive === false || cts.result.contactlist[k.toString()].IsDeleted === true ? "Inactive User" : cts.result.contactlist[k.toString()].ContactName,
            role: k.toString() in cts.result.user_roles['padreg'] ? cts.result.user_roles['padreg'][k.toString()] : "no_access"
          });
          let clists =  cts.result.contactlist;
          if(user_roles[k.toString()] !== undefined && clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false){
            if (cts.result.user_roles['padreg'][k.toString()] !== 'no_access' ) {
              cur_active_users[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
            }
          }
        }
        let module_config = JSON.parse(JSON.stringify(result.result.module_config));
        console.log("MODULE CONFIG : ", module_config);
        module_config.general.internal_approver_comments = "internal_approver_comments" in module_config.general === true ? module_config.general.internal_approver_comments : true;
        Store.updateStore("role", result.result.role);
        Store.updateStore("module_config", module_config);
        Store.updateStore("holdings", {});
        Store.updateStore('has_gc',result.result.has_gc);
        Store.updateStore('company_users', company_users);
        Store.updateStore('my_users', result.result.my_users);
        Store.updateStore('my_all_users', result.result.my_all_users);
        Store.updateStore('loggedin_contact_id', result.result.loggedin_contact_id);
        Store.updateStore('company_id', result.result.company_id);
        Store.updateStore('cur_active_users', cur_active_users);
        this.setState({ ready: true });
      }
    } else {
      const alert_param = {
        title: "System Error",
        message: "An error has occured. Please contact your System Administrator for further assistance.",
        ok_text: "Ok",
        confirm: false,
        alertHandler: this.alertHandler,
        stack: { id: 0 },
      };
      this.setState({ ready: true, alert_param: alert_param });
    }
  };

  alertHandler = (result, stack) => {
    Reload.ReloadPage();
  };

  refreshState = () => {
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  };

  // render () {
  //   return (<div>This is app.js</div>)
  // }

  render() {
    if (!this.state.ready) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }
    return (
      <Router>
        <Switch>
          <Route path="/moduleconfig" exact>
            <ModuleConfigHOC />
          </Route>
          <Route path="/moduleconfig/:requester" exact component={ModuleConfigHOC} />
          <Route path="/manage_confirmation" exact>
            <ManageConfirmation />
          </Route>
          <Route path="/manage_confirmation/:Id" exact component={ManageConfirmation} />
          <Route path="/confirmation" exact>
            <MyConfirmation />
          </Route>
          <Route path="/confirmation/:conId" exact component={MyConfirmation} />
          <Route path="/" exact>
            <Index refreshCallback={this.refreshState} noParam={true} />
          </Route>
          <Route path="/ui" exact>
            <UIElements refreshCallback={this.refreshState} noParam={true} />
          </Route>
          <Route path="/:taskId" exact component={Index} />
        </Switch>
      </Router>
    );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
    console.log('this.props', this.props)
    let api_url = process.env.REACT_APP_API_URL;
    if (api_url === undefined) {
      console.error("REACT_APP_API_URL is not defined. Please check environment variables.")
    }
    Store.updateStore('api_url', api_url)

    authService.refresh().then(() => {
      this.setState({ready: true})
    })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>
    // //return <SiteLayout AppContent={appContent} />

    return <AppContent />
  }
}

export default App;
