import React from "react";
import styled from "styled-components";
// import { Store } from "../Common";
import Store from '../../Common/Store';
import TasksList from "./TasksList";
// import TaskModal from "./TaskModal";
import AddPadModal from './Modals/AddPadModal';
import { FiMoreVertical } from "react-icons/fi";
// import { event } from 'jquery';
import PadMenu from './PadMenu';
// import MyHoldingList from './MyHoldingsList';

import HoldingModal from "./HoldingModal";
const SubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #223b5a;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 12px;
  font-size: 11px;
  padding-left: 12px;
  cursor: pointer;
`;
const IndexContainer = styled.div`
  padding-top: 8px;
  padding-left: 5px;
`;

class TasksIndex extends React.Component {
  state = {
    isLoaded: false,
    pending_tasks: [],
    showModal: null,
    show_menu: false,
  };

  refreshCallback = () => this.props.refreshCallback();

  showTaskModal = (row) => (event) => {
    event.preventDefault();
    const index = row.index === null ? 0 : row.index;
    Store.updateStore("q_index", index);
    this.setState({ showModal: "TaskModal" });
  };

  closeModal = (event) => this.setState({ showModal: null });

  toggleMenu = (event) => {
    event.preventDefault();
    this.setState({ show_menu: this.state.show_menu === true ? false : true });
  };

  closePadMenu = () => {
    this.setState({ show_menu: false });
  };
  showHoldingModal = () => {
    // event.preventDefault();
    //alert("hi");
    this.setState({ showModal: "HoldingModal" });
  };
  render() {
    const task_id = this.props.taskId ? this.props.taskId : 0;
    return (
      <IndexContainer>
        {this.state.show_menu === true && <PadMenu closePadMenu={this.closePadMenu} showHoldingModal={this.showHoldingModal} />}
        <div>
          <div style={{ float: "left" }}>{task_id === 0 && <SubmitButton onClick={this.showTaskModal({ index: -1 })}>ADD PAD ENTRY</SubmitButton>}</div>
          <div style={{ float: "right", position: "relative" }}>
            <div id="pad_ellipsis" onClick={this.toggleMenu} style={{ paddingTop: "7px", paddingRight: "15px", fontSize: "20px", cursor: "pointer" }}>
              <FiMoreVertical />
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>

        <TasksList add={false} taskId={task_id} refreshCallback={this.refreshCallback} />
        {/*{this.state.showModal === "TaskModal" && <TaskModal closeModal={this.closeModal} />}*/}
        {this.state.showModal === "TaskModal" && <AddPadModal closeModal={this.closeModal} />}
        {this.state.showModal === "HoldingModal" && <HoldingModal closeModal={this.closeModal} />}
      </IndexContainer>
    );
  }
}
export default TasksIndex;
