import React from 'react';
import PropTypes from "prop-types";
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import styled from 'styled-components';
import CSLTable from '../../../Common/CSLTable';
import EmailConfigModal from './EmailConfigModal';
import CSLLoader from './../../../Common/CSLLoader';

const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 10px 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

class Email extends React.Component
{

	static propTypes = {
        /** */
		general: PropTypes.object,
        /** */
        email: PropTypes.array,
        /** */
		variables: PropTypes.array,
        /** */
		updateEmail: PropTypes.func,
        /** */
		updateGeneralSave: PropTypes.func,
    };

	state = {
		email: null,
		variables: null,
		show_email_config_modal: null,
		content: null,
		general: true,
		isLoadedIcon: false
	};

	componentDidMount(){
		this.setState({
			email: this.props.email,
			variables: this.props.variables,
			general: this.props.general
		});
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props) {
			this.setState({
				email: this.props.email,
				variables: this.props.variables,
				general: this.props.general,
				isLoadedIcon: false
			});
		}
	}

	showEmailConfigModal = (email_id) => (event) => {
		event.preventDefault();
		const content = this.state.email.find((item) => item.id === email_id )
		this.setState({ content, show_email_config_modal: true});
	}

	modifyEmails = (content) => {
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === content.id)
				item = content;
			newemail.push(item);
		})
		this.props.updateEmail(newemail);
		this.setState({ email: newemail, show_email_config_modal: null });
	}

	closeModal = () =>
		this.setState({ show_email_config_modal: null, content: null });

	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Email', accessor: 'email', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{Header: 'Subject', accessor: 'subject', minWidth: 240, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Enabled', Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.enabled) {
											   return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <ConfigBtn onClick={this.showEmailConfigModal(row.original.id)}>Configure</ConfigBtn>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}];
        this.state.email.forEach((item) => {
        	let elem = {id: item.id, email: item.name, subject: item.subject, enabled: item.enabled};
        	ret.data.push(elem);
        })
        return ret;
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === section_id)
				item.enabled = item.enabled ? false : true;
			newemail.push(item);
		})
		this.setState({isLoadedIcon: true})
		this.props.updateEmail(newemail);
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}

	render()
	{
		if (this.state.email === null)
			return (<div>Loading...</div>);

		return (
			<div>
			{ this.state.show_email_config_modal &&
				<EmailConfigModal
					variables={this.state.variables}
					content={JSON.parse(JSON.stringify(this.state.content))}
					modifyEmails={this.modifyEmails}
					closeModal={this.closeModal} />
			}
			{
				(() => {
					if(this.state.isLoadedIcon){
						return <div><CSLLoader style={{position: 'absolute'}}/></div>
					}
				})()
			}
			<EmailContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Enable Email Reminder?</EmailBoldLabel>
					{
						(() => {
							if (this.state.general.email_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('email_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('email_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Include Admin Managers in Email Notifications</EmailBoldLabel>
					{
						(() => {
							if (this.state.general.email_to_admins) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('email_to_admins')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('email_to_admins')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
			</EmailContainer>
			<div styled={{clear:"both"}}></div>
				{
					(() => {
						if (this.state.general.email_enabled) {
							return(
									<EmailContainer>
										<EmailInnerContainer>
											<EmailBoldLabel style={{display: "inline-block"}}>Emails</EmailBoldLabel>
											{/* <EmailLabel style={{display: "inline-block"}}>(inherited from Task Manager)</EmailLabel> */}
											<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={5} refreshCallback={this.refreshState} />
										</EmailInnerContainer>
									</EmailContainer>
								)
							
						}
					})()
				}			
			</div>
		);
	}
}

export default Email;