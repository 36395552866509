import Store from '../../Common/Store.js';
import axios from 'axios';
import http from 'mcc-front-aux/dist/httpCommon'


class UploadFile {
    constructor () {
        this.api_url = Store.getStoreData('base_api_url');
        this.contact_id = Store.getStoreData('loggedin_contact_id');
        this.company_id = Store.getStoreData('company_id');
    }

    command(postData, callback, uploadCallback = null) {
        let url = this.api_url +"/uploaddata"

        let jsonDataString = JSON.stringify(postData);
        const payload = JSON.parse(jsonDataString);
        console.log("payload", payload)
        const data = new FormData();
        


        for (var i = 0; i < payload.length; i++) {
            data.append(`model[${i}].name`, payload[i].name);
            data.append(`model[${i}].bin_file`, payload[i].bin_file);
            data.append(`model[${i}].company_id`, this.company_id);
            data.append(`model[${i}].contact_id`, this.contact_id);
        }



        var config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                console.log('UploadProgress loaded, total', loaded, total)
                if (uploadCallback !== null) uploadCallback(progressEvent);
            },
            mcc_debug: false
        };

        console.log("config data", config)

        http.request(config)
            .then(function (response) {
                console.log("response.data", response.data);
                callback(response.data)
                // let callback_data = config.mcc_debug === true ? JSON.stringify(response.data) : response.data;
                // callback(callback_data);
            })
            .catch(function (error) {
                console.log("Upload Error:", error);
                // console.log("Data",error?.response?.data);
                // console.log("Headers",error.response.headers);
            });


    }
}

export default UploadFile;
