import React from "react";
import styled from "styled-components";
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import CSLTable from '../Common/CSLTable';
import ClearBoth from '../Common/ClearBoth';
//import Dropdown from "../Common/Dropdown";
//import SimpleDropdown from "../Common/SimpleDropdown";
import moment from "moment";
import FollowupModal from './Modals/FollowupModal';
import EllipsisMenu from "../Common/EllipsisMenu";
import SpinningLoader from "../Common/SpinningLoader";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
  // padding: 0 5px;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
`;
const OverviewInfoBody = styled.div`
  // padding: 5px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const OverviewInfoHeaderTitle = styled.div`
  position: relative;
  padding: 12px;
  float: left;
  color: #323232;
  font-weight: 600;
  margin: 2px;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
  cursor: pointer;
`;
const ExportsLine = styled.div`
  float: left;
  color: #04ada8;
  font-weight: 600;
  font-style: italic;
  margin-top: 28px;
  marginleft: 15px;
`;

class TasksList extends React.Component {
  state = {
    ready: false,
    tasksList: [],
    parent_tasks: [],
    followup_tasks: [],
    current_followup_task: null,
    modal_view: null,
    drs: null,
    show_spinner : false,
    export_clicked : false,
  };

  constructor(props) {
    super(props);
    this.api = new APICall();
  }

  componentDidMount() {
    const postData = { command: "list_tasks" };
    const api = new APICall();
    api.command(postData, this.processTasks);
    // let myTasks = Store.getStoreData("mytasks");
    // let updateFromDate = new Date(this.state.filterRequestedFromDate.getFullYear(), this.state.filterRequestedFromDate.getMonth() - 1, 1);
    // this.setState({
    //   tasksList: myTasks,
    //   tasksListFilter: myTasks,
    //   filterRequestedFromDate: updateFromDate,
    // });

    // // URL as a :taskId
    // if (this.props.taskId) this.goToTaskUrlBare(parseInt(this.props.taskId));
    // else this.setState({ ready: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.followup_task_id !== this.props.followup_task_id) {
      if (this.props.followup_task_id !== null) {
        const api = new APICall();
        let postData = { command: "get_subtask", task_id: parseInt(this.props.followup_task_id) };
        api.command(postData, this.openFollowupModal);
      }
    }
  }

  processTasks = (result) => {
    console.log("processTasks result:", result);
    Store.updateStore("drs", result.result.drs);
    Store.updateStore("holdings", result.result.holdings);
    // for (let task of result.result.parent_tasks) {
    //   task.approval_type = task.approval_type === "none" ? Store.getStoreData("module_config").role_types[task.role_type_id].approver_level : task.approval_type;
    // }
    console.log("result.result.parent_tasks:", result.result.parent_tasks);
    let parent_tasks = result.result.parent_tasks;
    parent_tasks.sort((a, b) => {
			let a1 = a.id;
			let b1 = b.id;
			return b1 - a1;
		})
    let followup_tasks = result.result.followup_tasks;
    this.setState({ready: true, parent_tasks: parent_tasks, followup_tasks: followup_tasks, drs: result.result.drs});
    if (this.props.followup_task_id !== null) {
      const api = new APICall();
      let postData = { command: "get_subtask", task_id: parseInt(this.props.followup_task_id) };
      api.command(postData, this.openFollowupModal);
    }
  }

  changeClear = () => this.setState({ isclear: false });

  goToTaskUrlBare = (task_id) => {
    const task = Store.getStoreData("mytasks").find((task) => task.id === task_id);

    if (task) this.showApprovalModal(task_id);
    else this.currentfollowupModal(task_id);
  };

  currentfollowupModal = (task_id) => {
    //event.preventDefault();
    let postData = { command: "get_subtask", task_id: task_id };
    this.api.command(postData, this.openCurrentFollowupModal);
  };

  openCurrentFollowupModal = (result) => {
    Store.updateStore("followup_task", result.result.task);
    this.setState({ showModal: "triggerFollowupModal", ready: true });
  };

  goToTaskUrl = (row) => (event) => {
    event.preventDefault();
    this.showApprovalModal(row);
  };

  showApprovalModal = (row) => {
    //event.preventDefault();
    if (this.props.taskId > 0) {
      // let mytaskdata = Store.getStoreData("mytasks");
      // let curlane = "";
      // for (let i = 0; i < mytaskdata.length; i++) {
      //   if (mytaskdata[i].id === parseInt(row)) curlane = mytaskdata[i].cur_lane;
      // }
      Store.updateStore("q_index", 1);
      let postData = { command: "get_task", task_id: row };
      this.api.command(postData, this.taskDataModal);
    } else {
      Store.updateStore("q_index", row.index);
      let postData = { command: "get_task", task_id: row.original.id };
      this.api.command(postData, this.taskDataModal);
    }
  };

  taskDataModal = (result) => {
    let modal = result.result.parenttask.cur_lane === "COMP_PADREG_TEAMSAVE" ? "triggerTaskModal" : "triggerTaskApprovalModal";
    modal = result.result.parenttask.cur_lane === "COMP_PADREG_APPROVED" ? "ActualTradeModal" : modal;
    Store.updateStore("cur_q_modal_data", result.result.parenttask);
    this.setState({ showModal: modal, ready: true });
  };

  showTask = (task_id) => (event) => {
    event.preventDefault();
    this.props.performAction(task_id, "view");
  }

  processDataTable = () => {
    const ret = { data: [], columns: [] };
    // let i = 0;
    const show_requester = Store.getStoreData("role") === "team" ? false : true;
    const module_config = Store.getStoreData("module_config");
    const actions = [{name: "View", action: "view"}, {name: "Remove", action: "delete"}];
    const conditional_item = {name: "Re-Submit", action: "resubmit", check_in: {"COMP_PAD_COMPLETED": 1, "COMP_PAD_REJECTED": 1}}
    ret.columns = [
      { Header: "", accessor: "status_color", width: 30, filterable: false, headerStyle: { textAlign: "center", lineHeight: "24px" }, Cell: (row) => <div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}></div> },
      { Header: "Status", accessor: "status", width: 100, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      {
        Header: "PAD ID",
        minWidth: 70,
        headerStyle: { textAlign: "left", lineHeight: "24px" },
        style: { lineHeight: "24px" },
        Cell: (row) => (
          <span onClick={this.showTask(row.original.id)} style={{cursor: "pointer"}}>{row.original.id}</span>
        ),
        filterMethod: (filter, row) => {return row._original.id.toString().startsWith(filter.value.toString());}
      },
      { Header: "Requester", accessor: "requester", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" }, show: show_requester },
      { Header: "Assigned To", accessor: "assigned_to", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Instruction", accessor: "instruction", minWidth: 80, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      // { Header: "Transaction", accessor: "transaction", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Security Name", accessor: "security_name", minWidth: 100, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center" } },
      { Header: "Transaction Date", accessor: "transaction_date", minWidth: 100, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center" }, sortMethod: (a, b) => {
          if (a === b) {
              return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let  bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
      } },
      { Header: "Volume", accessor: "volume", minWidth: 60, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "right" } },
      { Header: "Price", accessor: "price", minWidth: 60, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "right" } },
      { Header: "Approved By", accessor: "approved_by", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center" } },
      { Header: "Tasks", accessor: "child_tasks_count", minWidth: 60, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "right" } },
      {
        Header: "Action", Width: 55, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center", height : "50px" },
        Cell: (row) => {
          let show = {view: 1, delete: 1};
          if (Store.getStoreData("role") === "team") show.delete = 0;
          //return <SimpleDropdown home={true} show={show} entity_id={row.original.id} actions={actions} performAction={this.props.performAction} />
          //let props = {entity_id : {row.original.id}, actions : {actions}, performAction : {this.props.performAction}}
          return <div style={{marginTop : "-6px"}}><EllipsisMenu show={show} entity_id={row.original.id} actions={actions} performAction={this.props.performAction} /></div>
          //return <EllipsisMenu {...props}/>
          // <Dropdown home={true} entity_id={row.original.id} actions={actions} performAction={this.props.performAction} check={row.original.cur_lane} conditional_item={conditional_item} />
        },
        filterable: false
      },
      // {
      //   Header: "",
      //   Cell: (row) => {
      //     let show = {view: 1, delete: 1};
      //     if (Store.getStoreData("role") === "team") show.delete = 0;
      //     return <SimpleDropdown home={true} show={show} entity_id={row.original.id} actions={actions} performAction={this.props.performAction} toogleDropdown={this.props.toogleDropdown}/>
      //     // <Dropdown home={true} entity_id={row.original.id} actions={actions} performAction={this.props.performAction} check={row.original.cur_lane} conditional_item={conditional_item} />
      //   },
      //   width: 55,
      //   headerStyle: { textAlign: "center" },
      //   filterable: false
      // },
    ];
    console.log("this.state.parent_tasks:", this.state.parent_tasks);
    for (let task of this.state.parent_tasks) {
      let requester = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.requester_id));
      if(requester === undefined) {
        requester = {};
        requester.name = "Inactive User";
      }
      //console.log("task id : ", task.id, "Requester : ", task.requester_id);
      let status = "REGISTERED";
      let status_color = "#223B5A";
      let assigned_to = task.module_config.general.request_type === "approval" ? requester.name : "-";
      let approved_by = "-";
      if(task.id === 52010) debugger;
      if (task.module_config.general.request_type === "approval") {
        if (task.approval_type === "ar") {
          assigned_to = task.approval_data_length === null ? "Approver" : assigned_to;
          approved_by = task.approval_data_length === 1 ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.approved_by)).name : "-";
        }
        if (task.approval_type === "lm") {
          if (task.approval_data_length === null) {
            assigned_to = "Line Manager";
            if (task.reassigned_user_id !== 0) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.reassigned_user_id)).name;
          }
          approved_by = task.approval_data_length === 1 ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.approved_by)).name : "-";
        }
        if (task.approval_type === "lm-ar") {
          assigned_to = "Line Manager";
          if (task.reassigned_user_id !== 0) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.reassigned_user_id)).name;
          if (task.approval_data_length === 1) {
            assigned_to = "Approver";
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.approved_by)).name;
          }
          if (task.approval_data_length === 2) {
            assigned_to = requester.name;
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.approved_by)).name;
          }
        }
        if (task.approval_type === "ar-lm") {
          assigned_to = "Approver";
          if (task.approval_data_length === 1) {
            assigned_to = "Line Manager";
            if (task.reassigned_user_id !== 0) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.reassigned_user_id)).name;
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.approved_by)).name;
          }
          if (task.approval_data_length === 2) {
            assigned_to = requester.name;
            if (task.reassigned_user_id !== 0) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.reassigned_user_id)).name;
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.approved_by)).name;
          }
        }
        if (task.approval_type === "ad") {
          approved_by = 'Auto-declined';
        }
      }
      if (task.cur_lane === "COMP_PAD_SAVED") {
        status = "SAVED";
        status_color = "grey";
      }
      if (task.cur_lane === "COMP_PAD_FOR_APPROVAL") {
        if (task.approval_type !== "none") {
          let app_arr = task.approval_type.split("-");
          if (app_arr.length === 2) {
            if (task.approval_data_length !== null) {
              if (task.approval_data_length === 1)  {
                status = "FIRST APPROVAL";
              }
            }
          }
        }
      }
      const void_enabled = "void_enabled" in task.module_config.general === true ? task.module_config.general.void_enabled : false;
      if (task.cur_lane === "COMP_PAD_APPROVED") {
        status = "INCOMPLETE";
        status_color = "#ffc34d";
        if (task.module_config.general.request_type === "approval" && task.module_config.general.confirm_trade_information === true && task.approval_type !== "nr") {
          if (void_enabled === true) {
            const approved_moment = moment.unix(task.approved_at);
            const approved_moment_with_validity = moment(approved_moment).add(module_config.general.validity_window_hours, 'hours').unix();
            if (moment().unix() > approved_moment_with_validity) {
              status = "VOID";
              status_color = "#d95656";
            }
          }
        }
      }
      if (task.cur_lane === "COMP_PAD_COMPLETED") {
        status = "COMPLETED";
        status_color = "#37ada7";
        if (task.module_config.general.request_type === "approval" && task.module_config.general.confirm_trade_information === false) status = "APPROVED";
        if (task.module_config.general.request_type === "approval" && task.module_config.general.confirm_trade_information === true && task.approval_type !== "nr" && void_enabled === true) {
          const approved_moment = moment.unix(task.approved_at);
          const approved_moment_with_validity = moment(approved_moment).add(module_config.general.validity_window_hours, 'hours').unix();
          if (task.completed_at > approved_moment_with_validity || task.execution_date_time > approved_moment_with_validity) {
            status = "BREACH";
            status_color = "#d95656";
          }
        }
      }
      if (task.cur_lane === "COMP_PAD_REJECTED") {
        status = "REJECTED";
        status_color = "#d95656";
      }
      if (task.cur_lane === "COMP_PAD_CANCELLED") {
        status = "CANCELLED";
        status_color = "#ff0000";
      }
      //console.log("TASK : ", task);
      //console.log("Security ID to find : ",task.security_id);
      ret.data.push({
        id: task.id,
        status_color: status_color,
        status: status,
        cur_lane: task.cur_lane,
        requester: requester.name,
        instruction: Store.getStoreData("module_config").instruction_types[task.instruction_type_id.toString()].name,
        transaction: Store.getStoreData("module_config").transaction_types[task.transaction_type_id.toString()].name,
        transaction_date: task.transaction_date,
        security_name: Store.getStoreData("module_config").security_register[task.security_id.toString()].security_name,
        volume: task.volume,
        price: task.price,
        child_tasks_count: task.child_tasks_count,
        assigned_to: assigned_to,
        approved_by: approved_by
      });
    }
    return ret;
  };

  deleteTask = (taskid) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      let postData = { command: "remove_task", task_id: taskid };
      this.api.command(postData, this.processRemoveData);
    }
  };

  processRemoveData = (result) => {
    window.location.reload();
  };

  goToFollowupTask = (row) => (event) => {
    event.preventDefault();
    let command_data = { command: "listfollowups", parent_id: row.original.id };
    this.api.command(command_data, this.updateFollowups(row.original.refID));
  };

  updateFollowups = (refID) => (result) => {
    Store.updateStore("current_followup_lists", result.result.followuptasks);
    this.setState({ showModal: "current_followup_list", curID: refID });
  };

  processMyfollowupValue = () => {
    let ret = { data: [], columns: [] };
    const actions = [{name: "View", action: "view"}, {name: "Remove", action: "delete"}];
    ret.columns = [
      { Header: "", accessor: "status_color", width: 30, filterable: false, headerStyle: { textAlign: "left", lineHeight: "24px" }, Cell: (row) => <div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}></div> },
      { Header: "Status", accessor: "status", headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Task", accessor: "task_title", headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Assigned To", accessor: "assigned_to", headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Due Date", accessor: "due_date", width: 100, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" }, sortMethod: (a, b) => {
        if (a === b) {
            return 0;
        }
        let adue = a.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse > bReverse ? 1 : -1;
      } },
      {
        Header: "",
        Cell: (row) => {
          let show = {view: 1, delete: 1};
          if (Store.getStoreData("role") === "team") show.delete = 0;
          if (row.original.status === "COMPLETE") show.delete = 0;
          return <div style={{marginTop : "-6px"}}><EllipsisMenu show={show} entity_id={row.original.id} actions={actions} performAction={this.performFollowupAction} /></div>
        },
        width: 55,
        headerStyle: { textAlign: "center" },
        filterable: false
      }
    ];
    for (let task of this.state.followup_tasks) {
      const user = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(task.cur_assigned_to));
      const task_title = task.subject;
      const today = moment().format('YYYY/MM/DD');
      const today_arr = today.split("/");
      const today_num = (today_arr[0] * 10000) + (today_arr[1] * 100) + today_arr[2];
      const due_date = moment.unix(task.due_date).format('YYYY/MM/DD');
      const due_arr = due_date.split("/");
      const due_num = (due_arr[0] * 10000) + (due_arr[1] * 100) + due_arr[2];
      let status = "PENDING";
      if (due_num < today_num) status = "OVERDUE";
      if (task.cur_lane === "COMP_PAD_FOLLOWUP_COMPLETED") status = "COMPLETE";
      let status_color = "#223B5A";
      if (status === "PENDING") status_color = "#223B5A";
      if (status === "OVERDUE") status_color = "#FF0000";
      if (status === "COMPLETE") status_color = "#37ada7";
      ret.data.push({
        id: task.id,
        status_color: status_color,
        status: status,
        task_title: task_title,
        assigned_to: user.name,
        due_date: moment.unix(task.due_date).format('DD/MM/YYYY')
      });
    }
    return ret;
  };

  performFollowupAction = (entity_id, action_type) => {
    console.log("entity_id:", entity_id);
    console.log("action_type:", action_type);
    const api = new APICall();
    if (action_type === "view") {
      let postData = { command: "get_subtask", task_id: entity_id };
      api.command(postData, this.openFollowupModal);
      this.setState({ show_spinner : true })
    }
    if (action_type === "delete") {
      if (window.confirm("Are you sure you want to delete this follow up task?")) {
        let postData = { command: "remove_followup_task", task_id: entity_id };
        const api = new APICall();
        api.command(postData, this.reload);
      }
    }
  }

  reload = (result) => {
      // console.log(result);
      window.location.reload();
  }

  openFollowupModal = (result) => {
    console.log("follow up result:", result);
    this.setState({current_followup_task: result.result.task, modal_view: "followup_modal", show_spinner : false});
  };

  updateFollowupTask = (subtask) => {
    console.log("subtask:", subtask);
    let task_json = JSON.parse(JSON.stringify(subtask.task_json));
    task_json['task_data'].last_action = 'COMP_PAD_FOLLOWUP_COMPLETED';
    task_json['task_data'].cur_lane = 'COMP_PAD_FOLLOWUP_COMPLETED';
    task_json['task_data'].actiondate = Date.now();
    task_json['action_data'] = {action: "COMP_PAD_FOLLOWUP_COMPLETED", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_contact_id'), cur_lane: "COMP_PAD_FOLLOWUP_COMPLETED", assigned_to: subtask.cur_assigned_to };
    let postData = {command: "update_followup_task", task_json: task_json, task_id: subtask.id };
    console.log("task_json:", postData);
    this.api.command(postData, this.closeModalAndRefresh);
  }

  closeFollowupModal = () => {
    this.setState({current_followup_task: null, modal_view: null});
  }

  advanceFiltershow = (val) => this.setState({ advanceFilter: val });

  closeModal = (event) => this.setState({ showModal: null });

  closeModalAndRefresh = (event) => {
    window.location.reload();
  };

  processTask = (result) =>
    this.setState({
      tasksList: result.result["my_tasks"],
      showModal: null,
    });

  export = async (event) => {
    event.preventDefault();
    //this.setState({export_clicked : true});
    let current_record_ids = Store.getStoreData("list_task_current_record_ids");
    console.log("From Export : ", current_record_ids);
    const postData = {"command":"get_all_task_json","task_ids" : current_record_ids};
    const api = new APICall();
    const result = await api.commandWithoutCallback(postData);
    console.log("Export Tasks",result.result);
    const postData1 = {"command":"list_all_brokers"};
    const broker_result = await api.commandWithoutCallback(postData1);
    this.exportData(result.result, broker_result.result);
  }

  exportData = (tasks,brokers) => {
    let ret = [];
    let headerCSV = {
      status: "Status",
      id: "PAD ID",
      requester: "Requester",
      assigned_to: "Assigned To",
      instruction: "Instruction",
      transaction: "Transaction",
      broker_name: "Broker Name",
      account_id: "Account ID at Broker",
      ac_opening_date: "Account Opening Date",
      ac_close_date: "Account Closing Date",
      beneficiaries : "Beneficiaries",
      security_name: "Security Name",
      security_type: "Security Type",
      security_identifiers: "Security Identifiers",
      transaction_date: "Transaction Date",
      volume: "Volume",
      price: "Price",
      currency: "Currency",
      approved_by: "Approved By",
      child_tasks_count: "Tasks",
      first_line_approval: "1st Line Approval",
      first_line_approval_date: "1st Line Approval Date",
      first_line_approval_time: "1st Line Approval Time",
      first_line_approval_comment: "1st Line Approval Comment",
      second_line_approval: "2nd Line Approval",
      second_line_approval_date: "2nd Line Approval Date",
      second_line_approval_time: "2nd Line Approval Time",
      second_line_approval_comment: "2nd Line Approval Comment",
      execution_date: "Execution Date",
      execution_time: "Execution Time"
      //execution_comment: "Execution Comment"

    };
    let dataCSV = [];
    const show_requester = Store.getStoreData("role") === "team" ? false : true;
    const module_config = Store.getStoreData("module_config");
    //console.log("this.state.parent_tasks:", this.state.parent_tasks);

    let custom_fields = {};
    for (let task of tasks) {
      if("custom_fields" in task.object_data){
        if("additional_fields" in task.object_data.custom_fields && task.object_data.custom_fields.additional_fields.length > 0){
          for(let field of task.object_data.custom_fields.additional_fields){
            if(custom_fields.hasOwnProperty(field.nickname) === false) custom_fields[field.nickname] = field.nickname;
          }
        }
      }
    }
    if(custom_fields !== undefined && Object.keys(custom_fields).length > 0){
      for(let key in custom_fields) headerCSV[key] = custom_fields[key];
    }
    headerCSV["completed_comments"] = "Completion Comments";
    for (let task of tasks) {
      let requester = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.task_data.contact_id);
      if(requester === undefined || requester === null) {
        requester = {};
        requester.name = "Inactive User";
      }
      //console.log("task id : ", task.id);
      let status = "REGISTERED";
      let status_color = "#223B5A";
      let assigned_to = task.object_data.module_config.general.request_type === "approval" ? requester.name : "-";
      let approved_by = "-";
      let first_line_approval = "-";
      let second_line_approval = "-";
      let first_line_approval_date = "-";
      let second_line_approval_date = "-";
      let first_line_approval_time = "-";
      let second_line_approval_time = "-";
      let first_line_approval_comment = "-";
      let second_line_approval_comment = "-";
      let execution_date = "-";
      let execution_time = "-";
      let execution_comment = "_";
      let created_at = moment(task.created_at).unix();
      let completed_comments = "-"
      console.log("Created At : ", created_at);
      let trade_info = null;
      trade_info = "actual_trade_details" in task.object_data ? task.object_data.actual_trade_details : task.object_data.trade_details;
      if (task.object_data.module_config.general.request_type === "approval") {
        if (task.object_data.approval_type === "ar") {
          assigned_to = "approval_data" in task.object_data && task.object_data.approval_data.length === 0 ? "Approver" : assigned_to;
          approved_by = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by).name : "Inactive User" : "-";
          first_line_approval = approved_by;
          second_line_approval = "N/A";
          first_line_approval_date = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? moment.unix(task.object_data.approval_data[0].action_time).format("DD/MM/YYYY") : "-";
          second_line_approval_date = "N/A";
          first_line_approval_time = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? moment.unix(task.object_data.approval_data[0].action_time).format("HH:mm:ss") : "-";
          second_line_approval_time = "N/A";
          first_line_approval_comment = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? task.object_data.approval_data[0].comments : "-";
          second_line_approval_comment = "N/A";
        }
        if (task.object_data.approval_type === "lm") {
          if ("approval_data" in task.object_data && task.object_data.approval_data.length === 0) {
            assigned_to = "Line Manager";
            if ("reassigned_user_id" in task.object_data === true) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.reassigned_user_id).name;
          }
          approved_by = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by).name : "Inactive User" : "-";
          first_line_approval = approved_by;
          second_line_approval = "N/A";
          first_line_approval_date = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? moment.unix(task.object_data.approval_data[0].action_time).format("DD/MM/YYYY") : "-";
          second_line_approval_date = "N/A";
          first_line_approval_time = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? moment.unix(task.object_data.approval_data[0].action_time).format("HH:mm:ss") : "-";
          second_line_approval_time = "N/A";
          first_line_approval_comment = "approval_data" in task.object_data && task.object_data.approval_data.length === 1 ? task.object_data.approval_data[0].comments : "-";
          second_line_approval_comment = "N/A";
        }
        if (task.object_data.approval_type === "lm-ar") {
          assigned_to = "Line Manager";
          if ("reassigned_user_id" in task.object_data === true) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.reassigned_user_id) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.reassigned_user_id).name : "Inactive User";
          if ("approval_data" in task.object_data && task.object_data.approval_data.length === 1) {
            assigned_to = "Approver";
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by).name : "Inactive User";
            first_line_approval = approved_by;
            second_line_approval = "-";
            first_line_approval_date = moment.unix(task.object_data.approval_data[0].action_time).format("DD/MM/YYYY");
            second_line_approval_date = "-";
            first_line_approval_time = moment.unix(task.object_data.approval_data[0].action_time).format("HH:mm:ss");
            second_line_approval_time = "-";
            first_line_approval_comment = task.object_data.approval_data[0].comments;
            first_line_approval_comment =  first_line_approval_comment;
            second_line_approval_comment = "-";
          }
          if ("approval_data" in task.object_data && task.object_data.approval_data.length === 2) {
            assigned_to = requester.name;
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[1].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[1].performed_by).name : "Inactive User";
            first_line_approval = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by).name : "Inactive User";
            second_line_approval = approved_by;
            first_line_approval_date = moment.unix(task.object_data.approval_data[0].action_time).format("DD/MM/YYYY");
            second_line_approval_date = moment.unix(task.object_data.approval_data[1].action_time).format("DD/MM/YYYY");
            first_line_approval_time = moment.unix(task.object_data.approval_data[0].action_time).format("HH:mm:ss");
            second_line_approval_time = moment.unix(task.object_data.approval_data[1].action_time).format("HH:mm:ss");
            first_line_approval_comment = task.object_data.approval_data[0].comments;
            first_line_approval_comment =  first_line_approval_comment;
            second_line_approval_comment = task.object_data.approval_data[1].comments;
            second_line_approval_comment =  second_line_approval_comment;
          }
        }
        if (task.object_data.approval_type === "ar-lm") {
          assigned_to = "Approver";
          if ("reassigned_user_id" in task.object_data === true) assigned_to = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.reassigned_user_id) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.reassigned_user_id).name : "Inactive User";
          if ("approval_data" in task.object_data && task.object_data.approval_data.length === 1) {
            assigned_to = "Line Manager";
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by).name : "Inactive User";
            first_line_approval = approved_by;
            second_line_approval = "-";
            first_line_approval_date = moment.unix(task.object_data.approval_data[0].action_time).format("DD/MM/YYYY");
            second_line_approval_date = "-";
            first_line_approval_time = moment.unix(task.object_data.approval_data[0].action_time).format("HH:mm:ss");
            second_line_approval_time = "-";
            first_line_approval_comment = task.object_data.approval_data[0].comments;
            first_line_approval_comment =  first_line_approval_comment;
            second_line_approval_comment = "-";
          }
          if ("approval_data" in task.object_data && task.object_data.approval_data.length === 2) {
            assigned_to = requester.name;
            approved_by = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[1].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[1].performed_by).name : "Inactive User";
            first_line_approval = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by) !== undefined ? Store.getStoreData("company_users").find(item => parseInt(item.user_id) === task.object_data.approval_data[0].performed_by).name : "Inactive User";
            second_line_approval = approved_by;
            first_line_approval_date = moment.unix(task.object_data.approval_data[0].action_time).format("DD/MM/YYYY");
            second_line_approval_date = moment.unix(task.object_data.approval_data[1].action_time).format("DD/MM/YYYY");
            first_line_approval_time = moment.unix(task.object_data.approval_data[0].action_time).format("HH:mm:ss");
            second_line_approval_time = moment.unix(task.object_data.approval_data[1].action_time).format("HH:mm:ss");
            first_line_approval_comment = task.object_data.approval_data[0].comments;
            first_line_approval_comment =  first_line_approval_comment;
            second_line_approval_comment = task.object_data.approval_data[1].comments;
            second_line_approval_comment =  second_line_approval_comment;
          }
        }
      }
      if (task.task_data.cur_lane === "COMP_PAD_SAVED") {
        status = "SAVED";
        status_color = "grey";
      }
      //console.log("Approval type : ", task.object_data.approval_type, "id : ", task.id);
      if (task.task_data.cur_lane === "COMP_PAD_FOR_APPROVAL") {
        if ("approval_type" in task.object_data && task.object_data.approval_type !== "none") {
          let app_arr = task.object_data.approval_type.split("-");
          if (app_arr.length === 2) {
            if ("approval_data" in task.object_data && task.object_data.approval_data.length !== 0) {
              if (task.object_data.approval_data.length === 1)  {
                status = "FIRST APPROVAL";
              }
            }
          }
        }
      }
      const void_enabled = "void_enabled" in task.object_data.module_config.general === true ? task.object_data.module_config.general.void_enabled : false;
      if (task.task_data.cur_lane === "COMP_PAD_APPROVED") {
        status = "INCOMPLETE";
        status_color = "#ffc34d";
        if (task.object_data.module_config.general.request_type === "approval" && task.object_data.module_config.general.confirm_trade_information === true && task.object_data.approval_type !== "nr") {
          if (void_enabled === true) {
            const approved_moment = task.object_data.approval_data[task.object_data.approval_data.length - 1].action_time;
            const approved_moment_with_validity = moment(approved_moment).add(task.object_data.module_config.general.validity_window_hours, 'hours').unix();
            if (moment().unix() > approved_moment_with_validity) {
              status = "VOID";
              status_color = "#d95656";
            }
          }
        }
      }
      if (task.task_data.cur_lane === "COMP_PAD_COMPLETED") {
        status = "COMPLETED";
        status_color = "#37ada7";
        let completed_at = null;
        for (let item of task.action_data) {
          if (item.cur_lane === "COMP_PAD_COMPLETED") {
            completed_at = Math.floor(item.actiondate / 1000);
          }
        }
        let execution_date_time = "execution_date_time" in trade_info === true ? trade_info.execution_date_time : null;
        execution_date = "execution_date_time" in trade_info === true ? moment.unix(trade_info.execution_date_time).format("DD-MM-YYYY") : '-';
        execution_time = "execution_date_time" in trade_info === true ? moment.unix(trade_info.execution_date_time).format("HH:mm:ss") : '-';
        //execution_comment = "";
        if (task.object_data.module_config.general.request_type === "approval" && task.object_data.module_config.general.confirm_trade_information === false) status = "APPROVED";
        if (task.object_data.module_config.general.request_type === "approval" && task.object_data.module_config.general.confirm_trade_information === true && task.object_data.approval_type !== "nr" && void_enabled === true) {
          const approved_moment = task.object_data.approval_data[task.object_data.approval_data.length - 1].action_time;
          const approved_moment_with_validity = moment(approved_moment).add(module_config.general.validity_window_hours, 'hours').unix();
          if (completed_at > approved_moment_with_validity || execution_date_time > approved_moment_with_validity) {
            status = "BREACH";
            status_color = "#d95656";
          }
        }
        completed_comments = "completed_comments" in task.object_data ? task.object_data.completed_comments : "-"
        let nl = String.fromCharCode(10);
        completed_comments =  completed_comments;
        //console.log("Completed Comment : ", completed_comments);
      }
      if (task.task_data.cur_lane === "COMP_PAD_REJECTED") {
        status = "REJECTED";
        status_color = "#d95656";
      }
      if (task.task_data.cur_lane === "COMP_PAD_CANCELLED") {
        status = "CANCELLED";
        status_color = "#ff0000";
      }
      //broker data
      let broker_id = null;
      let broker_obj = null;
      let broker_name = "N/A";
      let account_id = "N/A";
      let ac_opening_date  = "N/A";
      let ac_close_date = "N/A";
      if("broker_id" in trade_info) broker_id = trade_info.broker_id;
      if( broker_id !==null && broker_id!==0) broker_obj = brokers.find(item => item.id === broker_id);
      if( broker_obj !== null && broker_obj !== undefined){
        broker_name = broker_obj.broker_name;
        account_id = broker_obj.account_id;
        ac_opening_date = moment.unix(broker_obj.ac_opening_date).format("DD/MM/YYYY");
        ac_close_date = broker_obj.ac_close_date !== -1 ? moment.unix(broker_obj.ac_close_date).format("DD/MM/YYYY") : "N/A";
      }
      //console.log("Trade Info : ", trade_info);
      let security = (trade_info.security_id !== null) ? Store.getStoreData("module_config").security_register[trade_info.security_id.toString()] !== undefined ? Store.getStoreData("module_config").security_register[trade_info.security_id.toString()] : null : null;
      let security_type = security !== null ? Store.getStoreData("module_config").security_types[security.security.toString()].name : "-";
      let security_identifiers = "";
      let id_arr = [];
      if(security.identifier_types !== null && security.identifier_types !== undefined){
        for(let key in security.identifier_types){
          let type = key in Store.getStoreData("module_config").security_identifier_types ? Store.getStoreData("module_config").security_identifier_types[key].name : '[Deleted Security Identifier]';
          id_arr.push(type + " : " + security.identifier_types[key]);
        }
      }
      security_identifiers = id_arr.join(', ');
      let custom_field_values = {};
      custom_field_values = custom_fields;
      for(let key in custom_field_values) custom_field_values[key] = "N/A";
      if("custom_fields" in task.object_data){
        if("additional_fields" in task.object_data.custom_fields && task.object_data.custom_fields.additional_fields.length > 0){
          for(let field of task.object_data.custom_fields.additional_fields){
            custom_field_values[field.nickname] = field.value;
          }
        }
      }
      //console.log("TASK : ", task);
      let elem = {
          status: status,
          id: task.id,
          requester: requester.name,
          assigned_to: assigned_to,
          instruction: task.object_data.instruction_type_id !== null && task.object_data.instruction_type_id !== undefined ? Store.getStoreData("module_config").instruction_types[task.object_data.instruction_type_id.toString()] !== undefined ? Store.getStoreData("module_config").instruction_types[task.object_data.instruction_type_id.toString()].name : "Deleted" : "-",
          transaction: created_at >= moment("2022-09-24", "YYYY-MM-DD").unix() ? "-" : task.object_data.transaction_type_id !== null && task.object_data.transaction_type_id !== undefined ? Store.getStoreData("module_config").transaction_types[task.object_data.transaction_type_id.toString()] !==undefined ? Store.getStoreData("module_config").transaction_types[task.object_data.transaction_type_id.toString()].name : "Deleted" : "-",
          broker_name: broker_name,
          account_id: account_id,
          ac_opening_date: ac_opening_date,
          ac_close_date: ac_close_date,
          beneficiaries : trade_info.beneficiary_id !== null && trade_info.beneficiary_id !== undefined ? Store.getStoreData("module_config").beneficiaries[trade_info.beneficiary_id.toString()] !== undefined ? Store.getStoreData("module_config").beneficiaries[trade_info.beneficiary_id.toString()].name : "Deleted" : "-",
          security_name: security !== null ? security.security_name : "-",
          security_type: security_type,
          security_identifiers: security_identifiers,
          transaction_date: "transaction_date" in task.object_data ? task.object_data.transaction_date : "-",
          volume: "volume" in trade_info && trade_info.volume !== null ? trade_info.volume : "-",
          price: "price" in trade_info && trade_info.price !== null ? trade_info.price : "-",
          currency: trade_info.currency_id !== null && trade_info.currency_id !== undefined ? Store.getStoreData("module_config").currencies[trade_info.currency_id.toString()] !== undefined ? Store.getStoreData("module_config").currencies[trade_info.currency_id.toString()].name : "Deleted" : "-",
          approved_by: approved_by,
          child_tasks_count: task.child_task_count,
          first_line_approval: first_line_approval,
          first_line_approval_date: first_line_approval_date,
          first_line_approval_time: first_line_approval_time,
          first_line_approval_comment: first_line_approval_comment,
          second_line_approval: second_line_approval,
          second_line_approval_date: second_line_approval_date,
          second_line_approval_time: second_line_approval_time,
          second_line_approval_comment: second_line_approval_comment,
          execution_date: execution_date,
          execution_time: execution_time
          //execution_comment: "Execution Comment"
      }
      //console.log("ELEM : ", elem);
      for(let key in custom_field_values){
        elem[key] = custom_field_values[key];
      }
      elem["completed_comments"] = completed_comments;
      dataCSV.push(elem);
    }
    this.exportToExcel(headerCSV,dataCSV);
  };

  exportToExcel = async (headerCSV, dataCSV) => {
    let wb = { SheetNames: [], Sheets: {} };
    let exData = [];
    for(let d of dataCSV){
      let data = {};
      for(let key of Object.keys(headerCSV)){
        data[headerCSV[key]] = d[key];
      }
      exData.push(data);
    }
    wb.SheetNames.push("PA Dealings");
    wb.Sheets["PA Dealings"] = XLSX.utils.json_to_sheet(exData);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "PADealing_Register"+ fileExtension);
  };


  handleChange = (evt) => this.setState({ [evt.target.name]: evt.target.value });

  onRequestedFromDate = (date) => this.setState({ filterRequestedFromDate: date });

  onRequestedToDate = (date) => this.setState({ filterRequestedToDate: date });

  filterConfigList = (list) => Utils.getLists(list).map((item) => ({ id: item.id, label: item.title }));

  filterSubmit = (tasksListFilter) => this.setState({ tasksList: tasksListFilter });

  render() {
    if (!this.state.ready) return <div>Loading...</div>;
    console.log("this.props.followup_task_id:", this.props.followup_task_id);
    return (
      <div style={{position: "relative"}}>
        {/*<div style={{ boxSizing: "border-box", padding: "12px 10px 0px 10px" }}>
          <AdvancedFilter contacts={this.state.contacts} TransactionsList={this.filterConfigList("transaction_types")} RolesList={this.filterConfigList("role_types")} InstructionsList={this.filterConfigList("instruction_types")} Tasks={this.state.tasksListFilter} filterSubmitValue={this.filterSubmit} />
        </div>*/}
        {this.state.show_spinner === true && <SpinningLoader />}
        {this.state.modal_view === "followup_modal" && <FollowupModal task={this.state.current_followup_task} updateFollowupTask={this.updateFollowupTask} closeFollowupModal={this.closeFollowupModal} />}
        <OverviewInfoHeader>
          <OverviewInfoHeaderTitle>Personal Account Dealing Register</OverviewInfoHeaderTitle>
          <ClearBoth />
        </OverviewInfoHeader>
        <OverviewInfoBody>
          <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={10} exportClicked = {this.state.export_clicked} from={"list_task"}/>
          <div style={{ padding: "12px" }}>
            <ExportButton style={{ float: "left" }} onClick={this.export}>
              Export
            </ExportButton>
            <ExportsLine>{this.state.parent_tasks.length} PA Dealing(s) listed</ExportsLine>
            <ClearBoth />
          </div>
        </OverviewInfoBody>

        <OverviewInfoHeader>
          <OverviewInfoHeaderTitle>Followup Tasks</OverviewInfoHeaderTitle>
          <ClearBoth />
        </OverviewInfoHeader>
        <OverviewInfoBody>
          <CSLTable add={false} processData={this.processMyfollowupValue} headerText={""} tableRows={6} />
        </OverviewInfoBody>
      </div>
    );
  }
}

export default TasksList;
