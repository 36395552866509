/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store';
import AlertBox from './Common/AlertBox';
import CSLHeader from './Common/CSLHeader';
import TasksIndex from './PAD/TasksIndex';
import PadMenu from './PAD/PadMenu';
import TasksList from "./PAD/TasksList";
import AddPadModal from './PAD/Modals/AddPadModal';
import ActualTradeModal from './PAD/Modals/ActualTradeModal';
import CompleteModal from './PAD/Modals/CompleteModal';
import CancelledModal from './PAD/Modals/CancelledModal';
import TaskApprovalModal from './PAD/TaskApprovalModal';
import HoldingModal from "./PAD/HoldingModal";
import BrokerListTable from './PAD/Modals/BrokerListTable';
import { FiMoreVertical } from "react-icons/fi";
import APICall from '../Common/APICall';
import moment from 'moment';
import SpinningLoader from './Common/SpinningLoader';
import Reload from '../Common/Reload';
import DropdownOptions from './Common/DropdownOptions';

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

const IndexContainer = styled.div`
  position : relative;
  padding-top: 8px;
  padding-left: 5px;
`;

const SubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #223b5a;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #223b5a;
  margin-left: 12px;
  font-size: 11px;
  padding-left: 12px;
  cursor: pointer;
`;

class Index extends React.Component
{
    state = {
        show_menu: false,
        showModal: null,
        task: null,
        add_readonly: false,
        followup_task_id: null,
        show_spinner : false,
    };

    componentDidMount() {
        console.log("componentDidMount");
        // let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
        if (this.props.match) {
            console.log("this.props.match.params.taskId:", this.props.match.params.taskId);
            this.showTask(parseInt(this.props.match.params.taskId));
        }
    }

    addPadModal = (event) => {
        event.preventDefault();
        // const index = row.index === null ? 0 : row.index;
        // Store.updateStore("q_index", index);
        this.setState({ showModal: "TaskModal" });
    };

    closeModal = (event) => this.setState({ showModal: null, task: null, add_readonly: false });

    toggleMenu = (event) => {
        event.preventDefault();
        this.setState({ show_menu: this.state.show_menu === true ? false : true });
    };

    closePadMenu = () => {
        this.setState({ show_menu: false });
    };

    showHoldingModal = () => {
        // event.preventDefault();
        //alert("hi");
        this.setState({ showModal: "HoldingModal" });
    };

    showBrokerList = () => {
        this.setState({ showModal: "BrokerList", show_menu: false });
    }

    // toggleDropdownOptions = (x,y,actions,show,entity_id) => {
    //     let show_dropdown_options = this.state.show_dropdown_options === true ? false : true;
    //     let dropdown_options_X = 0;
    //     let dropdown_options_Y = 0;
    //     let dropdown_options_show = null;
    //     let dropdown_options_actions = null;
    //     let dropdown_options_entity_id = null;
    //     if(show_dropdown_options === true) {
    //         dropdown_options_X = x;
    //         dropdown_options_Y = y;
    //         dropdown_options_show = show;
    //         dropdown_options_actions = actions;
    //         dropdown_options_entity_id = entity_id;
    //     }
    //     this.setState({ show_dropdown_options, dropdown_options_X, dropdown_options_Y, dropdown_options_show, dropdown_options_actions, dropdown_options_entity_id });
    // }

    // closeDropdownOptions = () => {
    //     this.setState({show_dropdown_options: false});
    // }

    alertHandler = (result, stack) => {
        Reload.ReloadPage();
    }

    performAction = (entity_id, action_type) => {
        console.log("entity_id:", entity_id);
        console.log("action_type:", action_type);
        if (action_type === "view") {
            console.log("inside view");
            this.showTask(entity_id);
        }
        if (action_type === "delete") {
            if (confirm("All associated follow up tasks, including completed tasks, will also be deleted. Are you sure you want to delete this PAD entry?")) {
                let postData = { command: "remove_task", task_id: entity_id };
                const api = new APICall();
                api.command(postData, this.reload);
            }
        }
        if (action_type === "resubmit") {
            let postData = { command: "get_task", task_id: entity_id };
            const api = new APICall();
            api.command(postData, this.showResubmit);
        }
    }

    reload = (result) => {
        console.log(result);
        window.location.reload();
    }

    performFollowupAction = (entity_id, action_type) => {
        console.log("entity_id:", entity_id);
        console.log("action_type:", action_type);
        // if (action_type === "view") {
        //     this.showTask(entity_id);
        // }
    }

    showTask = (task_id) => {
        console.log("task_id:", task_id);
        let postData = { command: "get_task", task_id: task_id };
        const api = new APICall();
        api.command(postData, this.showTaskModal);
        this.setState({ show_spinner : true })
    }

    showTaskModal = (result) => {
        const role = Store.getStoreData("role");
        const drs = Store.getStoreData("drs");
        console.log("Index task result:", result);
        if (result.error_code === 0) {
            let task = result.result;
            task.parenttask.task_json = JSON.parse(task.parenttask.task_json);
            task.childtasks = task.childtask.result;
            delete task.childtask;
            console.log("task:", task);
            if (task.parenttask.cur_lane === "COMP_PAD_SAVED") {
                this.setState({showModal: "TaskModal", task, show_spinner : false});
            }
            if (task.parenttask.cur_lane === "COMP_PAD_FOR_APPROVAL") {
                if (role === "team") {
                    if (task.parenttask.task_json.object_data.requester_id in drs) {
                        this.setState({showModal: "ApproveModal", task, show_spinner : false});
                    } else {
                        this.setState({showModal: "TaskModal", add_readonly: true, task, show_spinner : false});
                    }
                } else {
                    this.setState({showModal: "ApproveModal", task, show_spinner : false});
                }
            }
            if (task.parenttask.cur_lane === "COMP_PAD_APPROVED") {
                this.setState({showModal: "ActualTradeModal", task, show_spinner : false});
            }
            if (task.parenttask.cur_lane === "COMP_PAD_COMPLETED" || task.parenttask.cur_lane === "COMP_PAD_REJECTED") {
                this.setState({showModal: "CompleteModal", task, show_spinner : false});
            }
            if (task.parenttask.cur_lane === "COMP_PAD_REGISTERED") {
                this.setState({showModal: "ApproveModal", task, show_spinner : false});
            }
            if (task.parenttask.cur_lane === "COMP_PAD_CANCELLED") {
                this.setState({showModal: "CancelledModal", task, show_spinner : false});
            }
        } else {
            this.setState({followup_task_id: this.props.match.params.taskId, show_spinner : false});
        }
    }

    showResubmit = (result) => {
        let task = result.result;
        task.parenttask.task_json = JSON.parse(task.parenttask.task_json);
        task.childtasks = task.childtask.result;
        delete task.childtask;
        task.parenttask.id = 0;
        task.parenttask.task_json.object_data.transaction_date = moment().format("DD/MM/YYYY");
        task.parenttask.task_json.object_data.trade_details.volume = "00";
        task.parenttask.task_json.object_data.trade_details.price = "0";
        task.parenttask.task_json.object_data.trade_details.cash_consideration = "0";
        task.parenttask.task_json.object_data.requester_comments = "";
        task.parenttask.task_json.object_data.notes = {};
        task.parenttask.task_json.object_data.attachments = [];
        task.parenttask.task_json.object_data.not_short = false;
        task.parenttask.task_json.object_data.not_breach = false;
        delete task.parenttask.task_json.object_data.approval_data;
        delete task.parenttask.task_json.object_data.actual_trade_details;
        this.setState({showModal: "TaskModal", task});
    }

    alertHandler = (result, stack) => {
        Reload.ReloadPage();
    }

    render() {
        console.log("state in index:", this.state);
        const task_id = this.props.taskId ? this.props.taskId : 0;
        let activate_add_pad = this.state.showModal === null || this.state.showModal ==="TaskModal";
        if (Store.getStoreData('role') === "no_access") {
            let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is an error, please contact your administrator.', ok_text: 'Ok', confirm: false,
            alertHandler: this.alertHandler, stack: {id: 0}};
            return (
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <CSLHeader
                        isAdmin={(this.state.cur_view === 'manager_index')}
                        taskHeader={task_id > 0}
                        module={"padreg"}
                        headerText={'Personal Account Dealing Register'}
                    />
                    <AlertBox alertParam = {alert_param}/>
                </div>
            )
        }
        return(
            <>
                {this.state.show_spinner === true && <SpinningLoader />}
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <CSLHeader
                        isAdmin={(this.state.cur_view === 'manager_index')}
                        taskHeader={task_id > 0} module={"padreg"}
                        headerText={'Personal Account Dealing Register'}
                    />
                    <IndexContainer>
                        {this.state.show_menu === true && <PadMenu closePadMenu={this.closePadMenu} showHoldingModal={this.showHoldingModal} showBrokerList={this.showBrokerList}/>}
                        <div style={{marginTop: "10px"}}>
                            <div style={{ float: "left"}}>{task_id === 0 && <SubmitButton style={{backgroundColor: activate_add_pad ? "#223b5a" : "#dddddd", cursor: activate_add_pad ? "pointer" : "not-allowed", pointerEvents : activate_add_pad ? "auto" : "none", border: activate_add_pad ? "1px solid #223b5a" : "1px solid #dddddd"}} onClick={this.addPadModal}>ADD PAD ENTRY</SubmitButton>}</div>
                            <div style={{ float: "right", position: "relative" }}>
                                <div id="pad_ellipsis" onClick={this.toggleMenu} style={{ paddingTop: "7px", paddingRight: "15px", fontSize: "20px", cursor: "pointer" }}>
                                <FiMoreVertical />
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>

                        <TasksList performAction={this.performAction} performFollowupAction={this.performFollowupAction} add={false} taskId={task_id} refreshCallback={this.refreshCallback} followup_task_id={this.state.followup_task_id} />
                        {/*{this.state.showModal === "TaskModal" && <TaskModal closeModal={this.closeModal} />}*/}
                        {this.state.showModal === "TaskModal" && <AddPadModal task={this.state.task} closeModal={this.closeModal} add_readonly={this.state.add_readonly} />}
                        {this.state.showModal === "ApproveModal" && <TaskApprovalModal task={this.state.task} closeModal={this.closeModal} />}
                        {this.state.showModal === "ActualTradeModal" && <ActualTradeModal task={this.state.task} closeModal={this.closeModal} />}
                        {this.state.showModal === "CompleteModal" && <CompleteModal task={this.state.task} closeModal={this.closeModal} />}
                        {this.state.showModal === "CancelledModal" && <CancelledModal task={this.state.task} closeModal={this.closeModal} />}
                        {this.state.showModal === "HoldingModal" && <HoldingModal closeModal={this.closeModal} />}
                        {this.state.showModal === "BrokerList" && <BrokerListTable closeModal={this.closeModal}/>}
                    </IndexContainer>
                </div>
            </>
        );
    }
}

export default Index;

