import React from 'react';
import moment from 'moment';
import './TransactionReportViewModal.css';
import { FaTimes } from "react-icons/fa";
import Store from "../../../Common/Store";
import APICall from '../../../Common/APICall';
import CSLTable from '../../Common/CSLTable';
import SpinningLoader from '../../Common/SpinningLoader';

class HoldingReportViewModal extends React.Component{

    state = {
        ready : false,
        contactId : null,
        fromDate : null,
        toDate : null,
        enableEmployeeBrokerAc : Store.getStoreData("module_config")?.general?.enable_employee_broker_ac,
        reportTitle : "",
        reportStatus : null,
        respDate : null,
        respTime : "",
        nilHoldings : false,
        holdingsToView : [],
        showBrokers : false,
        brokersToView : [],
        rejectionCause : '',
        optComment: '',
        holdingRowsInView : 5,
        brokerRowsInView : 5
    }

    componentDidMount() {
        let reportToView = this.props.selected_report?.holding;
        let contactId = reportToView?.contact_id;
        let fromDate = reportToView?.from_date;
        let toDate = reportToView?.to_date;
        let reportStatus = reportToView?.status;
        this.setState({ contactId, fromDate, toDate, reportStatus})
        let reportId = this.props.view === 'admin' ? reportToView?.record_id : reportToView?.id;
        this.generateReport(reportId, contactId);
    }

    generateReport = async (reportId, contactId) => {
        const postData = { command: "fetch_holding_report_details", report_id : reportId, contact_id : contactId};
        const api = new APICall();
        const apiResult = await api.commandWithoutCallback(postData);
        let reportTitle = apiResult?.result?.report_detail?.report_title !== null ? apiResult?.result?.report_detail?.report_title : Store.getStoreData("module_config")?.holding_confirmation?.holding_report_title;
        let respDate = apiResult?.result?.report_detail?.response_date;
        let respTime = apiResult?.result?.report_detail?.response_time;
        let rejectionCause = apiResult?.result?.report_detail?.rejection_cause;
        let optComment = apiResult?.result?.report_detail?.opt_comment;
        let nilHoldings = !(JSON.parse(apiResult?.result?.report_detail?.track_holding_ids_involved))?.length;
        let showBrokers = "brokers" in apiResult?.result;
        let nilBrokers = null;
        if (showBrokers === true){
            nilBrokers = apiResult?.result?.brokers?.length === 0;
        }
        let holdingsToView = [];
        if(!nilHoldings){
            apiResult.result.holdings.forEach(item => {
                let data = {
                    id : item?.track_holding_id,
                    securityName : item?.security_name,
                    securityId : item?.security_id,
                    securityType : item?.security_type,
                    volume : item?.volume,
                    currency : item?.currency
                }
                holdingsToView.push(data);
            })
        }
        let holdingRowsInView = 5;
        if( holdingsToView?.length > 5 ){ 
            holdingRowsInView = holdingsToView?.length <20 ? holdingsToView?.length : 20;
        }

        let brokersToView = [];
        if(showBrokers === true){
            if(nilBrokers === false){
                apiResult?.result?.brokers?.forEach(item => {
                    let data = {
                        id : item?.broker_id,
                        brokerName : item?.broker_name,
                        accountName : item?.account_name,
                        acOpeningDate : item?.ac_opening_date,
                        acCloseDate : item?.ac_close_date
                    }
                    brokersToView.push(data);
                })
            }
        }
        let brokerRowsInView = 5;
        if( brokersToView.length > 5 ){ 
            brokerRowsInView = brokersToView?.length <20 ? brokersToView?.length : 20;
        }
        this.setState({ 
            reportTitle, 
            respDate, 
            respTime, 
            nilHoldings, 
            showBrokers, 
            nilBrokers, 
            holdingsToView, 
            brokersToView, 
            rejectionCause, 
            optComment,
            holdingRowsInView, 
            brokerRowsInView, 
            ready : true
        })
    }

    closeModal = (e) => {
        e.preventDefault();
        this.props.closeModal();
    }

    processDataTable = () => {
        const ret = { data: [], columns: [] };
        let tableData = [];
        ret.columns = [
            { Header: "Security Name", accessor: "securityName", width: 435, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Security ID", accessor: "securityId", width: 330, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Security Type", accessor: "securityType", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Volume", accessor: "volume", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Currency", accessor: "currency", width: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        for(let r of this.state.holdingsToView){
            let data = {
                id : r?.id,
                securityName : r?.securityName,
                securityId : r?.securityId,
                securityType : r?.securityType,
                volume : r?.volume,
                currency : r?.currency
            }
            tableData.push(data);
        }
        ret.data = tableData;
        return ret;
    }

    processDataBrokerTable = () => {
        const ret = { data: [], columns: [] };
        let tableData = [];
        ret.columns = [
            { Header: "Name of Broker/Dealer or Bank", accessor: "brokerName", width: 500, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Account Title (Your Reference)", accessor: "accountName", width: 380, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Account Opened", accessor: "acOpeningDate", width: 220, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Account Closed", accessor: "acCloseDate", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        for(let r of this.state.brokersToView){
            let data = {
                id : r.id,
                brokerName : r?.brokerName,
                accountName : r?.accountName,
                acOpeningDate : r?.acOpeningDate,
                acCloseDate : r?.acCloseDate
            }
            tableData.push(data);
        }
        ret.data = tableData;
        return ret;
    }
    formatDate(date){
        return moment(date.toString(), "YYYYMMDD").format("DD/MM/YYYY");
    }

    render(){
        if(this.state.ready !== true){ 
            return(<SpinningLoader />);
        }
        return(
            <div className='tr-report-view-modal-wrapper'>
                <div className='tr-report-view-modal-header'>
                    <div className='tr-report-view-modal-title-wrapper'>
                        <div className='tr-report-view-modal-heading'>
                          {this.state.reportTitle} - {this.state.reportStatus === 'confirmed' ? 'Confirmed' : 'Rejected'}
                        </div>
                        <div className='tr-report-view-modal-subheading'>
                            {this.props.view === 'admin' ? (Store.getStoreData("company_users")?.find(item => parseInt(item.user_id) === parseInt(this.state.contactId)))?.name + " - " : ""}
                            {
                                this.state.fromDate === 0 ?
                                    this.formatDate(this.state.toDate):
                                    `${this.formatDate(this.state.fromDate)} to ${this.formatDate(this.state.toDate)}`
                            }
                        </div>
                    </div>
                    <div className='tr-report-view-modal-close'><FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/></div>
                </div>
                <div className='tr-report-view-modal-body'>
                    <div className='tr-report-view-modal-help-text'>
                        {this.props.view === 'admin' ? (Store.getStoreData("company_users")?.find(item => parseInt(item.user_id) === parseInt(this.state.contactId)))?.name + " " : "You   "}
                        reviewed this information on {moment(moment(this.state.respDate.toString(),"YYYYMMDD")).format("DD/MM/YYYY")} 
                        {this.state.respTime !== null && this.state.respTime !== undefined && this.props.view === 'admin' ? ` at ${this.state.respTime}` : ""} and
                        {this.state.reportStatus === 'confirmed' ? <span> <span style={{fontWeight : "700", marginLeft : "4px"}}> Confirmed</span> the details</span> : <span> <span style={{fontWeight : 700, marginLeft : "4px"}}> Rejected</span> the details with the following explanation</span>}
                    </div>
                    {
                        this.state.reportStatus === 'rejected' &&
                        <div className='tr-report-view-modal-rejection-text'>
                            {this.state.rejectionCause}
                        </div>
                    }
                    {
                        this.state.optComment !== "" &&
                        <div className='tr-report-view-modal-rejection-text'>{this.state.optComment}</div>
                    }
                    <div className='tr-report-view-modal-table-container'>
                        {
                            this.state.nilHoldings && <div className='tr-report-view-nil_transaction-label'>Nil holding in this report period</div>
                        }   
                        <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={this.state.holdingRowsInView}/>
                        
                    </div>
                    {
                        this.state.showBrokers &&
                        <div className='tr-report-view-broker-table-label'>{Store.getStoreData("module_config")?.broker_confirmation?.broker_section_title}</div>
                    }
                    {
                        this.state.showBrokers && 
                        <div className='tr-report-view-modal-table-container'>
                            {
                                this.state.nilBrokers && <div className='tr-report-view-nil_transaction-label'>Nil broker in this report period</div>
                            }   
                            <CSLTable add={false} processData={this.processDataBrokerTable} zebra={false} tableRows={this.state.brokerRowsInView}/>
                            
                        </div>
                    }
                </div>
                <div className='tr-report-view-modal-footer'>
                    <div className='tr-report-view-modal-footer-button-container'>
                        <button className='tr-report-view-modal-footer-button tr-report-view-save-button' onClick={this.closeModal} >Ok</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default HoldingReportViewModal;
