import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Store from "../../Common/Store";

const ITEM_HEIGHT = 48;

export default function EllipsisMenuModConfig(props) {
  const {conditional_item = null, check = null} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const performAction = (entity_id, action_type) => (event) => {
    event.preventDefault();
    setAnchorEl(null);
    props.performAction(entity_id, action_type);
  }

  const module_config = Store.getStoreData("module_config");

  return (
    <div>
      <IconButton
        aria-label="more"
        id="ellipsis-button"
        aria-controls={open ? 'ellipsis-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="ellipsis-menu"
        MenuListProps={{
          'aria-labelledby': 'ellipsis-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            right: '50ch',
            fontWeight : 'bold',

          },
        }}
      >
        {
          props.actions.map((item, index) => {           
              return(<MenuItem key={item.name} onClick={performAction(props.entity_id, item.action)}> {item.name} </MenuItem>);                    
          })
        }
        {
            (() => {
                if (props.check !== null && props.conditional_item !== null) {
                    if (props.check in props.conditional_item.check_in === true && module_config.general.void_enabled === true) {
                        return (
                            <MenuItem key={props.entity_id+props.check} onClick={performAction(props.entity_id, props.conditional_item.action)}> 
                                {props.conditional_item.name} 
                            </MenuItem>
                        );
                    }
                }
            })
        }
      </Menu>
    </div>
  );
}


