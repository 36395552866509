import React from "react";
import styled from "styled-components";

const GeneralContainer = styled.div`
  width: 90%;
`;
const GeneralInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const GeneralLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const HeaderInfo = styled.div`
    line-height: 19px;
    font-size: 12px;
`;
const RadioBlock = styled.div`
  margin-top: 5px;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const RoleSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;
const TextBox = styled.input`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #d7d5d5;
    /*box-shadow: 0 0 4px #c4c4c4;*/
    font-family: "Montserrat",sans-serif;
    color: #222222;
`;

class HotLookup extends React.Component {

    state = {general: null};

    componentDidMount() {
        let general = JSON.parse(JSON.stringify(this.state.general));
        general = this.props.general;
        this.setState({general});
    }

    componentDidUpdate(prevprops) {
        if (this.props !== prevprops) {
            let general = JSON.parse(JSON.stringify(this.state.general));
            general = this.props.general;
            this.setState({general});
        }
    }

    toggleEnabled = (event) => {
        event.preventDefault();
        let general = JSON.parse(JSON.stringify(this.state.general));
        general.security_hot_lookup.enabled = general.security_hot_lookup.enabled === true ? false : true;
        this.props.updateGeneral(general);
    }

    changeService = (event) => {
        event.preventDefault();
        let general = JSON.parse(JSON.stringify(this.state.general));
        general.security_hot_lookup.selected_service = event.target.value;
        this.props.updateGeneral(general);
    }

    changeApiKey = (service_name) => (event) => {
        event.preventDefault();
        let general = JSON.parse(JSON.stringify(this.state.general));
        general.security_hot_lookup.service_names[service_name].api_key = event.target.value;
        this.props.updateGeneral(general);
    }

    toggleIndicativePrice = (event) => {
        event.preventDefault();
        let general = JSON.parse(JSON.stringify(this.state.general));
        general.security_hot_lookup.retrieve_indicative_price = general.security_hot_lookup.retrieve_indicative_price === true ? false : true;
        this.props.updateGeneral(general);
    }

    render() {
        if (this.state.general === null) return <div></div>

        return (
            <GeneralContainer>
                <GeneralInnerContainer>
                    <TypesBoldLabel>Security "Hot Lookup"</TypesBoldLabel>
                    <div style={{marginTop: "15px"}}>
                        <HeaderInfo>
                            On this screen you can select one of our supported 3rd Party Security Lookup services. These provide your users with the ability 
                            to search a large database of securities when entering their PA Dealing request.
                        </HeaderInfo>
                        <HeaderInfo>
                            You will need to open an account and get an "API Key" from your chosen supplier in order for the service to work. Some services have 
                            free, limited lookups with the option of paid subscriptions to get more daily lookups if you are a heavy user of this feature.
                        </HeaderInfo>
                    </div>
                    <TypesBoldLabel style={{marginTop: "15px"}}>Use Security "Hot Lookup"</TypesBoldLabel>
                    {(() => {
                        if (this.state.general.security_hot_lookup.enabled === true) {
                            return (
                                <RadioBlock>
                                    <SelectedRadio>Yes</SelectedRadio>
                                    <UnselectedRadio onClick={this.toggleEnabled}>No</UnselectedRadio>
                                </RadioBlock>
                            )
                        } else {
                            return (
                                <RadioBlock>
                                    <UnselectedRadio onClick={this.toggleEnabled}>Yes</UnselectedRadio>
                                    <SelectedRadio>No</SelectedRadio>
                                </RadioBlock>
                            )
                        }
                    })()}
                    {(() => {
                        if (this.state.general.security_hot_lookup.enabled === true) {
                            return (
                                <div style={{marginTop: "30px"}}>
                                    <TypesBoldLabel>Security "Hot Lookup" Service</TypesBoldLabel>
                                    <RoleSelect style={{width: "45%", marginTop: "10px"}} value={this.state.general.security_hot_lookup.selected_service} onChange={this.changeService}>
                                    {
                                        Object.keys(this.state.general.security_hot_lookup.service_names).map(key => {
                                            //if(key !== "yahoo_fin")                             //Exclusion of YAHOO FINANCE
                                            return (
                                                <option key={key} value={key}>{this.state.general.security_hot_lookup.service_names[key].nickname}</option>
                                            )
                                        })
                                    }
                                    </RoleSelect>
                                    <TypesBoldLabel style={{marginTop: "20px"}}>API Key</TypesBoldLabel>
                                    <TextBox style={{marginTop: "10px", width: "70%"}} value={this.state.general.security_hot_lookup.service_names[this.state.general.security_hot_lookup.selected_service].api_key} onChange={this.changeApiKey(this.state.general.security_hot_lookup.selected_service)} />
                                    <TypesBoldLabel style={{marginTop: "20px"}}>Retrieve indicative Trade Price when security selected?</TypesBoldLabel>
                                    <div style={{fontSize: "12px", marginTop: "4px"}}>Doing this will increase your calls to the Service Provider and may incur further charges.</div>
                                    {(() => {
                                        if (this.state.general.security_hot_lookup.retrieve_indicative_price === true) {
                                            return (
                                                <RadioBlock>
                                                    <SelectedRadio>Yes</SelectedRadio>
                                                    <UnselectedRadio onClick={this.toggleIndicativePrice}>No</UnselectedRadio>
                                                </RadioBlock>
                                            )
                                        } else {
                                            return (
                                                <RadioBlock>
                                                    <UnselectedRadio onClick={this.toggleIndicativePrice}>Yes</UnselectedRadio>
                                                    <SelectedRadio>No</SelectedRadio>
                                                </RadioBlock>
                                            )
                                        }
                                    })()}
                                </div>
                            )
                        }
                    })()}
                </GeneralInnerContainer>
            </GeneralContainer>
        )
    }

}

export default HotLookup;