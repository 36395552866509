    let approval_options = [
          { id: "ar", name: "Approver Role " },
          { id: "lm", name: "Line Manager" },
          { id: "ar-lm", name: "Approver Role then Line Manager" },
          { id: "lm-ar", name: "Line Manager then Approver Role" },
          { id: "nr", name: "Not required (Auto-approve)" },
          { id: "ad", name: "Auto-decline" },
        ]
    
    module.exports = approval_options
    
    