import Store from "./Store.js";

class Utils {
  // constructor() {
  // }
  genKey = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  sortArrayOfObjectsBy = (array, field) => {
    return array.sort((a, b) => (a[field] > b[field] ? 1 : -1));
  };

  toArrayOfObjects = (object, name, id = "id") => {
    // console.log("object =", object);
    // console.log("name = ", name);
    if (object === undefined) {
      // console.log("got undefined for ", name);
    } else {
      if (name) return Object.entries(object).map(([key, value]) => ({ [id]: key, [name]: value }));
      return Object.entries(object).map(([key, value]) => ({ [id]: key, ...value }));
    }
  };

  log() {

  }

  dueDateString(due_date) {
    const curmonth = parseInt(due_date.getMonth());
    const currmonth = curmonth + 1;
    var dueDateStr = currmonth >= 10 ? due_date.getFullYear() + "/" + currmonth + "/" : due_date.getFullYear() + "/0" + currmonth + "/";
    dueDateStr += due_date.getDate() < 10 ? "0" + due_date.getDate() : due_date.getDate();
    return dueDateStr;
  }

  endOfTheDay(date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  selectedOtions(selected_options) {
    return selected_options.reduce((acc, cur) => {
      acc[cur.id] = cur.label;
      return acc;
    }, {});
  }

  getContacts() {
    return this.sortArrayOfObjectsBy(this.toArrayOfObjects(Store.getStoreData("contacts"), "id", "name"), "name");
  }

  getLists(list) {
    // console.log("lists =", list);
    if (list === undefined) {
      //console.log("undefined list");
    } else {
      return this.sortArrayOfObjectsBy(this.toArrayOfObjects(Store.getStoreData("module_config")[list]), "title").filter((e) => e.isActive);
    }
  }

  preventNonNumerical(e) {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }

  csvStringEncoder(str) {
    return str ? `"${str.replace('"', '""').replace(/,/g, ",").replace(/'/g, "'")}"` : str;
  }

  setEnv(module_name) {
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('module_name',module_name);
    Store.updateStore('base_api_url', process.env.REACT_APP_BASE_URL)
    Store.updateStore('api_url',api_url);
    Store.updateStore('is_admin',false);

  }
}

export default new Utils();
