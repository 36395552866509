import React from 'react'
import styled from "styled-components";
import { FaTimes, FaCalendarAlt, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import DatePicker from "react-date-picker";
import APICall from '../../../Common/APICall';
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import moment from 'moment';

const Container = styled.div`
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	box-sizing: border-box;
	width: 40%;
    position: absolute;
    z-index: 1;
    top: 36%;
    left: 30%;
`;
const MRModalHeader = styled.div`
	border-left: 10px solid #11ABA6;
	height: 100px;
	background-color: #ffffff;
	padding: 0px 30px;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	width: 80%;
	margin-top: 24px;
	// margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	// float: left;
	// margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	float: right;
	box-sizing: border-box;
	margin-top: 21px;
	font-size: 20px;
	font-weight: 200;
	color: #656565;
`;
const Clearfix = styled.div`
	clear: both;
`;
const ModalBody = styled.div`
	padding: 20px 30px 20px 30px;
	background-color: #F1F6F7;
	border-top: 1px solid rgb(208 209 210);
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;
const TextArea = styled.textarea`
	resize: none;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	max-width: 100%;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
`;
const CSLDateCover = styled.div`
  width: 30%;
  box-sizing: border-box;
  height: 40px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  // font-size: 14px;
  // font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  & > div {
    padding: 3px 4px;
    border: none;
    & > div > button {
      padding-top: 9px;
    }
  }
`;
const ModalFooter = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 15px 30px 15px 0px;
	background-color: #ffffff;
`;
const SubmitButton = styled.button`
	box-sizing: border-box;
	color: #ffffff;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
  	padding: 10px 20px;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
    color: #2d2c2c;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #DBDBDB;
    padding: 10px 20px;
    font-weight: 600;
`;

class FollowupModal extends React.Component {

	state = {
		task: null
	}

    componentDidMount() {
        let task = this.props.task.subtask;
        console.log("task_data ====>", task)
        task.task_json = JSON.parse(task.task_json);
        task.task_json.object_data.comments = "comments" in task.task_json.object_data ? task.task_json.object_data.comments : "";
        this.setState({task});
    }

	changeComments = (event) => {
        event.preventDefault();
        let task = JSON.parse(JSON.stringify(this.state.task));
        task.task_json.object_data.comments = event.target.value;
        this.setState({task});
    }

    submit = (event) => {
        event.preventDefault();
        let task = JSON.parse(JSON.stringify(this.state.task));
        if (task.task_json.object_data.comments === "") {
            alert("Please enter comments");
            return false;
        }
        this.props.updateFollowupTask(this.state.task);
    }

    closeFollowupModal = (event) => {
        event.preventDefault();
        this.props.closeFollowupModal();
    }

    handleDownload(image_id, task_id) {
    	let api = new APICall();
      	let postData = { command: "download_attachment", image_id: image_id, task_id: task_id };
      	console.log("postData", postData);
      	api.command(postData, this.processDownloadFile);
    }

    processDownloadFile = (result) => {
      console.log("Attachment", result);
      let dbinfile = result.result;
      let dnld = dbinfile.bin_file.replace(/ /g, "+");
      let binaryString = window.atob(dnld);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      var blob = new Blob([bytes]);
      saveAs(blob, dbinfile.name);
    };

	render () {
        if (this.state.task === null) {
            return <></>
        }
        let attachments = "attachments" in this.state.task.task_json.object_data ? this.state.task.task_json.object_data.attachments : []
        if(!Array.isArray(attachments)){
          attachments = []
        }
		return (
			<Container>
				<MRModalHeader>
					<MRModalHeaderText>{`PAD-${this.state.task.id}`}</MRModalHeaderText>
					<MRModalHeaderCloseBtn><FaTimes style={{cursor: "pointer"}} onClick={this.closeFollowupModal} /></MRModalHeaderCloseBtn>
			        <Clearfix />
			        {/* <MRModalSubHeaderText>Create Note for this PA Dealing record</MRModalSubHeaderText> */}
				</MRModalHeader>
				<ModalBody>
					<Row>
						<MRModalLabel>Subject</MRModalLabel>
						<label>{this.state.task.task_json.object_data.subject}</label>
					</Row>
                    <Row>
						<MRModalLabel>Note</MRModalLabel>
						<label>{this.state.task.task_json.object_data.note}</label>
					</Row>

					<div style={{ width: "100%" }}>
					  <div style={{ float: "left", width: "30%" }}>
					    <MRModalLabel>Attachments</MRModalLabel>
					  </div>
					  <div style={{ float: "left", width: "70%" }}>
					    <label>
					      {attachments.map(file => {
					        return (
					          <Row>
					            {file.name}
					            <div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.id)}>
					              <FiDownload /> Download
					            </div>
					          </Row>
					        )
					      })}
					    </label>
					  </div>
					  <div style={{ clear: "both" }}></div>
					</div>

					<Row>
						<MRModalLabel>Comments</MRModalLabel>
                        {
                            this.state.task.cur_lane === "COMP_PAD_FOLLOWUP_ASSIGNMENT"
                            ?
                            <TextArea rows="5" cols="120" value={this.state.task.task_json.object_data.comments} onChange={this.changeComments}></TextArea>
                            :
                            <label>{this.state.task.task_json.object_data.comments}</label>
                        }
					</Row>
				</ModalBody>
				<ModalFooter>
                    {this.state.task.cur_lane === "COMP_PAD_FOLLOWUP_ASSIGNMENT" && <SubmitButton style={{float: "right"}} onClick={this.submit}>Complete</SubmitButton>}
					<CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeFollowupModal}>Close</CloseButton>
					<Clearfix />
				</ModalFooter>
			</Container>
		);
	}

}

export default FollowupModal;