import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

export default function EllipsisMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const performAction = (entity_id, action_type) => (event) => {
    event.preventDefault();
    setAnchorEl(null);
    props.performAction(entity_id, action_type);
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="ellipsis-button"
        aria-controls={open ? 'ellipsis-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="ellipsis-menu"
        MenuListProps={{
          'aria-labelledby': 'ellipsis-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            right: '50ch',
            fontWeight : 'bold',

          },
        }}
      >
        {
          props.actions.map((item, index) => {
            if(props.show[item.action] === 1) {
              return(<MenuItem key={item.name + props.entity_id} onClick={performAction(props.entity_id, item.action)}> {item.name} </MenuItem>);
            }                    
          })
        }
      </Menu>
    </div>
  );
}


