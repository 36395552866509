import React from 'react'
import styled from "styled-components";
import { FaTimes, FaCalendarAlt, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import DatePicker from "react-date-picker";
import moment from 'moment';

const Container = styled.div`
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	box-sizing: border-box;
	width: 40%;
`;
const MRModalHeader = styled.div`
	border-left: 10px solid #11ABA6;
	height: 100px;
	background-color: #ffffff;
	padding: 0px 30px;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	width: 80%;
	margin-top: 24px;
	// margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	// float: left;
	// margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	float: right;
	box-sizing: border-box;
	margin-top: 21px;
	font-size: 20px;
	font-weight: 200;
	color: #656565;
`;
const Clearfix = styled.div`
	clear: both;
`;
const ModalBody = styled.div`
	padding: 20px 30px 20px 30px;
	background-color: #F1F6F7;
	border-top: 1px solid rgb(208 209 210);
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;
const TextArea = styled.textarea`
	resize: none;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	max-width: 100%;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
`;
const CSLDateCover = styled.div`
  width: 30%;
  box-sizing: border-box;
  height: 40px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  // font-size: 14px;
  // font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  & > div {
    padding: 3px 4px;
    border: none;
    & > div > button {
      padding-top: 9px;
    }
  }
`;
const ModalFooter = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 15px 30px 15px 0px;
	background-color: #ffffff;
`;
const SubmitButton = styled.button`
	box-sizing: border-box;
	color: #ffffff;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
  	padding: 10px 20px;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
    color: #2d2c2c;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #DBDBDB;
    padding: 10px 20px;
    font-weight: 600;
`;

class NoteModal extends React.Component {

	state = {
		title: "",
		details: "",
		private_note: false,
		flag_followup_note: false,
		followup_date: moment().format('DD/MM/YYYY'),
		attachments: {}
	}

	handleChange = (element) => (event) => {
		event.preventDefault();
		this.setState({[element]: event.target.value});
	}

	changeCheck = (entity, status) => (event) => {
		event.preventDefault();
		this.setState({[entity]: status});
	}

	closeNoteModal = (event) => {
		event.preventDefault();
		this.props.closeNoteModal();
	}

	addNote = (event) => {
		event.preventDefault();
		this.props.addNote(this.state);
	}

	render () {
		return (
			<Container>
				<MRModalHeader>
					<MRModalHeaderText>Add Note</MRModalHeaderText>
					<MRModalHeaderCloseBtn><FaTimes style={{cursor: "pointer"}} onClick={this.closeNoteModal} /></MRModalHeaderCloseBtn>
			        <Clearfix />
			        <MRModalSubHeaderText>Create Note for this PA Dealing record</MRModalSubHeaderText>
				</MRModalHeader>
				<ModalBody>
					<Row>
						<MRModalLabel>Title</MRModalLabel>
						<TextBox type="text" value={this.state.title} onChange={this.handleChange("title")} />
					</Row>
					<Row>
						<MRModalLabel>Details</MRModalLabel>
						<TextArea rows="5" cols="120" value={this.state.details} onChange={this.handleChange("details")}></TextArea>
					</Row>
					<Row style={{width: "100%", boxSizing: "border-box", marginBottom: "22px"}}>
						<div style={{float: "left", width: "10%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
							{this.state.private_note === true && <FaRegCheckSquare style={{cursor: "pointer"}} onClick={this.changeCheck("private_note", false)} />}
							{this.state.private_note === false && <FaRegSquare style={{cursor: "pointer"}} onClick={this.changeCheck("private_note", true)} />}
						</div>
						<div style={{float: "left", width: "90%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
							Private Note
						</div>
						<Clearfix></Clearfix>
					</Row>
					<Row style={{width: "100%", boxSizing: "border-box", marginBottom: "20px"}}>
						<div style={{float: "left", width: "10%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
							{this.state.flag_followup_note === true && <FaRegCheckSquare style={{cursor: "pointer"}} onClick={this.changeCheck("flag_followup_note", false)} />}
							{this.state.flag_followup_note === false && <FaRegSquare style={{cursor: "pointer"}} onClick={this.changeCheck("flag_followup_note", true)} />}
						</div>
						<div style={{float: "left", width: "90%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
							Flag note for follow up
						</div>
						<Clearfix></Clearfix>
					</Row>
					<Row style={{marginBottom: "0px"}}>
						<MRModalLabel>Followup Date</MRModalLabel>
						<CSLDateCover style={{ display: "inline-block" }}>
							<DatePicker
								onChange={(e) => {
									this.setState({ followup_date: moment(e).format("DD/MM/YYYY") });
								}}
								clearIcon={null}
								calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
								locale={"en-GB"}
								className="csldatecontainer"
								value={moment(this.state.followup_date, "DD/MM/YYYY").toDate()}
							/>
						</CSLDateCover>
					</Row>
				</ModalBody>
				<ModalFooter>
					<SubmitButton style={{float: "right"}} onClick={this.addNote}>ADD NOTE</SubmitButton>
					<CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeNoteModal}>CLOSE</CloseButton>
					<Clearfix />
				</ModalFooter>
			</Container>
		);
	}

}

export default NoteModal;