import React from "react";
import styled from "styled-components";
import { FaTimes, FaCalendarAlt, FaPlusCircle, FaRegCheckSquare, FaRegSquare, FaExclamationTriangle } from "react-icons/fa";
import CslSimpleDropDown from "../../Common/CslSimpleDropDown";

import moment from "moment";

//import NoteModal from "./NoteModal";
//import CreateSecurityRegisterModal from "./CreateSecurityRegisterModal";

const ModalWraper = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 70%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  margin-left: 15%;
  padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  // width: 40%;
  // position: absolute;
  // top: 38px;
  // z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  // margin-bottom: 10px;
  // margin-left: 30%;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ada8;
  border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
`;
const MRModalSubHeaderText = styled.div`
  font-size: 13px;
  float: left;
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 10px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 21px;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 200;
  color: #656565;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #f1f6f7;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
`;
const ClearBoth = styled.div`
  clear: both;
`;
const MRModalLabel = styled.div`
  color: #030303;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 9px;
  font-family: "Montserrat", sans-serif;
`;
const HR = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(208 209 210);
  margin: 35px 0px;
`;
const Row = styled.div`
  margin-bottom: 10px;
`;
const InfoDiv = styled.div`
  width: 100%;
  background-color: #d6e6f3;
  border: 1px solid #adb1b5;
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
`;
const MRModalFooter = styled.div`
  padding: 17px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  margin-left: 10px;
  float: right;
  cursor: pointer;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  padding: 9px;
`;
const CSLDateCover = styled.div`
  width: 30%;
  box-sizing: border-box;
  height: 40px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  // font-size: 14px;
  // font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  & > div {
    padding: 3px 4px;
    border: none;
    & > div > button {
      padding-top: 9px;
    }
  }
`;
const Section50 = styled.div`
  width: 50%;
  float: left;
  box-sizing: border-box;
`;
const Section25 = styled.div`
  width: 25%;
  float: left;
  box-sizing: border-box;
`;
const Section30 = styled.div`
  width: 30%;
  float: left;
  box-sizing: border-box;
`;
const Section70 = styled.div`
  width: 70%;
  float: left;
  box-sizing: border-box;
`;
const Clearfix = styled.div`
  clear: both;
`;
const TextBox = styled.input`
  height: 41px;
  border: 1px solid #dbdbdb;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
`;
const TextArea = styled.textarea`
  resize: none;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  max-width: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  box-sizing: border-box;
`;
const NoteTableHeaderBar = styled.div`
  background-color: #11aba6;
  border: 1px solid #11aba6;
  box-sizing: border-box;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 15px;
`;
const NoteWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: calc(100vw - 320px);
  left: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1002;
`;
const NoteTableHeadBodyWrapper = styled.div`
  box-sizing: border-box;
  border-left: 1px solid rgb(203 204 206);
  border-right: 1px solid rgb(203 204 206);
  border-bottom: 1px solid rgb(203 204 206);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const NoteTableHeader = styled.div`
  box-sizing: border-box;
`;
const NoteTableBody = styled.div`
  box-sizing: border-box;
  background-color: #ffffff;
`;
const Cell30 = styled.div`
  width: 30%;
  float: left;
  box-sizing: border-box;
  padding: 10px 0px 10px 20px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const Cell70 = styled.div`
  width: 70%;
  float: left;
  box-sizing: border-box;
  padding: 10px 0px 10px 20px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const MRAlertContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #eccaca;
  border: 1px solid #b75050;
  border-radius: 5px;
  padding: 5px 10px;
`;

const MRDataContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e7f0f7;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px;
`;

// const table_data: {
// 	header: [
// 		{accessor: "icon", display: "z", width: "10", color: "transparent"},
// 		{accessor: "name", display: "Name", width: "30", color: "#030303"},
// 		{accessor: "identifiers", display: "Identifier(s)", width: "30", color: "#030303"},
// 		{accessor: "holding", display: "Holding", width: "30", color: "#030303"}
// 	],
// 	data: [
// 		{id: "1", name: "Alliance Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "none"},
// 		{id: "2", name: "Alliance1 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "none"},
// 		{id: "3", name: "Alliance2 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "none"},
// 		{id: "4", name: "Alliance3 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "5", name: "Alliance4 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "6", name: "Alliance5 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "7", name: "Alliance6 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "8", name: "Alliance7 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "9", name: "Alliance8 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "10", name: "Alliance9 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"},
// 		{id: "11", name: "Alliance10 Boosts Ltd.", identifiers: "AB:LN", holding: "1,000", icon: "Warning"}
// 	],
// 	selected: {id: "1", name: "Alliance Boosts Ltd.", identifiers: "AB:LN", holding: "1,000"}
// }

class ReassignModal extends React.Component {
  state = {
    task_id: 0,
    options: [
      { id: "1", name: "Alpha" },
      { id: "2", name: "Calendar" },
      { id: "3", name: "Numeric" },
      { id: "4", name: "Dropdown" },
    ],
    selected_option: { id: "1", name: "Alpha" },
    table_data: {},
    contacts: null,
    requester_obj: {},
    roles: null,
    selected_role_obj: {},
    instruction_types: null,
    selected_instruction_type: {},
    transaction_types: null,
    selected_transaction_type: {},
    counterparties: null,
    selected_counterparty: {},
    beneficiaries: null,
    selected_beneficiary: {},
    security_types: null,
    selected_security_type: {},
    transaction_date: moment().format("DD/MM/YYYY"),
    securities: null,
    selected_security: null,
    currencies: null,
    selected_currency: {},
    trade_details: {},
    not_short: true,
    not_breach: true,
    comments: "",
    notes: {},
    show_note_modal: false,
    show_security_modal: false,
    custom_fields: null,
    ready: false,
  };

  componentDidMount() {}

  closeView = () => this.props.closeModal();

  setSelection = (data) => {
    let selected_option = this.state.options.find((item) => item.id === data);
    this.setState({ selected_option });
  };

  changeRequester = (data) => {
    let requester_obj = this.state.contacts.find((item) => item.id === data);
    this.setState({ requester_obj });
  };

  changeRole = (data) => {
    let selected_role_obj = this.state.roles.find((item) => item.id === data);
    this.setState({ selected_role_obj });
  };

  changeCashConsideration = (event) => {
    event.preventDefault();
    let trade_details = JSON.parse(JSON.stringify(this.state.trade_details));
    trade_details.cash_consideration = event.target.value;
    this.setState({ trade_details });
  };

  changeCheck = (entity, status) => (event) => {
    event.preventDefault();
    this.setState({ [entity]: status });
  };

  changeComments = (event) => {
    event.preventDefault();
    this.setState({ comments: event.target.value });
  };

  closeSecurityModal = () => {
    this.setState({ show_security_modal: false });
  };

  showCreateSecurityModal = () => {
    this.setState({ show_security_modal: true });
  };

  saveAndClose = (event) => {
    event.preventDefault();
    console.log("saveAndClose");
  };

  changeFieldValue = (field_name, value) => {
    console.log(`${field_name} | ${value}`);
    let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
    custom_fields.additional_fields.forEach((item) => {
      if (item.name.toString() === field_name.toString()) {
        item.value = value;
      }
    });
    this.setState({ custom_fields });
  };

  changeOtherValue = (field_name, value) => {
    let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
    custom_fields.additional_fields.forEach((item) => {
      if (item.name.toString() === field_name.toString()) {
        item.other_value = value;
      }
    });
    this.setState({ custom_fields });
  };

  changeDropdownValue = (option_id, field_name) => {
    let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
    custom_fields.additional_fields.forEach((item) => {
      if (item.name.toString() === field_name.toString()) {
        if (option_id === "Select") {
          item.value = "";
          item.other_value = "";
        } else if (option_id === "other") {
          item.value = "other";
          item.other_value = "";
        } else {
          item.value = option_id;
        }
      }
    });
    this.setState({ custom_fields });
  };

  render() {
    return (
      <ModalWraper>
        <MRModalContainer>
          <MRModalHeader>
            <MRModalHeaderText>Reassign PADRI-000</MRModalHeaderText>
            <MRModalHeaderCloseBtn>
              <FaTimes />
            </MRModalHeaderCloseBtn>
            <ClearBoth />
            <MRModalSubHeaderText>lorem ipsum</MRModalSubHeaderText>
          </MRModalHeader>

          <MRModalBody>
            <MRDataContainer>You are opting to Reassign a PAD request to another user . Both current and newly assigned user will be notified via email of this change</MRDataContainer>
            <br />

            <Row>
              <MRModalLabel>Reassign To</MRModalLabel>
              <CslSimpleDropDown options={this.state.contacts} selected={this.state.requester_obj} setSelection={this.changeRequester} />
            </Row>

            <Row>
              <MRModalLabel>Comments</MRModalLabel>
              <TextArea rows="5" cols="120" value={this.state.comments} onChange={this.changeComments}></TextArea>
            </Row>

            <>
              <Row style={{ width: "100%", boxSizing: "border-box", marginBottom: "22px" }}>
                <div style={{ float: "left", width: "10%", boxSizing: "border-box", fontSize: "19px", color: "#030303" }}>
                  {this.state.not_short === true && <FaRegCheckSquare style={{ cursor: "pointer" }} onClick={this.changeCheck("not_short", false)} />}
                  {this.state.not_short === false && <FaRegSquare style={{ cursor: "pointer" }} onClick={this.changeCheck("not_short", true)} />}
                </div>
                <div style={{ float: "left", width: "90%", boxSizing: "border-box", fontWeight: "600", color: "#030303" }}>I confirm this PAD entry will be reassigned to the selected user and this action cannot been undone .</div>
                <Clearfix></Clearfix>
              </Row>
            </>
          </MRModalBody>

          <MRModalFooter>
            <MRModalNextBtn style={{ backgroundColor: "#ffffff", color: "#222222", border: "1px solid #c7c2c2" }}>CANCEL</MRModalNextBtn>

            <MRModalNextBtn>REGISTER</MRModalNextBtn>

            <Clearfix></Clearfix>
          </MRModalFooter>
        </MRModalContainer>
      </ModalWraper>
    );
  }
}

export default ReassignModal;
