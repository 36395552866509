import React from "react";
import { FaPlusCircle, FaExclamationTriangle, FaUserLock } from "react-icons/fa";
import styled from "styled-components";
import CSLTable from "../CSLTable/CSLTable";
import APICall from "../../../../Common/APICall";
import ManageSecurityRegister from './modals/ManageSecurityRegister';
//import Dropdown from "../../../Common/Dropdown";
import SpinningLoader from "../../../Common/SpinningLoader";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";
import RestrictedListModal from './RestrictedListModal';



const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 150%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;


class ConfigSecurityRegisterLists extends React.Component {  

  state = {
    ready: false,
    filters: null,
    security_register: null,
    show_modal: null,
    type: {},
    show_spinner : false,
    active_section_not_ready : true,
    show_restricted_list_modal: false,
    mRestrictedList: {},
    selected_security_id: 0
  };

  constructor() {
    super();    
    this.api = new APICall();
  }

  componentDidMount() {
    

    // const postData = { command: "get_security_lookup"};
    const postData = { command: "get_security_lookup_and_restricted_lists"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
    this.api.command(postData, this.getroleTypes);

    // console.log("security_register", this.props.security_register)
    // let security_types = Store.getStoreData("module_config")["security_types"];
    // let currencies = Store.getStoreData("module_config")["currencies"];
    // let security_identifier_types = Store.getStoreData("module_config")["security_identifier_types"];    
    // this.setState({security_types, currencies, security_identifier_types, ready: true})
  }

  getroleTypes = (result) => {
    console.log("result", result)
    let filters = result.filter;
    let security_register = result.security_register;
    let mRestrictedList = result.mRestrictedList;
    this.setState({filters:filters, security_register: security_register, show_spinner : false, active_section_not_ready : false, mRestrictedList, show_restricted_list_modal: false})

  }

  componentDidUpdate(prevProps) {    
  }

  closeModal = (event) => {    
    this.setState({ show_modal: null });
  }; 
  closeRestrictedModal = (event) => {    
    this.setState({ show_restricted_list_modal: null });
  }; 

  deleteType = (id) => (event) => {
    let type = this.state.security_register[id.toString()];
    if (window.confirm(`Are you sure you want to delete this ${type['name']} from the list?`)) {
     const postData = { command: "delete_lookup"};
     postData['lookup_id'] = this.props.lookup_id;
     postData['id'] = id;
     this.api.command(postData, this.removeroleType);
    }
  };

  removeroleType = (result) => {
    let msg = "Security Register type successfully deleted";
    alert(msg);
    // const postData = { command: "get_security_lookup"};
    const postData = { command: "get_security_lookup_and_restricted_lists"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
    this.api.command(postData, this.getroleTypes);
  }
  
  performAction = (role_id, action_type) => {
    console.log("role_id:", role_id);
    console.log("action_type:", action_type);
    if (action_type === "edit") {
      this.openModal(role_id);
    }
    if (action_type === "restrict") {
      // this.restrictSecurity(role_id);
      this.setState({show_restricted_list_modal: true, selected_security_id: role_id})
    }
    if (action_type === "delete") {
      if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
        const postData = {
          command: "delete_lookup",
          lookup_id: "security_register",
          check_key: "security_id",
          id: role_id
        };
        console.log("postData:", postData);
        this.api.command(postData, this.afterRemove);
      }
    }
  }

  afterRemove = (result) => {
    console.log(result);
    if (result.error_msg === "exists") {
      alert("This item cannot be removed as it is associated with tasks");
    } else {
      alert("This item is successfully removed");
      let security_register = JSON.parse(JSON.stringify(this.state.security_register));
      delete security_register[result.result.toString()];
      this.setState({security_register});
    }
  }
  
  restrictSecurity = (sec_id) => {
    let security = this.state.security_register[sec_id.toString()];
    console.log("security:", security);
    const postData = { command: "save_lookup"};
    postData['lookup_json'] = JSON.parse(security.lookup_json);
    postData['lookup_json']['is_restricted'] = true;
    postData['name'] = security.name;
    postData['status'] = security.is_active;
    postData['description'] = security.desc;
    postData['lookup_id'] = 'security_register';
    postData['id'] = sec_id;
    console.log("postData", postData)
    this.setState({show_spinner : true})
    this.api.command(postData, this.refreshParent);
  }

  tableData = () => {        
        let ret = {data: [], columns: []};
        const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}, {name: "Restrict", action: "restrict"}];
        ret.columns =[
                        {Header: "", width: 40, headerStyle: { textAlign: "center" }, filterable: false,
                          Cell: (row) => (
                            
                            <>
                              {row.original.source === 'private' && <FaUserLock style={{color: "#59C2EF", marginTop: "2px"}} />}
                              {row.original.is_restricted === true && <FaExclamationTriangle style={{color: "red", marginTop: "2px"}} />}
                            </>
                            // if (row.original.is_restricted === true) {
                            //   return <FaExclamationTriangle />
                            // }
                          )
                        },
                        {Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left'}},					
                        {Header: 'Type', accessor: 'type', minWidth: 80, headerStyle: {textAlign: 'left'}},
                        {Header: 'Currency', accessor: 'currency', minWidth: 80, headerStyle: {textAlign: 'left'}}                        
                    ];

        for(let key in this.state.filters){
            if(this.state.filters[key].lookup_id === "security_identifier_types"){
                let addColums = {Header: this.state.filters[key].name , accessor: key, minWidth: 80, headerStyle: {textAlign: 'left'}} 
                ret.columns.push(addColums)
            }
        }

        
        // let c1 = { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} />, style: { display: "flex", alignItems: "center", justifyContent: "center" } };
        // ret.columns.push(c1)
        let c2 = {
          Header: "",
          Cell: (row) => (
            <div style={{marginTop : "-8px"}}><EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
          ),
          width: 55,
          headerStyle: { textAlign: "center" },
          filterable: false
        };
        ret.columns.push(c2)
        
        let sl = 0 
        for(let key in this.state.security_register){
            let lookup_json = JSON.parse(this.state.security_register[key].lookup_json)
            sl++;            
            let elem = {
                slno: sl, 
                id: key, 
                name: this.state.security_register[key].name, 
                type: ('security_type_id' in lookup_json) ? this.state.filters[lookup_json.security_type_id].name : "", 
                currency: ('currency_id' in lookup_json) ? this.state.filters[lookup_json.currency_id].name : "", 
                status: this.state.security_register[key].is_active,
                status_value : this.state.security_register[key].is_active === 1 ? "Active" : "In-Active",
                is_restricted: "is_restricted" in this.state.security_register[key] ? this.state.security_register[key].is_restricted : false,
                contact_id: this.state.security_register[key].contact_id,
                source: this.state.security_register[key].source
            };

            if(Object.keys(lookup_json.identifier_types).length !== 0){
                let identifierTypes = lookup_json.identifier_types;
                for(let k in identifierTypes){
                    elem[k.toString()] = identifierTypes[k.toString()]
                }
            }

            ret.data.push(elem);
        }        
        return ret;
    }

    toggleStatus = (is_active, id) => {
      let security_register = this.state.security_register;
      console.log("security_register", security_register)
      security_register[id.toString()].is_active = is_active ? 1 : 0;
      const postData = { command: "toggle_status"};
      postData['lookup_id'] = this.props.lookup_id;
      postData['is_active'] = is_active ? 1 : 0;
      postData['id'] = id;
      this.api.command(postData, this.toggleroleType);
    }

    toggleroleType = (result) => {
      alert(result.error_msg);
    }

    openModal = (id) => {
      // event.preventDefault();
      let type = this.state.security_register[id.toString()];
      let t = {};
      t['id'] = id;
      t['status'] = type.is_active;
      t['name'] = type.name;
      t['desc'] = type.description;
      t['lookup_json'] = JSON.parse(type.lookup_json);
      t['is_restricted'] = "is_restricted" in type ? type.is_restricted : false;
      this.setState({ show_modal: 'roletype', type: t });
    };

    addType = (event) => {
      event.preventDefault();
      let type = {};
      type['id'] = 0
      type['status'] = 1
      type['name'] = ""
      type['desc'] = ""
      type['lookup_json'] = {is_restricted: false};
      type['lookup_json']['identifier_types'] = {};
      this.setState({show_modal:'roletype', type: type});
    };

    refreshParent = () => {
      this.setState({ show_modal: null });
      // const postData = { command: "get_security_lookup"};
      const postData = { command: "get_security_lookup_and_restricted_lists"};
      postData['lookup_id'] = this.props.lookup_id;
      postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
      this.api.command(postData, this.getroleTypes);
    }

  render() {
    // if (!this.state.ready) return <div>Loading...</div>;
    console.log("Security List Props : ", this.props.general);

    if(this.state.active_section_not_ready === true) {
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }
    
    return (
      <div>       
        {this.state.show_spinner === true && <SpinningLoader />}
         <div style={{ width: "90%" }}>          
          <TypesContainer>
          {
            (() => {
              if(this.state.show_modal){
                return <ManageSecurityRegister closeModal={this.closeModal} type = {this.state.type} filters = {this.state.filters} refreshParent={this.refreshParent}/>
              }
            })()
          }
          {
            (() => {
              if(this.state.show_restricted_list_modal){
                return <div><InactiveOverlay/><RestrictedListModal closeModal={this.closeRestrictedModal} mRestrictedList={this.state.mRestrictedList} selected_security_id={this.state.selected_security_id} security_register={this.state.security_register} refreshParent={this.refreshParent}/></div>
              }
            })()
          }
            <TypesInnerContainer>
              <div style={{ marginBottom : "16px", display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between"}}>
                <div>
                  <TypesBoldLabel style={{ display: "inline-block", height : "20px", margin : "0px" }}>{this.props.sectionName}</TypesBoldLabel>
                </div>
                <div  style={{display : "flex", flexDirection : "row", justifyContent : "flex-end", alignItems : "center"}}>
                  { this.props.general.security_hot_lookup.enabled === true && 
                    <div style={{fontSize: "11px", marginRight : "10px",  lineHeight : "20px" }}><span style={{fontWeight : "bold"}}>Note:</span> You have configured this module to use a 3rd party Lookup Service. Securities found on the Security Register will be shown last in the security selection box</div> }
                  <div onClick={this.addType} style={{cursor: "pointer", fontSize: "17px", color: "#04ADA8", paddingTop : "6px"}}>
                    <FaPlusCircle />
                  </div>
                </div>
              </div>
              <div style={{clear: "both"}}></div>
              <CSLTable add={false} processData={this.tableData} tableRows={20} />
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
    );
  }
}

export default ConfigSecurityRegisterLists;
