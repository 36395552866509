import React from 'react';
import moment from 'moment';
import './TransactionReportViewModal.css';
//import DatePicker from "react-date-picker";
import { FaTimes, FaCalendarAlt, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import Store from "../../../Common/Store";
import APICall from '../../../Common/APICall';
import CSLTable from '../../Common/CSLTable';
import TransConfirmationPdf from './TranConfirmationReport';
import SpinningLoader from '../../Common/SpinningLoader';

class TransactionReportViewModal extends React.Component{

    state = {
        ready : false,
        contact_id : null,
        from_date : null,
        to_date : null,
        enable_employee_broker_ac : Store.getStoreData("module_config").general.enable_employee_broker_ac,
        report_title : "",
        resp_date : null,
        optComment : "",
        resp_time : "",
        nil_transaction : false,
        transactions_to_view : [],
        rows_in_view : 5,
        brokersToView : [],
        brokerRowsInView : 5,
        showBrokers : false,
        nilBrokers : false
    }

    componentDidMount() {
        console.log("View : ", this.props)
        let report_to_view = this.props.selected_report.transaction;
        let contact_id = report_to_view.contact_id;
        let from_date = report_to_view.from_date;
        let to_date = report_to_view.to_date;
        let report_status = report_to_view.status;
        this.setState({ contact_id, from_date, to_date, report_status})
        let report_id = this.props.view === 'admin' ? report_to_view.record_id : report_to_view.id;
        this.generateReport(report_id, contact_id);
    }

    generateReport = async (report_id, contact_id) => {
        const postData = { command: "fetch_tran_report_details", report_id : report_id, contact_id : contact_id};
        const api = new APICall();
        const api_result = await api.commandWithoutCallback(postData);
        console.log("REport ==> ", api_result);
        let report_title = api_result.result.report_detail.report_title !== null ? api_result.result.report_detail.report_title : Store.getStoreData("module_config").pad_confirmation.report_title;
        let resp_date = api_result.result.report_detail.response_date;
        let resp_time = api_result.result.report_detail.response_time;
        let rejection_cause = api_result.result.report_detail.rejection_cause;
        let optComment = api_result?.result?.report_detail?.opt_comment;
        let nil_transaction = (JSON.parse(api_result.result.report_detail.pad_ids_involved)).length ? false : true; 
        let showBrokers = "brokers" in api_result.result;
        let nilBrokers = null;
        if (showBrokers === true){
            nilBrokers = api_result.result.brokers.length === 0;
        }

        let transactions_to_view = [];
        if(!nil_transaction){
            api_result.result.transactions.forEach(item => {
                let data = {
                    pad_id : item.pad_id,
                    trade_date : item.trade_date,
                    transaction_type : item.transaction_type,
                    security_name : item.security_name,
                    security_id : item.security_id,
                    currency : item.currency,
                    volume : item.volume,
                    price : item.price,
                    amount : item.amount,
                    broker : item.broker
                }
                transactions_to_view.push(data);
            })
        }
        let rows_in_view = 5;
        if( transactions_to_view.length > 5 ) rows_in_view = transactions_to_view.length <20 ? transactions_to_view.length : 20;

        let brokersToView = [];
        if(showBrokers === true){
            if(nilBrokers === false){
                brokersToView = [...api_result?.result?.brokers];
            }
        }
        let brokerRowsInView = 5;
        if( brokersToView?.length > 5 ){ 
            brokerRowsInView = brokersToView?.length <20 ? brokersToView?.length : 20;
        }

        this.setState({ 
            report_title, 
            resp_date, 
            resp_time, 
            nil_transaction, 
            transactions_to_view, 
            rejection_cause, 
            optComment,
            rows_in_view,
            showBrokers,
            nilBrokers,
            brokersToView, 
            brokerRowsInView,
            ready : true
        })
    }

    closeModal = (e) => {
        e.preventDefault();
        this.props.closeModal();
    }

    processDataTable = () => {
        const ret = { data: [], columns: [] };
        const actions = [{name: "View", action: "view"}];
        let table_data = [];
        //debugger;
        ret.columns = [
            { Header: "Trade Date", accessor: "trade_date", width: !this.state.enable_employee_broker_ac ? 130 : 100, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Transaction", accessor: "transaction_type", width: !this.state.enable_employee_broker_ac ? 130 : 100, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Security Name", accessor: "security_name", width: !this.state.enable_employee_broker_ac ? 220 : 180, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Security ID", accessor: "security_id", width: !this.state.enable_employee_broker_ac ? 250 : 220, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Volume", accessor: "volume", width: !this.state.enable_employee_broker_ac ? 130 : 110, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Price", accessor: "price", width: !this.state.enable_employee_broker_ac ? 130 : 110, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Amount", accessor: "amount", width: !this.state.enable_employee_broker_ac ? 170 : 150, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        if(this.state.enable_employee_broker_ac){
            ret.columns.push({ Header: "Broker", accessor: "broker", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" }})
        }
        ret.columns.push({ Header: "PAD ID", accessor: "pad_id", width: !this.state.enable_employee_broker_ac ? 140 : 135, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } })
        for(let r of this.state.transactions_to_view){
            let data = {
                pad_id : "PAD-" + r.pad_id,
                trade_date : r.trade_date,
                transaction_type : r.transaction_type,
                security_name : r.security_name,
                security_id : r.security_id,
                volume : r.volume,
                price : r.price,
                amount : r.amount,
                broker : r.broker
            }
            table_data.push(data);
        }
        // table_data.sort((a, b) => {
        //     let a1 = a.contact_name;
		// 	let b1 = b.contact_name;
        //     if (a1 < b1) return -1
        //     if (a1 > b1) return 1
        //     return 0
        // })
        ret.data = table_data;
        return ret;
    }

    processDataBrokerTable = () => {
        const ret = { data: [], columns: [] };
        let tableData = [];
        ret.columns = [
            { Header: "Name of Broker/Dealer or Bank", accessor: "brokerName", width: 550, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Account Title (Your Reference)", accessor: "accountName", width: 420, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Date Account Opened", accessor: "acOpeningDate", width: 330, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        for(let r of this.state.brokersToView){
            let data = {
                id : r.id,
                brokerName : r?.broker_name,
                accountName : r?.account_name,
                acOpeningDate : r?.ac_opening_date
            }
            tableData.push(data);
        }
        ret.data = tableData;
        return ret;
    }

    render(){
        if(this.state.ready !== true) return(<SpinningLoader />);
        return(
            <div className='tr-report-view-modal-wrapper'>
                <div className='tr-report-view-modal-header'>
                    <div className='tr-report-view-modal-title-wrapper'>
                        <div className='tr-report-view-modal-heading'>
                          {this.state.report_title} - {this.state.report_status === 'confirmed' ? 'Confirmed' : 'Rejected'}
                        </div>
                        <div className='tr-report-view-modal-subheading'>
                            {this.props.view === 'admin' ? (Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(this.state.contact_id))).name + " - " : ""}
                            {moment(moment(this.state.from_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY") + " "} 
                            to 
                            {" " + moment(moment(this.state.to_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    <div className='tr-report-view-modal-close'><FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/></div>
                </div>
                <div className='tr-report-view-modal-body'>
                    <div className='tr-report-view-modal-help-text'>
                        {this.props.view === 'admin' ? (Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(this.state.contact_id))).name + " " : "You   "}
                        reviewed this information on {moment(moment(this.state.resp_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")} 
                        {this.state.resp_time !== null && this.state.resp_time !== undefined && this.props.view === 'admin' ? ` at ${this.state.resp_time}` : ""} and
                        {this.state.report_status === 'confirmed' ? <span> <span style={{fontWeight : "700", marginLeft : "4px"}}> Confirmed</span> the details</span> : <span> <span style={{fontWeight : 700, marginLeft : "4px"}}> Rejected</span> the details with the following explanation</span>}
                        {
                            this.state.report_status === 'confirmed' && //this.props.view !== 'admin' &&
                            <TransConfirmationPdf 
                                report_title={this.state.report_title}
                                transactions={this.state.transactions_to_view} 
                                showBrokers = {this.state.showBrokers}
                                brokersToView = {this.state.brokersToView}
                                from_date={this.state.from_date} 
                                to_date={this.state.to_date} 
                                employee={(Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(this.state.contact_id))).name}
                                response_date={moment(moment(this.state.resp_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")}
                                response_time={this.state.resp_time}
                                download_as = {this.props.view}
                            />
                        }
                    </div>
                    {
                        this.state.report_status === 'rejected' &&
                        <div className='tr-report-view-modal-rejection-text'>
                            {this.state.rejection_cause}
                        </div>
                    }
                    {
                        this.state.optComment !== "" &&
                        <div className='tr-report-view-modal-rejection-text'>{this.state.optComment}</div>
                    }
                    <div className='tr-report-view-modal-table-container'>
                        {
                            this.state.nil_transaction && <div className='tr-report-view-nil_transaction-label'>Nil transaction in this report period</div>
                        }   
                        <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={this.state.rows_in_view} exportClicked = {this.state.export_clicked} from={"confirm_report"}/>
                    </div>
                    {
                        this.state.showBrokers &&
                        <div className='tr-report-view-broker-table-label'>{Store.getStoreData("module_config")?.new_broker_confirmation?.new_broker_section_title}</div>
                    }
                    {
                        this.state.showBrokers && 
                        <div className='tr-report-view-modal-table-container'>
                            {
                                this.state.nilBrokers && <div className='tr-report-view-nil_transaction-label'>Nil new broker in this report period</div>
                            }   
                            <CSLTable add={false} processData={this.processDataBrokerTable} zebra={false} tableRows={this.state.brokerRowsInView}/>
                            
                        </div>
                    }
                </div>
                <div className='tr-report-view-modal-footer'>
                    <div className='tr-report-view-modal-footer-button-container'>
                        <button className='tr-report-view-modal-footer-button tr-report-view-save-button' onClick={this.closeModal} >Ok</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TransactionReportViewModal;