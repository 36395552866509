import React from 'react';
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
// import Store from "../../../../../Common/Store";
import APICall from "../../../../../Common/APICall";

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 70%;
	position: absolute;
	z-index: 1001;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 100px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	float: left;
	margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const ClearBoth = styled.div`
	clear: both;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;

const Row = styled.div`
	margin-bottom: 10px;
`;
// const InfoDiv = styled.div`
// 	width: 100%;
// 	background-color: rgb(214 230 243);
// 	border: 1px solid rgb(173 177 181);
// 	border-radius: 3px;
// 	padding: 10px 25px;
// 	box-sizing: border-box;
// `;
const MRModalFooter = styled.div`
	padding: 17px;
	background-color: white;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
  	border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    padding: 10px 20px;
`;

const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
	font-family: 'Montserrat', sans-serif;
`;

const CloseButton = styled.button`
	box-sizing: border-box;
	color: #2d2c2c;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	padding: 10px 20px;
	font-weight: 600;
`;
const MRModalSelect = styled.select`
	display: block;
	box-sizing: border-box;
	height: 40px;
	width: 20%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: "Montserrat", sans-serif;
	color: #000000;
	margin-top: 10px;
	margin-bottom: 10px;
`;

class ManageCounterpartie extends React.Component {
    state = {
        ready: false,
        type:{}
    }
    constructor() {
      super();
      this.api = new APICall();
    }
    componentDidMount(){    
        this.setState({ready: true, type: this.props.type})
    }

    handleChange = (evt) => {      
    	let type = this.state.type;
    	type[evt.target.name]  = evt.target.value
        this.setState(type);
    }

    SaveRole = (event) => {
        event.preventDefault();
		if (this.state.type.name === "") {
			alert("Name must not be left blank");
			return false;
		}
        const postData = { command: "save_lookup"};
        let lookup_json = {};
        lookup_json['name'] = this.state.type["name"];
        postData['lookup_json'] = lookup_json;
        postData['name'] = this.state.type["name"];
        postData['status'] = this.state.type["status"];
        postData['description'] = '';
        postData['lookup_id'] = 'counterparties';
        postData['id'] = this.state.type['id'];
        // console.log("postData", postData)
        this.api.command(postData, this.saveroleTypes);
    };

    saveroleTypes = (result) => {
    	console.log("result", result)
    	let msg = this.state.type["id"] === 0 ? "Counterparty has been created" : "Counterparty has been updated";
    	alert(msg);
    	this.props.refreshParent();
    }

    closeView = () => {
        this.props.closeModal();
    };
    render () {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }	
		return (
			<div>
				<ModalWraper>
	                <MRModalContainer>
	                    <MRModalHeader>
							<MRModalHeaderText>Counterparty</MRModalHeaderText>
							<MRModalHeaderCloseBtn onClick={this.closeView}>
					            <FaTimes />
					        </MRModalHeaderCloseBtn>
					        <ClearBoth />
					        <MRModalSubHeaderText>Complete the required fields to create a new Counterparty</MRModalSubHeaderText>
						</MRModalHeader>
	                    <MRModalBody>
	                        <Row>
	                            <MRModalLabel>Name</MRModalLabel>
	                            <TextBox type="text" value={this.state.type["name"]} name="name" onChange={this.handleChange}/>
	                        </Row>
	                        <Row>
								<MRModalLabel>Status</MRModalLabel>
								<MRModalSelect onChange={this.handleChange} name="status" value={this.state.type['status']}>
								  <option value="1">Active</option>
								  <option value="0">Inactive</option>
								</MRModalSelect>
							</Row>
	                    </MRModalBody>
	                    <MRModalFooter>
	                        <MRModalNextBtn style={{float: "right"}} onClick={this.SaveRole}>SUBMIT</MRModalNextBtn>
	                        <CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeView}>CLOSE</CloseButton>
	                        <ClearBoth />
	                    </MRModalFooter>
	                </MRModalContainer>
	            </ModalWraper>
            </div>
        )
    }
}
export default ManageCounterpartie;