module.exports = {
    role_types: {
      Ul8BSWXINQ: { name: "Director",approver_level:"Approver role"},
      CoiNdA4LTD: { name: "Partner",approver_level:"Approver role"},
      NMNKmINMlJ: { name: "Sales",approver_level:"Approver role"},
      NKC5KMNQEo: { name: "Marketing",approver_level:"Approver role"},
      "349OttAWhi": { name: "Customer Services",approver_level:"Approver role"},
      xFcxiTO8R8: { name: "Finance",approver_level:"Approver role"},
      oJhHowaNlN: { name: "Compliance",approver_level:"Approver role"},
      IkDfISdazY: { name: "Administration",approver_level:"Approver role"},
      XHq7SVkgid: { name: "Senior Manager",approver_level:"Approver role"},
      "2re2PbTcqo": { name: "Operations",approver_level:"Approver role"},
      wjgTUIWeH3: { name: "Manager",approver_level:"Approver role"},
      "6eiekroiaB": { name: "Team Leader",approver_level:"Approver role"},
    },
    
  
    
  };
 