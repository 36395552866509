import React from "react";
import { saveAs } from "file-saver";
import styled from "styled-components";
import { FaTimes, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store";
import Utils from "../../Common/Utils";
// import { ClearBoth, CSLTable, Dropzone } from "@mcc-regtech/core-library";

import ClearBoth from "../Common/ClearBoth";
import moment from "moment";

//import ReassignModal from "../PAD/Modals/ReassignModal";
const Clearfix = styled.div`
  clear: both;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 812px;
  position: absolute;
  top: 38px;
  z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 100px;
  min-width: 750px;
`;

const MRDataContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e7f0f7;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 0px;
`;
const MRAlertContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #f4d4db;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 10px;
`;

const MRSuccessContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e2f0d9;
  // margin-bottom: 10px;
  border: 1px solid #6c9450;
  border-radius: 5px;
  padding: 5px 10px;
`;

const MRTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 92%;
  margin: 0 auto;
  // top: 30px;
  margin-bottom: 10px;
  // border-radius: 5px;
  padding: 5px 10px;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 100px;
  width: calc(100% - 10px);
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ada8;
  border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #143151;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-right: 20px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 200;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: rgb(243, 247, 251);
  padding: 10px;
  border-radius: 4px;
  color: #1a3552;
`;
const MRModalLabel = styled.div`
  color: #1a3552;
  font-weight: 600;
  margin-top: 15px;
  font-size: 15px;
  margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
  position: relative;
  padding: 17px;
  background-color: white;
  height: 80px;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  width: 145px;
  height: 45px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 15px;
  float: right;
  cursor: pointer;
`;
const MRModalNextBtn = styled(MRModalButton)`
  background-color: #37ada7;
  border: 1px solid #37ada7;
`;
const MRModalSaveCloseBtn = styled(MRModalButton)`
  background-color: #143151;
  border: 1px solid #143151;
`;
const MRModalCancelBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #545454;
  border: 2px solid #dadada;
`;
const MRModalTextarea = styled.textarea`
  width: 99%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
`;
const MRFollowUpTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;
const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-top: 30px;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  font-weight: 600;
  color: #1a3552;
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
`;
const HorizontalLine = styled.div`
  width: 100%;
  background-color: black;
  height: 0.5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;
const Row = styled.div`
  margin-bottom: 10px;
`;

class TaskApprovalModal extends React.Component {
  render() {
    return (
      <MRModalContainer>
        <MRModalHeader>
          {/* <MRModalHeaderText>{this.state.Ref_ID} - Approval</MRModalHeaderText> */}
          {/* <MRModalHeaderText>{`PAD-${this.state.task.parenttask.id} - ${this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" ? "Approval" : "Review"}`}</MRModalHeaderText>*/}
          <MRModalHeaderCloseBtn onClick={this.closeView}>
            <FaTimes />
          </MRModalHeaderCloseBtn>
          <ClearBoth />
        </MRModalHeader>

        <MRModalBody>
          <MRAlertContainer>
            <p style={{ color: "#ff6465" }}>
              <b> Approval Reassigned</b>{" "}
            </p>
            <p style={{ color: "#ff6465" }}>This PAD request has been reassigned to Andrew McSorely with the following comments</p>

            <p style={{ color: "#ff6465", paddingLeft: "30px" }}>The Employee requires approval by their Line Manager but no Line Manager is configured</p>
          </MRAlertContainer>

          <MRAlertContainer>
            <p style={{ color: "#ff6465" }}>
              <b>Two Level Approval</b>{" "}
            </p>
            <p style={{ color: "#ff6465" }}>This PAD request requires Line Manager approval but the employee does not have a Line Manager setup</p>
            <p style={{ color: "#ff6465" }}>Please reassign the request to employee's line manager or provide first level approval</p>
            <p style={{ color: "#ff6465" }}>
              <b>Please Note :</b> The same person can not provide first and second level approval
            </p>
          </MRAlertContainer>

          <MRSuccessContainer>
            <p style={{ color: "#6c9451" }}>
              <b>Two Level Approval</b>{" "}
            </p>
            <p style={{ color: "#6c9451" }}>This PAD request has given first level approval</p>
            <p style={{ color: "#6c9451" }}>Reviewer : &nbsp; &nbsp; &nbsp; Andrew McSorely </p>
            <p style={{ color: "#6c9451" }}></p>
            <p style={{ color: "#6c9451" }}></p>
          </MRSuccessContainer>
        </MRModalBody>

        <MRModalFooter>
          <MRModalNextBtn>APPROVE</MRModalNextBtn>
          <MRModalNextBtn>CONFIRM</MRModalNextBtn>
          <MRModalNextBtn style={{ backgroundColor: "#ffffff", color: "#222222", border: "1px solid #c7c2c2" }}>REASSIGN</MRModalNextBtn>
          <MRModalNextBtn style={{ backgroundColor: "#C67878", borderColor: "#C67878" }}>REJECT</MRModalNextBtn>
          <MRModalCancelBtn>Cancel</MRModalCancelBtn>
          <ClearBoth />
        </MRModalFooter>

        {/* {this.state.showModal === "ReassignModal" && <ReassignModal closeModal={this.closeModal} />} */}
      </MRModalContainer>
    );
  }
}

export default TaskApprovalModal;
