import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { FaHome, FaTasks } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { IoIosPaper } from "react-icons/io";
import { TiArrowBack } from "react-icons/ti";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faGear, faSquareList, faFilePen } from "@fortawesome/pro-thin-svg-icons";
import ClearBoth from './ClearBoth';
import Store from '../../Common/Store';
import {Link} from "react-router-dom";

const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #1A3552;
    // color: #929292;

`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #1A3552;
    // color: #929292;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    background-color: white;
    // background-color: #ebebeb;
    color: #c4c4c4;
    width: 23px;
    height: 25px;
    font-size: 21px;
    // font-size: 17px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class CSLHeader extends React.Component{

    static defaultProps = {
        taskHeader: false
    }

    static propTypes = {
        /**  */
        isAdmin: PropTypes.bool,
        /** module name */
        module: PropTypes.string,
        /** */
        headerText: PropTypes.string.isRequired,
        /** returns taskHeader */
        taskHeader: PropTypes.bool,
    };

    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
            let site_layout = JSON.parse(localStorage.getItem('site_layout'));
            use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)
        if(!this.props.taskHeader){
            const role = Store.getStoreData('role')
            console.log(role)
            return (
                <HeadContainer>
                    <Link to={'/'}><HomeText><FontAwesomeIcon icon={faHouseBlank} style={{marginRight: '7px'}} /><span style={{fontWeight: "600"}}>{this.props.headerText}</span></HomeText></Link>
                    { role === 'admin_manager' &&
                        <Categories><Link to={'/moduleconfig'}><FontAwesomeIcon icon={faGear} style={{color: "#848484" }} /></Link></Categories>
                    }
                    { role === 'admin_manager' &&
                        <Categories><a href={process.env.REACT_APP_AUDIT_URL}><FontAwesomeIcon icon={faSquareList} style={{color: "#848484"}} /></a></Categories>
                    }
                    { (role === 'admin_manager' || role === 'padm') &&
                        <Categories><Link to={'/manage_confirmation'}><FontAwesomeIcon icon={faFilePen} style={{color: "#848484" }} /></Link></Categories>
                    }
                    <ClearBoth />
                </HeadContainer>
            );
        } else {
            let config_url = process.env.REACT_APP_TMVIEW_URL
            return (
                <HeadContainer>
                    <HomeLogo><FaTasks /></HomeLogo>
                    <HomeText><span style={{fontWeight: "600"}}>Task Manager: {this.props.headerText} :: Task Viewer</span></HomeText>
                    <Categories><a href={config_url}><TiArrowBack /></a></Categories>
                    <ClearBoth />
                </HeadContainer>
            );
        }
    }
}
export default CSLHeader;
