import React from 'react';
import moment from 'moment';
import './ConfirmationReportModal.css';
//import DatePicker from "react-date-picker";
import { FaTimes, FaCalendarAlt, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import Store from "../../../Common/Store";
import APICall from '../../../Common/APICall';
import CSLTable from '../../Common/CSLTable';
import EllipsisMenu from '../../Common/EllipsisMenu';
import YesNoBox from '../../Common/YesNoBox';
import SpinningLoader from '../../Common/SpinningLoader';
import AlertBox from '../../Common/AlertBox';
import UserCommentModal from './UserCommentModal';

class ConfirmationReportModal extends React.Component{
    state = {
        ready : false,
        alert_param : null,
        contact_id : null,
        reportTitle : null,
        from_date : null,
        to_date :  null,
        pads_to_confirm : [],
        pad_ids_to_confirm : [],
        brokers : [],
        newBrokers : [],
        enable_employee_broker_ac : Store.getStoreData("module_config").general.enable_employee_broker_ac,
        confirm_checked : false,
        brokerConfirmChecked : false,
        show_spinning_loader : false,
        show_rejection_cause_modal : false,
        rows_in_view : 5,
        brokerRowsInView : 5,
        showConfirmDialog: false
    }

    componentDidMount(){
        console.log("This.props ==>", this.props)
        let from_date = this.props.selected_report.transaction.from_date;
        let to_date = this.props.selected_report.transaction.to_date;
        let contact_id = parseInt(this.props.selected_report.transaction.contact_id);
        let reportTitle = this.props.selected_report?.transaction?.report_title
        this.setState({ from_date, to_date, contact_id, reportTitle, show_spinning_loader : true, transaction_to_confirm : this.props.selected_report.transaction})
        this.intializeState(contact_id, from_date, to_date);
    }

    intializeState = async (contact_id, from_date, to_date) => {
        let pads_to_confirm = [];
        let pad_ids_to_confirm = [];
        let company_users = Store.getStoreData("company_users");
        const postData = { command: "list_pads_for_period", requester_id : parseInt(contact_id), from : moment(from_date.toString(),"YYYYMMDD").valueOf(), to : moment(to_date.toString(),"YYYYMMDD").valueOf()};
        const api = new APICall();
        const api_result = await api.commandWithoutCallback(postData);
        console.log("API RES", api_result);
        const module_config = Store.getStoreData('module_config');
        const pads = api_result.result.pads;
        const brokers = api_result.result.broker_list;
        if(pads.length > 0){
            for(let r of pads){
                pad_ids_to_confirm.push(r.pad_id);
                let security = module_config.security_register[r.security_id] !== undefined ? module_config.security_register[r.security_id] : null;
                let security_name = security !== null ? security.security_name : "-";
                let security_identifiers = [];
                let currency = module_config.currencies[security.currency].name;
                for(let keys of Object.keys(security.identifier_types)) security_identifiers.push(security.identifier_types[keys]);
                let data = {
                    pad_id : r.pad_id,
                    trade_date : r.trade_date,
                    transaction_type : module_config.instruction_types[r.transaction_type] !== undefined ? module_config.instruction_types[r.transaction_type].name : "-",
                    security_name : security_name,
                    security_id : security_identifiers.length > 0 ? security_identifiers.join(', ') : "-",
                    volume : r.volume !== null && r.volume !== undefined ? r.volume : "-",
                    price : r.price !== null && r.price !== undefined ? r.price : "-",
                    currency : currency !== undefined ? currency : "-",
                    amount : r.amount !== null && r.amount !== undefined ? r.amount : "-",
                    broker : r.broker_id !== null ? brokers.find(item => item.id === parseInt(r.broker_id))!== undefined ? brokers.find(item => item.id === parseInt(r.broker_id)).broker_name : "-" : "-"
                }
                pads_to_confirm.push(data);
                
            }
        }

        let newBrokers = [];
        newBrokers = brokers?.filter(item => item?.ac_opening_date >= moment(from_date.toString(),"YYYYMMDD").unix() && item?.ac_opening_date <= moment(to_date.toString(),"YYYYMMDD").unix());

        let rows_in_view = 5;
        if( pads_to_confirm.length > 5 ) rows_in_view = pads_to_confirm.length <20 ? pads_to_confirm.length : 20;

        let brokerRowsInView = 5;
        if( newBrokers?.length > 5 ) brokerRowsInView = newBrokers?.length <20 ? newBrokers?.length : 20;

        this.setState({ pad_ids_to_confirm, pads_to_confirm, brokers, newBrokers, show_spinning_loader : false, rows_in_view, brokerRowsInView, ready : true});
    }

    toggleCheck = (e) => {
        e.preventDefault();
        this.setState({ confirm_checked : !this.state.confirm_checked });
    } 

    toggleCheckBroker = (e) => {
        e.preventDefault();
        this.setState({ brokerConfirmChecked : !this.state.brokerConfirmChecked });
    }

    closeModal = (e) =>{
        e.preventDefault();
        this.props.closeModal();
    }

    showConfirmationDialog = (e) => {
        e.preventDefault();
        this.setState({showConfirmDialog: true});
    }

    closeConfirmationDialog = () => {
        this.setState({showConfirmDialog: false})
    }

    showRejectionCauseModal = (e) =>{
        e.preventDefault();
        this.setState({ show_rejection_cause_modal : true });
    }

    closeRejectionCauseModal = (e) =>{
        this.setState({ show_rejection_cause_modal : false });
    }

    confirmRejectTransactions = async (userInput, action) => {
        let alertParam = null;
        this.setState({ show_spinning_loader : true })
        let transaction = this.state.transaction_to_confirm;
        let padsInvolved = this.state.pads_to_confirm;
        let actionData = transaction?.action_data;
        actionData.push({
            "action": action === "pad_confirm" ? "PAD_CON_CONFIRMED" : "PAD_CON_REJECTED",
            "actiondate": moment().valueOf(),
            "cur_lane": action === "pad_confirm" ? "PAD_CON_CONFIRMED" : "PAD_CON_REJECTED",
            "performed_by": this.state.contact_id
        })
        const postData = { 
            command : 'confirm_reject_transaction_report', 
            involved_pad_transactions : padsInvolved,
            involved_pad_ids : this.state.pad_ids_to_confirm,
            resp_date : parseInt(moment(moment().format("DD/MM/YYYY"),"DD/MM/YYYY").format("YYYYMMDD")),
            resp_time : moment().format('HH:mm:ss'),
            report_id : transaction.id,
            //rejection_cause : rej_cause.trim(),
            action : action,
            action_data : actionData
        }

        if(action === 'pad_confirm'){
            postData["optional_comments"] = userInput?.trim();
        }else{
            postData["rejection_cause"] = userInput?.trim();
        }

        if(this.state.enable_employee_broker_ac === true){
            let newBrokersToConfirm = this.state.newBrokers;
            let involvedNewBrokerIds = [];
            for(let b of this.state.newBrokers) {
                involvedNewBrokerIds.push(b.id);
            }
            postData["involved_brokers"] = newBrokersToConfirm;
            postData["involved_broker_ids"] = involvedNewBrokerIds;
        }

        const api = new APICall();
        const apiResult = await api.commandWithoutCallback(postData);
        
        if(apiResult?.error_code !== 0){
            alertParam = {
                title: 'Error', message: `Something went wrong. Try again after sometime`, ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlertSmall, stack: {}
            }
            this.setState({ alert_param : alertParam, showConfirmDialog : false, show_rejection_cause_modal : false, show_spinning_loader : false });
        }else{
	        this.setState({showConfirmDialog: false, show_rejection_cause_modal : false});
            this.props.closeModal('reload');
        }
    }

    closeAlertSmall = (value, stack) => {
        this.setState({ alert_param : null});
    }

    processDataTable = () => {
        const ret = { data: [], columns: [] };
        const actions = [{name: "View", action: "view"}];
        let table_data = [];
        //debugger;
        ret.columns = [
            { Header: "Trade Date", accessor: "trade_date", width: !this.state.enable_employee_broker_ac ? 130 : 100, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Transaction", accessor: "transaction_type", width: !this.state.enable_employee_broker_ac ? 130 : 100, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Security Name", accessor: "security_name", width: !this.state.enable_employee_broker_ac ? 220 : 180, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Security ID", accessor: "security_id", width: !this.state.enable_employee_broker_ac ? 250 : 220, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Volume", accessor: "volume", width: !this.state.enable_employee_broker_ac ? 130 : 110, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Price", accessor: "price", width: !this.state.enable_employee_broker_ac ? 130 : 110, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } },
            { Header: "Amount", accessor: "amount", width: !this.state.enable_employee_broker_ac ? 170 : 150, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        if(this.state.enable_employee_broker_ac){
            ret.columns.push({ Header: "Broker", accessor: "broker", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" }})
        }
        ret.columns.push({ Header: "PAD ID", accessor: "pad_id", width: !this.state.enable_employee_broker_ac ? 140 : 135, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } })
        for(let r of this.state.pads_to_confirm){
            let data = {
                pad_id : "PAD-" + r.pad_id,
                trade_date : r.trade_date,
                transaction_type : r.transaction_type,
                security_name : r.security_name,
                security_id : r.security_id,
                volume : r.volume,
                price : r.price,
                amount : r.amount,
                broker : r.broker
            }
            table_data.push(data);
        }
        // table_data.sort((a, b) => {
        //     let a1 = a.contact_name;
		// 	let b1 = b.contact_name;
        //     if (a1 < b1) return -1
        //     if (a1 > b1) return 1
        //     return 0
        // })
        ret.data = table_data;
        return ret;
    }

    processDataBrokerTable = () => {
        const ret = { data: [], columns: [] };
        let tableData = [];
        ret.columns = [
            { Header: "Name of Broker/Dealer or Bank", accessor: "brokerName", width: 550, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }},
            { Header: "Account Title (Your Reference)", accessor: "accountName", width: 420, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Date Account Opened", accessor: "acOpeningDate", width: 330, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign : "right" } }
        ]
        for(let r of this.state.newBrokers){
            let data = {
                id : r.id,
                brokerName : r?.broker_name,
                accountName : r?.account_name,
                acOpeningDate : moment.unix(r.ac_opening_date).format('DD/MM/YYYY')
            }
            tableData.push(data);
        }
        ret.data = tableData;
        return ret;
    }

    render(){
        if(this.state.ready !== true) return(<SpinningLoader />);
        return(
            <>
            <div className='confirmation-report-modal-wrapper'>
                {/* {this.state.alertParam !== null && this.state.show_alert === true && <YesNoBox alertParam={this.state.alertParam}/>} */}
                {this.state.show_spinning_loader === true && <SpinningLoader />}
                <div className='confirmation-report-modal-header'>
                    <div className='confirmation-report-modal-title-wrapper'>
                        <div className='confirmation-report-modal-heading'>
                            { this.state.reportTitle === null ? Store.getStoreData("module_config")?.pad_confirmation?.report_title : this.state.reportTitle}
                        </div>
                        <div className='confirmation-report-modal-subheading'>
                            {this.props.view === 'admin' ? (Store.getStoreData("company_users").find(item => parseInt(item.user_id) === this.state.contact_id)).name + " - " : ""}
                            {moment(moment(this.state.from_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY") + " "} 
                            to 
                            {" " + moment(moment(this.state.to_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    <div className='confirmation-report-modal-close'>
                        <FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/>
                    </div>
                </div>
                <div className='confirmation-report-modal-body'>
                    {
                        this.props.view === 'requester' && 
                        <div className='confirmation-report-modal-help-text'>
                            {Store.getStoreData("module_config").pad_confirmation.assignee_help_text}
                        </div>
                    }
                    <div className='confirmation-report-modal-table-container'>
                        <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={this.state.rows_in_view} exportClicked = {this.state.export_clicked} from={"confirm_report"}/>
                    </div>
                    {
                        this.props.view === 'requester' && 
                        <div className='confirmation-report-modal-confirmation-text-container'>
                            {
                                (()=>{
                                
                                    if(this.state.confirm_checked === false) return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheck}><FaRegSquare /></div>;
                                    return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheck}><FaRegCheckSquare /></div>;            
                                    
                                })()
                            }
                            {
                                (()=>{
                                
                                    if(this.state.pads_to_confirm.length > 0) return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config").pad_confirmation.attestation_text}</div>;
                                    return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config").pad_confirmation.nil_transactions_text}</div>;            
                                    
                                })()
                            }
                        </div>
                    }
                    {
                        this.state.enable_employee_broker_ac &&
                        <div className='tr-report-view-broker-table-label'>{Store.getStoreData("module_config")?.new_broker_confirmation?.new_broker_section_title}</div>
                    }
                    {
                        this.state.enable_employee_broker_ac &&
                        <div className='confirmation-report-modal-table-container'>
                            <CSLTable add={false} processData={this.processDataBrokerTable} zebra={false} tableRows={this.state.brokerRowsInView}/>
                        </div>
                    }
                    {
                        this.props.view === 'requester' && this.state.enable_employee_broker_ac &&
                        <div className='confirmation-report-modal-confirmation-text-container'>
                            {
                                (()=>{
                                
                                    if(this.state.brokerConfirmChecked === false){ 
                                        return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheckBroker}><FaRegSquare /></div>;
                                    }
                                    return <div className='confirmation-report-modal-confirmation-checkbox' onClick={this.toggleCheckBroker}><FaRegCheckSquare /></div>;            
                                    
                                })()
                            }
                            {
                                (()=>{
                                
                                    if(this.state.newBrokers?.length > 0){ 
                                        return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config")?.new_broker_confirmation?.new_broker_attestation_text}</div>;
                                    }
                                    return <div className='confirmation-report-modal-confirmation-text'>{Store.getStoreData("module_config")?.new_broker_confirmation?.nil_new_brokers_text}</div>;            
                                    
                                })()
                            }
                        </div>
                    }
                </div>
                
                <div className='confirmation-report-modal-footer'>
                    <div className='confirmation-report-modal-footer-button-container'>
                        {
                            this.props.view === 'admin' && <button className='confirmation-report-modal-footer-button confirmation-report-close-button' onClick={this.closeModal} >Close</button>
                        }
                        {
                            this.props.view === 'requester' && <button className='confirmation-report-modal-footer-button confirmation-report-close-button' onClick={this.closeModal} >CANCEL</button>
                        }
                        <div className='confirmation-report-modal-footer-button-subcontainer'>
                            {
                                this.props.view === 'requester' && <button className='confirmation-report-modal-footer-button confirmation-report-reject-button' onClick={this.showRejectionCauseModal}>REJECT</button>
                            }
                            {   
                                (()=>{
                                    if(this.props.view === 'requester' && this.state.confirm_checked === true && ((this.state.enable_employee_broker_ac === true && this.state.brokerConfirmChecked === true) || this.state.enable_employee_broker_ac === false)){
                                        return <button className='confirmation-report-modal-footer-button confirmation-report-save-button' onClick={this.showConfirmationDialog}>CONFIRM</button>
                                    }else if(this.props.view === 'requester' && (this.state.confirm_checked === false || (this.state.enable_employee_broker_ac === true && this.state.brokerConfirmChecked === false))){
                                        return <button className='confirmation-report-modal-footer-inactive-button'>CONFIRM</button>
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.show_rejection_cause_modal && 
                    <div className='tr-rejection-cause-modal-overlay'>
                        <UserCommentModal action={"pad_reject"} reportTitle={this.state.reportTitle} from_date={this.state.from_date} to_date={this.state.to_date} closeModal={this.closeRejectionCauseModal} confirmReject={this.confirmRejectTransactions}/>
                    </div>
                }
                {
                    this.state.showConfirmDialog === true &&
                    <div className='tr-rejection-cause-modal-overlay'>
                        <UserCommentModal action={"pad_confirm"} closeModal={this.closeConfirmationDialog} confirmReject={this.confirmRejectTransactions} />
                    </div>
                }
            </div>
            <AlertBox alertParam={this.state.alert_param} />
            </>
        )
    }
}

export default ConfirmationReportModal;