import React from "react";
// import styled from "styled-components";
import Store from "../../Common/Store";
import OutsideClickHandler from "react-outside-click-handler";

class PadMenu extends React.Component {
  handleOutsideClick = () => {
    console.log("clicked outside");
    this.props.closePadMenu();
  };
  showHoldingModal = (event) => {
    event.preventDefault();
    this.props.showHoldingModal();
  };
  showBrokerList = (event) => {
    event.preventDefault();
    this.props.showBrokerList();
  }
  render() {
    let module_config_url = "/moduleconfig"
    let confirmation_url = "/confirmation"
    let enable_emp_broker_ac = Store.getStoreData("module_config").general.enable_employee_broker_ac;
    let selected_tran_confirmation_frequency = Store.getStoreData("module_config").pad_confirmation.selected_confirmation_frequency;
    //console.log("enable_emp_broker_ac : ", Store.getStoreData("module_config"));
    let role = Store.getStoreData("role");
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div style={{ top: "50px", right: "20px", position: "absolute", backgroundColor: "#ffffff", padding: "15px 15px 0px 15px", boxShadow: "rgb(167 166 166) 0px 0px 5px", zIndex: "1" }}>
          <div style={{ paddingBottom: "15px", color: "#1A3552", cursor: "pointer", fontWeight: "600" }} onClick={this.showHoldingModal}>
            Holdings List
          </div>
          <div style={{ paddingBottom: "15px", color: "#1A3552", cursor: "pointer", fontWeight: "600" }}>
            <a style={{ textDecoration: "none", color: "#1A3552" }} href={module_config_url}>
              Module Configuration
            </a>
          </div>
          {
            enable_emp_broker_ac === true &&
            <div style={{ paddingBottom: "15px", color: "#1A3552", cursor: "pointer", fontWeight: "600" }} onClick={this.showBrokerList}>
              Manage Brokers
            </div>
          }
          {
            selected_tran_confirmation_frequency !== 'none' &&
            <div style={{ paddingBottom: "15px", color: "#1A3552", cursor: "pointer", fontWeight: "600" }}>
              <a style={{ textDecoration: "none", color: "#1A3552" }} href={confirmation_url}>
                My PAD Reports
              </a>
            </div>
          }
        </div>
      </OutsideClickHandler>
    );
  }
}
export default PadMenu;
