import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

// import { CustomFields, Email } from "@mcc-regtech/core-library";
import CustomFields from './partials/CustomFields';
import Email from './partials/Email';
import General from "./General";
import HotLookup from "./HotLookup";
// import ConfigTypes from "./ConfigTypes";
// import ConfigSecurityRegister from "./ConfigSecurityRegister";
// import ConfigSecurityRegisterLists from "./ConfigSecurityRegisterLists";

import Utils from "../../../Common/Utils";
import Store from "../../../Common/Store";
import APICall from "../../../Common/APICall";
import CSLLoader from "../../Common/CSLLoader";

// new development
import Sla from "./Sla";
import RoleTypes from "./include/RoleTypes";
import InstructionTypes from "./include/InstructionTypes";
import TransactionTypes from "./include/TransactionTypes";
import SecurityTypes from "./include/SecurityTypes";
import SecurityidentityTypes from "./include/SecurityidentityTypes";
import Currencies from "./include/Currencies";
import Counterparties from "./include/Counterparties";
import Beneficiaries from "./include/Beneficiaries";
import SecurityRegisterLists from "./include/SecurityRegisterLists";
import PADConfirmation from "./include/PADConfirmation";
import SecurityRestrictedRegisters from "./include/SecurityRestrictedRegisters";
import ManageRestrictedLists from "./include/ManageRestrictedLists";
import ApprovalRulesTable from "./include/ApprovalRulesTable";
import Reload from '../../../Common/Reload.js';
import YesNoBox from "../../Common/YesNoBox";
//import SpinningLoaderSmall from "../../Common/SpinningLoaderSmall";


const ReviewBodyContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;
const ReviewLeftContainer = styled.div`
  display: inline-table;
  overflow: hidden;
  text-overflow: ellipsis;

  position: sticky;
  top: 2px;
  float: left;
  padding-right: 20px;
  width: 20%;
  box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
  float: right;
  padding-left: 20px;
  width: 80%;
  box-sizing: border-box;
`;
const ReviewTab = styled.div`
  background-color: #ffffff;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 10px 20px;
  cursor: pointer;
`;
const CloseBtn = styled.div`
  float: right;
  font-size: 20px;
  color: #9c9c9c;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 90%;
`;
const CancelBtn = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #333333;
  cursor: pointer;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;
const ErrorBar = styled.div`
  background-color: #f6dbdb;
  color: #de8d8e;
  font-weight: 600;
  padding: 10px 20px;
`;
const LeftLabel = styled.div`
  float: left;
`;
const RightArrow = styled.div`
  float: right;
  color: #39b3ad;
  margin-top: 2px;
  padding-left: 3px;
`;
const ClearBoth = styled.div`
  clear: both;
`;



class ModuleConfigHOC extends React.Component {
  state = {
    active_section_id: "general",
    dirty: false,
    permissions: {},
    module_config: null,
    is_debug: Store.getStoreData("is_debug"),
    debug_port: Store.getStoreData("debug_port"),
    all_company_users: null,
    changed_user_roles: {},
    requester: "",
    section_name: "",
    transaction_types: {},
    instruction_types: {},

    security_register: {},
    restricted_list: {},

    security_types: {},
    security_identifier_type: {},
    currencies_types: {},
    counterparties_types: {},
    beneficiaries: {},

    role_types: {},
    ready: false,
    roles: null,
    alertParam : null,
  };

  constructor(props) {
    super(props);
    // this.callApi = this.callApi.bind(this);
    this.api = new APICall();
  }

  componentDidMount() {
    //console.log("componentDidMount");
    //await this.callApi();
    if (Store.getStoreData("role") === "admin_manager") {
      const postData = { command: "list_company_and_users" };
      this.api.command(postData, this.getInitialPermission);
    }
  }

  componentDidUpdate(prevprops) {
    //console.log("componentDidUpdate");
    if (this.props !== prevprops) {
      //console.log("componentDidUpdate --- prevprops");
      // await this.callApi();
    }
  }
  // async callApi() {
  //   console.log("callApi");
  //   if (Store.getStoreData("role") === "admin_manager") {
  //     const postData = { command: "list_company_and_users" };
  //     this.api.command(postData, this.getInitialPermission);
  //   }
  // }

  getInitialPermission = (result) => {
    //console.log("getInitialPermission");
    console.log("result ===>", result);
    console.log("company_users:", Store.getStoreData("company_users"));
    let requester = this.props.match ? this.props.match.params.requester : "";
    let permissions = {
      roles: result.roles,
      company_users: Store.getStoreData("company_users")
    };
    let all_company_users = JSON.parse(JSON.stringify(permissions.company_users));
    //console.log("m_config ////", result.moduleconfig);

    let module_config = result.moduleconfig;
    //module_config = this.loadDefaultConfig(module_config);
    //module_config["sla"] = {};
    this.setState({
      permissions: permissions,
      module_config: module_config,
      requester: requester,
      all_company_users: all_company_users,
      role: Store.getStoreData("role")
    });
    //this.setState({ role_types: result.moduleconfig.role_types, transaction_types: result.moduleconfig.transaction_types, instruction_types: result.moduleconfig.instruction_types,beneficiaries_types:init_obj_instrtype result.moduleconfig.beneficiaries,counterparties_types:result.moduleconfig.counterparties,currencies_types:init_obj_instrtype });
    //this.setState({ permissions: permissions, module_config: module_config, requester, all_company_users: all_company_users, role: result.role });
  };

  returnSection = (section) => (event) => {
    event.preventDefault();
    //alert(event)
    // if (Store.getStoreData("role") === "admin_manager") {
    //   const postData = { command: "list_company_and_users" };
    //   this.api.command(postData, this.getInitialPermission);
    // }
    this.setState({ active_section_id: section, active_section_not_ready : true });
    //pp this.forceUpdate();
  };

  changeActiveInactive = (obj) => {
    let { module_config } = this.state;
    module_config.general.reason_active_inactive = obj;
    const postData = { command: "save_module_config", module_config: module_config };
    this.api.command(postData, this.afterUpdateEmailRemainder);
  };

  afterUpdate = (result) => {
    alert(result.error_msg);
    this.setState({ module_config: result.result });
    // window.location.reload();
  };

  afterRoleUpdate = (result) => {
    alert(result.error_msg);
  };

  updateGeneral = (obj) => {
    let { module_config } = this.state;
    // console.log("old_config", this.state);
    // const old_config = { ...module_config };
    // console.log("old_config", old_config);
    module_config.general = obj;
    // const new_config = { ...module_config };
    // console.log("new_config", new_config);
    // if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
    //   dirty = true;
    // }
    //console.log("module_config", module_config);
    //console.log("module_config /// Gen", obj);
    this.setState({ module_config });
  };

  updateSla = (obj) => {
    let module_config = JSON.parse(JSON.stringify(this.state.module_config));
    // const old_config = { ...module_config };
    module_config.sla = obj;
    //console.log(obj);
    // const new_config = { ...module_config };

    // if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
    //   dirty = true;
    // }
    //console.log("module_config", module_config);
    //console.log("module_config /// Gen", obj);
    this.setState({ module_config });
    // const postData = { command: "save_module_config", module_config: module_config };
    // this.api.command(postData, this.afterUpdateEmailRemainder);
  };

  updatePADConfirmation = (tranObj, holdObj, brokerObj, newBrokerObj) => {
    let module_config = JSON.parse(JSON.stringify(this.state.module_config));
    module_config.pad_confirmation = tranObj;
    module_config.holding_confirmation = holdObj;
    module_config.broker_confirmation = brokerObj;
    module_config.new_broker_confirmation = newBrokerObj;
    this.setState({ module_config });
  };

  updateEmail = (obj) => {
    let { module_config } = this.state;
    module_config.email = obj;
    const postData = { command: "save_module_config", module_config: module_config };
    this.api.command(postData, this.afterUpdateEmailRemainder);
  };

  updateEmailRemainder = (obj) => {
    let { module_config } = this.state;
    module_config.general = obj;
    const postData = { command: "save_module_config", module_config: module_config };
    this.api.command(postData, this.afterUpdateEmailRemainder);
  };

  afterUpdateEmailRemainder = (result) => {
    this.setState({ module_config: result.result });
  };

  updateUserRoleValue = (obj) => {
    console.log("object",obj);
    let {module_config} = this.state;
    module_config.users_role_type = obj;
    const postData = {command: "save_module_config", module_config: module_config};
    const api = new APICall();
    api.command(postData, this.afterUpdateUserRoleValue);
  }
  afterUpdateUserRoleValue = (result) => {
      console.log(result);
      //alert("Distribution List updated successfully")
      this.setState({module_config: result.result});
  }

  updateCustomFields = (obj) => {
    let module_config = JSON.parse(JSON.stringify(this.state.module_config));
    module_config.custom_fields = obj;
    // module_config.custom_fields = module_config.custom_fields.enabled ? module_config.custom_fields : this.state.initial_custom_fields;
    this.setState({ module_config });
    // const postData = {command: "save_module_config", module_config: module_config};
    // this.api.command(postData);
  };

  // updatePermissions = (obj) => {
  //   console.log("obj:", obj);
  //   const postData = {command: "save_user_role", user_id: parseInt(obj.user_id), role: obj.role};
  //   this.api.command(postData, this.afterSaveRole);
  // };

  updatePermissions = (obj) => {
    console.log("permissions:", obj);
    // console.log("single_obj:", single_obj);
    let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
    changed_user_roles[obj.user_id.toString()] = obj.role;
    let permissions = JSON.parse(JSON.stringify(this.state.permissions));
    permissions.company_users.forEach(user => {
      if (parseInt(user.user_id) === parseInt(obj.user_id)) {
        user.role = obj.role;
      }
    })
    this.setState({permissions: permissions, all_company_users: permissions.company_users, changed_user_roles});
  }

  // afterSaveRole = (result) => {
  //   console.log(result);
  //   let permissions = JSON.parse(JSON.stringify(this.state.permissions));
  //   permissions.company_users.forEach(user => {
  //     if (parseInt(user.user_id) === parseInt(result.result.user_id)) {
  //       user.role = result.result.role;
  //     }
  //   })
  //   this.setState({permissions: permissions, all_company_users: permissions.company_users});
  // }

  searchUsers = (search_string) => {
    if (search_string === "") {
      let permissions = JSON.parse(JSON.stringify(this.state.permissions));
      permissions.company_users = this.state.all_company_users;
      this.setState({ permissions });
    } else {
      let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
      let filtered_company_users = [];
      all_company_users.forEach((user) => {
        if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
          filtered_company_users.push(user);
        }
      });
      let permissions = JSON.parse(JSON.stringify(this.state.permissions));
      permissions.company_users = filtered_company_users;
      this.setState({ permissions });
    }
  };

  changeAllRoles = (role) => {
    let permissions = JSON.parse(JSON.stringify(this.state.permissions));
    if (role !== "0")
      permissions.company_users.forEach((user) => {
        user.role = role;
      });
    else permissions.company_users = this.state.all_company_users;

    let changed_user_roles = {};
    permissions.company_users.forEach((user) => {
      changed_user_roles[user.user_id] = user.role;
    });
    this.setState({ permissions, changed_user_roles });
  };

  updateModuleConfig = (event) => {
    event.preventDefault();
    let { module_config, changed_user_roles } = this.state;
    if(this.state.active_section_id === "hot_lookup" && module_config.general.security_min_search_chars < 4 && module_config.general.security_hot_lookup.enabled === true){
      let alertParam = {
          "header" : "Minimum Letter Search Setting",
          "msg" : "When using Third Party Security engines, it is recommended that you search only after 4 or 5 letters have been entered by the employee. This reduces the number of calls made to the service which will optimise the search and may reduce costs.<br><br>Would you like to set this value now?",
          "closefunc" : this.closeYesNO,
          "buttons" : {
            "1" : {
              "caption" : "I'll check later",
              "bgcolor" : "#4472C4",
              "color" : "white",
              "callback" : this.closeYesNO
            },
            "2" : {
              "caption" : "Yes, please",
              "bgcolor" : "#203864",
              "color" : "white",
              "callback" : this.setDefaultSearchChars
            }
          }
			}
      this.setState({ alertParam });
    } else{
        let postData = { command: "save_module_config", module_config: module_config };
        this.api.command(postData, this.afterUpdate);
    }
    // console.log("module_config:", module_config);
    // return false;

  };

  closeYesNO = () => {
		console.log("Yes No Closed");
		this.setState({alertParam: null});
    let { module_config, changed_user_roles } = this.state;
    let postData = { command: "save_module_config", module_config: module_config };
    this.api.command(postData, this.afterUpdate);
	}

  // closeSpinner = () => {
  //   this.setState({active_section_not_ready : false});
  // }

  setDefaultSearchChars = () => {
		let module_config = JSON.parse(JSON.stringify(this.state.module_config));
    module_config.general.security_min_search_chars = 5;
    let postData = { command: "save_module_config", module_config: module_config };
    this.api.command(postData, this.afterUpdate);
    this.setState({alertParam: null});
	}

  closePage = (event) => {
    event.preventDefault();
    let url = "/"
    if (this.state.requester !== "") {
      url = '/'
    }
    window.location.replace(url);
  };



  updateConfigTypes = (clientTypes) => {
    let { module_config, active_section_id } = this.state;
    module_config[active_section_id] = clientTypes;
    const postData = { command: "save_module_config", module_config };
    this.api.command(postData);
  };
  modifySecurityRegister = (secRegData, curid) => {
    let module_config = JSON.parse(JSON.stringify(this.state.module_config));
    if (curid === 0) {
      module_config.security_register[Utils.genKey(10)] = secRegData;
    } else {
      module_config.security_register[curid] = secRegData;
    }
    const postData = { command: "save_module_config", module_config };
    let api = new APICall();
    api.command(postData, this.afterSecurityRegister);
  };

  afterSecurityRegister = (result) => {
    console.log(result);
    this.setState({module_config: result.result, security_register: result.result.security_register});
  }

  updateTableCurData = (data) => Store.updateStore("table_cur_data", data);

  exportToCSV = (event) => {
    event.preventDefault();

    const cur_table_data = Object.values(this.state.active_section_id.includes("checklist") ? this.state.checklist_items : this.state[this.state.active_section_id]);

    const type = this.state.active_section_id.toUpperCase();
    const headerCSV = { title: type, status: "STATUS", deleted: "DELETED" };
    const cur_table_data_change = [headerCSV];

    for (let i = 0; i < cur_table_data.length; i++) {
      cur_table_data_change.push({
        title: Utils.csvStringEncoder(cur_table_data[i].title),
        status: cur_table_data[i].isActive ? "Active" : "Inactive",
        deleted: cur_table_data[i].isDelete ? "DELETED" : "",
      });
    }

    const cur_table_arr = cur_table_data_change
      .map((obj) => Object.values(obj))
      .map((e) => e.join(","))
      .join("\n");

    const csvContent = "data:text/csv;charset=utf-8," + cur_table_arr;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", `${type}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  // render () {
  //   return (<div>Loading...</div>)
  // }

  render() {
    if (Store.getStoreData("role") !== "admin_manager") {
      return (
        <ErrorBar>
          <div style={{ float: "left" }}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
          <div style={{ float: "right", fontSize: "16px", cursor: "pointer" }} onClick={this.closePage}>
            x
          </div>
          <ClearBoth />
        </ErrorBar>
      );
    }

    if (this.state.module_config === null)
      return (
        <div>
          <CSLLoader />
        </div>
      );

      // if(this.state.alertParam !== null){
      //   return (
      //     <div>
      //       <YesNoBox alertParam={this.state.alertParam} />
      //     </div>
      //   )
      // }


    return (
      <div style={{ padding: "10px 10px 20px 10px" }}>
        <CloseBtn onClick={this.closePage}>
          <FaTimes />
        </CloseBtn>
        <ClearBoth />
        <ReviewBodyContainer>
          <ReviewLeftContainer>
            <ReviewTab onClick={this.returnSection("general")} style={{ marginTop: "2px" }}>
              {(() => {
                if (this.state.active_section_id === "general") {
                  return <div style={{ float: "left", fontWeight: "600" }}>General</div>;
                } else {
                  return <div style={{ float: "left" }}>General</div>;
                }
              })()}
              <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                <IoIosArrowForward />
              </div>
              <div style={{ clear: "both" }}></div>
            </ReviewTab>

            <ReviewTab onClick={this.returnSection("sla")} style={{ marginTop: "2px" }}>
              {(() => {
                if (this.state.active_section_id === "sla") {
                  return <div style={{ float: "left", fontWeight: "600" }}>SLAs</div>;
                } else {
                  return <div style={{ float: "left" }}>SLAs</div>;
                }
              })()}
              <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                <IoIosArrowForward />
              </div>
              <div style={{ clear: "both" }}></div>
            </ReviewTab>

            <ReviewTab onClick={this.returnSection("email")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "email" ? { fontWeight: "600" } : {}}>Email</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("custom_fields")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "custom_fields" ? { fontWeight: "600" } : {}}>Custom Fields</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("role_types")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "role_types" ? { fontWeight: "600" } : {}}>Role Types</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("instruction_types")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "instruction_types" ? { fontWeight: "600" } : {}}>Instruction Types</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            {/* <ReviewTab onClick={this.returnSection("transaction_types")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "transaction_types" ? { fontWeight: "600" } : {}}>Transaction Types</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab> */}

            <ReviewTab onClick={this.returnSection("security")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "security" ? { fontWeight: "600" } : {}}>Security Types</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("security_identifier_types")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "security_identifier_types" ? { fontWeight: "600" } : {}}>Security Identifier Type</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("security_register")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "security_register" ? { fontWeight: "600" } : {}}>Security Register</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("hot_lookup")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "hot_lookup" ? { fontWeight: "600" } : {}}>Security "Hot Lookup" services</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("restricted_list")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "restricted_list" ? { fontWeight: "600" } : {}}>Restricted List</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("currencies")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "currencies" ? { fontWeight: "600" } : {}}>Currencies</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>

            {/* <ReviewTab onClick={this.returnSection("counterparties")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "counterparties" ? { fontWeight: "600" } : {}}>Counterparties</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab> */}
            <ReviewTab onClick={this.returnSection("beneficiaries")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "beneficiaries" ? { fontWeight: "600" } : {}}>Beneficiaries</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
            <ReviewTab onClick={this.returnSection("pad_reports")} style={{ marginTop: "2px" }}>
              <LeftLabel style={this.state.active_section_id === "pad_reports" ? { fontWeight: "600" } : {}}>PAD Reports</LeftLabel>
              <RightArrow>
                <IoIosArrowForward />
              </RightArrow>
              <ClearBoth />
            </ReviewTab>
          </ReviewLeftContainer>
          <ReviewRightContainer>

            {this.state.active_section_id === "general" && <General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />}
            {this.state.active_section_id === "sla" && <Sla sla={JSON.parse(JSON.stringify(this.state.module_config.sla))} updateSla={this.updateSla} />}
            {
              this.state.active_section_id === "pad_reports" && 
              <PADConfirmation 
                pad_confirmation={JSON.parse(JSON.stringify(this.state.module_config.pad_confirmation))} 
                holding_confirmation={JSON.parse(JSON.stringify(this.state.module_config.holding_confirmation))} 
                broker_confirmation={JSON.parse(JSON.stringify(this.state.module_config.broker_confirmation))} 
                new_broker_confirmation={JSON.parse(JSON.stringify(this.state.module_config.new_broker_confirmation))} 
                updatePADConfirmation={this.updatePADConfirmation} 
              />
            }
            {this.state.active_section_id === "email" && <Email email={JSON.parse(JSON.stringify(this.state.module_config.email))} variables={this.state.module_config.variables} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} updateEmail={this.updateEmail} />}
            {this.state.active_section_id === "custom_fields" && <CustomFields custom_fields={this.state.module_config.custom_fields} updateCustomFields={this.updateCustomFields} closePage={this.closePageCustomField} />}
            {/*new implementation*/}
            {this.state.active_section_id === "role_types" && <RoleTypes active_section_id={this.state.active_section_id} sectionName="Role Types" lookup_id = "role_types" users_role_type={JSON.parse(JSON.stringify(this.state.module_config.users_role_type))} updateUserRoleValue={this.updateUserRoleValue}/>}
            {this.state.active_section_id === "instruction_types" && <InstructionTypes active_section_id={this.state.active_section_id} sectionName="Instruction Type" lookup_id = "instruction_types"/>}
            {/* {this.state.active_section_id === "transaction_types" && <TransactionTypes active_section_id={this.state.active_section_id} sectionName="Transaction Type" lookup_id = "transaction_types"/>} */}
            {this.state.active_section_id === "security" && <SecurityTypes active_section_id={this.state.active_section_id} sectionName="Security Types" lookup_id = "security_types"/>}
            {this.state.active_section_id === "security_identifier_types" && <SecurityidentityTypes active_section_id={this.state.active_section_id} sectionName="Security Identifier Type" lookup_id = "security_identifier_types"/>}
            {this.state.active_section_id === "currencies" && <Currencies active_section_id={this.state.active_section_id} sectionName="Currencies" lookup_id = "currencies"/>}
            {/* {this.state.active_section_id === "counterparties" && <Counterparties active_section_id={this.state.active_section_id} sectionName="Counterparties" lookup_id = "counterparties"/>} */}
            {this.state.active_section_id === "beneficiaries" && <Beneficiaries active_section_id={this.state.active_section_id} sectionName="Beneficiaries" lookup_id = "beneficiaries"/>}


            {this.state.active_section_id === "security_register" && <SecurityRegisterLists active_section_id={this.state.active_section_id} sectionName="Security Register" lookup_id = "security_register" general={this.state.module_config.general} />}
            {this.state.active_section_id === "hot_lookup" && <HotLookup general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />}
            {this.state.active_section_id === "restricted_list" && <ManageRestrictedLists active_section_id={this.state.active_section_id} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} sectionName="Restricted List" lookup_id = "security_register" />}
            {this.state.active_section_id === "approval_rules" && <ApprovalRulesTable role_types={JSON.parse(JSON.stringify(this.state.module_config.role_types))} />}
            {this.state.alertParam !== null && <YesNoBox alertParam={this.state.alertParam} />}

            {/* ----  FOOTER  ---- */}
            {
              (() => {
                if (this.state.active_section_id === "general" || this.state.active_section_id === "sla" || this.state.active_section_id === "email" || this.state.active_section_id === "custom_fields" || this.state.active_section_id === "hot_lookup" || this.state.active_section_id === "pad_reports") {
                  return (
                    <FooterButtonsContainer>
                      <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                      <SaveBtn onClick={this.updateModuleConfig}>Save</SaveBtn>
                    </FooterButtonsContainer>
                  )
                }
              })()
            }
          </ReviewRightContainer>
          <ClearBoth />
        </ReviewBodyContainer>
      </div>
    );
  }
}

export default ModuleConfigHOC;
