import React from "react";
import styled from "styled-components";
import CSLNumberTextBox from "../../Common/CSLNumberTextBox";

import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';
import automatic_rejection_advices from './automatic_rejection_advices.js'

const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const GeneralContainer = styled.div`
  width: 90%;
`;
const GeneralInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
// const GeneralsubLabel = styled.div`
//   font-weight: 400;
//   color: #595959;
//   margin-top: 5px;
// `;
const RadioBlock = styled.div`
  margin-top: 5px;
`;
// const ResponseDropdown = styled.select`
//   box-sizing: border-box;
//   height: 40px;
//   width: 100%;
//   background-color: #fff;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   color: #b5b5b5;
// `;
const FloatingSection = styled.div`
  float: left;
  box-sizing: border-box;
`;

const BoldLabel = styled.div`
  font-weight: 600;
`;

const Clearfix = styled.div`
  clear: both;
`;

// const TextBox = styled.textarea`
//   height: 40px;
//   width: 98%;
//   rows:4;
//   cols:40;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   padding: 5px;
//   color: ${process.env.DEFAULT_TEXT_COLOR};
//   font-size: 15px;
//   font-family: "Montserrat", sans-serif;
//   &:focus {
//     outline-width: 0;
//   }
// `;

const TextArea = styled.textarea`
  resize: none;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  padding: 5px 10px;
  box-sizing: border-box;
`;

class EntryFormRadioButton extends React.Component {
  render() {
    // console.log('this.props', this.props)
    if(this.props.enabled) {
      return (<div>
          <RadioBlock style={{float: 'right', marginTop: -7, marginRight: '5%'}}>Enable &nbsp;
            <SelectedRadio>Yes</SelectedRadio>
            <UnselectedRadio onClick={() => this.props.changeEnable(this.props.itemIndex, false)}>No</UnselectedRadio>
          </RadioBlock>
        </div>)
    } else {
      return (<div>
          <RadioBlock style={{float: 'right', marginTop: -7, marginRight: '5%'}}>Enable &nbsp;
            <UnselectedRadio onClick={() => this.props.changeEnable(this.props.itemIndex, true)}>Yes</UnselectedRadio>
            <SelectedRadio>No</SelectedRadio>
          </RadioBlock>
      </div>)
    }
  }
}

class General extends React.Component {
  state = {
    general: null,
    request_options: [
      {id: "notification", name: "Notification"},
      {id: "approval", name: "Approval"}
    ],
    selected_request_option: null,
    deactivate_user_holding : false,
  };

  // constructor(props) {
  //   super(props);
  // }
  

  componentDidMount() {
    let selected_request_option = this.state.request_options.find(item => item.id === this.props.general.request_type);
    let general = this.props.general;
    console.log('general.automatic_rejection_advices', general.automatic_rejection_advices)
    for(let item of general.automatic_rejection_advices) {
      if(!('show_on_entry_form' in item)) {
        item.show_on_entry_form = true
      }
      automatic_rejection_advices[item.identifier].included = true
      if(!('pos' in item)) {
        item.pos = automatic_rejection_advices[item.identifier].pos
      }
    }
    
    for(let aid in automatic_rejection_advices) {
      if(!automatic_rejection_advices[aid].included) {
        delete automatic_rejection_advices[aid].included
        general.automatic_rejection_advices.push(automatic_rejection_advices[aid])
      }
    }
    if(!('internal_approver_comments' in general)) {
      general.internal_approver_comments = true
    }
    this.setState({general: general, selected_request_option});
  }

  setSelection = (item_id) => {
    let general = JSON.parse(JSON.stringify(this.state.general));
    let selected_request_option = this.state.request_options.find(item => item.id === item_id);
    general.request_type = item_id;
    this.props.updateGeneral(general);
    this.setState({selected_request_option, general});
  }

  validityWindow = (number) => {
    let general = JSON.parse(JSON.stringify(this.state.general));
    general.validity_window_hours = parseInt(number);
    this.props.updateGeneral(general);
    this.setState({general});
  }

  changeSearch = (number) => {
    let general = JSON.parse(JSON.stringify(this.state.general));
    general.security_min_search_chars = parseInt(number);
    this.props.updateGeneral(general);
    this.setState({general});
  }

  holdingPeriod = (number) => {
    let general = JSON.parse(JSON.stringify(this.state.general));
    general.holding_period_days = parseInt(number);
    this.props.updateGeneral(general);
    this.setState({general});
  }

  // setHolding = (item_id) => {
  //   let general = JSON.parse(JSON.stringify(this.state.general));
  //   let selected_holding_option = this.state.holding_options.find(item => item.id === parseInt(item_id));
  //   general.holding_period_days = parseInt(item_id);
  //   this.props.updateGeneral(general);
  //   this.setState({selected_holding_option, general});
  // }

  switchRadio = (key) => (event) => {
    event.preventDefault();
    let general = JSON.parse(JSON.stringify(this.state.general));
    general[key] = general[key] === true ? false : true;
    if(general.checkbox_short === true || general.checkbox_breach === true) general.add_mandatory_checkboxes = true;
    else general.add_mandatory_checkboxes = false;
    if (key === "confirm_trade_information") {
      if (general.confirm_trade_information === false) general.void_enabled = false;
    }
    if (key === "void_enabled") {
      if (general.confirm_trade_information === false) general.void_enabled = false;
    }
    if (key === "use_security_register" ) {
      if (general.use_security_register === false) {
        general.hide_employee_holdings = true;
        this.setState({deactivate_user_holding : true})
      } else {
        this.setState({deactivate_user_holding : false})
      }
    }

    this.props.updateGeneral(general);
    this.setState({general});
  };

  changeAdvice = (identifier) => (event) => {
    event.preventDefault();
    let general = JSON.parse(JSON.stringify(this.state.general));
    general.automatic_rejection_advices.forEach(item => {
      if (item.identifier === identifier) {
        item.text = event.target.value;
      }
    })
    this.props.updateGeneral(general);
    this.setState({general});
  }
  
  changeEnable = (i, value) => {
    let general = this.state.general;
    general.automatic_rejection_advices[i].show_on_entry_form = value
    this.props.updateGeneral(general);
    this.setState({general})
  }


  render() {
    console.log("Gen ",this.state);
    if (this.state.general === null) {
      return <div>Loading...</div>;
    }
    
    console.log('gen this.props', this.props)
    let advs = this.state.general.automatic_rejection_advices;
    return (
       
      <GeneralContainer>
        <GeneralInnerContainer>
          <GeneralLabel>Request Type</GeneralLabel>
          <div style={{ paddingTop: "10px", width: "40%" }}>
            <CslSimpleDropDown options={this.state.request_options} selected={this.state.selected_request_option} setSelection={this.setSelection} />
          </div>
        </GeneralInnerContainer>

        {
          (() => {
            if (this.state.general.request_type === "notification") {
              return (
                <GeneralInnerContainer>
                  <GeneralLabel>Require Confirmation by Manager</GeneralLabel>
                  {(() => {
                    if (this.state.general.require_confirmation_by_manager === true) {
                      return (
                        <RadioBlock>
                          <SelectedRadio>Yes</SelectedRadio>
                          <UnselectedRadio onClick={this.switchRadio("require_confirmation_by_manager")}>No</UnselectedRadio>
                        </RadioBlock>
                      );
                    } else {
                      return (
                        <RadioBlock>
                          <UnselectedRadio onClick={this.switchRadio("require_confirmation_by_manager")}>Yes</UnselectedRadio>
                          <SelectedRadio>No</SelectedRadio>
                        </RadioBlock>
                      );
                    }
                  })()}
                </GeneralInnerContainer>
              );
            }
          })()
        }

        {
          this.state.general.request_type === "approval" &&
          <GeneralInnerContainer>
            <GeneralLabel>Confirm trade information </GeneralLabel>
            {(() => {
              if (this.state.general.confirm_trade_information === true) {
                return (
                  <RadioBlock>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.switchRadio("confirm_trade_information")}>No</UnselectedRadio>
                  </RadioBlock>
                );
              } else {
                return (
                  <RadioBlock>
                    <UnselectedRadio onClick={this.switchRadio("confirm_trade_information")}>Yes</UnselectedRadio>
                    <SelectedRadio>No</SelectedRadio>
                  </RadioBlock>
                );
              }
            })()}
          </GeneralInnerContainer>
        }

        {
          this.state.general.request_type === "approval" && this.state.general.confirm_trade_information === true &&
          <GeneralInnerContainer>
            <GeneralLabel>Enable 'Void' status for trades with expired Approval</GeneralLabel>
            {(() => {
              if (this.state.general.void_enabled === true) {
                return (
                  <RadioBlock>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.switchRadio("void_enabled")}>No</UnselectedRadio>
                  </RadioBlock>
                );
              } else {
                return (
                  <RadioBlock>
                    <UnselectedRadio onClick={this.switchRadio("void_enabled")}>Yes</UnselectedRadio>
                    <SelectedRadio>No</SelectedRadio>
                  </RadioBlock>
                );
              }
            })()}
          </GeneralInnerContainer>
        }

        {/*<GeneralInnerContainer>
          <GeneralLabel>Track Employee Position </GeneralLabel>
          {(() => {
            if (this.state.general.track_employee_position === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("track_employee_position")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("track_employee_position")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer>*/}
        {
          this.state.general.request_type === "approval" &&
          <GeneralInnerContainer>
            <GeneralLabel>Enable internal Approver Comments </GeneralLabel>
            {(() => {
              if (this.state.general.internal_approver_comments === true) {
                return (
                  <RadioBlock>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.switchRadio("internal_approver_comments")}>No</UnselectedRadio>
                  </RadioBlock>
                );
              } else {
                return (
                  <RadioBlock>
                    <UnselectedRadio onClick={this.switchRadio("internal_approver_comments")}>Yes</UnselectedRadio>
                    <SelectedRadio>No</SelectedRadio>
                  </RadioBlock>
                );
              }
            })()}
          </GeneralInnerContainer>
        }
        {/* change arnab */}

        {/* 1 */}
        <GeneralInnerContainer>
          <GeneralLabel>Allow requests on Private / Unlisted Securities </GeneralLabel>
          <div>Employees can create securities that are unique to them.</div>
          {(() => {
            if (this.state.general.private_securities_enabled === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("private_securities_enabled")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("private_securities_enabled")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer>

        {/* 2 */}
        <GeneralInnerContainer>
          <GeneralLabel>Use the Security Register </GeneralLabel>
          <div>Employees can search the Security Register and may see other employee's securities.</div>
          {(() => {
            if (this.state.general.use_security_register === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("use_security_register")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("use_security_register")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer>

        {/* 3 */}
        <GeneralInnerContainer>
          <GeneralLabel>Hide all employee holdings </GeneralLabel>
          <div>Securities added by employee's are not visible for lookup by other employees.</div>
          {(() => {
            if (this.state.general.hide_employee_holdings === true) {
              if(this.state.deactivate_user_holding === true){
                return (
                  <RadioBlock>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio style={{color : "#dddddd", cursor : "default"}}>No</UnselectedRadio>
                  </RadioBlock>
                );  
              } else {
                return (
                  <RadioBlock>
                    <SelectedRadio>Yes</SelectedRadio>
                    <UnselectedRadio onClick={this.switchRadio("hide_employee_holdings")}>No</UnselectedRadio>
                  </RadioBlock>
                );
            }
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("hide_employee_holdings")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer>

        {/* 4 */}
        <GeneralInnerContainer>
          <GeneralLabel>At least one Security ID is required for new securities</GeneralLabel>
          <div>Switching this off allows securities to be added without a security ID which will make it easier to add new securities but may lead to confusion and duplicate entries</div>
          {(() => {
            if (this.state.general.one_security_id_required === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("one_security_id_required")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("one_security_id_required")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer>
        {/* change arnab upto here */}

        {/* dbr-employee-broker-ac */}
        <GeneralInnerContainer>
          <GeneralLabel style={{ marginBottom : "12px" }}>Enable Employee Broker Accounts</GeneralLabel>
          {(() => {
            if (this.state.general.enable_employee_broker_ac === true) {
              return (
                <RadioBlock>
                  <SelectedRadio>Yes</SelectedRadio>
                  <UnselectedRadio onClick={this.switchRadio("enable_employee_broker_ac")}>No</UnselectedRadio>
                </RadioBlock>
              );
            } else {
              return (
                <RadioBlock>
                  <UnselectedRadio onClick={this.switchRadio("enable_employee_broker_ac")}>Yes</UnselectedRadio>
                  <SelectedRadio>No</SelectedRadio>
                </RadioBlock>
              );
            }
          })()}
        </GeneralInnerContainer>
          {/* dbr-employee-broker-ac */}

        <GeneralInnerContainer>
          <GeneralLabel>Minimum number of characters before the system searches for a security match</GeneralLabel>
          <div style={{ paddingTop: "10px", width: "20%" }}>
            {/* <CslSimpleDropDown options={this.state.validity_options} selected={this.state.selected_validity_option} setSelection={this.setValidity} /> */}
            <CSLNumberTextBox value={this.props.general.security_min_search_chars} changeValue={this.changeSearch} />
          </div>
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <GeneralLabel>Show the following confirmation tick boxes on the PAD Entry screen</GeneralLabel>
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <FloatingSection>
            {(() => {
              if (this.state.general.checkbox_short === true) {
                return <FaRegCheckSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_short")} />;
              } else {
                return <FaRegSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_short")} />;
              }
            })()}
          </FloatingSection>
          <FloatingSection style={{ paddingLeft: "7px" }}>
            <BoldLabel>I confirm the trade does not take the beneficiary owner short</BoldLabel>
          </FloatingSection>
          <Clearfix></Clearfix>
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <FloatingSection>
            {(() => {
              if (this.state.general.checkbox_breach === true) {
                return <FaRegCheckSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_breach")} />;
              } else {
                return <FaRegSquare style={{ cursor: "pointer", color: "#030303", fontSize: "18px" }} onClick={this.switchRadio("checkbox_breach")} />;
              }
            })()}
          </FloatingSection>
          <FloatingSection style={{ paddingLeft: "7px" }}>
            <BoldLabel>I confirm the trade does not breach any Regulator or Company Rules or Policies</BoldLabel>
          </FloatingSection>
          <Clearfix></Clearfix>
        </GeneralInnerContainer>
        
        {(() => {
          if (this.state.general.request_type === "approval") {
            return (
              <div>
                <GeneralInnerContainer>
                  <GeneralLabel>Validity Windows(hrs)</GeneralLabel>
                  <div style={{ paddingTop: "10px", width: "20%" }}>
                    {/* <CslSimpleDropDown options={this.state.validity_options} selected={this.state.selected_validity_option} setSelection={this.setValidity} /> */}
                    <CSLNumberTextBox value={this.props.general.validity_window_hours} changeValue={this.validityWindow} />
                  </div>
                </GeneralInnerContainer>

                <GeneralInnerContainer>
                  <GeneralLabel style>Holding Period(days)</GeneralLabel>
                  <div style={{ paddingTop: "10px", width: "20%" }}>
                    {/* <CslSimpleDropDown options={this.state.holding_options} selected={this.state.selected_holding_option} setSelection={this.setHolding} /> */}
                    <CSLNumberTextBox value={this.props.general.holding_period_days} changeValue={this.holdingPeriod} />
                  </div>
                </GeneralInnerContainer>

                <GeneralInnerContainer>
                  {
                    advs.map((item, i) => {
                      return (
                        <div style={{ paddingTop: 5 }} key={i}>
                          <GeneralLabel style={{marginTop: 10}}>{item.header}
                              <EntryFormRadioButton enabled={item.show_on_entry_form} changeEnable={this.changeEnable} itemIndex={i} />
                          </GeneralLabel>
                          <Clearfix />
                          <div style={{ paddingTop: "5px", width: "95%" }}>
                            <TextArea rows="5" cols="120" value={item.text} onChange={this.changeAdvice(item.identifier)}></TextArea>
                          </div>
                        </div>
                      )
                    })
                  }
                </GeneralInnerContainer>
              </div>
            );
          }
        })()}
      </GeneralContainer>
    );
  }
}

export default General;
