import React from "react";
import { FaTimes, FaRegCheckSquare, FaRegSquare, FaEllipsisV } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import CSLHeader from "../../Common/CSLHeader";
import Store from "../../../Common/Store";
import SpinningLoader from "../../Common/SpinningLoader";
import "./ManageConfirmation.css"
import CSLTable from "../../Common/CSLTable";
import EllipsisMenu from "../../Common/EllipsisMenu";
import APICall from '../../../Common/APICall';
import moment from "moment";
import SendConfirmationModal from "./SendConfirmationModal";
import ConfirmationReportModal from "./ConfirmationReportModal"
import TransactionReportViewModal from "./TransactionReportViewModal";
import HoldingConfirmationReportModal from "./HoldingConfirmationReportModal";
import HoldingReportViewModal from "./HoldingReportViewModal";
import AlertBox from "../../Common/AlertBox";
//import PdfGen from "./ConfirmationReport";


class MyConfirmation extends React.Component{
    state = {
        contact_id : Store.getStoreData("loggedin_contact_id"),
        is_debug: Store.getStoreData("is_debug"),
        show_spinner : false,
        pills: {
           all : { text : "All", count : 100, active : true },
           new: { text : "New", count : 2, active : false, color : "#ffc34d" },
           pending : { text : "Pending", count : 1, active : false, color : "#ffc34d" },
           rejected : { text : "Rejected", count : 20, active : false, color : "#d95656" },
           confirmed : { text : "Confirmed", count : 100, active : false, color : "#37ada7" },
        },
        report_types : {
            1 : {text : "TRANSACTION"},
            2 : {text : "HOLDING"}
        },
        report_list : [],
        show_list : [],
        show_main_context_menu : false,
        select_all : false,
        multi_select : 0,
        hide_inactive : true,
        show_confirmation_report_modal : false,
        show_report_view_modal : false,
        selected_report : null,
        render_key : null,
        tran_confirmation_id : null,
        hold_confirmation_id : null,
        url_requester : null,
        ready : false,
        alert_param : null
    }

    async componentDidMount() {
        //this.setState({ show_spinner : true });
        let tran_confirmation_id = null;
        let hold_confirmation_id = null;
        let url_requester = null;
        if (this.props.match) {
            console.log("this.props.match.params.conId:", this.props.match.params.conId);
            let conId = this.props.match.params.conId;
            let sp_ar = []
            if(conId.startsWith('TR-')){
                sp_ar = conId.split('-');
                tran_confirmation_id = parseInt(sp_ar[1]);
            }else if(conId.startsWith('HOLD-')){
                sp_ar = conId.split('-');
                hold_confirmation_id = parseInt(sp_ar[1]);
            }else if(isNaN(conId) === false){
                url_requester = parseInt(conId);
            }

        }
        //    this.showConfirmation(parseInt(this.props.match.params.conId));
        this.setState({ tran_confirmation_id, hold_confirmation_id, url_requester})
        this.intializeState(this.state.contact_id);
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps) {
            let tran_confirmation_id = null;
            let hold_confirmation_id = null;
            let url_requester = null;
            if (this.props.match) {
                console.log("this.props.match.params.conId:", this.props.match.params.conId);
                let conId = this.props.match.params.conId;
                let sp_ar = []
                if(conId.startsWith('TR-')){
                    sp_ar = conId.split('-');
                    tran_confirmation_id = parseInt(sp_ar[1]);
                }else if(conId.startsWith('HOLD-')){
                    sp_ar = conId.split('-');
                    hold_confirmation_id = parseInt(sp_ar[1]);
                }else if(isNaN(conId) === false){
                    url_requester = parseInt(conId);
                }

            }
            //    this.showConfirmation(parseInt(this.props.match.params.conId));
            this.setState({ tran_confirmation_id, hold_confirmation_id, url_requester})
            this.intializeState(this.state.contact_id);
        }
    }

    intializeState = async (contact_id) => {
        let report_list = [];
        let alert_param = null;
        // let inactive_user_ids = [];
        // let all_users = Store.getStoreData("my_all_users");
        // let company_users = Store.getStoreData("company_users");
        let report_ids = [];
        const postData = { command: "list_my_confirmation", contact_id : contact_id };
        const api = new APICall();
        const api_result = await api.commandWithoutCallback(postData);
        if(api_result.result.length > 0){
            for(let r of api_result.result){
                report_ids.push(r.id);
                let status = r.cur_lane.split('_')
                let data = {
                    id : r.id,
                    status : status[2].toLowerCase(),
                    report_type : status[0] === 'PAD' ? "1" : "2",
                    report_title : r.report_title,
                    contact_id : r.contact_id,
                    from_date : r.from_date,
                    to_date : r. to_date,
                    date_sent : r.date_sent,
                    due_date : r.due_date,
                    resp_date : r.response_date,
                    issued_by : r.issued_by.toString(),
                    action_data : r.action_data !== undefined && r.action_data !== null ? JSON.parse(r.action_data) : null,
                    last_action_time : r?.last_action_time
                }
                report_list.push(data);
            }
        }
        if(this.state.tran_confirmation_id !== null && report_ids.includes(this.state.tran_confirmation_id) === false){
            alert_param = {
				title: 'Error', message: `You are not authorized to view transaction confirmation report with ID TR-${this.state.tran_confirmation_id.toString()}`, ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlertSmall, stack: {}
			}
        }else if(this.state.tran_confirmation_id !== null && report_ids?.includes(this.state.tran_confirmation_id) === true){
            let tran_confirmation_id = this.state.tran_confirmation_id;
            let report = report_list?.find(item => item.id === tran_confirmation_id);
            if(report?.report_type !== "1"){
                alert_param = {
                    title: 'Error', message: `Report with ID TR-${this.state.tran_confirmation_id?.toString()} is invalid`, ok_text: 'Ok', confirm: false,
                    alertHandler: this.closeAlertSmall, stack: {}
                }    
            }else{
                this.showModal(tran_confirmation_id, report_list);
            }
            this.setState({ tran_confirmation_id : null });
        }
        if(this.state.hold_confirmation_id !== null && report_ids?.includes(this.state.hold_confirmation_id) === false){
            alert_param = {
				title: 'Error', message: `You are not authorised to view holding confirmation report with ID HOLD-${this.state.hold_confirmation_id?.toString()}`, ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlertSmall, stack: {}
			}
        }else if(this.state.hold_confirmation_id !== null && report_ids?.includes(this.state.hold_confirmation_id) === true){
            let holdConfirmationId = this.state.hold_confirmation_id;
            let report = report_list?.find(item => item.id === holdConfirmationId);
            if(report?.report_type !== "2"){
                alert_param = {
                    title: 'Error', message: `Report with ID HOLD-${this.state.hold_confirmation_id?.toString()} is invalid`, ok_text: 'Ok', confirm: false,
                    alertHandler: this.closeAlertSmall, stack: {}
                }    
            }else{
                this.showModal(holdConfirmationId, report_list);
            }
            this.setState({ hold_confirmation_id : null });
        }
        console.log("Report List : ", report_list);
        this.setState({ report_list, ready : true, report_ids, alert_param});
    }

    makeShowListActive = async (show_list) => {
        let show_list_active = [];
        for(let item of show_list){
            if(item.inactive_contact === false) show_list_active.push(item);
        }
        return show_list_active;
    }

    makeid = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

    showMainContextMenu = (event) => {
        event.preventDefault();
        this.setState({show_main_context_menu : this.state.show_main_context_menu === true ? false : true })
    }

    hideMainContextMenu = (event) => {
        event.preventDefault();
        this.setState({show_main_context_menu : false })
    }

    showModal = async (id, report_list) =>{
        //const report_list = JSON.parse(JSON.stringify(report_list));
        let selected_report = { "transaction" : null, "holding" : null};
        let record = report_list.find(item => item.id === id);
        if(record?.report_type === "1") {
            selected_report.transaction = record;
            selected_report.holding = null;
        }
        else if(record?.report_type === "2") {
            selected_report.holding = record;
            selected_report.transaction = null;
        }
        if(record.status === "pending") {
            this.setState({ show_confirmation_report_modal : true, selected_report });
        }else{
            this.setState({ show_report_view_modal : true, selected_report });
        }
        //this.setState({ show_confirmation_report_modal : true, selected_report });
    }

    closeConfirmationReportModal = async (status = null) =>{
        if(status === 'reload') await this.intializeState(this.state.contact_id);
        this.setState( { show_confirmation_report_modal : false, selected_report : null });
    }

    closeTransactionReportViewModal = () => {
        this.setState( { show_report_view_modal : false, selected_report : null });
    }

    closeAlertSmall = (value, stack) => {
        this.setState({ alert_param : null});
    }

    performAction = (id, action) => {
        const report_list = JSON.parse(JSON.stringify(this.state.report_list));
        let record = report_list.find(item => item.id === id);
        let selected_report = { "transaction" : null, "holding" : null};
        if(record?.report_type === "1") {
            selected_report.transaction = record;
            selected_report.holding = null;
        }
        else if(record?.report_type === "2") {
            selected_report.holding = record;
            selected_report.transaction = null;
        }
        if(record.status === "pending") {
            this.setState({ show_confirmation_report_modal : true, selected_report });
        }else{
            this.setState({ show_report_view_modal : true, selected_report });
        }
    }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";

        window.location.replace(url);
    };

    processDataTable = () => {
        const ret = { data: [], columns: [] };
        const actions = [{name: "View", action: "view"}];
        let table_data = [];
        //debugger;
        ret.columns = [
            { Header: "", accessor: "status_color", width: 40, filterable: false, headerStyle: { textAlign: "center", lineHeight: "24px" }, Cell: (row) =><div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}></div>},
            { Header: "Status", accessor: "status", width: 280, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Report Type", accessor: "report_type", width: 260, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Date Sent", accessor: "date_sent", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Due Date", accessor: "due_date", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Response Date", accessor: "resp_date", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Issued By", accessor: "issued_by", width: 270, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            {
                Header: "Action", Width: 40, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center", height : "50px" },
                Cell: (row) => {
                    //if(row.original.is_inactive_user) return <div style={{color : "#ddd", fontSize : "18px", paddingTop : "5px"}}><FaEllipsisV /></div>
                    let show = {view: 1};
                    //if(row.original.status === 'NEW') show.view = 0;
                    return <div style={{marginTop : "-6px"}}><EllipsisMenu show={show} entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
                },
                filterable: false
            }
        ]
        for(let record of this.state.report_list){
            let data = {
                id : record.id,
                status : this.state.pills[record.status].text.toUpperCase(),
                status_color : this.state.pills[record.status].color,
                report_type : this.state.report_types[record.report_type].text.toUpperCase(),
                date_sent : record.date_sent === null ? "--/--/----" : moment(moment(record.date_sent.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                due_date : record.due_date === null ? "--/--/----" : moment(moment(record.due_date.toString())).format("DD/MM/YYYY"),
                resp_date : record.resp_date === null ? "--/--/----" : moment(moment(record.resp_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                issued_by : record.issued_by !== null ? Store.getStoreData("company_users").find(item => item.user_id === record.issued_by).name : "",
                last_action_time : record?.last_action_time
            }
            table_data.push(data);
        }
        
        table_data.sort((a, b) => b.last_action_time - a.last_action_time);
        ret.data = table_data;
        return ret;
    }

    exportConfirmationList = (event) =>{
        event.preventDefault();
        let headerCSV = {
            status : "Status",
            report_type : "Report Type",
            date_sent : "Date Sent",
            due_date: "Due Date",
            resp_date: "Response Date",
            issued_by : "Issued by"
        };
        let dataCSV = [];
        let report_list = JSON.parse(JSON.stringify(this.state.report_list))
        let export_ids = Store.getStoreData("mng_confirm_current_record_ids")
        for(let id of export_ids){
            let record = report_list.find(item => item.id === id)
            let data = {
                status : this.state.pills[record.status].text.toUpperCase(),
                status_color : this.state.pills[record.status].color,
                report_type : this.state.report_types[record.report_type].text.toUpperCase(),
                date_sent : record.date_sent === null ? "--/--/----" : moment(moment(record.date_sent.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                due_date : record.due_date === null ? "--/--/----" : moment(moment(record.due_date.toString())).format("DD/MM/YYYY"),
                resp_date : record.resp_date === null ? "--/--/----" : moment(moment(record.resp_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                issued_by : record.issued_by !== null ? Store.getStoreData("company_users").find(item => item.user_id === record.issued_by).name : ""
            }
            dataCSV.push(data);
        }
        console.log("Headers : ", headerCSV, "Data : ", dataCSV);
        this.exportToCSV(headerCSV, dataCSV);
    }

    exportToCSV = (headerCSV, dataCSV) => {
        //event.preventDefault();
        //let tableData = this.processDataTable();
        // if (Store.getStoreData("role") === "team") {
        //   delete headerCSV.requester;
        // }
        let data= dataCSV;
        let csv_data_arr = [];
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
          console.log("Data in Export : ", data[k]);
          let cont = {};
          Object.entries(headerCSV).forEach(([key, value]) => {
            if(data[k][key] !== null) {
              console.log("Data key", data[k][key]);
              cont[key] = data[k][key].toString().replace(/,/g, '  ').replace(/-/g, '-');
            }
          })
          csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr)
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0);
          csv_data_final_arr.push(container)
        }
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Confirmation List.csv");
        document.body.appendChild(link);
        link.click();
    }

    render(){
        console.log("State My Confirmation : ", this.state);
        if (this.state.url_requester !== null && this.state.contact_id !== this.state.url_requester || Store.getStoreData("module_config").pad_confirmation.selected_confirmation_frequency === 'none') {
            return (
                <div className="error-bar">
                    <div className="error-msg-div">You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div className="error-bar-close" onClick={this.closePage}><FaTimes /></div>
                </div>
            );
        }
        if(this.state.ready !==true) return <SpinningLoader />
        console.log("This.STate ==>", this.state);
        let render_key = this.makeid(8);
        let table_ids = Store.getStoreData("mng_confirm_current_record_ids");
        console.log("RENDER CURRENT TABLE IDS : ",table_ids);
        return(
            <>
                {this.state.show_spinner === true && <SpinningLoader />}
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <CSLHeader
                        isAdmin={(this.state.cur_view === 'manager_index')}
                        isDebug={Store.getStoreData('is_debug')}
                        taskHeader={false} module={"padreg"}
                        headerText={'My PAD Reports'}
                    />
                    <div className="container">
                        <div className="pill-bar">
                            <div className="header-text-container">My PAD Reports</div>
                            <div className='main-ellipsis' style={{marginTop : "10px"}}>
                                <FaEllipsisV onClick={this.showMainContextMenu}/>
                                {
                                    this.state.show_main_context_menu &&
                                    <div className='main-context-menu' onMouseLeave={this.hideMainContextMenu}>
                                        <div className='main-context-menu-item' onClick={this.exportConfirmationList}>Export</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="table-container">
                            <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={12} exportClicked = {this.state.export_clicked} from={"mng_confirm"} render_key={render_key}/>
                        </div>
                        {
                            this.state.show_confirmation_report_modal === true && this.state.selected_report?.transaction !== null && this.state.selected_report?.holding === null && <div className="inactive-overlay"><ConfirmationReportModal selected_report={this.state.selected_report} view={"requester"} closeModal={this.closeConfirmationReportModal} /></div>
                        }
                        {
                            this.state.show_confirmation_report_modal === true && this.state.selected_report?.transaction === null && this.state.selected_report?.holding !== null && <div className="inactive-overlay"><HoldingConfirmationReportModal selected_report={this.state.selected_report} view={"requester"} closeModal={this.closeConfirmationReportModal} /></div>
                        }
                        {
                            this.state.show_report_view_modal === true && this.state.selected_report?.transaction !== null && this.state.selected_report?.holding === null && <div className="inactive-overlay"><TransactionReportViewModal selected_report={this.state.selected_report} view={"requester"} closeModal={this.closeTransactionReportViewModal} /></div>
                        }
                        {
                            this.state.show_report_view_modal === true && this.state.selected_report?.transaction === null && this.state.selected_report?.holding !== null && <div className="inactive-overlay"><HoldingReportViewModal selected_report={this.state.selected_report} view={"requester"} closeModal={this.closeTransactionReportViewModal} /></div>
                        }
                    </div>
                </div>
                <AlertBox alertParam={this.state.alert_param} />
            </>
        )
    }
}

export default MyConfirmation;
