import React from 'react';
import styled from "styled-components";
import { FaTimes, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import APICall from "../../../../Common/APICall";

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 50%;
	position: fixed;
	z-index: 1001;
    //margin-top: 10%;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 100px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F0F6FC;
	padding: 20px 40px;
	border-radius: 4px;
	overflow-y: scroll;
    height: 520px;
`;
const ClearBoth = styled.div`
	clear: both;
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalFooter = styled.div`
	padding: 17px;
	background-color: white;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
  	border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    padding: 10px 20px;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
	color: #2d2c2c;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	padding: 10px 20px;
	font-weight: 600;
`;

const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	float: left;
	margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;

class RestrictedListModal extends React.Component {
    state = {
        ready: false,    
        mRestrictedList: {}
    }

    constructor() {
        super();      
        this.api = new APICall();
    }

    componentDidMount(){ 
        let mRestrictedList = this.props.mRestrictedList;
        let selected_security = this.props.security_register[this.props.selected_security_id.toString()] 
        let restricted_list_ids = selected_security.restricted_list_ids;
        let updateMRestrictedList = {}
        updateMRestrictedList['0'] = {'name': 'Default List, applicable to all employees', 'isCheck': '0' in restricted_list_ids ? true : false}
        for(let k in mRestrictedList){                       
            updateMRestrictedList[k.toString()] = {'name': mRestrictedList[k].name, 'isCheck': k.toString() in restricted_list_ids ? true : false}
        }        
        console.log('Sourav===>', updateMRestrictedList)
        this.setState({ready: true, mRestrictedList: updateMRestrictedList})
    }

    closeView = () => {
        this.props.closeModal();
    };

    handlenaCheckUnCheck = (res_id) => (event) => {
		console.log("content:", res_id);
		let {mRestrictedList} = this.state;

        for(let k in mRestrictedList){
            if(k === res_id){
                mRestrictedList[k].isCheck = mRestrictedList[k].isCheck ? false : true
            }
        }
        this.setState({mRestrictedList})			
	}

    submitRestrictedValue = (event) => {
        event.preventDefault();

		let selected_security = this.props.security_register[this.props.selected_security_id.toString()] 
        let restricted_list_ids = selected_security.restricted_list_ids;

        let current_restricted_ids = {}
        for(let k in this.state.mRestrictedList){
            if(this.state.mRestrictedList[k.toString()].isCheck){
                current_restricted_ids[k.toString()] = 1
            }else{
				current_restricted_ids[k.toString()] = 0
			}
        }
		let up_cur_res = {}
		for(let c in current_restricted_ids){
			if(c.toString() in restricted_list_ids && current_restricted_ids[c] === 1){
				up_cur_res[c] = {'id': c, 'status': 'no_change'}
			}
			if(c.toString() in restricted_list_ids && current_restricted_ids[c] === 0){
				up_cur_res[c] = {'id': c, 'status': 'update'}
			}
			if(!(c.toString() in restricted_list_ids) && current_restricted_ids[c] === 1){
				up_cur_res[c] = {'id': c, 'status': 'insert'}
			}
		}
		console.log("up_cur_res===>", up_cur_res)
        const postData = {command: "save_restrited_security", current_restricted_ids: up_cur_res, selected_security_id: this.props.selected_security_id};
		console.log("postData===>", postData)
        this.api.command(postData, this.afterSubmitRestrictedValue);
    }

    afterSubmitRestrictedValue = (result) => {
    	console.log("result", result)    	
    	this.props.refreshParent();
    }

    render () {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }	
		return (
			<div>                
				<ModalWraper>                
	                <MRModalContainer>
	                    <MRModalHeader>
							<MRModalHeaderText>Manage Restricted Securities</MRModalHeaderText>
							<MRModalHeaderCloseBtn onClick={this.closeView}>
					            <FaTimes />
					        </MRModalHeaderCloseBtn>
                            <ClearBoth />	
                            <MRModalSubHeaderText>Please select the Restricted List(s) you would like to add this security to</MRModalSubHeaderText>					        				        
						</MRModalHeader>
	                    <MRModalBody>  
                            <table style={{width: "80%", borderCollapse: "separate",borderSpacing: "0 1em"}}>                                
                                {
                                    Object.keys(this.state.mRestrictedList).map((k, i)=>{
                                        return (<tr key={i}>
                                            <td><div style={{width: "50%", wordWrap: "break-word"}}>{this.state.mRestrictedList[k].name}</div></td>
                                            {
                                                (() => {
                                                    if(this.state.mRestrictedList[k].isCheck){
                                                        return <td style={{cursor: "pointer"}} onClick={this.handlenaCheckUnCheck(k)}><FaRegCheckSquare /></td>
                                                    }else{
                                                        return <td style={{cursor: "pointer"}} onClick={this.handlenaCheckUnCheck(k)}><FaRegSquare /></td>
                                                    }
                                                })()
                                            }                                            
                                            </tr>)
                                    })                                    
                                }                              
                            </table>
	                    </MRModalBody>
	                    <MRModalFooter>
	                        <MRModalNextBtn style={{float: "right"}} onClick={this.submitRestrictedValue}>SUBMIT</MRModalNextBtn>
	                        <CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeView}>CLOSE</CloseButton>
	                        <ClearBoth />
	                    </MRModalFooter>
	                </MRModalContainer>
	            </ModalWraper>
            </div>
        )
    }
}
export default RestrictedListModal;