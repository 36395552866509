import React from "react";
import ReassignModal from "../UIElements/ReassignModal";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactJson from 'react-json-view'
import styled from "styled-components";
import TaskApprovalModal from "../UIElements/TaskApprovalModal";
import RoleTypes from "../UIElements/RoleTypes";
import Sla from "./Sla";
import General from "./General";
import SecurityRestrictedRegisters from "../UIElements/SecurityRestrictedRegisters";
import Required_Json from "./Required_Json"
import Required_Module_Json from "./Required_Module_Json"

const Comment= styled.div `

overflow: auto;

  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e2f0d9;
  // margin-bottom: 10px;
  border: 1px solid #6c9450;
  border-radius: 5px;
  padding: 5px 10px;
`
const Header= styled.div `

overflow: auto;

  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e8eaed;
  // margin-bottom: 10px;
  border: 1px solid #6c9450;
  border-radius: 5px;
  padding: 5px 10px 10px;
  margin-bottom: 20px;
`
class UIElements extends React.Component {
  state = {
    menu_item: null,
    showModal: null,

    //this.props.updateModuleConfig;
  };
  componentDidMount() {
    console.log( Required_Json)
  }
  clickMenu = (menu_item) => (e) => {
    e.preventDefault();
    this.setState({ menu_item });
  };
  closeModal = (event) => this.setState({ showModal: null });

  render() {
    return (
      <div>
        <div>
          <div style={{ padding: 10, cursor: "pointer" }} onClick={this.clickMenu("reassignmodal")}>
          2.1.5.	Reassignment Pop-up
          </div>
          <Comment>
           (Note: It will be implemented on TaskApprovalModal.js where after clicking the Reassignment button the reassignment pop up (the above UI) will be displayed. The current UI is hard coded )
          </Comment>
        </div>

        <div>
          <div style={{ padding: 10, cursor: "pointer" }} onClick={this.clickMenu("RoleTypes")}>
          2.1.1.	Identify what approval level each user requires
          </div>
          <Comment>
            (Note : This will be implemented on  Role types section of module configuration. the data in the table will be coming from config_lookups table from database . When a new company will be added then it will fetch the object the Role_types key from  default_value.js and the drop down option are taken hard coded      )
          </Comment>
        </div>
        <div>
          <div style={{ padding: 10, cursor: "pointer" }} onClick={this.clickMenu("TaskApprovalModal")}>
          2.1.4. Manager Form UI Changes
          </div>
          <Comment>
           (Note: It will be implemented TaskApprovalModal.js where three alert boxes are hard coded till now. )
          </Comment>
        </div>
        <div>
          <div style={{ padding: 10, cursor: "pointer" }} onClick={this.clickMenu("Sla")}>
          2.2.2.	‘SLA Breached’ warning message
          </div>
          <Comment>
           (Note: This will be implemented on Sla section of module configuration. The data is fetched from moduleConfig.js , module_config.general.automatic_rejection_advices array is taken  )
          </Comment>
        </div>
        <div>
          <div style={{ padding: 10, cursor: "pointer" }} onClick={this.clickMenu("SecurityRestrictedRegisters")}>
          2.3	Control access to the Restricted List and Information
          </div>
          <Comment>
           (Note: This will be implemented on securityregisteredlist.js whre the toggle that will be included as per spec , is hard coded. depending upon the toggle action it will display warning in add pad entry modal )
          </Comment>
          <div style={{ padding: 10, cursor: "pointer" }} onClick={this.clickMenu("General")}>
          2.4.1.	Module Configuration changes
          </div>
          <Comment>
           (Note: Here it will be implemented on general sec6ion of module configuration , where the check boxes are hard coded currently and "Add Mandatory CheckBoxes" must be implemented as per spec )
          </Comment>
        </div>

        {(() => { 

          switch (this.state.menu_item) {
            case "reassignmodal":
              return <ReassignModal
              closeModal={this.closeModal} />;
            case "RoleTypes":
              return <RoleTypes lookup_id="role_types" />;
            case "Sla":
              return <Sla general={this.state.general} />;
            case "SecurityRestrictedRegisters":
              return <SecurityRestrictedRegisters  />;
            case "TaskApprovalModal":
              return <TaskApprovalModal />;
            case "General":
              return <General />;
          }
        })()}
        <Header> Data Structure Preview of default value for Role types section implementation

        <ReactJson src={Required_Json.role_types} />
        </Header>
        <Header> Data Structure Preview of module_config for Sla section implementation ,module_config.general.automatic_rejection_advices array

        <ReactJson src={Required_Module_Json.general} />
        </Header>
      </div>
    );
  }
}

export default UIElements;
