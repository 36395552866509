import React from 'react';
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';
import Store from "./../../../Common/Store";
import SpinningLoader from '../../Common/SpinningLoader';

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 600px;
	position: absolute;
	//top: 38px;
	z-index: 1001;
	margin-left: 7.8%;
	//padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	// width: 40%;
	// position: absolute;
	// top: 38px;
	// z-index: 1001;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	// margin-bottom: 10px;
	// margin-left: 30%;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 100px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	float: left;
	margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const ClearBoth = styled.div`
	clear: both;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalFooter = styled.div`
  padding: 17px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    padding: 10px 20px;
`;
const Section30 = styled.div`
	width: 30%;
	float: left;
	box-sizing: border-box;
`;
const Clearfix = styled.div`
	clear: both;
`;
const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
    color: #2d2c2c;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #DBDBDB;
    padding: 10px 20px;
    font-weight: 600;
`;

class CreateSecurityRegisterModal extends React.Component {
    state = {
        ready: false,
        security_types: null,
		selected_security_type_obj: {},
        currencies: null,
        selected_currency_obj: {},
        security_identifier_types: [],
        security_name: "",
		show_spinner : false
    }
    componentDidMount(){

        if (this.props.curid !== 0) {
        }else{
            let securityTypeData = Store.getStoreData("module_config")["security_types"];
            let security_types = [];
            for(let key in securityTypeData){
                if(securityTypeData[key].isActive === 1){
                    security_types.push({id: key, name: securityTypeData[key].title})
                }
            }
            let selected_security_type_obj = security_types[0];

            let currenciesData = Store.getStoreData("module_config")["currencies"];
            let currencies = [];
            for(let key in currenciesData){
                if(currenciesData[key].isActive === 1){
                    currencies.push({id: key, name: currenciesData[key].title})
                }
            }
            let selected_currency_obj = currencies[0];
            let securityIdentifierTypesData = Store.getStoreData("module_config")["security_identifier_types"]
            let security_identifier_types = [];
            for(let key in securityIdentifierTypesData){
                if(securityIdentifierTypesData[key].isActive === 1){
                    let value = securityIdentifierTypesData[key];
                    value["id"] = key;
                    security_identifier_types.push(value);
                }
            }
            this.setState({ security_types, selected_security_type_obj, currencies, selected_currency_obj, security_identifier_types, ready: true })
        }
    }

    handleChangeD = (evt) => {
        let keyarr = evt.target.name.split("_");
        this.state.security_identifier_types.forEach((item) => {
            if (item.title === keyarr[1]) item.value = evt.target.value;
        });
    };
    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    changeRequeterSecurityType = (data) => {
		let selected_security_type_obj = this.state.security_types.find(item => item.id === data);
		this.setState({selected_security_type_obj});
	}

    changeRequeterCurrency = (data) => {
		let selected_currency_obj = this.state.currencies.find(item => item.id === data);
		this.setState({selected_currency_obj});
	}

    addNewSecurity = (event) => {
        event.preventDefault();
        let identifier_types = {};
        this.state.security_identifier_types.forEach((item) => {
			if (item.value !== undefined && item.value !== "") identifier_types[item.id] = item.value;
        });
		let data = {
			command: "save_lookup",
			name: this.state.security_name,
			status: 1,
			description: "",
			lookup_id: "security_register",
			id: 0,
			contact_id: Store.getStoreData("role") === "team" ? parseInt(this.props.requester_id) : 0,
			lookup_json: {
				is_restricted: false,
				security_type_id: this.state.selected_security_type_obj.id,
				currency_id: this.state.selected_currency_obj.id,
				identifier_types: identifier_types
			}
		};
		if (data.name === "") {
			alert("Name cannot be blank");
			return false;
		}
		//console.log("On Submit Identifier Types : ", identifier_types , " need One sec : ", Store.getStoreData("module_config").general.one_security_id_required);
		if (Object.keys(identifier_types).length === 0 && Store.getStoreData("module_config").general.one_security_id_required === true) {
			alert("Atleast one identifier type is mandatory");
			return false;
		}
		this.setState({ show_spinner : true });
		console.log("data:", data);
        this.props.modifySecurityRegister(data);
    };

    closeView = () => {
        this.props.closeModal();
    };
    render () {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }
		return (
			<div >
				{
					(()=>{
						if(this.state.show_spinner === true){
							console.log("Spinning Loader fired here")
							return <SpinningLoader />
						}
					})()
				}
				<ModalWraper style={{top : "40%", left : "50%", transform: "translate(-50%, -50%)", zIndex : "5000"}}>
	                <MRModalContainer>
	                    <MRModalHeader>
							<MRModalHeaderText>Create New Security</MRModalHeaderText>
							<MRModalHeaderCloseBtn onClick={this.closeView}>
					            <FaTimes />
					        </MRModalHeaderCloseBtn>
					        <ClearBoth />
					        <MRModalSubHeaderText>Complete the required fields to create a new Security Holding</MRModalSubHeaderText>
						</MRModalHeader>
	                    <MRModalBody>
	                        <Row>
	                            <MRModalLabel>Name</MRModalLabel>
	                            <TextBox type="text" value={this.state.security_name} name="security_name" onChange={this.handleChange}/>
	                        </Row>
	                        <Row>
								<MRModalLabel>Security Type</MRModalLabel>
								<CslSimpleDropDown options={this.state.security_types} selected={this.state.selected_security_type_obj} setSelection={this.changeRequeterSecurityType} />
							</Row>
	                        <Row>
								<MRModalLabel>Currency</MRModalLabel>
	                            <Section30>
								    <CslSimpleDropDown options={this.state.currencies} selected={this.state.selected_currency_obj} setSelection={this.changeRequeterCurrency} />
	                            </Section30>
	                            <Clearfix></Clearfix>
							</Row>
	                        {
	                            this.state.security_identifier_types.map((key) => {
	                                return (
	                                    <Row>
	                                        <MRModalLabel>{key.title}</MRModalLabel>
	                                        <TextBox type="text" name={"secu_" + key.title} value={key.value} onChange={this.handleChangeD} />
	                                    </Row>
	                                );
	                            })
	                        }
	                    </MRModalBody>
	                    <MRModalFooter>
	                        <MRModalNextBtn style={{float: "right"}} onClick={this.addNewSecurity}>SUBMIT</MRModalNextBtn>
	                        <CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeView}>CLOSE</CloseButton>
	                        <Clearfix />
	                    </MRModalFooter>
	                </MRModalContainer>
	            </ModalWraper>
            </div>
        )
    }
}
export default CreateSecurityRegisterModal;
