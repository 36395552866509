import React from 'react'
import styled from "styled-components";
import { FaTimes, FaCalendarAlt, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import DatePicker from "react-date-picker";
import moment from 'moment';
import Store from '../../../Common/Store';
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';
import Dropzone from "../../Dropzone/Dropzone.js";
import AlertBox from "../../Common/AlertBox.js";
import APICall from '../../../Common/APICall';
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { MdClear } from "react-icons/md";
// const moment = require("moment");
const last_action_time = moment().unix();

const Container = styled.div`
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	box-sizing: border-box;
	width: 40%;
`;
const MRModalHeader = styled.div`
	border-left: 10px solid #11ABA6;
	height: 100px;
	background-color: #ffffff;
	padding: 0px 30px;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	width: 80%;
	margin-top: 24px;
	// margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	// float: left;
	// margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	float: right;
	box-sizing: border-box;
	margin-top: 21px;
	font-size: 20px;
	font-weight: 200;
	color: #656565;
`;
const Clearfix = styled.div`
	clear: both;
`;
const ModalBody = styled.div`
	padding: 20px 30px 20px 30px;
	background-color: #F1F6F7;
	border-top: 1px solid rgb(208 209 210);
    height: 500px;
    overflow-y: scroll;
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
	font-family: 'Montserrat',sans-serif;
`;
const TextArea = styled.textarea`
	resize: none;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	max-width: 100%;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
`;
const CSLDateCover = styled.div`
  width: 30%;
  box-sizing: border-box;
  height: 40px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  // font-size: 14px;
  // font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  & > div {
    padding: 3px 4px;
    border: none;
    & > div > button {
      padding-top: 9px;
    }
  }
`;
const ModalFooter = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 15px 30px 15px 0px;
	background-color: #ffffff;
`;
const SubmitButton = styled.button`
	box-sizing: border-box;
	color: #ffffff;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
  	padding: 10px 20px;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
    color: #2d2c2c;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #DBDBDB;
    padding: 10px 20px;
    font-weight: 600;
`;

class CSLAtteachmentViewer extends React.Component {
  state = { ready: false, files: null, task_id: null };

  setComponentState = () => {
    console.log("this.props", this.props);
    if (this.props.task_id) {
      this.setState({ ready: true, files: this.props.files, task_id: this.props.task_id });
    }
  };
  componentDidMount = () => {
    this.setComponentState();
  };
  componentDidUpdate = (prevprops) => {
    if (this.props === prevprops) return;
    this.setComponentState();
  };



  handleDownload(image_id) {
  	console.log("image_id", image_id)
    let api = new APICall();
    let postData = { command: "download_followup_attachment", image_id: image_id};
    console.log("postData", postData);
    api.command(postData, this.processDownloadFile);
  }
  handleRemove(image_id) {
    let bin_files = this.state.files;
    let fls = bin_files.filter(item => item.ref_id !== image_id);
    this.setState({files: fls});
    console.log("bin_files", fls);
    this.props.updateAttachments(fls)
  }

  processDownloadFile = (result) => {
    console.log("Attachment", result);
    let dbinfile = result.result;
    let dnld = dbinfile.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, result.name);
  };



  imageUrl(file) {
    const file_parts = file.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOC";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      default:
        ext = "PDF";
        break;
    }
    console.log("ext", ext);
    let file_icon_var = ext === "DOCX" ? "DOC" : ext;
    file_icon_var = ext === "XLSX" ? "XLS" : ext;
    const file_icon = `/dropzoneImages/${ext}.png`;
    return file_icon;
  }

  render() {
    if (!this.state.ready) return <div></div>;
    return (
      <div style={{ width: "100%" }}>
        {
        	this.state.files.map((f, index) => {
        		return (
        		  <div key={index} style={{ width: "50%", boxSizing: "border-box", padding: index === 0 || index % 2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px" }}>
        		    <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
        		      <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
        		        <img src={this.imageUrl(f.name)} style={{ height: "100px", width: "auto" }} />
        		      </div>
        		      <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
        		        <div>
        		          <strong title={f.name} style={{ color: "#000000" }}>
        		            {f.name.length > 20 ? f.name.substring(0, 20) : f.name}
        		          </strong>
        		          <br />
        		          <br />
        		        </div>
        		        <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(f.ref_id)}>
        		          <FiDownload /> Download
        		        </div>
        		        <div style={{ cursor: "pointer", marginTop: "10px" }} onClick={() => this.handleRemove(f.ref_id)}>
        		          <MdClear /> Remove
        		        </div>
        		      </div>
        		      <div style={{ clear: "both" }}></div>
        		    </div>
        		  </div>
        		);
        	})
        }
      </div>
    );
  }
}

class AddFollowupModal extends React.Component {

	state = {task: null, contacts: [], selected_contact_obj: null, binFiles: [], cur_files_json: null,alert_param: null, attachments: []};

    componentDidMount() {
        let task = JSON.parse(JSON.stringify(this.props.current_followup));
        let attachments = task.attachments;
        console.log("task current followup", task)
		const selected_contact_obj = this.props.followup_contacts.find(item => parseInt(item.id) === parseInt(task.assigned_to));
        this.setState({task, contacts: this.props.followup_contacts, selected_contact_obj, attachments: attachments});
    }

    updateAttachments = (bin_files) => {
    	let task = this.state.task;
    	task['attachments'] = bin_files;
    	this.setState({task})
    }

    changeAssignTo = (data) => {
		let selected_contact_obj = this.state.contacts.find(item => item.id === data);
        let task = JSON.parse(JSON.stringify(this.state.task));
        task.assigned_to = data;
		this.setState({selected_contact_obj, task});
	}

	handleChange = (element) => (event) => {
		event.preventDefault();
        let task = JSON.parse(JSON.stringify(this.state.task));
        task[element] = event.target.value;
		this.setState({task});
	}

	changeCheck = (entity, status) => (event) => {
		event.preventDefault();
		this.setState({[entity]: status});
	}

	closeFollowupModal = (event) => {
		event.preventDefault();
		this.props.closeFollowupModal();
	}

	addFollowup = (event) => {
		event.preventDefault();
		// this.props.modifyCurrentFollowup(this.state.task);
		let bin_files = this.state.binFiles;
		if(bin_files.length === 0) {
			let task = this.state.task;
			// task['attachments'] = [];
			this.props.modifyCurrentFollowup(task);
		}else{
			let dnld = bin_files[0].bin_file.replace(/ /g, "+");
			let binaryString = window.atob(dnld);
			var fileSize = binaryString.length;
			if(fileSize > 5000000){
			  let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
			  this.setState({ alert_param: alert_param });

			}else{
			  this.refs.dropZone.uploadFilesFromChild(bin_files);
			}
		}
	}

	processORcancel = (result, stack) => {
	  if (!result || stack.prevent) {
	    this.setState({ alert_param: null });
	    return;
	  }
	  this.setState({ alert_param: null });
	  let bin_files = this.state.binFiles;
	  this.refs.dropZone.uploadFilesFromChild(bin_files);
	}

	onUploadComplete = (files) => {
		let contact_id = Store.getStoreData("loggedin_contact_id");
		// alert("hello", files)
	  let returnedFiles = JSON.parse(files);
	  // console.log("hello==>", returnedFiles)
	  let bin_files = this.state.binFiles;
	  let attachments = this.state.task['attachments'];
	  console.log("attachments==>", returnedFiles)
	  if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
	    bin_files.forEach((file) => {
	      let fileName = file.name;
	      let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
	      delete file.bin_file;
	      file.ref_id = refID;
	      // attachments.push({uid: file.uid, name: file.name, ref_id: refID})
	      attachments.push({uid: file.uid, name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time})
	    });
	  }
	  let task = this.state.task;
	  task['bin_files'] = attachments;
	  task['attachments'] = attachments;
	  console.log("task==>", task)
	  this.props.modifyCurrentFollowup(this.state.task);
	}

	filesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_contact_id");
	  let a = []
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	  }
	  this.setState({binFiles: files,addnew: false});
	};

	render () {
        if (this.state.task === null) {
            return <div></div>
        }
		return (
			<Container>
				<MRModalHeader>
					<MRModalHeaderText>Add Follow Up Task</MRModalHeaderText>
					<MRModalHeaderCloseBtn><FaTimes style={{cursor: "pointer"}} onClick={this.closeFollowupModal} /></MRModalHeaderCloseBtn>
			        <Clearfix />
			        <MRModalSubHeaderText>Create Follow Up task for this PA Dealing record</MRModalSubHeaderText>
				</MRModalHeader>
				<ModalBody>
					<Row>
						<MRModalLabel>Subject</MRModalLabel>
						<TextBox type="text" value={this.state.task.subject} onChange={this.handleChange("subject")} />
					</Row>
					<Row>
						<MRModalLabel>Note</MRModalLabel>
						<TextArea rows="5" cols="120" value={this.state.task.note} onChange={this.handleChange("note")}></TextArea>
					</Row>
                    <Row>
                        <MRModalLabel>Assign To</MRModalLabel>
                        <CslSimpleDropDown options={this.state.contacts} selected={this.state.selected_contact_obj} setSelection={this.changeAssignTo} />
                    </Row>
					<Row style={{marginBottom: "0px"}}>
						<MRModalLabel>Due Date</MRModalLabel>
						<CSLDateCover style={{ display: "inline-block" }}>
							<DatePicker
								onChange={(e) => {
									// this.setState({ followup_date: moment(e).format("DD/MM/YYYY") });
                                    let task = JSON.parse(JSON.stringify(this.state.task));
                                    task.due_date = moment(e).unix();
                                    this.setState({task});
								}}
								clearIcon={null}
								calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
								locale={"en-GB"}
								className="csldatecontainer"
								value={moment.unix(this.state.task.due_date).toDate()}
							/>
						</CSLDateCover>
					</Row>
					<Row style={{marginTop: "10px"}}>
						  <MRModalLabel>Attachments</MRModalLabel>
						  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />
					</Row>
					{
							(() => {
						  if (this.state.task['attachments'].length > 0) {
						    return <Row><CSLAtteachmentViewer files={this.state.task['attachments']} task_id={this.state.task["id"]} updateAttachments={this.updateAttachments} /></Row>;
						  }
						})()
					}
					<AlertBox alertParam={this.state.alert_param} />
				</ModalBody>
				<ModalFooter>
					<SubmitButton style={{float: "right"}} onClick={this.addFollowup}>ADD</SubmitButton>
					<CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeFollowupModal}>CANCEL</CloseButton>
					<Clearfix />
				</ModalFooter>
			</Container>
		);
	}

}

export default AddFollowupModal;
