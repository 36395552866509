import React from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import Store from "./../../Common/Store";
import APICall from './../../Common/APICall';
import YesNoBox from './YesNoBox';
import Utils from '../../Common/Utils';
import SpinningLoaderSmall from './SpinningLoaderSmall';
// import { Icon } from 'react-icons-kit';
// import {chevronDown} from 'react-icons-kit/ionicons/chevronDown';
// import {chevronUp} from 'react-icons-kit/ionicons/chevronUp';

import { FaSort, FaExclamationTriangle, FaRecordVinyl } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";

import { FaSearch } from "react-icons/fa";
import { data } from 'jquery';
import ConfigSecurityRegisterLists from '../PAD/module_config/include/SecurityRegisterLists';

const DropContainer = styled.div`
	min-height: 40px;
	width: 100%;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 6px 10px;
	background-color: #ffffff;
	cursor: pointer;
`;
const Section85 = styled.div`
	float: left;
	width: 85%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
// const Section20 = styled.div`
// 	float: left;
// 	width: 20%;
// 	box-sizing: border-box;
// `;
const Section15 = styled.div`
	float: left;
	width: 15%;
	box-sizing: border-box;
`;
// const ColorBox = styled.div`
// 	width: 24px;
// 	height: 24px;
// 	border-radius: 4px;
// `;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 5px;
`;
const SingleOption = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 20px 0px;
	cursor: pointer;
	&:hover {
		background-color: #DBDBDB;
	}
`;
const ActionDiv = styled.div`
	border-top: 1px solid #DBDBDB;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    color: #01B0A2;
    text-decoration: underline;
    cursor: pointer;
`;

const HeaderCell = styled.div`
	float: left;
	padding: 10px 0px;
	font-weight: 600;
`;
const HR = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgb(208 209 210);
`;
const TextSearch = styled.input`
	height: 30px;
	box-sizing: border-box;
	width: 100%;
	font-family: 'Montserrat', sans-serif;
`;

const MinCharMsg = styled.div`
	padding : 30px;
	text-align : center;
	font-weight : bold;
	color : #bbbbbb;
`
const ApiRecordSeperator = styled.div`
	height : 25px;
	position : relative;
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
`
const ApiSeperatorHr = styled.div`
	height : 3px;
	background-color : #837F7F;
	width : 80%;
	margin : auto;
	position : absolute;
`

const ApiSeperatorMsg = styled.div`
	color : #787878;
	font-weight : bold; 
	background-color : white;
	z-index : 2;
	padding : 0px 10px 0px 10px;
	position : absolute;
`

const NoRecFoundMsg = styled.div`
	position : relative;
	width : 100%;
	height : 100%;
	display : flex;
	justify-content : center;
	align-items : center;
	font-weight : bold;
	color : #999999;
`


class TableDropdown extends React.Component {

	state = {
		show_options: false,
		search_highlight : false,
		search_active : false,
		search_string : "",
		search_string_user_entered : "",
		api_data_found : false,
		api_data : [],
		api_data_searched : [],
		original_data : [],
		filtered_data : [],
		filtered_data_searched : [],
		alertParam : null,
		selected_data : "",
		is_holding : false,
		activate_security_not_listed : false,
		show_small_spinner : false,
		no_rec_found : false,
		res_user: {},
		ready: false
	};

	componentDidMount() {
		let original_data = JSON.parse(JSON.stringify(this.props.table_data.data));
		let filtered_data = [];
		let module_config = Store.getStoreData("module_config");
		let selected_data = JSON.parse(JSON.stringify(this.props.table_data.selected));
		let alertParam = null;
		let is_holding = false;
		let activate_security_not_listed = false;
		//Change Arnab
		for(let item of this.props.table_data.header) {
			if(item.accessor === "holding") is_holding = true;
		}	
		filtered_data = Store.getStoreData('module_config').general.security_min_search_chars === 0 || is_holding === true ? JSON.parse(JSON.stringify(original_data)) : [];
		if (Store.getStoreData('module_config').general.security_min_search_chars === 0 ) activate_security_not_listed = true;
		if(Store.getStoreData('module_config').general.use_security_register === false && Store.getStoreData('module_config').general.security_hot_lookup.enabled === false) activate_security_not_listed = true;
		this.setState({ original_data,
			// filtered_data,
			selected_data, is_holding,
			activate_security_not_listed,
			show_small_spinner: true
		});

		let api = new APICall();
		let postData = { command: "financial_services", action: "list_user_restricted_api", requester_id: this.props.requester_id };
		console.log("postData", postData);
		api.command(postData, this.processUserRestrictedResult(filtered_data));
	}

	processUserRestrictedResult = (filtered_data) => (result) => {
		console.log("result.res_user==>", result.res_user)

		let show_restricted_security_information = Store.getStoreData('module_config').general.show_restricted_security_information
		let update_filtered_data = []
		console.log("filtered_data", filtered_data)
		if(filtered_data.length !== 0){
			for(let d of filtered_data){
				let found_record_object = d;
				if(d.icon === 'Warning'){
					if(d.id in this.state.res_user){
						found_record_object['icon'] = 'Warning'
						if(!show_restricted_security_information){
							found_record_object['icon'] = 'none'
						}
					}
				}else{
					if(d.id in this.state.res_user){
						found_record_object['icon'] = 'Warning'
					}
				}
				update_filtered_data.push(found_record_object);
			}
			// filtered_data.forEach(record => {
			// 	let found_record_object = record;
			// 	if(record.icon === 'Warning'){
			// 		if(record.id in this.state.res_user){
			// 			found_record_object['icon'] = 'Warning'
			// 			if(!show_restricted_security_information){
			// 				found_record_object['icon'] = 'none'
			// 			}
			// 		}else{
			// 			found_record_object['icon'] = 'none'
			// 		}
			// 	}else{
			// 		console.log("Sourav")
			// 		if(record.id in this.state.res_user){
			// 			found_record_object['icon'] = 'Warning'
			// 		}
			// 	}
			// 	update_filtered_data.push(found_record_object);
			// });
		}

		console.log("update_filtered_data", update_filtered_data)

		this.setState({ res_user : result.res_user, filtered_data: update_filtered_data, show_small_spinner: false, ready: true});
	}

	componentDidUpdate(prevprops) {
		if (this.props.table_data.data !== prevprops.table_data.data) {
			let api = new APICall();
			let postData = { command: "financial_services", action: "list_user_restricted_api", requester_id: this.props.requester_id };
			console.log("postData", postData);
			api.command(postData, this.processUserUpdateRestrictedResult);
		}
	}

	processUserUpdateRestrictedResult = (result) => {
		this.setState({ res_user : result.res_user});

		let original_data = JSON.parse(JSON.stringify(this.props.table_data.data));
		let filtered_data = [];
		let module_config = Store.getStoreData("module_config");
		let selected_data = JSON.parse(JSON.stringify(this.props.table_data.selected));
		let alertParam = null;
		let activate_security_not_listed = false;
		let is_holding = false;
		//Change Arnab
		for(let item of this.props.table_data.header) {
			if(item.accessor === "holding") is_holding = true;
		}
		if (Store.getStoreData('module_config').general.security_min_search_chars === 0 ) activate_security_not_listed = true;
		if(Store.getStoreData('module_config').general.use_security_register === false && Store.getStoreData('module_config').general.security_hot_lookup.enabled === false) activate_security_not_listed = true;
		filtered_data = Store.getStoreData('module_config').general.security_min_search_chars === 0 || is_holding === true ? JSON.parse(JSON.stringify(original_data)) : [];


		let show_restricted_security_information = Store.getStoreData('module_config').general.show_restricted_security_information
		let update_filtered_data = []
		if(filtered_data.length !== 0){
			filtered_data.forEach(record => {
				let found_record_object = record;
				if(record.icon === 'Warning'){
					if(record.id in this.state.res_user){
						found_record_object['icon'] = 'Warning'
						if(!show_restricted_security_information){
							found_record_object['icon'] = 'none'
						}
					}else{
						found_record_object['icon'] = 'none'
					}
				}
				update_filtered_data.push(found_record_object);
			});
		}

		this.setState({ original_data, filtered_data: update_filtered_data, selected_data, is_holding, search_highlight : false, activate_security_not_listed, ready: true});
	}

	setDefaultSearchChars = () => {
		console.log("Callback 2");
		let api = new APICall();
		let postData = { command: "set_default_search_chars" };
		console.log("postData", postData);
		api.command(postData, this.defaultSet);
	}

	defaultSet = (result) => {
		console.log(result);
		let module_config = JSON.parse(JSON.stringify(Store.getStoreData("module_config")));
		module_config.general.security_min_search_chars = 5;
		Store.updateStore("module_config", module_config);
		this.setState({alertParam: null});
	}

	closeYesNO = () => {
		console.log("Yes No Closed");
		this.setState({alertParam: null});
	}
	
	toggleOptions = (event) => {
		event.preventDefault();
		const show_options = this.state.show_options ? false : true;
		this.setState({show_options});
	}

	handleClick = (data) => (event) => {
		event.preventDefault();
		if ("from_api" in data) {
			this.setState({show_options: false, selected_data: data});
			this.props.setApiSecurity(data);
		} else {
			this.props.setSelected(data);
			this.setState({show_options: false, selected_data: data});
		}
	}

	genKey = (length) => {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	doAction = (event) => {
		event.preventDefault();
		this.props.actionCallback();
	}

	searchBoxOnChange = (e) => {
		e.preventDefault();
		let defChars = Store.getStoreData('module_config').general.security_min_search_chars;
		let secHot = Store.getStoreData('module_config').general.security_hot_lookup.enabled;
		let search_highlight = false;
		if(e.target.value.length >= defChars && defChars != 0 && this.state.is_holding === false){	
			search_highlight = true;
		}
		let search_string = e.target.value;
		let found_record = this.state.filtered_data;
		let found_api_record = this.state.api_data_searched;
		let data_to_search = this.state.filtered_data_searched;
		let api_data_to_search = this.state.api_data_searched;
		let activate_security_not_listed = false;  //change arnab
		let no_rec_found = false;
		//console.log("defChars => ", defChars, "secHot => ", secHot, "found_record => ", found_record);
		let show_restricted_security_information = Store.getStoreData('module_config').general.show_restricted_security_information
		if(secHot === false  || this.state.is_holding === true){
			if(defChars === 0 || this.state.is_holding === true){
				found_record = [];
				//console.log("IN FILTER");
				if(search_string.length > 0){
					this.state.original_data.forEach(record => {
						//console.log(record.name);
						if(record.name.toLowerCase().includes(search_string.toLowerCase()) || record.identifiers.toLowerCase().includes(search_string.toLowerCase())){
							// found_record.push(record);
							let found_record_object = record;
							console.log("Search founded record===>", record)
							if(record.icon === 'Warning'){
								if(record.id in this.state.res_user){
									found_record_object['icon'] = 'Warning'
									if(!show_restricted_security_information){
										found_record_object['icon'] = 'none'
									}
								}else{
									found_record_object['icon'] = 'none'
								}
							}
							found_record.push(found_record_object);
						}
					});
					if(found_record.length === 0) no_rec_found = true;
					console.log("no_rec_found 1 : ", no_rec_found);
				} else {
					found_record = JSON.parse(JSON.stringify(this.state.original_data))
				}
				activate_security_not_listed = true;   //change arnab
			}else if(defChars !==0){              //change arnab
					found_record = [];
					//console.log("IN FILTER");
					if(search_string.length >= defChars){
						this.state.original_data.forEach(record => {
							//console.log(record.name);
							if(record.name.toLowerCase().includes(search_string.toLowerCase()) || record.identifiers.toLowerCase().includes(search_string.toLowerCase())){
								// found_record.push(record);
								let found_record_object = record;
								console.log("Search founded record===>", record)
								if(record.icon === 'Warning'){
									if(record.id in this.state.res_user){
										found_record_object['icon'] = 'Warning'
										if(!show_restricted_security_information){
											found_record_object['icon'] = 'none'
										}
									}else{
										found_record_object['icon'] = 'none'
									}
								}
								found_record.push(found_record_object);
							}
							activate_security_not_listed = true;   //change arnab
							if(found_record.length === 0 && search_string.length >= defChars) {
								no_rec_found = true;
							} else {
								no_rec_found = false;
							}
							console.log("no_rec_found 2 : ", no_rec_found);
						});
					} else {
						found_record = [];
						//no_rec_found = true;
					}
			}           //change arnab
		} else {
			console.log("SEARCH STRING NOW : ", this.state.search_string_user_entered);
			if( search_string.includes(this.state.search_string_user_entered) && search_string.length >= this.state.search_string_user_entered.length) {
				if(this.state.filtered_data_searched.length > 0)
				{
					found_record = [];
					this.state.filtered_data_searched.forEach(record => {
						if(record.name.toLowerCase().includes(search_string.toLowerCase()) || record.identifiers.toLowerCase().includes(search_string.toLowerCase())){
							// found_record.push(record);
							let found_record_object = record;
							console.log("Search founded record===>", record)
							if(record.icon === 'Warning'){
								if(record.id in this.state.res_user){
									found_record_object['icon'] = 'Warning'
									if(!show_restricted_security_information){
										found_record_object['icon'] = 'none'
									}
								}else{
									found_record_object['icon'] = 'none'
								}
							}
							found_record.push(found_record_object);
						}
					})
				}
				if(this.state.api_data_searched.length > 0)
				{
					found_api_record = [];
					this.state.api_data_searched.forEach(record => {
						if(record.name.toLowerCase().includes(search_string.toLowerCase()) || record.identifiers.toLowerCase().includes(search_string.toLowerCase())){
							found_api_record.push(record);
							console.log("found_record : ", found_api_record);
						}
					})
				}
				if(found_record.length === 0 && found_api_record.length === 0 && defChars.length < search_string.length ) no_rec_found = true;
				console.log("no_rec_found 3 : ", no_rec_found);
			}
			else{
				console.log("Here no filtered data found");
				found_record = [];
				found_api_record = [];
				//no_rec_found = true;
			}
		}
		if(secHot === true && search_string.length < this.state.search_string_user_entered.length)
		{
			console.log("SEARCH STRING NOT VALID");
			data_to_search = [];
			api_data_to_search = [];
			found_record = [];
			found_api_record = [];
		}
		if(Store.getStoreData('module_config').general.use_security_register === false && Store.getStoreData('module_config').general.security_hot_lookup.enabled === false) activate_security_not_listed = true;
		this.setState({ filtered_data : found_record, search_highlight, search_string, api_data : found_api_record, api_data_searched : api_data_to_search, filtered_data_searched : data_to_search, activate_security_not_listed, no_rec_found });
	}

	searchBoxKeyPress = (e) =>
	{
		if(e.key.toString() === "Enter"){
			if(this.state.search_string.length >= Store.getStoreData('module_config').general.security_min_search_chars) this.fireSearch();
		}		
	}

	searchIconOnClick = (e) => {
		e.preventDefault();
		this.fireSearch();
	}

	fireSearch = () => {
		//e.preventDefault();
		let no_rec_found = false;
		let hot_enabled = Store.getStoreData('module_config').general.security_hot_lookup.enabled;
		let search_string = this.state.search_string;
		let show_restricted_security_information = Store.getStoreData('module_config').general.show_restricted_security_information
		if(hot_enabled === true){
			let api = new APICall();
			let postData = { command: "financial_services", action: "list_securities_from_api", search_string : this.state.search_string };
			console.log("postData", postData);
			api.command(postData, this.processResult);
		} else {
			let defChars = Store.getStoreData('module_config').general.security_min_search_chars;
			let found_record = [];
			if(search_string.length >= defChars){
				this.state.original_data.forEach(record => {
					//console.log(record.name);
					if(record.name.toLowerCase().includes(search_string.toLowerCase()) || record.identifiers.toLowerCase().includes(search_string.toLowerCase())){
						let found_record_object = record;
						console.log("Search founded record===>", record)
						if(record.icon === 'Warning'){
							if(record.id in this.state.res_user){
								found_record_object['icon'] = 'Warning'
								if(!show_restricted_security_information){
									found_record_object['icon'] = 'none'
								}
							}else{
								found_record_object['icon'] = 'none'
							}
						}
						found_record.push(found_record_object);
					}
				});
				if(found_record.length === 0) no_rec_found = true;
				console.log("no_rec_found 4 : ", no_rec_found);
			} else {
				found_record = [];
			}
			this.setState({ filtered_data : found_record, show_small_spinner : false, no_rec_found});
		}
		this.setState({activate_security_not_listed : true, show_small_spinner : true});
	}

	processResult = (result) => {                            /*Only for Hot Look up enabled*/
		console.log("Serch Result ===>",result);
		let no_rec_found = false;
		if (result.result === "Api Key is not set.") {
			let alertParam = {
				"header" : "Security Lookup Error",
				"subheader" : "",
				"msg" : "Api Key is not set. Please set it first",
				"closefunc" : this.closeYesNO,
				"buttons" : {
					"1" : {
						"caption" : "Ok",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeYesNO
					}
				}
			}
			this.setState({alertParam, show_small_spinner : false});
		} 
		else if(result.result === "API Error") {
			let alertParam = {
				"header" : "Security Lookup Error Returned",
				"subheader" : "",
				"msg" : "An error has occured due to invalid API Key or any other reason.<br>Please contact your system administrator.",
				"closefunc" : this.closeYesNO,
				"buttons" : {
					"1" : {
						"caption" : "Ok",
						"bgcolor" : "#4472C4",
						"color" : "white",
						"callback" : this.closeYesNO
					}
				}
			}
			this.setState({alertParam, show_small_spinner : false});
		} else {
			let defChars = Store.getStoreData('module_config').general.security_min_search_chars;
			let selected_service = Store.getStoreData('module_config').general.security_hot_lookup.selected_service;
			let search_string = this.state.search_string;
			let found_record = [];
			let show_restricted_security_information = Store.getStoreData('module_config').general.show_restricted_security_information
			if(search_string.length >= defChars){
				this.state.original_data.forEach(record => {
					//console.log(record.name);
					if(record.name.toLowerCase().includes(search_string.toLowerCase()) || record.identifiers.toLowerCase().includes(search_string.toLowerCase())){
						let found_record_object = record;
						console.log("Search founded record===>", record)
						if(record.icon === 'Warning'){
							if(record.id in this.state.res_user){
								found_record_object['icon'] = 'Warning'
								if(!show_restricted_security_information){
									found_record_object['icon'] = 'none'
								}
							}else{
								found_record_object['icon'] = 'none'
							}
						}
						found_record.push(found_record_object);
					}
				});
			} else {
				found_record = [];
			}

			let api_result = JSON.parse(result.result);
			let api_records = [];
			switch(selected_service){
				case "yahoo_fin" :
					api_records = api_result.ResultSet.Result;	
					break;
				case "alpha" :
					api_records = api_result;	
					break;
				case "market_stack" :
					api_records = api_result;
					break;
				case "eodhd" :
					api_records = api_result;
					break;
				default:
			}


			let api_data = [];
			if(api_records.length !==0) {
				for(let item of api_records) {
					let data =  {
						id : show_restricted_security_information ? 'process_id' in item ? item['process_id'] : Utils.genKey(16) : Utils.genKey(16),
						name : item["name"],
						identifiers : item["symbol"],
						icon : show_restricted_security_information ? 'process_id' in item ? "Warning" : "none" : "none",
						symbol : item["symbol"]
					}
					switch(selected_service){
						case "yahoo_fin" :
							data['typeDisp'] = item.typeDisp
							if(show_restricted_security_information){
								if(!('process_id' in item)) data['from_api'] = true
							}else{
								data['from_api'] = true
							}
							api_data.push(data)
							break;
						case "alpha" :
							data['typeDisp'] = item["type"]
							data['currency'] = item["currency"]
							if(show_restricted_security_information){
								if(!('process_id' in item)) data['from_api'] = true
							}else{
								data['from_api'] = true
							}
							api_data.push(data)
							break;
						case "market_stack" :
							data['typeDisp'] = item["type"]
							data['currency'] = item["currency"]
							data['has_eod'] = item["has_eod"]
							if(show_restricted_security_information){
								if(!('process_id' in item)) data['from_api'] = true
							}else{
								data['from_api'] = true
							}
							api_data.push(data)
							break;
						case "eodhd" :
							data['typeDisp'] = item["type"]
							data['currency'] = item["currency"]
							data['price'] = item["price"]
							data['ISIN'] = item["ISIN"]
							if(show_restricted_security_information){
								if(!('process_id' in item)) data['from_api'] = true
							}else{
								data['from_api'] = true
							}
							api_data.push(data)
							break;
						default:
					}					
				}
			}
			if(found_record.length === 0 && api_data.length === 0) {
				no_rec_found = true;
			} else {
				no_rec_found = false;
			}

			let update_api_data = []
			for(let d of api_data){
				if(!(d.id in this.state.res_user)){
					d['icon'] = 'none'
				}
				update_api_data.push(d)
			}
			console.log("no_rec_found 5 : ", no_rec_found);
			console.log("api_data : ", api_data);
			console.log("update_api_data : ", update_api_data);
			console.log("found_record : ", found_record);
			this.setState({ filtered_data : found_record, api_data : update_api_data, api_data_found : true, api_data_searched : update_api_data, filtered_data_searched : found_record, search_string_user_entered : search_string , show_small_spinner : false, no_rec_found })
		}
	}


	render()
	{
		// let column_width = 100 / this.props.table_data.header.length;
		if(this.state.original_data === []){
			return (
				<div>
					{this.state.alertParam !== null && <YesNoBox alertParam={this.state.alertParam} />}
				</div>
			)
		}
		let accessors = [];
		for (let item of this.props.table_data.header) {
			accessors.push(item.accessor);
		}
		
		let original_table_data = this.props.table_data;

		//console.log("Filtered Data in REnder : ", this.state.filtered_data);
		//console.log("alertParam in render:", this.state.alertParam);
		//Change Arnab

		console.log("Acitivate Security Not Listed : ", this.state.activate_security_not_listed);

		if(!this.state.ready){
			return(<div></div>)
		}
		
		return (
			<>
				{this.state.alertParam !== null && <YesNoBox alertParam={this.state.alertParam} />}
				<OutsideClickHandler onOutsideClick={() => {this.setState({show_options: false})}}>
					<div style={{position: "relative"}}>
						<DropContainer onClick={this.toggleOptions}>
							<Section85 style={{paddingTop: "5px"}}>{(this.state.selected_data === "" || this.state.selected_data === null) ? "" : this.state.selected_data.name}</Section85>
							<Section15 style={{textAlign: "right", paddingTop: "3px"}}>
								<FaSort style={{fontSize: "20px"}} />
							</Section15>
							<div style={{clear: "both"}}></div>
						</DropContainer>
						{
							(() => {
								// console.log("Original_data : ", this.state.original_data);
								console.log("Filtered_data in render : ", this.state.filtered_data);
								// console.log("Table Data : " , this.props.table_data);
								console.log("Table data in render : ", this.props.table_data);
								console.log("is holding in render : ", this.state.is_holding);
								if (this.state.show_options) {
									return (
										<OptionsContainer>
											<div style={{margin: "4px", border: "1px solid #DBDBDB", borderRadius: "4px"}}>
												<div style={{width: "92%", boxSizing: "border-box"}}><TextSearch style={{border: "0px", outline : "0px", margin: "0px", borderRadius: "4px", paddingLeft: "6px"}} onChange={this.searchBoxOnChange} onKeyPress={this.searchBoxKeyPress}/></div>
												<div style={{width: "10%", boxSizing: "border-box", textAlign: "center", position: "absolute", top: "5px", right: "3px"}}>
													<AiOutlineSearch style={{
														fontSize: this.state.search_highlight === true ? "26px" : "21px", 
														cursor: "pointer", 
														color : this.state.search_highlight === true ? "#000000" : "#656565", 
														marginTop: this.state.search_highlight === true ? "2px" : "5px",
														pointerEvents : Store.getStoreData('module_config').general.security_min_search_chars === 0 || Store.getStoreData('module_config').general.security_hot_lookup.enabled === false ? "none" : this.state.search_highlight === true ? "auto" : "none"
														
													}} onClick={this.searchIconOnClick}/>
												</div>
												<div style={{clear: "both"}}></div>
											</div>
											<div>
												{
													this.props.table_data.header.map(item => {
														return (
															<HeaderCell key={item.accessor} style={{width: `${item.width}%`, color: item.color}}>{item.display}</HeaderCell>
														)
													})
												}
												<div style={{clear: "both"}}></div>
											</div>
											<HR />
											<div style={{height: "250px", overflowY: "auto", boxSizing: "border-box"}}>
												{
													(() => {
														if(Store.getStoreData('module_config').general.security_min_search_chars !== 0 && this.state.search_string.length < Store.getStoreData('module_config').general.security_min_search_chars && this.state.is_holding === false){
															return <MinCharMsg>Please enter at least {Store.getStoreData('module_config').general.security_min_search_chars} characters to start your search</MinCharMsg>
														}	
													})()
												}
												{
													(() => {
														if(this.state.show_small_spinner === true){
															//console.log("Small Spinner Fired");
															return <SpinningLoaderSmall />
														}	
													})()
												}
												{
													this.state.api_data.map(item => {
														// console.log("item:", item);
														return (
															this.state.is_holding !== true &&
															<SingleOption key={item.id} onClick={this.handleClick(item)}>
																{
																	this.props.table_data.header.map(head => {
																		if (head.accessor === "icon") {
																			if (item.icon === "Warning") {
																				return (
																					<div key={this.genKey(10)} style={{width: `${head.width}%`, float: "left", color: "red", paddingLeft: "10px", boxSizing: "border-box"}}><FaExclamationTriangle /></div>
																				)
																			} else {
																				return (
																					<div key={this.genKey(10)} style={{width: `${head.width}%`, color: head.color, float: "left"}}>{head.display}</div>
																				)
																			}
																		} else {
																			// change if ISIN present in api_data
																			if(head.accessor === "identifiers" && Store.getStoreData('module_config').general.security_hot_lookup.selected_service === "eodhd" && "ISIN" in item === true)
																			{
																				return (
																					<div key={this.genKey(10)} style={{width: `${head.width}%`, float: "left"}}>{item["ISIN"]}, {item[head.accessor]}</div>
																				)	
																			} 
																			return (
																				<div key={this.genKey(10)} style={{width: `${head.width}%`, float: "left"}}>{item[head.accessor]}</div>
																			)
																		}
																	})
																}
																<div style={{clear: "both"}}></div>
															</SingleOption>
														)
													})
												}
												{
													(() => {
														console.log("Before reg found hr ", this.state.filtered_data)
														if(this.state.api_data_found === true && this.state.filtered_data.length !== 0 && this.state.is_holding !== true){
															return (
																<ApiRecordSeperator>
																	<ApiSeperatorHr />
																	<ApiSeperatorMsg>Found on your Security Register</ApiSeperatorMsg>
																</ApiRecordSeperator>
															)
														}	
													})()
												}
												{
													this.state.filtered_data.map(item => {
														// console.log("item:", item);
														return (
															<SingleOption key={item.id} onClick={this.handleClick(item)}>
																{
																	this.props.table_data.header.map(head => {
																		if (head.accessor === "icon") {
																			if (item.icon === "Warning") {
																				return (
																					<div key={this.genKey(10)} style={{width: `${head.width}%`, float: "left", color: "red", paddingLeft: "10px", boxSizing: "border-box"}}><FaExclamationTriangle /></div>
																				)
																			} else {
																				return (
																					<div key={this.genKey(10)} style={{width: `${head.width}%`, color: head.color, float: "left"}}>{head.display}</div>
																				)
																			}
																		} else {
																			return (
																				<div key={this.genKey(10)} style={{width: `${head.width}%`, float: "left"}}>{item[head.accessor]}</div>
																			)
																		}
																	})
																}
																<div style={{clear: "both"}}></div>
															</SingleOption>
														)
													})
												}
												{
													(() => {
														console.log("this.state.no_rec_found : ", this.state.no_rec_found);
														if(this.state.no_rec_found === true){
															
															return <NoRecFoundMsg>No securities match your search criteria</NoRecFoundMsg>
														}	
													})()
												}
											</div>
											<ActionDiv style={{ color: this.state.activate_security_not_listed === true ? "#01B0A2" : "#DDDDDD", pointerEvents: this.state.activate_security_not_listed === true ? "auto" : "none"}}>
												<span onClick={this.doAction}>{this.props.action_text}</span>
											</ActionDiv>
										</OptionsContainer>
									);
								}
							})()
						}
					</div>
				</OutsideClickHandler>
			</>
		);
	}

}

export default TableDropdown;