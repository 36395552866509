import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import styled from "styled-components";
import CSLTable from "../CSLTable/CSLTable";
import APICall from "../../../../Common/APICall";
//import Dropdown from "../../../Common/Dropdown";
import CSLToggle from "../CSLToggle";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";
import Store from "./../../../../Common/Store";
import ManageRestrictedConfigModal from './ManageRestrictedConfigModal';
import ToggleSwitch from "./../../../Common/ToggleSwitch";
import CSLLoader from './../../../Common/CSLLoader';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from './../../../../Common/XcelLoader';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`;

class ManageRestrictedLists extends React.Component {  

  state = {
    ready: false,
    filters: null,
    security_register: null,
    show_modal: null,
    type: {},
    active_section_not_ready : true,
    show_restricted_list: false,
    cur_list_id: null,
    mRestrictedList: {},
    mRestrictedActiveInactive: {},
    isLoadedIcon: false,
    showInactiveRSLists: false
  };

  constructor() {
    super();    
    this.api = new APICall();
  }

  componentDidMount() {  
    const postData = { command: "get_security_lookup_and_restricted_lists"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
    this.api.command(postData, this.getroleTypes);
  }

  getroleTypes = (result) => {
    console.log("result###", result)
    let filters = result.filter;
    let security_register = result.security_register;
    let mRestrictedList = result.mRestrictedList;
    let mRestrictedActiveInactive = result.mRestrictedListAll;
    this.setState({filters:filters, security_register: security_register, active_section_not_ready : false, mRestrictedList, mRestrictedActiveInactive, show_restricted_list: false})
  }

  componentDidUpdate(prevProps) {    
  } 
  
  performActionManageRestrictedList = (curid, action_type) => {
    console.log("role_id:", curid);
    console.log("action_type:", action_type);    
    if (action_type === "view") {      
      this.setState({cur_list_id: curid, show_restricted_list: true});
    }else if(action_type === 'export'){
      let wb = {SheetNames:[], Sheets:{}};        
      let open_tasks = Store.getStoreData('table_cur_data') === null ? [] : Store.getStoreData('table_cur_data'); 
      wb.SheetNames.push("Manage Restricted Lists"); 
      wb.Sheets["Manage Restricted Lists"] = XLSX.utils.json_to_sheet(XcelLoader.manage_restricted_lists(open_tasks)); 
      const format = "DD-MM-YYYY HH:mm:ss"
      let tm = moment().format(format);  
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, 'Manage_Restricted' + '-' + tm + fileExtension);
    }        
  }  

  toggleStatus = (id) => {    
    console.log("id:", id);
    let mRestrictedActiveInactive = this.state.mRestrictedActiveInactive;
    mRestrictedActiveInactive[id.toString()].is_active = mRestrictedActiveInactive[id.toString()].is_active === 0 ? 1 : 0;
    const postData = { command: "change_status_restricted_list"};    
    postData['is_active'] = mRestrictedActiveInactive[id.toString()].is_active;  
    postData['id'] = id;
    this.setState({mRestrictedActiveInactive, isLoadedIcon: true})
    this.api.command(postData, this.afterToggleStatus);
  }

  afterToggleStatus = (result) => {
    alert(result.error_msg); 
    this.setState({isLoadedIcon: false})   
  }
  
  tableDataMangeRestrictedList = () => {        
      let ret = {data: [], columns: []};
      const actions = [{name: "View", action: "view"}];
      const actionsExport = [{name: "Export", action: "export"}];
      ret.columns =[                      
                      {Header: 'Restricted List Name', accessor: 'restricted_list_name', minWidth: 120, headerStyle: {textAlign: 'left'}},					
                      {Header: 'Securities', accessor: 'securites', Cell: (row) =>
                          (() => {
                            if (row.original.securites === 0) {
                              return <div style={{ textDecoration: "underline", color: "#00B5B2", textUnderlinePosition: "under"}}>{row.original.securites}</div>;
                            } else {
                              return (<div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2", textUnderlinePosition: "under"}} placeholder="View" onClick={this.showRestrictedList(row.original.id)}>{row.original.securites}</div>);
                            }
                          })(), 
                          minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},
                      {Header: 'Employees', accessor: 'employees', Cell: (row) =>
                          (() => {
                            if (row.original.employees === 0) {
                              return <div style={{ textDecoration: "underline", color: "#00B5B2", textUnderlinePosition: "under"}}>{row.original.employees}</div>;
                            } else {
                              return (<div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2", textUnderlinePosition: "under"}} placeholder="View" onClick={this.showRestrictedList(row.original.id)}>{row.original.employees}</div>);
                            }
                          })(), 
                          minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},                        
                      {Header: 'Active', accessor: 'active', minWidth: 80, filterable: false, headerStyle: {textAlign: 'center'}, Cell: (row) =>
                          (() => {
                            if (row.original.id !== '0') {
                              return (<ToggleSwitch id={row.original.id} checked={parseInt(row.original.active) === 1 ? true : false} onClick={this.toggleStatus}/>)
                            }else{
                              return(<div></div>)
                            }
                          })(), 
                          style: { display: "flex", alignItems: "center", justifyContent: "center" }},
                      {
                        Header: <EllipsisMenuModConfig entity_id={0} actions={actionsExport} performAction={this.performActionManageRestrictedList} />,
                        Cell: (row) => (
                          <div style={{marginTop: "-8px"}}><EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performActionManageRestrictedList} /></div>
                        ),
                        width: 55,
                        headerStyle: { textAlign: "center" },
                        sortable: false,
                        filterable: false
                      }                        
                  ];   
      let count_restricted = 0 
      console.log("this.state.security_register===>", this.state.security_register)
      for(let key in this.state.security_register){             
          if (this.state.security_register[key].is_restricted === true && '0' in this.state.security_register[key].restricted_list_ids) {
            count_restricted ++;
          } 
      }  
      let count_cur_active_users = Object.keys(Store.getStoreData('cur_active_users')).length; 
      let elem = { 'id': '0', 'restricted_list_name': 'Default List, applicable to all employees', 'securites': count_restricted, 'employees': count_cur_active_users, 'active': ''}; 
      ret.data.push(elem);  
      
      for(let k in this.state.mRestrictedActiveInactive){
        if(this.state.showInactiveRSLists === false && this.state.mRestrictedActiveInactive[k.toString()].is_active === 1){
          let countEmployees = Object.keys(this.state.mRestrictedActiveInactive[k].employee_ids).length
          let coutsec = 0
          for(let l in this.state.security_register){
            if(k.toString() in this.state.security_register[l].restricted_list_ids){
              coutsec ++;
            }
          }
          
          let elem2 = { 
            'id': k, 
            'restricted_list_name': this.state.mRestrictedActiveInactive[k].name, 
            'securites': coutsec, 
            'employees': countEmployees, 
            'active': this.state.mRestrictedActiveInactive[k].is_active
          };
          ret.data.push(elem2);
        }else if(this.state.showInactiveRSLists){
          let countEmployees = Object.keys(this.state.mRestrictedActiveInactive[k].employee_ids).length
          let coutsec = 0
          for(let l in this.state.security_register){
            if(k.toString() in this.state.security_register[l].restricted_list_ids){
              coutsec ++;
            }
          }
          
          let elem2 = { 
            'id': k, 
            'restricted_list_name': this.state.mRestrictedActiveInactive[k].name, 
            'securites': coutsec, 
            'employees': countEmployees, 
            'active': this.state.mRestrictedActiveInactive[k].is_active
          };
          ret.data.push(elem2);
        }        
      }

      console.log("Ret===>", ret)
      Store.updateStore('table_cur_data', ret.data)
      return ret;
  }     

    showRestrictedList = (curlistid) => (event) => {
      event.preventDefault();
      this.setState({cur_list_id: curlistid, show_restricted_list: true})
    }    
    
    changeRestriction = (status) => {
      // event.preventDefault();
      let general = JSON.parse(JSON.stringify(this.props.general));
      general.show_restricted_security_information = general.show_restricted_security_information === true ? false : true;
      this.props.updateGeneralSave(general);
    }
    changeRestrictionAutoDecline = (status) => {
      // event.preventDefault();
      let general = JSON.parse(JSON.stringify(this.props.general));
      general.show_auto_decline = general.show_auto_decline === true ? false : true;
      this.props.updateGeneralSave(general);
    }

    showInactiveList = () => {     
      this.setState({showInactiveRSLists: true})
    }
    showHideInactiveList = () => {     
      this.setState({showInactiveRSLists: false})
    }

    closeModal = (event) => {    
      this.setState({ show_restricted_list: false });
    };

    refreshAndCloseModal = (event) => {
      const postData = { command: "get_security_lookup_and_restricted_lists"};
      postData['lookup_id'] = this.props.lookup_id;
      postData['lookup_ids'] = ["security_types","currencies","security_identifier_types"];
      this.api.command(postData, this.getroleTypes);      
    };

    refreshParent = (updateSecReg) => { 
      console.log("updateSecReg==>", updateSecReg)   
      this.setState({ security_register: updateSecReg });
    };      

    showAddMRestrictedListModal = (event) => {
      event.preventDefault();      
      this.setState({show_restricted_list: true, cur_list_id: null});
    };

  render() {
    // if (!this.state.ready) return <div>Loading...</div>;
    if(this.state.active_section_not_ready === true) {
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }
    return (
      <div>       
         <div style={{ width: "90%" }}>             
          {
            (() => {
              if(this.state.show_restricted_list){
                return <ManageRestrictedConfigModal mRestrictedActiveInactive={this.state.mRestrictedActiveInactive} closeModal={this.closeModal} refreshAndCloseModal={this.refreshAndCloseModal} curid={this.state.cur_list_id} filters={this.state.filters} security_register={this.state.security_register} lookup_id={this.props.lookup_id} updateSecurityRegister={this.refreshParent}/>
              }
            })()
          }  
          {
            (() => {
              if(this.state.isLoadedIcon){
                return <div><CSLLoader style={{position: 'absolute'}}/></div>
              }
            })()
          }  
          <TypesContainer>          
            <TypesInnerContainer>              
              <div style={{width: "100%", marginBottom: "2%", marginTop: "1%"}}>
                <div style={{ display: "inline-block", width: "25%", float: "left"}}>                                    
                  <TypesBoldLabel style={{float: "left", marginTop: "2px"}}>Manage Restricted Lists</TypesBoldLabel>
                </div>
                <div style={{ display: "inline-block", width: "70%"}}>     
                  <div style={{float: "left", marginRight: "10px", marginTop: "2px"}}>Show Restricted Security Information</div>
                  <div style={{float: "left", marginRight: "20px"}}><CSLToggle checked={this.props.general.show_restricted_security_information} onClick={this.changeRestriction} /></div>
                  
                  <div style={{float: "left", marginRight: "10px", marginTop: "2px"}}>Automatically Decline</div>
                  <div style={{float: "left"}}><CSLToggle checked={this.props.general.show_auto_decline} onClick={this.changeRestrictionAutoDecline} /></div>    
                </div>
                <div style={{ display: "inline-block", width: "5%"}}>
                  <div onClick={this.showAddMRestrictedListModal} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}><FaPlusCircle /></div>
                </div>
              </div>
              <CSLTable add={false} processData={this.tableDataMangeRestrictedList} tableRows={5} />
              {
                (() => {
                  if(this.state.showInactiveRSLists){
                    return(<div style={{float: "left", color: "#2E75B6", fontWeight: "600", fontStyle: "italic",marginTop: "21px",marginLeft: "4px", cursor: "pointer"}} onClick={this.showHideInactiveList}>Hide Inactive Restricted Lists</div>)
                  }else{
                    return(<div style={{float: "left", color: "#2E75B6", fontWeight: "600", fontStyle: "italic",marginTop: "21px",marginLeft: "4px", cursor: "pointer"}} onClick={this.showInactiveList}>Show Inactive Restricted Lists</div>)
                  }
                })()
              }
              
              <div style={{clear: "both"}}></div>  
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
    );
  }
}

export default ManageRestrictedLists;
