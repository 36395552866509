import React from "react";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import CSLTable from "../CSLTable/CSLTable";
import CSLToggle from "../CSLToggle";
// import Utils from "../../../../Common/Utils";
// import Store from "../../../../Common/Store";
import APICall from "../../../../Common/APICall";
import ManageBeneficiarie from "./modals/ManageBeneficiarie";
//import Dropdown from "../../../Common/Dropdown";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";

// const SearchInput = styled.input`
//   height: 30px;
//   width: 98%;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   padding: 5px;
//   color: #222222;
//   font-size: 15px;
//   font-family: "Montserrat", sans-serif;
// `;
// const MRModalLabel = styled.div`
//   color: #212121;
//   font-weight: 600;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   display: inline-block;
// `;
const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`;


class Beneficiaries extends React.Component {

  state = {
    role_types: null,
    prefered_columns: null,
    show_modal: null,
    type:{},
    active_section_not_ready : true,
  };

  constructor() {
    super();
    this.api = new APICall();
  }

  componentDidMount() {
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    console.log("postData", postData)
    this.api.command(postData, this.getroleTypes);
  }

  getroleTypes = (result) => {
    console.log(result.result)
    let prefered_columns = {};
    prefered_columns['name'] = 'Name';
    prefered_columns['status_value'] = 'Status';
    this.setState({role_types: result.result, prefered_columns: prefered_columns, active_section_not_ready : false})
  }

  toggleStatus = (is_active, id) => {
    let role_types = JSON.parse(JSON.stringify(this.state.role_types));
    // console.log("role_types", role_types)
    role_types[id.toString()].is_active = is_active ? 1 : 0;
    const postData = { command: "toggle_status"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['is_active'] = is_active ? 1 : 0;
    postData['id'] = id;
    this.role_types = role_types;
    this.api.command(postData, this.toggleroleType);
  }

  toggleTrackHoldings = (is_active, id) => {
    let role_types = JSON.parse(JSON.stringify(this.state.role_types));
    let lookup_json = JSON.parse(role_types[id.toString()].lookup_json);
    lookup_json.track_holdings = is_active;
    role_types[id.toString()].lookup_json = JSON.stringify(lookup_json);
    let harr = [];
    for (let key in role_types) {
      let ljson = JSON.parse(role_types[key.toString()].lookup_json);
      harr.push(ljson.track_holdings);
    }
    //console.log("Harr : ",harr);
    if (harr.every(val => val === false) === true) {
      let ajson = JSON.parse(role_types[id.toString()].lookup_json);
      ajson.track_holdings = true;
      role_types[id.toString()].lookup_json = JSON.stringify(ajson);
      alert("At least one Beneficiary must be flagged to track holdings. Please turn on tracking for another Beneficiary Type before switching this one off.");
      this.setState({role_types});
    } else {
      this.role_types = role_types;
      const postData = {command: "toggle_track_holdings", lookup_id: this.props.lookup_id, id: id, lookup_json: lookup_json};
      this.api.command(postData, this.afterToggleTrackHoldings);
    }
  }

  afterToggleTrackHoldings = (result) => {
    console.log(result);
    alert(result.error_msg);
    this.setState({role_types: this.role_types});
  }

  toggleroleType = (result) => {
    alert(result.error_msg);
    this.setState({role_types: this.role_types});
  }

  addType = (event) => {
    event.preventDefault();
    let type = {};
    type['id'] = 0
    type['status'] = 1
    type['name'] = ""
    this.setState({show_modal:'roletype', type: type});
  };

  openModal = (id) => {
    // event.preventDefault();
    let type = this.state.role_types[id.toString()];
    let t = {};
    t['id'] = id;
    t['status'] = type.is_active;
    t['name'] = type.name;
    this.setState({ show_modal: 'roletype', type: t });
  };

  deleteType = (id) => (event) => {
    let type = this.state.role_types[id.toString()];
    if (window.confirm(`Are you sure you want to delete this ${type['name']} from the list?`)) {
     const postData = { command: "delete_lookup"};
     postData['lookup_id'] = this.props.lookup_id;
     postData['id'] = id;
     const api = new APICall();
     api.command(postData, this.removeroleType);
    }
  };

  removeroleType = (result) => {
    console.log("In Remove Role Type")
    let msg = "Beneficiary successfully deleted";
    alert(msg);
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    this.api.command(postData, this.getroleTypes);
  }

  closeModal = (event) => {
    this.setState({ show_modal: null });
  };
  refreshParent = () => {
    this.setState({ show_modal: null });
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    console.log("postData", postData)
    this.api.command(postData, this.getroleTypes);
  }
  
  performAction = (role_id, action_type) => {
    console.log("role_id:", role_id);
    console.log("action_type:", action_type);
    if (action_type === "edit") {
      this.openModal(role_id);
    }
    if (action_type === "delete") {
      if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
        const postData = {
          command: "delete_lookup",
          lookup_id: this.props.lookup_id,
          check_key: "beneficiary_id",
          id: role_id
        };
        console.log("postData:", postData);
        this.api.command(postData, this.afterRemove);
      }
    }
  }

  afterRemove = (result) => {
    console.log(result);
    if (result.error_msg === "exists") {
      alert("This item cannot be removed as it is associated with tasks");
    } else {
      alert("This item is successfully removed");
      let role_types = JSON.parse(JSON.stringify(this.state.role_types));
      delete role_types[result.result.toString()];
      this.setState({role_types});
    }
  }
  
  tableData = () => {
    let table = { data: [], columns: [] };
    const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}];
    table.columns = [
      { Header: "#", accessor: "index", width: 30, headerStyle: { textAlign: "center" }, style: { fontFamily: "monospace", fontSize: "14px", textAlign: "right" } },
      { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      { Header: "Track Holdings", accessor: "track_holdings", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.track_holdings} onClick={this.toggleTrackHoldings} />, style: { display: "flex", alignItems: "center", justifyContent: "center" } },
      { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} />, style: { display: "flex", alignItems: "center", justifyContent: "center" } },
      {
        Header: "",
        Cell: (row) => (
          <EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performAction} />
        ),
        width: 55,
        headerStyle: { textAlign: "center" },
        filterable: false
      },
    ];
    let data = this.state.role_types;
    console.log("data:", data);
    let i = 0;
    for(let key in data){
      let lookup_json = JSON.parse(data[key].lookup_json);
      i++;
      let elem = {'index' : i, 
              'name': data[key].name,
              'id': data[key].id,
              'track_holdings': lookup_json.track_holdings,
              'status': data[key].is_active,
              'status_value' : data[key].is_active === 1 ? "Active" : "In-Active"
             };
      table.data.push(elem);
    }
    return table;
  }

  render() {

    if(this.state.active_section_not_ready === true) {
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }
    
    return (
      <div>
        <div style={{ width: "90%" }}>
          <TypesContainer>
          {
            (() => {
              if(this.state.show_modal){
                return <ManageBeneficiarie closeModal={this.closeModal} type = {this.state.type} refreshParent={this.refreshParent}/>
              }
            })()
          }
            <TypesInnerContainer>
              <div style={{ display: "flow-root", paddingBottom: "16px" }}>
                <div style={{ float: "left" }}><TypesBoldLabel style={{ display: "inline-block" }}>{this.props.sectionName}</TypesBoldLabel></div>
                <div onClick={this.addType} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                  <FaPlusCircle />
                </div>
              </div>
              <CSLTable add={false} processData={this.tableData} tableRows={10} />
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
    );
  }
}

export default Beneficiaries;
