import React from 'react';
import Store from '../../../Common/Store.js'
import Iframe from 'react-iframe'
import { jsPDF } from 'jspdf'
//import * as jsPDF from 'jspdf'
// import {FaTimes} from 'react-icons/fa';
// import styled from 'styled-components';
// import Montserrat from './Montserrat/Montserrat-Regular.ttf';
import './Pdf_Font_n_Icons/Montserrat-Bold-bold'
import './Pdf_Font_n_Icons/Montserrat-BoldItalic-bolditalic'
import './Pdf_Font_n_Icons/Montserrat-Italic-italic'
import './Pdf_Font_n_Icons/Montserrat-Medium-normal'
import './Pdf_Font_n_Icons/Montserrat-MediumItalic-italic'
import './Pdf_Font_n_Icons/Montserrat-Regular-normal'
import './Pdf_Font_n_Icons/Montserrat-SemiBold-bold'
import './Pdf_Font_n_Icons/Montserrat-SemiBoldItalic-bold'
import icon_url from './Pdf_Font_n_Icons/pdf_icon.svg';
import moment from 'moment';
import "./TranConfirmationReport.css"

//import demodata from './Demodata'

const marginX = 12;
const marginY = 12;
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const centerX = 105;
const footer_height = 15;
const para_gap = 9;
const line_gap = 5;

class TransConfirmationPdf extends React.Component{

    state = {
        padPDF : null, 
        pdfTemp : null, 
        pdfURL: null, 
        pageWidth: maxX, 
        pageHeight: maxY, 
        ready: false,
        report_title : "",
        from_date : null,
        to_date : null,
        employee : null,
        report_data : [],
        showBrokers : false,
        brokerData : []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        //this.generatePdf();
        //console.log('Props report : ', this.props);
        let transactions_to_view = this.props.transactions;
        let report_data = [];
        if(transactions_to_view.length > 0){
            for(let r of transactions_to_view){
                let data = {
                    pad_id : "PAD-" + r.pad_id,
                    trade_date : r.trade_date,
                    instruction : r.transaction_type,
                    security : r.security_name !== undefined && r.security_name !== null && r.security_name !== 'undefined'? r.security_name : '-',
                    identifier : r.security_id !== undefined && r.security_id !== null && r.security_id !== 'undefined' ? r.security_id : '-',
                    currency : r.currency !== undefined && r.currency !== null && r.currency !== 'undefined' ? r.currency : '',
                    volume : r.volume !== undefined && r.volume !== null && r.volume !== 'undefined' ? r.volume : '-',
                    price : r.price !== undefined && r.price !== null && r.price !== 'undefined' ? r.price : '-',
                    amount : r.amount !== undefined && r.amount !== null && r.amount !== 'undefined' ? r.amount : '-',
                    broker : r.broker
                }
                report_data.push(data);
            }
        }
        let showBrokers = this.props.showBrokers;
        let brokerData = [];
        if(showBrokers === true){
            brokerData = this.props.brokersToView;
        }
        //console.log("ROWS : ", report_data);
        this.setState({ 
            report_data, 
            report_title : this.props.report_title, 
            from_date : this.props.from_date, 
            to_date : this.props.to_date, 
            employee : this.props.employee, 
            response_date : this.props.response_date, 
            response_time : this.props.response_time,
            showBrokers,
            brokerData 
        })        
    }

    headerSection = async () =>{
        //console.log("Header start Y : ",this.current_y);
        this.padPDF.setFontSize(13)
        this.padPDF.setFont('Montserrat-Bold','bold');
        let titleSplit = this.padPDF.splitTextToSize(this.state.report_title, 108);
        this.padPDF.text(titleSplit, this.current_x, this.current_y, {align : "left"});

        this.padPDF.setFontSize(12)
        let nameSplit = this.padPDF.splitTextToSize(this.state.employee, 63);
        this.padPDF.text(nameSplit, maxX + marginX, this.current_y, {align : "right"});

        if(titleSplit?.length > nameSplit?.length){
            this.current_y += (titleSplit?.length-1)*(13*0.42);
        }else{
            this.current_y += (nameSplit?.length-1)*(12*0.42);
        }

        this.current_y += 10 
        //console.log("Increase Y . 1 : ",this.current_y);

        this.padPDF.setFontSize(10)
        this.padPDF.setFont('Montserrat-Medium','normal');
        this.padPDF.text(`${moment(moment(this.state.from_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")} to ${moment(moment(this.state.to_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")}`, this.current_x, this.current_y, {align : "left"});

        if(this.currentPageNumber > 1){
            this.padPDF.setFont('Montserrat-BoldItalic','bolditalic');
            this.padPDF.text('Continued', centerX, this.current_y, {align : "center"});
        }

        this.current_y += 6
        //console.log("Increase Y . 2 : ",this.current_y);

        this.padPDF.setDrawColor('#ccc')
        this.padPDF.rect(this.current_x, this.current_y, maxX, 8, 'S');

        this.current_y += 5
        //console.log("Increase Y . 3 : ",this.current_y);

        this.padPDF.setFont('Montserrat-Medium','normal');
        let response = `${ this.props.download_as === 'admin' ? this.state.employee : 'You'} reviewed this information on ${this.state.response_date} ${this.state.response_time !== null && this.state.response_time !== undefined && this.props.download_as === 'admin' ? `at ${this.state.response_time}` : ""} and `
        this.padPDF.text(response, this.current_x + 1, this.current_y + 0.5, {align : "left"});

        let sentence_end = this.padPDF.getStringUnitWidth(response)*3.54;
        //console.log("Sentence End", sentence_end)
        this.padPDF.setFont('Montserrat-SemiBold','bold');
        this.padPDF.text('Confirmed ', this.current_x + sentence_end + 1, this.current_y + 0.5, {align : "left"});

        sentence_end = this.padPDF.getStringUnitWidth(`${response}Confirmed `)*3.51;
        //console.log("Sentence End 2", sentence_end)
        this.padPDF.setFont('Montserrat-Medium','normal');
        this.padPDF.text('the details.', this.current_x + sentence_end + 1, this.current_y + 0.5, {align : "left"});

        this.current_y += 6
        //console.log("Increase Y . 4 : ",this.current_y);

        this.tableTop = this.current_y

        this.padPDF.setDrawColor('#ccc')
        this.padPDF.setFillColor('#ccc')
        this.padPDF.rect(this.current_x, this.current_y, maxX, 6, 'FD');

        this.current_y += 4
        //console.log("Increase Y . 5 : ",this.current_y);

        this.padPDF.setFontSize(9)
        // this.date_col_x = marginX + 1;
        // this.tran_info_col_x = marginX + 25;
        // this.sec_info_col_x = marginX + 102;
        // this.broker_col_x = marginX + 140;
        this.padPDF.setFont('Montserrat-Bold','bold');
        this.padPDF.text('Trade Date', this.date_col_x, this.current_y + 0.25, {align : "left"});
        this.padPDF.text('Transaction Information', this.tran_info_col_x, this.current_y + 0.25, {align : "left"});
        this.padPDF.text('Security and PAD ID', this.sec_info_col_x, this.current_y + 0.25, {align : "left"});
        this.padPDF.text('Broker', this.broker_col_x, this.current_y + 0.25, {align : "left"});

        this.current_y += 5
        //console.log("Header end Y : ",this.current_y);
        //this.header_height = this.current_y;
        //console.log("Demo", demodata)
    }

    printRows = async () =>{
        let y_covered = 39 + marginY;
        //console.log("Row Start ===> Current_y : ", this.current_y, "Y_covered : ", y_covered);
        for(let i=0; i<this.state.report_data.length; i++){
            let item = this.state.report_data[i];
            let row_height = await this.calculateRowHeight(item);
            //console.log("Row height in print rows ===> ",row_height);
            if(this.current_y + row_height > (marginY + maxY - 10)){
                await this.drawVerticalLines(this.tableTop);
                this.padPDF.addPage('a4','portrait');
                this.current_y = marginY + 3;
                this.current_x = marginX;
                this.currentPageNumber++;
                await this.headerSection();
            }

            this.current_y += 5;
            //console.log("Increase Y . 6 : ",this.current_y);
            
            this.padPDF.setFontSize(10)
            this.padPDF.setFont('Montserrat-Medium','normal');

            this.padPDF.text(item.trade_date, this.date_col_x, this.current_y, {align : "left"});
            
            let insSplit = this.padPDF.splitTextToSize(item.instruction, 22);
            this.padPDF.text(insSplit, this.tran_info_col_x, this.current_y, {align : "left"});

            this.padPDF.text(item.volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), this.tran_info_col_x + 20, this.current_y, {align : "center"});
            this.sec_name_col_x = this.tran_info_col_x + 35;
            let sec_name_arr = item.security.split(' ');
            //console.log('Sec Arr : ', sec_name_arr);
            let sec_name_str_arr=[];
            let extra_y_for_security = 0;
            if(sec_name_arr.length <= 1) sec_name_str_arr.push(item.security);
            else{
                let j=0;
                for(let i=0; i<sec_name_arr.length; i++){
                    if(i===0) sec_name_str_arr.push(sec_name_arr[i]);
                    else{
                        let temp_str = `${sec_name_str_arr[j]} ${sec_name_arr[i]}`
                        //console.log("actual string width in print : ", this.padPDF.getStringUnitWidth(temp_str))
                        if(this.padPDF.getStringUnitWidth(temp_str)*3.54 < this.sec_info_col_x - 5 - this.sec_name_col_x) sec_name_str_arr[j] = temp_str;
                        else{
                            sec_name_str_arr.push(sec_name_arr[i]);
                            j++;  
                            extra_y_for_security += 6;
                        }
                    }
                    
                }
            }
            //console.log("sec_name_str_arr : ",sec_name_str_arr);
            for(let i=0;i<sec_name_str_arr.length;i++) this.padPDF.text(sec_name_str_arr[i], this.sec_name_col_x, this.current_y + i*6, {align : "left"});
            // this.padPDF.text(item.security, this.sec_name_col_x, this.current_y, {align : "left"});

            if(insSplit?.length > 1  && sec_name_str_arr?.length === 0){
                this.current_y += (insSplit?.length-1)*(10*0.42);
            }

            let identifier_ar = item.identifier.split(' ');
            let extra_y_for_identifier = 0;
            for(let i=0;i<identifier_ar.length;i++){
                this.padPDF.text(identifier_ar[i], this.sec_info_col_x, this.current_y + i*6, {align : "left"});
                extra_y_for_identifier = i*6;
            }
            
            this.padPDF.text(item.broker, this.broker_col_x, this.current_y, {align : "left"});

            //console.log("print row : extra_y_for_identifier : ", extra_y_for_identifier, "extra_y_for_security : ", extra_y_for_security);

            this.current_y += (extra_y_for_identifier>extra_y_for_security ? extra_y_for_identifier :extra_y_for_security) + 7;
            //console.log("Increase Y . 7 : ",this.current_y);

            this.padPDF.text('At', this.tran_info_col_x, this.current_y, {align : "left"});
            this.padPDF.text(`${item.currency} ${item.price}`.trim(), this.tran_info_col_x + 20, this.current_y, {align : "center"});
            if(item.instruction === 'Buy')
                this.padPDF.text(`${item.currency} ${item.amount}`.trim(), this.sec_info_col_x - 3, this.current_y, {align : "right"});
            else
                this.padPDF.text(`${item.currency} ${item.amount}`.trim(), this.tran_info_col_x + 35, this.current_y, {align : "left"});
            this.padPDF.text(item.pad_id, this.sec_info_col_x, this.current_y, {align : "left"});

            this.current_y += 5;
            //console.log("Increase Y . 8 : ",this.current_y);
            this.padPDF.line(marginX, this.current_y, marginX+maxX, this.current_y, 'S');
            //if(i===10) break;
            y_covered +=row_height;
            //console.log("Current_y : ", this.current_y, "Y_covered : ", y_covered);
        }
    }

    printBrokerHeader = async () => {
        let calcHeaderPrintHeight = 34;
        if(this.state.brokerData?.length) {
            let firstRecord = this.state.brokerData[0];
            this.padPDF.setFontSize(10)
            this.padPDF.setFont('Montserrat-Medium','normal');
            let bNameSplit = this.padPDF.splitTextToSize(firstRecord.broker_name, 70);
            let bAcTitleSplit = this.padPDF.splitTextToSize(firstRecord.account_name, 70);
            if(bNameSplit?.length > 1  || bAcTitleSplit?.length >1){
                if(bNameSplit?.length > bAcTitleSplit?.length){
                    calcHeaderPrintHeight += (bNameSplit?.length-1)*(10*0.42);
                }else{
                    calcHeaderPrintHeight += (bAcTitleSplit?.length-1)*(10*0.42);
                }
            }
        }

        if(this.current_y + calcHeaderPrintHeight > (marginY + maxY - 10)){
            this.padPDF.addPage('a4','portrait');
            this.current_y = marginY + 3;
            this.current_x = marginX;
            this.currentPageNumber++;
        }
        this.current_y += 15;

        this.padPDF.setFontSize(11);
        this.padPDF.setFont('Montserrat-Bold','bold');
        this.padPDF.text(Store.getStoreData("module_config")?.new_broker_confirmation?.new_broker_section_title, marginX, this.current_y, {align : "left"});

        this.current_y += 6;

        this.brokerTableTop = this.current_y;

        this.padPDF.setDrawColor('#ccc');
        this.padPDF.setFillColor('#ccc');
        this.padPDF.rect(this.current_x, this.current_y, maxX, 6, 'FD');

        this.current_y += 4;

        this.padPDF.setFontSize(9);
        this.padPDF.setFont('Montserrat-Bold','bold');
        this.padPDF.text('Name of Broker/Dealer or Bank', this.date_col_x, this.current_y + 0.25, {align : "left"});
        this.padPDF.text('Account Title', this.brokerAcTitleColX, this.current_y + 0.25, {align : "left"});
        this.padPDF.text('Date Account Opened', this.AcOpenDateColX, this.current_y + 0.25, {align : "right"});
        let dim = this.padPDF.getTextDimensions('Account Title');
        this.padPDF.setFont('Montserrat-MediumItalic','italic');
        this.padPDF.text('(Your reference)', this.brokerAcTitleColX + dim.w + 1, this.current_y + 0.25, {align : "left"});

        this.current_y += 3;
    }

    printBrokerRows = async () => {
        for(let i=0; i < this.state.brokerData?.length ; i++){
            if(this.current_y + 6 > (marginY + maxY - 10)){
                this.current_y += 3;
                await this.drawVerticalLines(this.brokerTableTop);
                this.padPDF.line(marginX, this.current_y, marginX+maxX, this.current_y, 'S');
                this.padPDF.addPage('a4','portrait');
                this.current_y = marginY + 3;
                this.current_x = marginX;
                this.currentPageNumber++;
                await this.printBrokerHeader();
            }
            let item = this.state.brokerData[i];
            this.current_y += 6;
            
            this.padPDF.setFontSize(10)
            this.padPDF.setFont('Montserrat-Medium','normal');
            
            let bNameSplit = this.padPDF.splitTextToSize(item.broker_name, 70);
            this.padPDF.text(bNameSplit, this.date_col_x, this.current_y, {align : "left"});

            let bAcTitleSplit = this.padPDF.splitTextToSize(item.account_name, 70);
            this.padPDF.text(bAcTitleSplit, this.brokerAcTitleColX, this.current_y, {align : "left"});
            
            this.padPDF.text(item.ac_opening_date, this.AcOpenDateColX, this.current_y, {align : "right"});

            if(bNameSplit?.length > 1  || bAcTitleSplit?.length >1){
                if(bNameSplit?.length > bAcTitleSplit?.length){
                    this.current_y += (bNameSplit?.length-1)*(10*0.42);
                }else{
                    this.current_y += (bAcTitleSplit?.length-1)*(10*0.42);
                }
            }

        }
        this.current_y += 5;
        this.padPDF.line(marginX, this.current_y, marginX+maxX, this.current_y, 'S');
    }

    printNilTransactionText = async () =>{
        this.current_y += 15;

        this.padPDF.setFontSize(11)
        this.padPDF.setFont('Montserrat-Bold','bold');
        this.padPDF.text(`${ this.props.download_as === 'admin' ? this.state.employee : 'You'} had no transactions to report within the period.`, centerX, this.current_y, {align : "center"});

        this.current_y += 10;
        this.padPDF.line(marginX, this.current_y, marginX+maxX, this.current_y, 'S');
    }

    printNilBrokerText = async () =>{
        this.current_y += 15;

        this.padPDF.setFontSize(11)
        this.padPDF.setFont('Montserrat-Bold','bold');
        this.padPDF.text(`${ this.props.download_as === 'admin' ? this.state.employee : 'You'} had no new brokers to report within the period.`, centerX, this.current_y, {align : "center"});

        this.current_y += 10;
        this.padPDF.line(marginX, this.current_y, marginX+maxX, this.current_y, 'S');
    }

    calculateRowHeight = async (row) =>{
        //console.log("Calculate height for row : ",row);
        let row_height = 5;
        let extra_y_for_identifier = 0;
        let extra_y_for_security = 0
        let identifier_ar = row.identifier.split(' ');
        if(identifier_ar.length > 1){
            for(let i=0; i<identifier_ar.length; i++) extra_y_for_identifier+= i*6;
        }
        let sec_name_arr = row.security.split(' ');
        let sec_name_str_arr=[];
        
        if(sec_name_arr.length <= 1) sec_name_str_arr.push(row.security);
        else{
            let j=0;
            for(let i=0; i<sec_name_arr.length; i++){
                this.padPDF.setFontSize(10)
                this.padPDF.setFont('Montserrat-Medium','normal');
                if(i===0) sec_name_str_arr.push(sec_name_arr[i]);
                else{
                    let temp_str = `${sec_name_str_arr[j]} ${sec_name_arr[i]}`
                    //console.log("string width in calc row height : ", this.padPDF.getStringUnitWidth(temp_str))
                    if(this.padPDF.getStringUnitWidth(temp_str)*3.54 < this.sec_info_col_x - 5 - this.sec_name_col_x) sec_name_str_arr[j] = temp_str;
                    else{
                        sec_name_str_arr.push(sec_name_arr[i]);
                        j++;  
                        extra_y_for_security += 6;
                    }
                }
                
            }
        }
        //console.log("calc row height : extra_y_for_identifier : ", extra_y_for_identifier, "extra_y_for_security : ", extra_y_for_security);
        row_height += (extra_y_for_identifier>extra_y_for_security ? extra_y_for_identifier :extra_y_for_security) + 12;
        //console.log("Row Height : ", row_height);
        return row_height;
    }

    calculatePageNumber = async (data) => {
        let single_page_y = 0;
        let total_pages = 1;
        let row_height = 0
        let item = {};
        single_page_y = 39 + marginY;
        //console.log("Page Calculation start ==> Single Page Y : ", single_page_y);
        for(let i=0;i<data.length;i++){
            item=JSON.parse(JSON.stringify(data[i]));
            row_height = await this.calculateRowHeight(item);
            if((row_height + single_page_y) > (marginY + maxY - 10)) {
                //console.log("row_height + single_page_y : ", (row_height + single_page_y), "marginY + maxY - 10", (marginY + maxY - 10));
                total_pages++;
                single_page_y = 39 + marginY;
            }
            else single_page_y += row_height;
            //console.log("Single Page Y : ", single_page_y);
        }
        //console.log("Total Pages : ", total_pages);
        return total_pages;
    }

    printPageNo = async () =>{
        this.padPDF.setFontSize(10)
        this.padPDF.setTextColor('#000000');
        this.padPDF.setFont('Montserrat-Medium','normal');

        for(let i=1;i<=this.currentPageNumber;i++){
            this.padPDF.setPage(i);
            this.padPDF.text(`Page ${i} of ${this.currentPageNumber}`, centerX, marginY + maxY + 5, {align : "center"});
        }
    }

    drawVerticalLines = async (y) =>{
        this.padPDF.setDrawColor('#ccc');
        this.padPDF.line(marginX, y, marginX, this.current_y, 'S');
        this.padPDF.line(marginX + maxX, y, marginX + maxX, this.current_y, 'S');
    }

    generatePdf = async () => {
        this.current_y = marginY + 3;
        this.current_x = marginX;
        this.currentPageNumber = 1;
        this.date_col_x = marginX + 1;
        this.tran_info_col_x = marginX + 25;
        this.sec_info_col_x = marginX + 102;
        this.broker_col_x = marginX + 140;
        this.brokerAcTitleColX = marginX + 80;
        this.AcOpenDateColX = marginX + maxX - 1;
        this.padPDF = new jsPDF({
                                    orientation: 'p',
                                    unit: 'mm',
                                    format: 'a4',
                                    putOnlyUsedFonts: true
                                });
        //this.padPDF.addFont('Montserrat-Regular-normal.ttf', 'Montserrat-Reg', 'regular', 300);
        // this.padPDF.addFileToVFS('Montserrat-Regular.ttf', Montserrat);
        // this.padPDF.addFont('Montserrat-Regular.ttf', 'Montserrat-Regular', 'regular',300);
        this.padPDF.setFont('Montserrat-Bold','bold');
        this.totalPages = await this.calculatePageNumber(JSON.parse(JSON.stringify(this.state.report_data)));
        await this.headerSection();
        if(this.state.report_data.length > 0) await this.printRows();
        else await this.printNilTransactionText();
        await this.drawVerticalLines(this.tableTop);
        if(this.state.showBrokers === true){ 
            await this.printBrokerHeader();
            if(this.state.brokerData.length){ 
                await this.printBrokerRows();
            }else{
                await this.printNilBrokerText();
            }
        }
        if(this.state.showBrokers === true){ 
            await this.drawVerticalLines(this.brokerTableTop);
        }
        await this.printPageNo();
        var blob = this.padPDF.output('blob')
        var binaryData = [];
        binaryData.push(blob);
        var pdfurl = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
        this.setState({pdfURL:pdfurl, padPDF : this.padPDF, ready: true});
        this.padPDF.save("PA Dealings Transaction Confirmation Report.pdf");
    }

    render(){
        // if(this.padPDF === null) {
        //     return (<div>Loading...</div>);
        // } else {
        //     if (this.state.ready) {
        //         return (
        //             <div>
        //                 <div style={{paddingRight: "10px"}}>
        //                 {/* <Style.Categories><span style={{cursor: "pointer", color: "#337ab7"}} onClick={this.closePdf}><FaTimes /></span></Style.Categories> */}
        //                     <div style={{clear: "both"}}></div>
        //                 </div>
        //                 <Iframe url={this.state.pdfURL} width="100%" height="900px" type="application/pdf" />
        //             </div>
        //         );
        //     } else {
        //         return (<div>Loading...</div>);
        //     }
        // }
        return(
        <div className='report-link-wrapper'>
            <a className='report-link' href='#' onClick={this.generatePdf}><span className='report-link-text'>Download { this.props.download_as === 'admin' ? '' : 'my '}Confirmation Report</span> <img className='report-link-icon' src={'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNNjQgMEMyOC43IDAgMCAyOC43IDAgNjRWNDQ4YzAgMzUuMyAyOC43IDY0IDY0IDY0SDMyMGMzNS4zIDAgNjQtMjguNyA2NC02NFYxNjBIMjU2Yy0xNy43IDAtMzItMTQuMy0zMi0zMlYwSDY0ek0yNTYgMFYxMjhIMzg0TDI1NiAwek0yMTYgMjMyVjMzNC4xbDMxLTMxYzkuNC05LjQgMjQuNi05LjQgMzMuOSAwczkuNCAyNC42IDAgMzMuOWwtNzIgNzJjLTkuNCA5LjQtMjQuNiA5LjQtMzMuOSAwbC03Mi03MmMtOS40LTkuNC05LjQtMjQuNiAwLTMzLjlzMjQuNi05LjQgMzMuOSAwbDMxIDMxVjIzMmMwLTEzLjMgMTAuNy0yNCAyNC0yNHMyNCAxMC43IDI0IDI0eiIvPjwvc3ZnPg=='} style={{width : "20px"}}/></a> 
        </div>)
    }

}

export default TransConfirmationPdf