import React from 'react'
import styled from "styled-components";
import { FaTimes, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import Store from '../../../Common/Store';
import APICall from './../../../Common/APICall';
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';

const Container = styled.div`
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	box-sizing: border-box;
	width: 40%;
`;
const MRModalHeader = styled.div`
	border-left: 10px solid #11ABA6;
	height: 100px;
	background-color: #ffffff;
	padding: 0px 30px;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	width: 80%;
	margin-top: 24px;
	// margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	// float: left;
	// margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	float: right;
	box-sizing: border-box;
	margin-top: 21px;
	font-size: 20px;
	font-weight: 200;
	color: #656565;
`;
const Clearfix = styled.div`
	clear: both;
`;
const ModalBody = styled.div`
	padding: 20px 30px 20px 30px;
	background-color: #F1F6F7;
	border-top: 1px solid rgb(208 209 210);
    height: 500px;
    overflow-y: scroll;
`;
const Row = styled.div`
	margin-bottom: 10px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const TextArea = styled.textarea`
	resize: none;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	max-width: 100%;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
`;
const ModalFooter = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 15px 30px 15px 0px;
	background-color: #ffffff;
`;
const SubmitButton = styled.button`
	box-sizing: border-box;
	color: #ffffff;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
  	padding: 10px 20px;
`;
const CloseButton = styled.button`
	box-sizing: border-box;
    color: #2d2c2c;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #DBDBDB;
    padding: 10px 20px;
    font-weight: 600;
`;
const InfoDiv = styled.div`
  box-sizing: border-box;
  background-color: #e7f0f7;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px 20px;
`;
const Loader = styled.div`
    position: fixed;
    left: 45vw;
    top:45vh;
    z-index: 20001;
`;

class ReassignmentModal extends React.Component {
    state = {reassignment_comments: "", check: false, ready: false, contacts: [], selected_contact_obj: null};

    componentDidMount() {
        const api = new APICall();
        const postData = {command: "list_my_gc_users", user_id: this.props.requester_id};
        api.command(postData, this.processData);
    }

    processData = (result) => {
        console.log(result);
        const company_users = Store.getStoreData("company_users");
        let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
        let contacts = [];
        for (let user_id of result) {
            // console.log(user_id);
            if (parseInt(user_id) !== parseInt(this.props.requester_id) && user_id in company_users_obj === true && company_users_obj[user_id].role in {"team": 1, "no_access": 1} === false) {
                contacts.push({id: user_id, name: company_users_obj[user_id].name});
            }
        }
        let selected_contact_obj = contacts[0];
        if (parseInt(this.props.reassigned_user_id) !== 0) selected_contact_obj = contacts.find(item => parseInt(item.id) === parseInt(this.props.reassigned_user_id));
        console.log("contacts:", contacts);
        this.setState({contacts, selected_contact_obj, ready: true});
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeReassignmentModal();
    }
    changeAssignTo = (data) => {
		let selected_contact_obj = this.state.contacts.find(item => item.id === data);
		this.setState({selected_contact_obj});
	}
    changeComments = (event) => {
        event.preventDefault();
        this.setState({reassignment_comments: event.target.value});
    }
    changeCheck = (event) => {
        event.preventDefault();
        let check = this.state.check;
        check = check === true ? false : true;
        this.setState({check});
    }
    reassign = (event) => {
        event.preventDefault();
        this.props.reassign(this.state);
    }
    render() {
        if (this.state.ready === false) {
            return (
                <Loader>
                    <ClipLoader size={100} color={"#123abc"} loading={true} />
                </Loader>
            )
        }
        return (
            <Container>
				<MRModalHeader>
					<MRModalHeaderText>{`PAD-${this.props.task_id}`}</MRModalHeaderText>
					<MRModalHeaderCloseBtn><FaTimes style={{cursor: "pointer"}} onClick={this.closeModal} /></MRModalHeaderCloseBtn>
			        <Clearfix />
			        <MRModalSubHeaderText>Reassign this PAD request to a PA Dealing Manager or Admin Manager</MRModalSubHeaderText>
				</MRModalHeader>
				<ModalBody>
                    <InfoDiv style={{marginBottom: "20px"}}>
                        <div style={{ color: "#1a3552" }}>You are opting to reassign a PAD request to another user. The newly assigned user will be notified via email of this change.</div>
                    </InfoDiv>
                    <Row>
                        <MRModalLabel>Reassign To</MRModalLabel>
                        <CslSimpleDropDown options={this.state.contacts} selected={this.state.selected_contact_obj} setSelection={this.changeAssignTo} />
                    </Row>
					<Row>
						<MRModalLabel>Comments</MRModalLabel>
						<TextArea rows="5" cols="120" value={this.state.reassignment_comments} onChange={this.changeComments}></TextArea>
					</Row>
                    <Row style={{width: "100%", boxSizing: "border-box", marginBottom: "22px"}}>
                        <div style={{float: "left", width: "10%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
                            {this.state.check === true && <FaRegCheckSquare style={{cursor: "pointer"}} onClick={this.changeCheck} />}
                            {this.state.check === false && <FaRegSquare style={{cursor: "pointer"}} onClick={this.changeCheck} />}
                        </div>
                        <div style={{float: "left", width: "90%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
                            I confirm this PAD entry will be reassigned to the selected user and this action cannot be undone.
                        </div>
                        <Clearfix></Clearfix>
                    </Row>
				</ModalBody>
				<ModalFooter>
					<SubmitButton
                        style={{
                            float: "right",
                            cursor: (this.state.check === true && this.state.reassignment_comments !== "") ? "pointer" : "not-allowed",
                            opacity: (this.state.check === true && this.state.reassignment_comments !== "") ? "1" : "0.4"
                        }}
                        onClick={this.reassign}
                        disabled={(this.state.check === true && this.state.reassignment_comments !== "") ? false : true}
                    >
                        REASSIGN
                    </SubmitButton>
					<CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeModal}>CANCEL</CloseButton>
					<Clearfix />
				</ModalFooter>
			</Container>
        )
    }
}

export default ReassignmentModal;