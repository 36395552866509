import React from 'react';
import moment from 'moment';
import './TransactionReportRejectionCauseModal.css';
import { FaTimes } from "react-icons/fa";
import Store from "../../../Common/Store";

class UserCommentModal extends React.Component{

    state = {
        userInput : "",
    }

    changeUserInput = (e) => {
        e.preventDefault();
        let userInput = e.target.value
        this.setState({ userInput })
    }

    auto_grow = (e) => {
        e.target.style.height = "5px";
        e.target.style.height = (e.target.scrollHeight)+"px";
    }

    confirmReject = (e) => {
        e.preventDefault();
        this.props.confirmReject(this.state.userInput, this.props.action);
    }

    closeModal = (e) => {
        e.preventDefault();
        this.props.closeModal();
    }

    render(){
        return(
                <div className='tr-rejection-modal-wrapper'>
                    <div className='tr-rejection-modal-header'>
                        {
                            (()=>{
                                if(this.props.action?.split('_')[1] === 'confirm'){
                                    return(
                                        <div className='tr-rejection-modal-title-wrapper'>
                                            <div className='tr-rejection-modal-heading'>Information Confirmed</div>
                                        </div>
                                    )
                                }
                                return(
                                    <div className='tr-rejection-modal-title-wrapper'>
                                        <div className='tr-rejection-modal-heading'>
                                            { this.props.reportTitle } - Rejected
                                        </div>
                                        <div className='tr-rejection-modal-subheading'>
                                            {moment(moment(this.props.from_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY") + " "} 
                                            to 
                                            {" " + moment(moment(this.props.to_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY")}
                                        </div> 
                                    </div>
                                )
                            })()   
                        }
                        <div className='tr-rejection-modal-close'>
                            <FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/>
                        </div> 
                    </div>
                    <div className='tr-rejection-modal-body'>
                        {
                            (()=>{
                                if(this.props.action?.split('_')[1] === 'confirm'){
                                    return(
                                        <div className='tr-rejection-modal-help-text' style={{border: "none", fontWeight: "500"}}>
                                            <p>You have chosen to confirm the information provided from your PA Dealing records.<br/><br/>Please leave comments if required and then press 'Submit' to finalize this confirmation</p>
                                        </div>
                                    )
                                }
                                return(
                                    <div className='tr-rejection-modal-help-text'>
                                        {this.props.action === 'pad_reject' ? Store.getStoreData("module_config")?.pad_confirmation?.rejection_guidance : Store.getStoreData("module_config")?.holding_confirmation?.holding_rejection_guidance }
                                    </div>
                                )
                            })()
                        }
                        {
                            (()=>{
                                if(this.props.action?.split('_')[1] === 'confirm'){
                                    return(
                                        <div className='tr-rejection-modal-label'>Comments</div>
                                    )
                                }
                                return(
                                    <div className='tr-rejection-modal-label'>Please provide a reason for rejecting the information</div>
                                )
                            })()
                        }
                        <textarea className='tr-rejection-modal-cause-textarea' value={this.state.userInput} onInput={this.auto_grow} onChange={this.changeUserInput}/>
                    </div>
                    <div className='tr-rejection-modal-footer'>
                        <div className='tr-rejection-modal-footer-button-container'>
                            <button className='tr-rejection-modal-footer-button tr-rejection-close-button' onClick={this.closeModal} >CLOSE</button>
                            {   
                                (()=>{
                                    if(this.props.action?.split('_')[1] === 'confirm'){
                                        return <button className='tr-rejection-modal-footer-button tr-rejection-save-button' onClick={this.confirmReject}>SUBMIT</button>
                                    }else if(this.props.action?.split('_')[1] === 'reject' && this.state.userInput?.length > 0){
                                        return <button className='tr-rejection-modal-footer-button tr-rejection-save-button' onClick={this.confirmReject}>CONFIRM</button>
                                    }
                                    return <button className='tr-rejection-modal-footer-inactive-button'>CONFIRM</button>
                                })()
                            }
                        </div>
                    </div>
                </div>
        )
    }
}

export default UserCommentModal;