import React from "react";
import { saveAs } from "file-saver";
import styled from "styled-components";
import { FaTimes, FaRegCheckSquare, FaPlusCircle, FaRegSquare } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
// import { ClearBoth, CSLTable, Dropzone } from "@mcc-regtech/core-library";
import CSLTable from '../Common/CSLTable';
import ClearBoth from '../Common/ClearBoth';
import moment from "moment";
import FollowupTask from "./FollowupTask";
import Dropdown from "../Common/Dropdown";
import SimpleDropdown from "../Common/SimpleDropdown";
import AddFollowupModal from "./Modals/AddFollowupModal";
import ReassignmentModal from "./Modals/ReassignmentModal";
import Dropzone from "../Dropzone/Dropzone.js";
import AlertBox from "../Common/AlertBox.js";
import ClipLoader from "react-spinners/ClipLoader";
import SpinningLoader from "../Common/SpinningLoader";
import EllipsisMenu from "../Common/EllipsisMenu";
import { ThirtyFpsRounded } from "@mui/icons-material";

const last_action_time = moment().unix();

const Clearfix = styled.div`
  clear: both;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 812px;
  position: absolute;
  top: 38px;
  z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 100px;
  min-width: 750px;
`;

const MRDataContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e7f0f7;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 0px;
`;
const MRAlertContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #eccaca;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 10px;
`;

const MRTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 92%;
  margin: 0 auto;
  // top: 30px;
  margin-bottom: 10px;
  // border-radius: 5px;
  padding: 5px 10px;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 100px;
  width: calc(100% - 10px);
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ada8;
  border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #143151;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-right: 20px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 200;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: rgb(243, 247, 251);
  padding: 10px;
  border-radius: 4px;
  color: #1a3552;
`;
const MRModalLabel = styled.div`
  color: #1a3552;
  font-weight: 600;
  margin-top: 15px;
  font-size: 15px;
  margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
  position: relative;
  padding: 17px;
  background-color: white;
  height: 80px;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  width: 145px;
  height: 45px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 15px;
  float: right;
  cursor: pointer;
`;
const MRModalNextBtn = styled(MRModalButton)`
  background-color: #37ada7;
  border: 1px solid #37ada7;
`;
const MRModalSaveCloseBtn = styled(MRModalButton)`
  background-color: #143151;
  border: 1px solid #143151;
`;
const MRModalCancelBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #545454;
  border: 2px solid #dadada;
`;
const MRModalTextarea = styled.textarea`
  width: 99%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
`;
const MRFollowUpTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;
const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-top: 30px;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  font-weight: 600;
  color: #1a3552;
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HorizontalLine = styled.div`
  width: 100%;
  background-color: black;
  height: 0.5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;
const Row = styled.div`
  margin-bottom: 10px;
`;
const Section50 = styled.div`
	width: 50%;
	float: left;
	box-sizing: border-box;
`;
const NoteTableHeaderBar = styled.div`
	background-color: #11ABA6;
    border: 1px solid #11ABA6;
    box-sizing: border-box;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 6px 15px;
`;
const NoteWrapper = styled.div`
	position: fixed;
    height: 100%;
    width: calc(100vw - 320px);
    left: 300px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1002;
`;
const MRSuccessContainer = styled.div`
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #e2f0d9;
  border: 1px solid #6c9450;
  border-radius: 5px;
  padding: 10px 20px;
`;
const MRDangerContainer = styled.div`
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #eccaca;
	border: 1px solid #b75050;
  border-radius: 5px;
  padding: 10px 20px;
`;
const InfoDiv = styled.div`
  box-sizing: border-box;
  background-color: #e7f0f7;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px 20px;
`;
const Loader = styled.div`
    position: fixed;
    left: 45vw;
    top:45vh;
    z-index: 20001;
`;

class TaskApprovalModal extends React.Component {
  state = {
    task: null,
    company_users_obj: null,
    custom_fields: [],
    current_followup: null,
    show_followup: false,
    followup_contacts: [],
    binFiles: [],
    cur_files_json: null,
    approver_comments: "",
    alert_param: null,
    action_type: 'approve',
    app_levels: null,
    approval_state: null,
    approver_entitled: null,
    manager_id: null,
    reassigned_user_id: 0,
    show_reassignment_modal: false,
    loader: false,
    submitting: false,
    show_spinner : false,
    broker_list : []
  };

  constructor(props) {
    super(props);
    this.api = new APICall();
  }

  async componentDidMount() {
    const company_users = Store.getStoreData("company_users");
    let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
    let task = this.props.task;
    let app_levels = "approval_type" in task.parenttask.task_json.object_data ? task.parenttask.task_json.object_data.approval_type.split("-") : ["ar"]; //**** */
    let approval_data = "approval_data" in task.parenttask.task_json.object_data ? task.parenttask.task_json.object_data.approval_data : [];
    let approval_state = approval_data.length;
    let approver_entitled = app_levels[approval_state];
    if ("approval_data" in task.parenttask.task_json.object_data === false) {
      task.parenttask.task_json.object_data.approval_data = [{
        comments: "",
        performed_by: Store.getStoreData("loggedin_contact_id"),
        action_time: moment().unix(),
        status: "",
        attachments: []
      }];
    } else {
      task.parenttask.task_json.object_data.approval_data.push({
        comments: "",
        performed_by: Store.getStoreData("loggedin_contact_id"),
        action_time: moment().unix(),
        status: "",
        attachments: []
      })
    }

    let custom_fields = [];
    task.parenttask.task_json.object_data.followups = "followups" in task.parenttask.task_json.object_data === true ? task.parenttask.task_json.object_data.followups : {};
    for (let item in task.parenttask.task_json.object_data.followups) {
      task.parenttask.task_json.object_data.followups[item].created = "created" in task.parenttask.task_json.object_data.followups[item] === true ? task.parenttask.task_json.object_data.followups[item].created : false;
    }
    for (let item of task.parenttask.task_json.object_data.approval_data) {
      item.internal_approver_comments = "internal_approver_comments" in item ? item.internal_approver_comments : "";
    }
    task.parenttask.task_json.object_data.internal_approver_comments = "internal_approver_comments" in task.parenttask.task_json.object_data ? task.parenttask.task_json.object_data.internal_approver_comments : "";
    if ("custom_fields" in task.parenttask.task_json.object_data) {
      if (task.parenttask.task_json.object_data.custom_fields.enabled === true && task.parenttask.task_json.object_data.custom_fields.additional_fields.length !== 0) {
        task.parenttask.task_json.object_data.custom_fields.additional_fields.forEach(field => {
          if (field.type === "Dropdown") {
            if (field.value === "other") {
              custom_fields.push({name: field.name, nickname: field.nickname, value: field.other_value});
            }
            if (field.value !== "" && field.value !== "other") {
              custom_fields.push({name: field.name, nickname: field.nickname, value: field.value});
            }
          } else {
            if (field.value !== "") {
              custom_fields.push({name: field.name, nickname: field.nickname, value: field.value});
            }
          }
        })
      }
    }
    let broker_list = [];
		if(Store.getStoreData("module_config").general.enable_employee_broker_ac === true){
			const api = new APICall();
			const postData = {command: "list_brokers", requester_id : task.parenttask.contact_id};
			let result = await api.commandWithoutCallback(postData);
			broker_list = result.result;
		}
    // const postData = {command: "list_brokers", requester_id : task.parenttask.contact_id};
    // let result = await this.api.commandWithoutCallback(postData);
    // console.log("API result approval :  ", result);
    // let broker_list = result.result;
    this.setState({task, company_users_obj, custom_fields, app_levels, approval_state, approver_entitled, manager_id: task.manager_id, broker_list});
  }

  changeApprovalComments = (event) => {
    event.preventDefault();
    let task = JSON.parse(JSON.stringify(this.state.task));
    task.parenttask.task_json.object_data.approval_data[this.state.approval_state].comments = event.target.value;
    this.setState({task});
  }

  changeInternalApprovalComments = (event) => {
    event.preventDefault();
    let task = JSON.parse(JSON.stringify(this.state.task));
    task.parenttask.task_json.object_data.approval_data[this.state.approval_state].internal_approver_comments = event.target.value;
    this.setState({task});
  }

  showFollowupsTask = (result) => {
    Store.updateStore("updated_followups", result.result.followuptasks);
    this.setState({ ready: true });
  };

  /*handleDownload = (index) => {
    var binaryString = window.atob(this.state.cur_files_json[index].bin_file);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, this.state.cur_files_json[index].name);
  };*/

  showAddFollowupTaskModal = (index) => (event) => {
    //event.preventDefault();
    Store.updateStore("ftask_index", -1);
    this.setState({ showModal: true, showfollowupclose: false });
  };

  closeFollowupModal = () => {
    this.setState({ showModal: false, showfollowupclose: true });
  };

  followupTaskValueFetch = (taskValue) => {
    let ref_id = Utils.genKey(12);
    let task = {
      task_data: {
        module_id: "padreg",
        last_action: "COMP_PADREG_FOLLOWUP",
        ref_id: ref_id,
        actiondate: Date.now(),
        cur_assigned_to: taskValue.assignedto,
        task_type: "COMP_PADREG_FOLLOWUP",
        parent_task_id: this.state.taskid,
        cur_lane: "COMP_PADREG_FOLLOWUP_ASSIGNMENT",
        due_date: taskValue.dueby,
      },
      object_data: {
        subject: taskValue.subject,
        note: taskValue.note,
      },
      action_data: [
        {
          action: "COMP_PADREG_FOLLOWUP",
          actiondate: Date.now(),
          assigned_to: taskValue.assignedto,
          cur_lane: "COMP_PADREG_FOLLOWUP_ASSIGNMENT",
          due_by: taskValue.dueby,
          note: taskValue.note,
        },
      ],
    };

    const postData = { command: "createfollowuptask", task: task, bin_files: taskValue.binFiles };
    this.api.command(postData, this.processAddTask);
    this.state.followupTask.push(taskValue);
    this.setState({ followupTask: this.state.followupTask });
  };

  processAddTask = (result) => {
    const command_data = { command: "listfollowups", parent_id: this.state.taskid };
    this.api.command(command_data, this.updateFollowups);
  };

  updateFollowups = (result) => {
    Store.updateStore("updated_followups", result.result.followuptasks);
    this.setState({ showModal: false, showfollowupclose: true });
  };

  processMyfollowupTaskData = () => {
    var taskdata = Store.getStoreData("updated_followups");
    let ret = { data: [], columns: [] };
    let i = 0;
    ret.columns = [
      // {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
      { Header: "Status", accessor: "status", width: 96, headerStyle: { textAlign: "center" }, Cell: (cellInfo) => <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton> },
      { Header: "Notes", accessor: "notes", minWidth: 200, headerStyle: { textAlign: "left" } },
      { Header: "Assigned To", accessor: "assign_to", width: 120, headerStyle: { textAlign: "left" } },
      { Header: "Due Date", accessor: "due_date", width: 100, headerStyle: { textAlign: "center" }, Cell: (row) => <div style={{ textAlign: "center" }}>{row.original.due_date}</div> },
    ];
    for (let d of taskdata) {
      i++;
      let tdata = JSON.parse(d.task_json).action_data;
      let taskdata1 = JSON.parse(d.task_json).task_data;
      let status = "Pending";
      if (d.last_action === "COMP_PADREG_FOLLOWUP_DISMISS") {
        status = "Dismissed";
      } else if (d.last_action === "COMP_PADREG_FOLLOWUP_COMPLETE") {
        status = "Completed";
      }
      let assigntoName = Store.getStoreData("contacts")[taskdata1.cur_assigned_to];
      let notes = tdata[0].note;
      let due_date_str = d.due_date ? d.due_date : "1970-01-01T00:00:00.000Z";

      const c_date = new Date(due_date_str);
      let day = c_date.getDate().toString().length === 1 ? "0" + c_date.getDate().toString() : c_date.getDate().toString();
      let month = (c_date.getMonth() + 1).toString().length === 1 ? "0" + (c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
      let year = c_date.getFullYear().toString();
      const due_date = day + "/" + month + "/" + year;

      var dd = parseInt(due_date_str.substring(8, 12));
      var mm = parseInt(due_date_str.substring(5, 7));
      var yyyy = parseInt(due_date_str.substring(0, 4));

      let due_date_status = new Date(yyyy, mm - 1, dd);
      var due_date_status_final = new Date(due_date_status);
      due_date_status_final.setHours(due_date_status.getHours() + 23);
      due_date_status_final.setMinutes(due_date_status.getMinutes() + 59);
      due_date_status_final.setSeconds(due_date_status.getSeconds() + 59);

      let today = new Date();

      let text = due_date_status_final > today ? status : "Overdue";
      let color = due_date_status_final > today ? "#2E8B57" : "#FF0000";
      if (status === "Pending") {
        text = due_date_status_final > today ? status : "Overdue";
        color = due_date_status_final > today ? "#2E8B57" : "#FF0000";
      } else if (status === "Completed") {
        text = status;
        color = "#2E8B57";
      }
      let elem = {
        index: i,
        assign_to: assigntoName,
        notes: notes,
        due_date: due_date,
        status: { text: text, color: color, id: d.id },
      };
      ret.data.push(elem);
    }
    return ret;
  };

  // Not used by - cleanup
  showFollowUp = (task_id) => (event) => {
    event.preventDefault();
    let postData = { command: "get_subtask", task_id: task_id };
    this.api.command(postData, this.openFollowupModal);
  };
  openFollowupModal = (result) => {
    Store.updateStore("followup_task", result.result.task);
    this.setState({ showModal: "view_followup_modal" });
  };

  showfollowtaskModal = (row) => (event) => {
    event.preventDefault();
    Store.updateStore("ftask_index", row.index);
    this.setState({ showModal: true });
  };

  incompleteFollowupTasks = () => {
    const child_task = Store.getStoreData("updated_followups");
    let child_task_incomplete = 0;
    for (let i = 0; i < child_task.length; i++) {
      if (child_task[i].cur_lane === "COMP_PADREG_FOLLOWUP_ASSIGNMENT") child_task_incomplete++;
    }
    return child_task_incomplete;
  };

  submitApprove = (type) => (event) => {
    event.preventDefault();
    let module_config = Store.getStoreData("module_config");
    let task = JSON.parse(JSON.stringify(this.state.task));
    let task_json = JSON.parse(JSON.stringify(task.parenttask.task_json));
    if (task_json.object_data.approval_data[this.state.approval_state].comments === "") {
      alert("Comments field is mandatory and cannot be left blank.");
      return false;
    }
    this.setState({action_type: type})
    let bin_files = this.state.binFiles;
    let cur_lane = "COMP_PAD_COMPLETED";
    let sent_mail_type = "completed";
    console.log("this.state.app_levels:", this.state.app_levels);
    console.log("approval_data:", task_json.object_data.approval_data);
    if (type === "approve" && task_json.object_data.approval_data.length === 2) {
      if (task_json.object_data.approval_data[0].performed_by === task_json.object_data.approval_data[1].performed_by) {
        alert("Same person cannot provide first and second approval.");
        return false;
      }
    }
    this.setState({submitting: true, show_spinner : true});
    if(bin_files.length === 0) {
      if (this.state.app_levels.length === 1) {
        cur_lane = task_json.object_data.module_config.general.confirm_trade_information === true ? "COMP_PAD_APPROVED" : "COMP_PAD_COMPLETED";
        sent_mail_type = task_json.object_data.module_config.general.confirm_trade_information === true ? "approved_trade_confirm" : "approved_no_trade_confirm";
      } else {
        if (task_json.object_data.approval_data.length === 2) {
          cur_lane = task_json.object_data.module_config.general.confirm_trade_information === true ? "COMP_PAD_APPROVED" : "COMP_PAD_COMPLETED";
          sent_mail_type = task_json.object_data.module_config.general.confirm_trade_information === true ? "approved_trade_confirm" : "approved_no_trade_confirm";
        } else {
          cur_lane = "COMP_PAD_FOR_APPROVAL";
          sent_mail_type = "send_email_first_level_approval_for_lm_ar";
        }
      }
      console.log("Sent mail type : ", sent_mail_type);

      if (type === "reject") {
        cur_lane = "COMP_PAD_REJECTED";
        sent_mail_type = "rejected";
      }
      if (type === "confirm") {
        cur_lane = "COMP_PAD_COMPLETED";
        sent_mail_type = "completed";
      }
      task_json.task_data.last_action = cur_lane;
      task_json.task_data.cur_lane = cur_lane;
      task_json.task_data.actiondate = Date.now();
      task_json.object_data.approval_type = module_config.role_types[task_json.object_data.role_type_id].approver_level;
      task_json.object_data.approval_data[this.state.approval_state].status = type === "reject" ? "rejected" : "approved";
      task_json.object_data.approval_data[this.state.approval_state].status = type === "confirm" ? "confirmed" : task_json.object_data.approval_data[this.state.approval_state].status;
      task_json.action_data = {
        action: cur_lane,
        actiondate: Date.now(),
        assigned_to: 0,
        cur_lane: cur_lane,
        performed_by: Store.getStoreData("loggedin_contact_id")
      }
      const postData = {
        command: "update_task",
        task_json,
        bin_files: [],
        taskid: task.parenttask.id,
        create_followups: Object.keys(task_json.object_data.followups).length === 0 ? false : true,
        sent_mail_type
      };
      this.api.command(postData, this.closeModalAndRefresh);
    }else{
      let dnld = bin_files[0].bin_file.replace(/ /g, "+");
      let binaryString = window.atob(dnld);
      var fileSize = binaryString.length;
      if(fileSize > 5000000){
        let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
        this.setState({ alert_param: alert_param });
      }else{
        this.refs.dropZone.uploadFilesFromChild(bin_files);
      }
    }
  };

  closeModalAndRefresh = (result) => {
    window.location.reload();
  }

  processUpdateRemoveTask = (result) => {
    // alert('PA Dealing updated Successfully');
    const postData = { command: "get_task", task_id: this.state.taskid };
    this.api.command(postData, this.processTaskData);
  };

  processTaskData = (result) => {
    Store.updateStore("cur_q_modal_data", result.result.parenttask);
  };

  showalertFollowup = (event) => {
    event.preventDefault();
    alert("Adding follow up tasks to a removed PA Dealing is not allowed.");
  };



  closeView = () => this.props.closeModal();

  handleDownload(image_id, task_id) {
    let postData = { command: "download_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData);
    this.api.command(postData, this.processDownloadFile);
  }

  processDownloadFile = (result) => {
    console.log("Attachment", result);
    let dbinfile = result.result;
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  };

  showAddFollowupModal = (event) => {
    event.preventDefault();
    this.setState({loader: true});
    console.log("showAddFollowupModal function fired");
    const api = new APICall();
    const postData = {command: "list_my_gc_users", user_id: this.state.task.parenttask.task_json.object_data.requester_id};
    api.command(postData, this.processFollowupUsers);
    // const my_users = Store.getStoreData("my_users");
    // let current_followup = {
    //   id: Utils.genKey(6),
    //   subject: "",
    //   note: "",
    //   assigned_to: my_users[0],
    //   due_date: moment().unix(),
    //   attachments: [],
    //   created: false
    // };
    // this.setState({current_followup, show_followup: true});
  }

  processFollowupUsers = (result) => {
    console.log(result);
    const company_users = Store.getStoreData("company_users");
    let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
    let followup_contacts = [];
    for (let user_id of result) {
        // console.log(user_id);
        if (user_id in company_users_obj === true && company_users_obj[user_id].role !== "no_access") {
            followup_contacts.push({id: parseInt(user_id), name: company_users_obj[user_id].name});
        }
    }
    let current_followup = {
      id: Utils.genKey(6),
      subject: "",
      note: "",
      assigned_to: followup_contacts[0].id,
      due_date: moment().unix(),
      attachments: [],
      created: false,
      status: "Pending"
    };
    this.setState({current_followup, show_followup: true, followup_contacts, loader: false});
  }

  processFollowups = () => {
    const company_users = Store.getStoreData("company_users");
    let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
    const ret = { data: [], columns: [] };
    const actions = [{name: "View", action: "view"}, {name: "Remove", action: "delete"}];
    ret.columns = [
      { Header: "Status", accessor: "status", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Task", accessor: "subject", minWidth: 120, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Assigned To", accessor: "assigned_to", minWidth: 130, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
      { Header: "Due Date", accessor: "due_date", minWidth: 100, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center" } },
      {
        Header: "",
        Cell: (row) => {
          let show = {view: 1, delete: 1};
          if (row.original.created === false) {
            return <EllipsisMenu show={show} entity_id={row.original.id} actions={actions} performAction={this.performAction} />
          } else {
            return <div></div>
          }
        },
        width: 55,
        headerStyle: { textAlign: "center" },
        filterable: false
      }
    ];
    for (let task_id in this.state.task.parenttask.task_json.object_data.followups) {
      ret.data.push({
        id: task_id,
        status: this.state.task.parenttask.task_json.object_data.followups[task_id].status,
        created: this.state.task.parenttask.task_json.object_data.followups[task_id].created,
        subject: this.state.task.parenttask.task_json.object_data.followups[task_id].subject,
        assigned_to: company_users_obj[this.state.task.parenttask.task_json.object_data.followups[task_id].assigned_to.toString()].name,
        due_date: moment.unix(this.state.task.parenttask.task_json.object_data.followups[task_id].due_date).format('DD/MM/YYYY')
      });
    }
    return ret;
  }


  processNewlyAddedFollowup = (result) => {
    console.log(result);
    const company_users = Store.getStoreData("company_users");
    let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
    let followup_contacts = [];
    for (let user_id of result) {
        // console.log(user_id);
        if (user_id in company_users_obj === true && company_users_obj[user_id].role !== "no_access") {
            followup_contacts.push({id: parseInt(user_id), name: company_users_obj[user_id].name});
        }
    }
    this.setState({show_followup: true, followup_contacts, loader: false});
  }

  performAction = (entity_id, action_type) => {
    console.log("entity_id:", entity_id);
    console.log("action_type:", action_type);
    if (action_type === "view") {
      let current_followup = JSON.parse(JSON.stringify(this.state.task.parenttask.task_json.object_data.followups[entity_id.toString()]));
      this.setState({current_followup, loader: true});
      const api = new APICall();
      const postData = {command: "list_my_gc_users", user_id: this.state.task.parenttask.task_json.object_data.requester_id};
      api.command(postData, this.processNewlyAddedFollowup);
    }
    if (action_type === "delete") {
      if (window.confirm(`Are you sure you want to remove this followup task?`)) {
        let task = JSON.parse(JSON.stringify(this.state.task));
        delete task.parenttask.task_json.object_data.followups[entity_id.toString()];
        this.setState({task});
      }
    }
  }

  modifyCurrentFollowup = (current_followup) => {
    let task = JSON.parse(JSON.stringify(this.state.task));
    task.parenttask.task_json.object_data.followups[current_followup.id] = JSON.parse(JSON.stringify(current_followup));
    console.log("task:", task);
    this.setState({task, show_followup: false, current_followup: null});
  }

  closeFollowupModal = () => {
    this.setState({show_followup: false});
  }

  filesLoaded = (files) => {
    console.log("files", files)
    let contact_id = Store.getStoreData("loggedin_contact_id");
    let a = []
    for (let i = 0; i < files.length; i++) {
      files[i].name = files[i].name.replace("&", "");
      if (!("user_id" in files[i])) {
        files[i].user_id = contact_id;
        files[i].upload_date = last_action_time;
      }
    }
    console.log("files a", a)
    this.setState({binFiles: files,addnew: false});
  };

  processORcancel = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    let bin_files = this.state.binFiles;
    this.refs.dropZone.uploadFilesFromChild(bin_files);
  }

  onUploadComplete = (files) => {
    let contact_id = Store.getStoreData("loggedin_contact_id");
    const module_config = Store.getStoreData("module_config");
    let returnedFiles = JSON.parse(files);
    console.log("hello==>", returnedFiles)
    let bin_files = this.state.binFiles;
    let attachments = [];
    if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
      bin_files.forEach((file) => {
        let fileName = file.name;
        let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
        delete file.bin_file;
        file.ref_id = refID;
        attachments.push({uid: file.uid, name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time})
      });
    }
    console.log("bin_files", bin_files, "attachments", attachments)
    let task = JSON.parse(JSON.stringify(this.state.task));
    let task_json = JSON.parse(JSON.stringify(task.parenttask.task_json));
    let previous_bins = task_json.object_data.attachments;
    let new_bins = [...previous_bins, ...bin_files];
    console.log("new_bins", new_bins)
    let sent_mail_type = "completed";
    let cur_lane = task_json.object_data.module_config.general.confirm_trade_information === true ? "COMP_PAD_APPROVED" : "COMP_PAD_COMPLETED";
    if (this.state.app_levels.length === 1) {
      cur_lane = task_json.object_data.module_config.general.confirm_trade_information === true ? "COMP_PAD_APPROVED" : "COMP_PAD_COMPLETED";
      sent_mail_type = task_json.object_data.module_config.general.confirm_trade_information === true ? "approved_trade_confirm" : "approved_no_trade_confirm";
    } else {
      // cur_lane = task_json.object_data.approval_data.length === 2 ? "COMP_PAD_COMPLETED" : "COMP_PAD_FOR_APPROVAL"
      if (task_json.object_data.approval_data.length === 2) {
        cur_lane = task_json.object_data.module_config.general.confirm_trade_information === true ? "COMP_PAD_APPROVED" : "COMP_PAD_COMPLETED";
        sent_mail_type = task_json.object_data.module_config.general.confirm_trade_information === true ? "approved_trade_confirm" : "approved_no_trade_confirm";
      } else {
        cur_lane = "COMP_PAD_FOR_APPROVAL";
        sent_mail_type = "none";
      }
    }
    if (this.state.action_type === "reject") {
      cur_lane = "COMP_PAD_REJECTED";
      sent_mail_type = "rejected";
    }
    if (this.state.action_type === "confirm") {
      cur_lane = "COMP_PAD_COMPLETED";
      sent_mail_type = "completed";
    }
    task_json.task_data.last_action = cur_lane;
    task_json.task_data.cur_lane = cur_lane;
    task_json.task_data.actiondate = Date.now();
    task_json.object_data.approval_type = module_config.role_types[task_json.object_data.role_type_id].approver_level;
    /*task_json.object_data.approval_data[0].status = this.state.action_type === "reject" ? "rejected" : "approved";
    task_json.object_data.approval_data[0].status = this.state.action_type === "confirm" ? "confirmed" : task_json.object_data.approval_data[0].status;
    task_json.object_data.approval_data[0].attachments = attachments;*/
    task_json.object_data.approval_data[this.state.approval_state].status = this.state.action_type === "reject" ? "rejected" : "approved";
    task_json.object_data.approval_data[this.state.approval_state].status = this.state.action_type === "confirm" ? "confirmed" : task_json.object_data.approval_data[this.state.approval_state].status;
    task_json.object_data.approval_data[this.state.approval_state].attachments = attachments;
    task_json.action_data = {
      action: cur_lane,
      actiondate: Date.now(),
      assigned_to: 0,
      cur_lane: cur_lane,
      performed_by: Store.getStoreData("loggedin_contact_id")
    }
    const postData = {
      command: "update_task",
      task_json,
      bin_files: new_bins,
      taskid: task.parenttask.id,
      create_followups: Object.keys(task_json.object_data.followups).length === 0 ? false : true,
      sent_mail_type
    };
    console.log("postData", postData)
    this.api.command(postData, this.closeModalAndRefresh);
  }

  showReassignModal = (event) => {
    event.preventDefault();
    const reassigned_user_id = "reassigned_user_id" in this.state.task.parenttask.task_json.object_data === true ? this.state.task.parenttask.task_json.object_data.reassigned_user_id : 0;
    this.setState({reassigned_user_id, show_reassignment_modal: true});
  }

  closeReassignmentModal = () => {
    this.setState({show_reassignment_modal: false});
  }

  reassign = (obj) => {
    console.log("obj:", obj);
    let show_spinner = true;
    let task = JSON.parse(JSON.stringify(this.state.task));
    let task_json = JSON.parse(JSON.stringify(task.parenttask.task_json));
    let task_json_to_be_sent = JSON.parse(JSON.stringify(task.parenttask.task_json));
    task_json_to_be_sent.object_data.reassigned_user_id = parseInt(obj.selected_contact_obj.id);
    task_json_to_be_sent.object_data.reassignment_comments = obj.reassignment_comments;
    task_json_to_be_sent.object_data.reassigned_at = moment().unix();
    task_json_to_be_sent.action_data = {
      action: "approval_reassigned",
      actiondate: Date.now(),
      assigned_to: parseInt(obj.selected_contact_obj.id),
      cur_lane: "COMP_PAD_FOR_APPROVAL",
      performed_by: Store.getStoreData("loggedin_contact_id")
    }
    const popped = task_json_to_be_sent.object_data.approval_data.pop();
    const api = new APICall();
    const postData = {
      command: "update_task",
      task_json: task_json_to_be_sent,
      bin_files: [],
      taskid: task.parenttask.id
    };
    task_json.object_data.reassigned_user_id = parseInt(obj.selected_contact_obj.id);
    task_json.object_data.reassignment_comments = obj.reassignment_comments;
    task_json.object_data.reassigned_at = moment().unix();
    task.parenttask.task_json = task_json;
    this.setState({task, show_spinner})
    api.command(postData, this.afterReassignment);
  }

  afterReassignment = (result) => {
    console.log(result);
    this.setState({show_reassignment_modal: false, show_spinner : false});
  }

  render() {
    if (this.state.task === null) {
      return <div></div>;
    }
    console.log("task in approve modal:", this.state);
    const module_config = Store.getStoreData("module_config");
    console.log("MODULE CONFIG : ", module_config);
    const security_id = this.state.task.parenttask.task_json.object_data.trade_details.security_id;
    const currency = module_config.currencies[this.state.task.parenttask.task_json.object_data.trade_details.currency_id.toString()];
    console.log("currency:", currency);
    let identifiers_arr = [];
    let identifier_type_arr = [];
    Object.keys(module_config.security_register[security_id].identifier_types).forEach(key => {
      if (module_config.security_register[security_id].identifier_types[key] !== "") {
        identifiers_arr.push(module_config.security_register[security_id].identifier_types[key]);
        identifier_type_arr.push(module_config.security_identifier_types[key].title);
      }
    })
    const identifiers = identifiers_arr.join();
    const identifier_types = identifier_type_arr.join();

    const currency_symbols = {
      'GBP': "&pound;",
      'EUR': "&euro;",
      'USD': "&dollar;"
    };
    console.log("GBP:", currency_symbols['GBP']);

    let attachments = "attachments" in this.state.task.parenttask.task_json.object_data ? this.state.task.parenttask.task_json.object_data.attachments : []
    if(!Array.isArray(attachments)){
      attachments = []
    }

		const my_holdings = this.state.task.holdings;
		const automatic_rejection_advices = {};
		for (let item of module_config.general.automatic_rejection_advices) {
			automatic_rejection_advices[item.identifier] = {text: item.text, show: item.show_on_entry_form};
		}
    console.log("automatic_rejection_advices",automatic_rejection_advices);
		if (Store.getStoreData("role") === "team") {
			automatic_rejection_advices.restricted_security.show = module_config.general.show_restricted_security_information;
		}
		// console.log("automatic_rejection_advices:", automatic_rejection_advices);
		let warning = {short: false, breach: false, restrict: false, holding: false, past: false};
		if (this.state.task.parenttask.task_json.object_data.module_config.general.add_mandatory_checkboxes === true) {
			if (automatic_rejection_advices.short_trade.show === true && this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_short === true && this.state.task.parenttask.task_json.object_data.not_short === false) warning.short = true;
			if (automatic_rejection_advices.breaches_confirmation.show === true && this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_breach === true && this.state.task.parenttask.task_json.object_data.not_breach === false) warning.breach = true;
		}
		if (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval") {
			if (moment(this.state.task.parenttask.task_json.object_data.transaction_date, 'DD/MM/YYYY').set('hour', 23).set('minute', 59).set('second', 59).unix() < this.state.task.parenttask.task_json.object_data.disclosure_time && automatic_rejection_advices.past_trade_date.show === true) warning.past = true;
		}
    const trade_details = "actual_trade_details" in this.state.task.parenttask.task_json.object_data ? this.state.task.parenttask.task_json.object_data.actual_trade_details : this.state.task.parenttask.task_json.object_data.trade_details;
    // if (module_config.security_register[trade_details.security_id.toString()].is_restricted === true && automatic_rejection_advices.restricted_security.show === true) {
    //   warning.restrict = true;
    // }
    if('selected_security' in this.state.task.parenttask.task_json.object_data && this.state.task.parenttask.task_json.object_data.selected_security.icon === 'Warning' && automatic_rejection_advices.restricted_security.show === true){
      warning.restrict = true;
    }

    if (trade_details.security_id.toString() in my_holdings === true) {
      const holding_time = my_holdings[trade_details.security_id.toString()].holding_time;
      const holding_period_days = module_config.general.holding_period_days;
      console.log("holding_period_days", holding_period_days)
      const holding_check_time = moment.unix(holding_time).add(holding_period_days, 'days').unix();
      console.log("HOLDING TIME : ", holding_check_time, moment().set('hour', 23).set('minute', 59).set('second', 59).unix());
      if (moment().set('hour', 23).set('minute', 59).set('second', 59).unix() <= holding_check_time && automatic_rejection_advices.already_tread_hp.show === true) warning.holding = true;
    }
		let error = false;
		for ( let key in warning) {

			if (warning[key] === true) error = true;
		}
    console.log("Error",error);

    console.log("this.state.approval_state:", this.state.approval_state);
    return (
      <MRModalContainer>
        {this.state.show_spinner === true && <SpinningLoader />}
        {
          this.state.loader === true &&
          <Loader>
              <ClipLoader size={100} color={"#123abc"} loading={true} />
          </Loader>
        }
        {
          this.state.show_followup === true &&
          <NoteWrapper>
            <AddFollowupModal current_followup={this.state.current_followup} followup_contacts={this.state.followup_contacts} modifyCurrentFollowup={this.modifyCurrentFollowup} closeFollowupModal={this.closeFollowupModal} />
          </NoteWrapper>
        }
        {
          this.state.show_reassignment_modal === true &&
          <NoteWrapper>
            <ReassignmentModal reassigned_user_id={this.state.reassigned_user_id} requester_id={this.state.task.parenttask.task_json.object_data.requester_id} task_id={this.state.task.parenttask.id} closeReassignmentModal={this.closeReassignmentModal} reassign={this.reassign} />
          </NoteWrapper>
        }
        <MRModalHeader>
          {/* <MRModalHeaderText>{this.state.Ref_ID} - Approval</MRModalHeaderText> */}
          <MRModalHeaderText>{`PAD-${this.state.task.parenttask.id} - ${this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" ? "Approval" : "Review"}`}</MRModalHeaderText>
          <MRModalHeaderCloseBtn onClick={this.closeView}>
            <FaTimes />
          </MRModalHeaderCloseBtn>
          <ClearBoth />
        </MRModalHeader>

        <MRModalBody>
          {
            (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" && error === true) && (automatic_rejection_advices.already_tread_hp.show === true && warning.holding === true) &&
            <Row>
              <MRAlertContainer style={{ backgroundColor : "#def0d8", border :"1px solid #d1d4d2" }}>
                <p style={{ color: "#5e978d" }}><b>{automatic_rejection_advices.already_tread_hp.text}</b></p>
              </MRAlertContainer>
            </Row>
          }
        {
            (() => {
              if (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval") {
                if ("reassigned_user_id" in this.state.task.parenttask.task_json.object_data) {
                  const reassigned_user_name = Store.getStoreData("company_users").find(item => parseInt(item.user_id) === parseInt(this.state.task.parenttask.task_json.object_data.reassigned_user_id)).name;
                  const reassignment_comments = this.state.task.parenttask.task_json.object_data.reassignment_comments;
                  return (
                    <div style={{padding: "0px 40px"}}>
                      <InfoDiv>
                          <div style={{ color: "#1a3552", fontWeight: "600" }}>Approval Reassigned</div>
                          <div style={{ color: "#1a3552", marginTop: "5px" }}>This PAD Request has been reassigned to {reassigned_user_name} with the following comments:</div>
                          <div style={{ color: "#1a3552", marginTop: "15px" }}>{reassignment_comments}</div>
                      </InfoDiv>
                    </div>
                  )
                }
              }
            })()
          }
          <MRDataContainer>
            <div style={{ padding: "0px 20px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Name</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{this.state.company_users_obj[this.state.task.parenttask.contact_id.toString()].name}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Role</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{Store.getStoreData("module_config").role_types[this.state.task.parenttask.task_json.object_data.role_type_id.toString()].name}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Instruction</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{Store.getStoreData("module_config").instruction_types[this.state.task.parenttask.task_json.object_data.instruction_type_id.toString()].name}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              {/* <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Transaction Type</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>Approval</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div> */}
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Transaction Date</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{this.state.task.parenttask.task_json.object_data.transaction_date}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              {this.state.custom_fields.map(field => {
                return (
                  <div key={field.name} style={{ width: "100%" }}>
                    <div style={{ float: "left", width: "30%" }}>
                      <ModalNameLabelDiv>{field.nickname}</ModalNameLabelDiv>
                    </div>
                    <div style={{ float: "left", width: "70%" }}>
                      <ModalNameInputDiv>{field.value}</ModalNameInputDiv>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                )
              })}
            </div>
            <HorizontalLine />
            <div style={{ padding: "0px 20px" }}>
              <ModalNameLabelDiv>Security Details</ModalNameLabelDiv>

              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Name</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{Store.getStoreData("module_config").security_register[this.state.task.parenttask.task_json.object_data.trade_details.security_id.toString()].security_name}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>

              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Identifier</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv title={identifiers}>{identifiers}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>

              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Identifier type</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{identifier_types}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <HorizontalLine />
              <ModalNameLabelDiv>Trade details</ModalNameLabelDiv>

              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Volume</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{this.state.task.parenttask.task_json.object_data.trade_details.volume}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Price</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  {/*<ModalNameInputDiv>&pound; {data.price}</ModalNameInputDiv>*/}
                  <ModalNameInputDiv><span dangerouslySetInnerHTML={{ __html: currency_symbols[currency.title] }}></span>{this.state.task.parenttask.task_json.object_data.trade_details.price}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              {/* <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Counterparty</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{module_config.counterparties[this.state.task.parenttask.task_json.object_data.trade_details.counterparty_id].title}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div> */}
              {
                Store.getStoreData("module_config").general.enable_employee_broker_ac === true &&
                <div style={{ width: "100%" }}>
                  <div style={{ float: "left", width: "30%" }}>
                    <ModalNameLabelDiv>Broker</ModalNameLabelDiv>
                  </div>
                  <div style={{ float: "left", width: "70%" }}>
                    <ModalNameInputDiv>
                      {
                        (()=>{
                          let broker_name = "N/A"
                          if("broker_id" in this.state.task.parenttask.task_json.object_data.trade_details && this.state.task.parenttask.task_json.object_data.trade_details.broker_id !==0){
                            let broker_obj = this.state.broker_list.find(item => item.id === this.state.task.parenttask.task_json.object_data.trade_details.broker_id)
                            broker_name = broker_obj.broker_name;
                            if(broker_obj.is_active === 0) broker_name += " (Inactive)";
                          }
                          return broker_name;
                        })()
                      }
                    </ModalNameInputDiv>
                  </div>
                  <div style={{ clear: "both" }}></div>
              </div>
              }
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Beneficial owner</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{module_config.beneficiaries[this.state.task.parenttask.task_json.object_data.trade_details.beneficiary_id].title}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Comments</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>{this.state.task.parenttask.task_json.object_data.requester_comments}</ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              {
                this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_short === true &&
                <Row style={{width: "100%", boxSizing: "border-box", marginBottom: "22px", marginTop: "20px"}}>
                  <div style={{float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
                    {this.state.task.parenttask.task_json.object_data.not_short === true && <FaRegCheckSquare style={{cursor: "pointer"}} />}
                    {this.state.task.parenttask.task_json.object_data.not_short === false && <FaRegSquare style={{cursor: "pointer"}} />}
                  </div>
                  <div style={{float: "left", width: "95%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
                    I confirm that this trade does not take the beneficial owner "Short"
                  </div>
                  <Clearfix></Clearfix>
                </Row>
              }
              {
                this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_breach === true &&
                <Row style={{width: "100%", boxSizing: "border-box", marginBottom: "20px"}}>
                  <div style={{float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
                    {this.state.task.parenttask.task_json.object_data.not_breach === true && <FaRegCheckSquare style={{cursor: "pointer"}} />}
                    {this.state.task.parenttask.task_json.object_data.not_breach === false && <FaRegSquare style={{cursor: "pointer"}} />}
                  </div>
                  <div style={{float: "left", width: "95%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
                    I confirm that this trade does not breach Regulator or Company rules or policies
                  </div>
                  <Clearfix></Clearfix>
                </Row>
              }

              <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "30%" }}>
                  <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                </div>
                <div style={{ float: "left", width: "70%" }}>
                  <ModalNameInputDiv>
                    {attachments.map(file => {
                      return (
                        <Row>
                          {file.name}
                          <div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
                            <FiDownload /> Download
                          </div>
                        </Row>
                      )
                    })}
                  </ModalNameInputDiv>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
          </MRDataContainer>
          <MRTasksContainer>
            {this.state.approval_state >= 1 &&
              <MRSuccessContainer>
                <div style={{ color: "#6c9451", fontWeight: "600" }}>Two Level Approval</div>
                <div style={{ color: "#6c9451", marginTop: "5px" }}>This PAD request has given first level approval</div>
                <div style={{ color: "#6c9451", marginTop: "20px" }}>
                  <div style={{float: "left", width: "15%", fontWeight: "600"}}>Reviewer:</div>
                  <div style={{float: "left", width: "85%"}}>
                    {this.state.company_users_obj[this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state - 1].performed_by].name}
                    </div>
                  <div style={{clear: "both"}}></div>
                </div>
                <div style={{ color: "#6c9451", marginTop: "5px" }}>
                  <div style={{float: "left", width: "15%", fontWeight: "600"}}>Approved:</div>
                  <div style={{float: "left", width: "85%"}}>
                    {moment.unix(this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state - 1].action_time).format('DD/MM/YYYY hh:mm a')}
                  </div>
                  <div style={{clear: "both"}}></div>
                </div>
                <div style={{ color: "#6c9451", marginTop: "5px" }}>
                  <div style={{float: "left", width: "15%", fontWeight: "600"}}>Comments:</div>
                  <div style={{float: "left", width: "85%"}}>
                    {this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state - 1].comments}
                  </div>
                  <div style={{clear: "both"}}></div>
                </div>
                {
                  (() => {
                    if (Store.getStoreData("role") !== "team" && module_config.general.internal_approver_comments === true) {
                      return (
                        <>
                          <div style={{ color: "#6c9451", marginTop: "5px" }}>
                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Internal Comments:</div>
                            <div style={{float: "left", width: "85%"}}>
                              {this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state - 1].internal_approver_comments}
                            </div>
                            <div style={{clear: "both"}}></div>
                          </div>
                        </>
                      )
                    }
                  })()
                }
                <div style={{ color: "#6c9451", marginTop: "5px" }}>
                  <div style={{float: "left", width: "15%", fontWeight: "600"}}>Attachments</div>
                  <div style={{float: "left", width: "85%"}}>
                    <ModalNameInputDiv style={{paddingTop: "0px"}}>
                      {this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state - 1].attachments.map(file => {
                        return (
                          <Row key={file.uid}>
                            {file.name}
                            <div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
                              <FiDownload /> Download
                            </div>
                          </Row>
                        )
                      })}
                    </ModalNameInputDiv>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              </MRSuccessContainer>
            }
            {
              (() => {
                if (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" && this.state.approver_entitled === "lm") {
                  if (this.state.manager_id === null || parseInt(this.state.manager_id) === parseInt(this.state.task.parenttask.task_json.object_data.requester_id)) {
                    return (
                      <MRDangerContainer>
                        <div style={{ color: "#a91414", fontWeight: "600" }}>Two Level Approval</div>
                        <div style={{ color: "#b75050", marginTop: "5px" }}>This PAD Request requires Line Manager approval but the employee does not have a Line Manager set up</div>
                        <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the first level approval. Note the same person cannot provide first and second approval.</div>
                      </MRDangerContainer>
                    )
                  }
                }
              })()
            }
            <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
            <MRModalTextarea rows="5" name="padreg_comments" readOnly={this.state.readOnly} value={this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />

            <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
            <ModalNameInputDiv>
              <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />
            </ModalNameInputDiv>
          </MRTasksContainer>

          {this.state.task.parenttask.cur_lane === "COMP_PAD_FOR_APPROVAL" &&
            <MRTasksContainer>
              <NoteTableHeaderBar>
                <Section50 style={{fontWeight: "600", color: "#ffffff", fontSize: "15px"}}>Follow Up Tasks</Section50>
                <Section50 style={{textAlign: "right", color: "#ffffff", fontSize: "15px", marginTop: "2px"}}>
                  <FaPlusCircle style={{cursor: "pointer"}} onClick={this.showAddFollowupModal} />
                </Section50>
                <Clearfix></Clearfix>
              </NoteTableHeaderBar>
              <CSLTable add={false} processData={this.processFollowups} zebra={false} tableRows={3} />
            </MRTasksContainer>
          }

          {
            (() => {
              if (Store.getStoreData("role") !== "team" && module_config.general.internal_approver_comments === true && this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval") {
                return (
                  <MRTasksContainer>
                    <MRModalLabel style={{ marginTop: "50px" }}>Internal Approver Comments</MRModalLabel>
                    <MRModalTextarea rows="5" name="internal_approver_comments" readOnly={this.state.readOnly} value={this.state.task.parenttask.task_json.object_data.approval_data[this.state.approval_state].internal_approver_comments} onChange={this.changeInternalApprovalComments} />
                  </MRTasksContainer>
                )
              }
            })()
          }

          {
            (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval" && error === true && warning.holding === false) &&
            <Row>
              <MRAlertContainer>
                {automatic_rejection_advices.introductory_text_paf.show === true && <p style={{ color: "#a91414" }}>{automatic_rejection_advices.introductory_text_paf.text}</p>}
                {(automatic_rejection_advices.short_trade.show === true && warning.short === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.short_trade.text}</b></p>}
                {(automatic_rejection_advices.breaches_confirmation.show === true && warning.breach === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.breaches_confirmation.text}</b></p>}
                {(automatic_rejection_advices.restricted_security.show === true && warning.restrict === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.restricted_security.text}</b></p>}
                {/* {(automatic_rejection_advices.already_tread_hp.show === true && warning.holding === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.already_tread_hp.text}</b></p>} */}
                {(automatic_rejection_advices.past_trade_date.show === true && warning.past === true) && <p style={{ color: "#b75050" }}><b>{automatic_rejection_advices.past_trade_date.text}</b></p>}
              </MRAlertContainer>
            </Row>
          }
          <AlertBox alertParam={this.state.alert_param} />
        </MRModalBody>

        <MRModalFooter>
          {
            (() => {
              if (parseInt(this.state.task.parenttask.task_json.object_data.requester_id) !== parseInt(Store.getStoreData("loggedin_contact_id"))) {
                return (
                  <>
                    {
                      this.state.task.parenttask.cur_lane === "COMP_PAD_FOR_APPROVAL" &&
                      <MRModalNextBtn
                        onClick={this.submitApprove("approve")}
                        disabled={this.state.submitting}
                        style={{
                          cursor: this.state.submitting === true ? "not-allowed" : "pointer",
                          filter: this.state.submitting === true ? "grayscale(1)" : "none"
                        }}
                      >
                        APPROVE
                      </MRModalNextBtn>
                    }
                    {
                      this.state.task.parenttask.cur_lane === "COMP_PAD_REGISTERED" &&
                      <MRModalNextBtn
                        onClick={this.submitApprove("confirm")}
                        disabled={this.state.submitting}
                        style={{
                          cursor: this.state.submitting === true ? "not-allowed" : "pointer",
                          filter: this.state.submitting === true ? "grayscale(1)" : "none"
                        }}
                      >
                        CONFIRM
                      </MRModalNextBtn>
                    }
                    {
                      this.state.task.parenttask.cur_lane === "COMP_PAD_FOR_APPROVAL" &&
                      <MRModalNextBtn
                        onClick={this.submitApprove("reject")}
                        disabled={this.state.submitting}
                        style={{
                          backgroundColor: "#C67878",
                          borderColor: "#C67878",
                          cursor: this.state.submitting === true ? "not-allowed" : "pointer",
                          filter: this.state.submitting === true ? "grayscale(1)" : "none"
                        }}
                      >
                        REJECT
                      </MRModalNextBtn>
                    }
                  </>
                )
              }
            })()
          }
          {
            (() => {
              if (this.state.task.parenttask.task_json.object_data.module_config.general.request_type === "approval") {
                if (this.state.approver_entitled === "lm") {
                  if (this.state.manager_id === null || this.state.manager_id === "0" || (parseInt(this.state.manager_id) === parseInt(this.state.task.parenttask.task_json.object_data.requester_id)) ) {
                    return <MRModalCancelBtn onClick={this.showReassignModal}>REASSIGN</MRModalCancelBtn>
                  }
                }
              }
            })()
          }
          <MRModalCancelBtn onClick={this.closeView}>CANCEL</MRModalCancelBtn>
          <ClearBoth />
        </MRModalFooter>
      </MRModalContainer>
    );
  }
}

export default TaskApprovalModal;
