import React from 'react';
import styled from 'styled-components';

const SliderBack = styled.div`
    height : 20px;
    width : 40px;
    border-radius : 12px;
    background-color : #F4F9FF;
    display : flex;
    flex-direction : row;
    position : relative;
    align-items: center;
    cursor : pointer;
`

const Slider = styled.div`
    height : 95%;
    width : 47%;
    border-radius : 12px;
    position : absolute;
    cursor : pointer;
`

class ToggleSwitch extends React.Component {

    state = {checked : false}

    // constructor(props) {
    //     super(props);
    //     this.state = {checked : this.props.checked};
    // }

    componentDidMount() {
        this.setState({checked: this.props.checked});
    }

    componentDidUpdate(prevprops) {
        if (prevprops !== this.props) this.setState({checked: this.props.checked});
    }

    handleToggle = (e) => {
        e.preventDefault();
        this.setState({checked : this.state.checked === true ? false : true})
        this.props.onClick(this.props.id);
    }
    render (){
        // console.log("Switch Id : ", this.props.id);
        // console.log("Switch cheched : ", this.props.checked);
        return (
            <SliderBack onClick={this.handleToggle} style={{justifyContent : this.state.checked === true ? "flex-end" : "flex-start"}}>
                <Slider style={{backgroundColor : this.state.checked === true ? "#11ABA6" : "#CD5251"}}/>
            </SliderBack>
        )
    }

}

export default ToggleSwitch;