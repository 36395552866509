import React from 'react';
import styled from "styled-components";
import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall';
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';
import { FaTimes, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import Dropzone from "../../Dropzone/Dropzone.js";
import AlertBox from "../../Common/AlertBox.js";
import SpinningLoader from '../../Common/SpinningLoader';
import YesNoBox from '../../Common/YesNoBox';
// const moment = require("moment");
const last_action_time = moment().unix();

const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 45%;
	position: absolute;
	top: 38px;
	z-index: 1001;
	margin-left: 27%;
	padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	// width: 40%;
	// position: absolute;
	// top: 38px;
	// z-index: 1001;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	// margin-bottom: 10px;
	// margin-left: 30%;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 85px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const MRModalFooter = styled.div`
  padding: 17px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 45px;
	border-radius: 4px;
	font-size: 13px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
  	background-color: #37ada7;
  	border: 1px solid #37ada7;
`;
const MsgBox = styled.div`
	color: #5A916A;
    font-weight: 600;
    background-color: #DEF0D8;
    border: 1px solid #d1d4d2;
    border-radius: 3px;
    padding: 10px 20px;
    box-sizing: border-box;
`;
const InfoBox = styled.div`
	background-color: #E7F0F7;
    border: 1px solid #d1d4d2;
    border-radius: 3px;
    margin-top: 20px;
`;
const Box = styled.div`
	padding: 20px;
`;
const Row = styled.div`
	box-sizing: border-box;
    width: 100%;
    margin-bottom: 15px;
`;
const Rowx = styled.div`
	margin-bottom: 10px;
`;
const Cell30 = styled.div`
	float: left;
	box-sizing: border-box;
	width: 30%;
`;
const Cell70 = styled.div`
	float: left;
    box-sizing: border-box;
    width: 70%;
    padding-left: 30px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	// margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;
const Section25 = styled.div`
	width: 25%;
	float: left;
	box-sizing: border-box;
`;
const Section50 = styled.div`
	width: 50%;
	float: left;
	box-sizing: border-box;
`;
const Section30 = styled.div`
	width: 30%;
	float: left;
	box-sizing: border-box;
`;
const Section70 = styled.div`
	width: 70%;
	float: left;
	box-sizing: border-box;
`;
const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;
const HR = styled.div`
	width: 100%;
	height: 1px;
	background-color: #bbbbbb;
`;
const BoxHeader = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	font-family: 'Montserrat',sans-serif;
	margin-bottom: 20px;
`;
const TradeDetailsContainer = styled.div`
  display : grid;
  grid-template-columns : 20% 47% 27%;
  grid-template-rows : minmax(25px, auto) 50px;
  grid-column-gap : 3%;
  margin-top : 20px;
`;

const TextArea = styled.textarea`
	resize: none;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	max-width: 100%;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 10px;
	box-sizing: border-box;
	margin-top : 13px;
`;

class ActualTradeModal extends React.Component {

	// state = {task_json: null};

	state = {
		task: null,
		company_users_obj: null,
		custom_fields: [],
		execdate: moment().toDate(),
		alert_param: null,
		binFiles: [],
		cur_files_json: null,
		submitting: false,
		cancelling : 0,
		show_spinner : false,
		alertParam : null,
		cancellation_reason : "",
		broker_list : []
	};

	async componentDidMount() {
		const company_users = Store.getStoreData("company_users");
		let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
		let task = this.props.task;
		task.parenttask.task_json.object_data.actual_trade_details = JSON.parse(JSON.stringify(task.parenttask.task_json.object_data.trade_details));
		let counterparties_data = Store.getStoreData('module_config').counterparties;
		let counterparties = [];
		for (let key in counterparties_data) {
			if (counterparties_data[key].isActive === 1) {
				counterparties.push({id: key, name: counterparties_data[key].title});
			}
		}
		let selected_counterparty = counterparties.find(item => item.id === task.parenttask.task_json.object_data.actual_trade_details.counterparty_id);

		let beneficiaries_data = Store.getStoreData('module_config').beneficiaries;
		let beneficiaries = [];
		for (let key in beneficiaries_data) {
			if (beneficiaries_data[key].isActive === 1) {
				beneficiaries.push({id: key, name: beneficiaries_data[key].title});
			}
		}
		let selected_beneficiary = beneficiaries.find(item => item.id === task.parenttask.task_json.object_data.actual_trade_details.beneficiary_id);

		let company_currencies = Store.getStoreData('module_config').currencies;
		let currencies = [];
		for (let key in company_currencies) {
			if (company_currencies[key].isActive === 1) {
				currencies.push({id: key, name: company_currencies[key].title});
			}
		}
		console.log("currencies:", currencies);
		let selected_currency = currencies.find(item => item.id === task.parenttask.task_json.object_data.actual_trade_details.currency_id);
		let custom_fields = [];
		if ("custom_fields" in task.parenttask.task_json.object_data) {
			if (task.parenttask.task_json.object_data.custom_fields.enabled === true && task.parenttask.task_json.object_data.custom_fields.additional_fields.length !== 0) {
				task.parenttask.task_json.object_data.custom_fields.additional_fields.forEach(field => {
					if (field.type === "Dropdown") {
					if (field.value === "other") {
						custom_fields.push({name: field.name, nickname: field.nickname, value: field.other_value});
					}
					if (field.value !== "" && field.value !== "other") {
						custom_fields.push({name: field.name, nickname: field.nickname, value: field.value});
					}
					} else {
					if (field.value !== "") {
						custom_fields.push({name: field.name, nickname: field.nickname, value: field.value});
					}
					}
				})
			}
		}

		let broker_list = [];
		if(Store.getStoreData("module_config").general.enable_employee_broker_ac === true){
			const api = new APICall();
			const postData = {command: "list_brokers", requester_id : task.parenttask.contact_id};
			let result = await api.commandWithoutCallback(postData);
			broker_list = result.result;
		}
		
		this.setState({task, company_users_obj, counterparties, selected_counterparty, beneficiaries, selected_beneficiary, currencies, selected_currency, custom_fields, broker_list});
	}

	componentDidMount_old () {
		const curmytaskdata = Store.getStoreData("cur_q_modal_data");
		const taskid = curmytaskdata.id;
		const discloserid = curmytaskdata.contact_id;
		let task_json = JSON.parse(curmytaskdata.task_json);
		const security_id = task_json.object_data.trade_details.security_id;
	    const trade_details = task_json.object_data.trade_details;
		task_json.object_data.actual_trade_details = {
			security_id: security_id,
	        volume: trade_details.volume,
	        currency_id: trade_details.currency_id,
	        price: trade_details.price,
	        counterparty_id: trade_details.counterparty_id,
	        beneficiary_id: trade_details.beneficiary_id
      	};
      	let counterparties_data = Store.getStoreData('module_config').counterparties;
		let counterparties = [];
		for (let key in counterparties_data) {
			if (counterparties_data[key].isActive === 1) {
				counterparties.push({id: key, name: counterparties_data[key].title});
			}
		}
		let selected_counterparty = counterparties.find(item => item.id === task_json.object_data.actual_trade_details.counterparty_id);

		let beneficiaries_data = Store.getStoreData('module_config').beneficiaries;
		let beneficiaries = [];
		for (let key in beneficiaries_data) {
			if (beneficiaries_data[key].isActive === 1) {
				beneficiaries.push({id: key, name: beneficiaries_data[key].title});
			}
		}
		let selected_beneficiary = beneficiaries.find(item => item.id === task_json.object_data.actual_trade_details.beneficiary_id);

		let company_currencies = Store.getStoreData('module_config').currencies;
		let currencies = [];
		for (let key in company_currencies) {
			if (company_currencies[key].isActive === 1) {
				currencies.push({id: key, name: company_currencies[key].title});
			}
		}
		console.log("currencies:", currencies);
		let selected_currency = currencies.find(item => item.id === task_json.object_data.actual_trade_details.currency_id);
		console.log("selected_currency:", selected_currency);
      	this.setState({task_json, counterparties, selected_counterparty, beneficiaries, selected_beneficiary, currencies, selected_currency, taskid, discloserid});
	}

	handleChange = (key) => (event) => {
		event.preventDefault();
		let task = JSON.parse(JSON.stringify(this.state.task));
		task.parenttask.task_json.object_data.actual_trade_details[key] = event.target.value;
		if (key === "volume" || key === "price") {
			task.parenttask.task_json.object_data.actual_trade_details.cash_consideration = (parseFloat(task.parenttask.task_json.object_data.actual_trade_details.volume) * parseFloat(task.parenttask.task_json.object_data.actual_trade_details.price)).toFixed(2);
		}
		this.setState({task});
	}

	changeCashConsideration = (event) => {
		event.preventDefault();
		let task = JSON.parse(JSON.stringify(this.state.task));
		task.parenttask.task_json.object_data.actual_trade_details.cash_consideration = event.target.value;
		this.setState({task});
	}

	changeCounterparty = (data) => {
		let selected_counterparty = this.state.counterparties.find(item => item.id === data);
		let task = JSON.parse(JSON.stringify(this.state.task));
		task.parenttask.task_json.object_data.actual_trade_details.counterparty_id = selected_counterparty.id;
		this.setState({selected_counterparty, task});
	}

	changeBeneficiary = (data) => {
		let selected_beneficiary = this.state.beneficiaries.find(item => item.id === data);
		let task = JSON.parse(JSON.stringify(this.state.task));
		task.parenttask.task_json.object_data.actual_trade_details.beneficiary_id = selected_beneficiary.id;
		this.setState({selected_beneficiary, task});
	}

	changeCurrency = (data) => {
		let selected_currency = this.state.currencies.find(item => item.id === data);
		let task = JSON.parse(JSON.stringify(this.state.task));
		task.parenttask.task_json.object_data.actual_trade_details.currency_id = selected_currency.id;
		this.setState({selected_currency, task});
	}

	changeDate = (value) => {
		console.log(value);
		console.log(moment(value).format('DD/MM/YYYY'));
		let unix_time = moment(value).unix();
		console.log(unix_time);
		this.setState({execdate: moment.unix(unix_time).toDate()});
	}

	cancelTask = (event) => {
		event.preventDefault();
		let submitting = true;
		let cancelling = 1;
		let alertParam = {
			"header" : "Alert",
			"subheader" : "",
			"msg" : "You are choosing to cancel this trade, are you happy to continue?",
			"closefunc" : this.closeSaveAndCloseAlert,
			"buttons" : {
				"1" : {
					"caption" : "Yes",
					"bgcolor" : "#4472C4",
					"color" : "white",
					"callback" : this.proceedToCancel
				},
				"2" : {
					"caption" : "No",
					"bgcolor" : "#203864",
					"color" : "white",
					"callback" : this.closeSaveAndCloseAlert
				}
			}
		}
		this.setState({cancelling, submitting, alertParam});
	}

	proceedToCancel = () => {
		this.setState({cancelling : 2});
		//this.submitTask();
	}

	proceedToSubmit = (event) => {
		event.preventDefault();
		//this.setState({submitting: true, show_spinner : true});
		this.submitTask();
	}

	changeComments = (event) => {
		event.preventDefault();
		let submitting = true;
		if(event.target.value.length > 0) submitting = false;
		let cancellation_reason = event.target.value;
		this.setState({submitting, cancellation_reason});
	}

	submitTask = () => {
		//event.preventDefault();
		console.log("submitTask");
		// console.log("task_json:", this.state.task_json);
		this.setState({submitting: true, show_spinner : true});
		let bin_files = this.state.binFiles;
		if(bin_files.length === 0) {
				let task = JSON.parse(JSON.stringify(this.state.task));
				task.parenttask.task_json.task_data.last_action = this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED";
				task.parenttask.task_json.task_data.cur_lane = this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED";
				task.parenttask.task_json.task_data.actiondate = Date.now();
				task.parenttask.task_json.object_data.actual_trade_details.execution_date_time = moment(this.state.execdate).unix();
				if(this.state.cancelling === 2){
					let cancellation_data = {
						reason : this.state.cancellation_reason,
						performed_by : Store.getStoreData("loggedin_contact_id"),
						action_time : moment(this.state.execdate).unix(),
						status : 'cancelled'
					}
					task.parenttask.task_json.object_data["cancellation_data"] = cancellation_data;
				}
				task.parenttask.task_json.action_data = {
					action: this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED",
					actiondate: Date.now(),
					assigned_to: 0,
					cur_lane: this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED",
					performed_by: Store.getStoreData("loggedin_contact_id")
				};
			    let postData = {
					command: "update_task",
					task_json: task.parenttask.task_json,
					bin_files: [],
					taskid: task.parenttask.id,
					sent_mail_type : this.state.cancelling === 2 ? "cancelled" : "completed"
				};
				//if(this.state.cancelling !== 2) postData["sent_mail"] = "completed";
				console.log("postData:", postData);
				const api = new APICall();
				api.command(postData, this.closeModalAndRefresh);
		}else{
		  let dnld = bin_files[0].bin_file.replace(/ /g, "+");
		  let binaryString = window.atob(dnld);
		  var fileSize = binaryString.length;
		  if(fileSize > 5000000){
		    let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
		    this.setState({ alert_param: alert_param });
		  }else{
		    this.refs.dropZone.uploadFilesFromChild(bin_files);
		  }
		}

	}

	closeSaveAndCloseAlert = () => {
		//console.log("Save and Close Alert Closed for Registration")
		this.setState({ cancelling : 0, submitting : false });
	}

	closeModalAndRefresh = (event) => {
		window.location.reload();
	};

	closeView = () => this.props.closeModal();

	handleDownload(image_id, task_id) {
		const api = new APICall();
	  let postData = { command: "download_attachment", image_id: image_id, task_id: task_id };
	  console.log("postData", postData);
	  api.command(postData, this.processDownloadFile);
	}

	processDownloadFile = (result) => {
	  console.log("Attachment", result);
	  let dbinfile = result.result;
	  let dnld = dbinfile.bin_file.replace(/ /g, "+");
	  let binaryString = window.atob(dnld);
	  var binaryLen = binaryString.length;
	  var bytes = new Uint8Array(binaryLen);
	  for (var i = 0; i < binaryLen; i++) {
	    var ascii = binaryString.charCodeAt(i);
	    bytes[i] = ascii;
	  }
	  var blob = new Blob([bytes]);
	  saveAs(blob, dbinfile.name);
	};

	filesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_contact_id");
	  let a = []
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	  }
	  console.log("files a", a)
	  this.setState({binFiles: files,addnew: false});
	};


	processORcancel = (result, stack) => {
	  if (!result || stack.prevent) {
	    this.setState({ alert_param: null });
	    return;
	  }
	  this.setState({ alert_param: null });
	  let bin_files = this.state.binFiles;
	  this.refs.dropZone.uploadFilesFromChild(bin_files);
	}

	filesLoaded = (files) => {
	  console.log("files", files)
	  let contact_id = Store.getStoreData("loggedin_contact_id");
	  for (let i = 0; i < files.length; i++) {
	    files[i].name = files[i].name.replace("&", "");
	    if (!("user_id" in files[i])) {
	      files[i].user_id = contact_id;
	      files[i].upload_date = last_action_time;
	    }
	  }

	  this.setState({binFiles: files,addnew: false});
	};

	onUploadComplete = (files) => {
		// alert("hello", files)
	  let returnedFiles = JSON.parse(files);
	  console.log("hello==>", returnedFiles)
	  let bin_files = this.state.binFiles;
	  let attachments = [];
	  let contact_id = Store.getStoreData("loggedin_contact_id");
	  if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
	    bin_files.forEach((file) => {
	      let fileName = file.name;
	      let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
	      delete file.bin_file;
	      file.ref_id = refID;
	      attachments.push({uid: file.uid, name: file.name, ref_id: refID, user_id: contact_id, upload_date: last_action_time})
	    });
	  }

	  	let task = JSON.parse(JSON.stringify(this.state.task));
	  	let trade_attachments = ('attachments' in task.parenttask.task_json.object_data) ? task.parenttask.task_json.object_data.attachments : [];
	  	let approval_attachments = ('approval_data' in task.parenttask.task_json.object_data && 'attachments' in task.parenttask.task_json.object_data.approval_data[0]) ? task.parenttask.task_json.object_data.approval_data[0].attachments : [];
	  	let new_bins = [...trade_attachments, ...approval_attachments, ...bin_files];
	  	let new_attachments = [];
		if(this.state.cancelling !==2 ){
			new_attachments = [...trade_attachments, ...attachments];
			task.parenttask.task_json.object_data.attachments = new_attachments;
		}		
		task.parenttask.task_json.task_data.last_action = this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED";
	  	task.parenttask.task_json.task_data.cur_lane = this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED";
	  	task.parenttask.task_json.task_data.actiondate = Date.now();
	  	task.parenttask.task_json.object_data.actual_trade_details.execution_date_time = moment(this.state.execdate).unix();
		  if(this.state.cancelling === 2){
			let cancellation_data = {
				reason : this.state.cancellation_reason,
				performed_by : Store.getStoreData("loggedin_contact_id"),
				action_time : moment(this.state.execdate).unix(),
				status : 'cancelled'
			}
			task.parenttask.task_json.object_data["cancellation_data"] = cancellation_data;
			task.parenttask.task_json.object_data.cancellation_data.attachments = task.parenttask.task_json.object_data.cancellation_data.attachments === undefined ? [] : task.parenttask.task_json.object_data.cancellation_data.attachments;
			for(let file of attachments)
				task.parenttask.task_json.object_data.cancellation_data.attachments.push(file);
		}
	  	task.parenttask.task_json.action_data = {
	  		action: this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED",
	  		actiondate: Date.now(),
	  		assigned_to: 0,
	  		cur_lane: this.state.cancelling === 2 ? "COMP_PAD_CANCELLED" : "COMP_PAD_COMPLETED",
	  		performed_by: Store.getStoreData("loggedin_contact_id")
	  	};
	    let postData = {
	  		command: "update_task",
	  		task_json: task.parenttask.task_json,
	  		bin_files: new_bins,
	  		taskid: task.parenttask.id,
			sent_mail_type : this.state.cancelling === 2 ? "cancelled" : "completed"
	  	};
		//if(this.state.cancelling !== 2) postData["sent_mail"] = "completed";
	  	console.log("postData:", postData);
	  	const api = new APICall();
	  	api.command(postData, this.closeModalAndRefresh);
	}

	render () {
		if (this.state.task === null) {
			return (<div>Loading...</div>);
		}
		// const curmytaskdata = Store.getStoreData("cur_q_modal_data");
	    const module_config = Store.getStoreData("module_config");
	    // const contacts = Store.getStoreData("contacts");
	    // console.log("contacts:", contacts);
	    // const task_json = JSON.parse(curmytaskdata.task_json);
	    const security_id = this.state.task.parenttask.task_json.object_data.actual_trade_details.security_id;
		const currency = module_config.currencies[this.state.task.parenttask.task_json.object_data.trade_details.currency_id.toString()];
	    // const trade_details = task_json.object_data.trade_details[security_id];
	    let identifier_values_arr = [];
	    let identifier_type_arr = [];
	    for (let key in module_config.security_register[security_id.toString()].identifier_types) {
	    	if (module_config.security_register[security_id.toString()].identifier_types[key] !== "") {
	    		identifier_values_arr.push(module_config.security_register[security_id].identifier_types[key]);
	    		identifier_type_arr.push(module_config.security_identifier_types[key].title);
	    	}
	    }
	    let identifier_values = identifier_values_arr.join();
	    let identifier_types = identifier_type_arr.join();
		const currency_symbols = {
			'GBP': "&pound;",
			'EUR': "&euro;",
			'USD': "&dollar;"
		};
		let attachments = "attachments" in this.state.task.parenttask.task_json.object_data ? JSON.parse(JSON.stringify(this.state.task.parenttask.task_json.object_data.attachments)) : []
		if(!Array.isArray(attachments)){
			attachments = []
		}
		let approval_attachments = ("approval_data" in this.state.task.parenttask.task_json.object_data && "attachments" in this.state.task.parenttask.task_json.object_data.approval_data[0]) ? this.state.task.parenttask.task_json.object_data.approval_data[0].attachments : []
		if(!Array.isArray(approval_attachments)){
			approval_attachments = []
		}
		let approval_data = "approval_data" in this.state.task.parenttask.task_json.object_data ? this.state.task.parenttask.task_json.object_data.approval_data : [];
		for (let item of approval_data) {
			item.internal_approver_comments = "internal_approver_comments" in item === true ? item.internal_approver_comments : "";
		}
		return (
			<ModalWraper>
				{this.state.show_spinner === true && <SpinningLoader />}
				{this.state.alertParam !== null && this.state.cancelling === 1 && <YesNoBox alertParam={this.state.alertParam}/>}
				<MRModalContainer>

					<MRModalHeader>
						<MRModalHeaderText>{`PAD-${this.state.task.parenttask.id}`}</MRModalHeaderText>
						<MRModalHeaderCloseBtn onClick={this.closeView}>
				            <FaTimes />
				        </MRModalHeaderCloseBtn>
				        <Clearfix />
				        {/*<MRModalSubHeaderText>Complete the required fields to create a new PA Dealing record</MRModalSubHeaderText>*/}
					</MRModalHeader>

					<MRModalBody>
						<MsgBox>The PAD request has been approved. You can now enter the actual trade data.</MsgBox>
						<InfoBox>
							<Box>
								<Row>
									<Cell30><MRModalLabel>Name</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{this.state.company_users_obj[this.state.task.parenttask.task_json.object_data.requester_id.toString()].name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{
									(() => {
									if (parseInt(this.state.task.parenttask.task_json.object_data.requester_id) !== parseInt(Store.getStoreData("loggedin_contact_id"))) {
										return(<Row>
											<Cell30><MRModalLabel>Role</MRModalLabel></Cell30>
											<Cell70><MRModalLabel>{Store.getStoreData("module_config").role_types[this.state.task.parenttask.task_json.object_data.role_type_id.toString()].name}</MRModalLabel></Cell70>
											<Clearfix></Clearfix>
										</Row>)
									}
									})()
								}
								
								<Row>
									<Cell30><MRModalLabel>Instruction</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{Store.getStoreData("module_config").instruction_types[this.state.task.parenttask.task_json.object_data.instruction_type_id.toString()].name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{/* <Row>
									<Cell30><MRModalLabel>Transaction Type</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{module_config.transaction_types[this.state.task.parenttask.task_json.object_data.transaction_type_id.toString()].name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row> */}
								<Row>
									<Cell30><MRModalLabel>Transaction Date</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.transaction_date}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{this.state.custom_fields.map(field => {
									return (
										<Row key={field.name}>
											<Cell30><MRModalLabel>{field.nickname}</MRModalLabel></Cell30>
											<Cell70><MRModalLabel>{field.value}</MRModalLabel></Cell70>
											<Clearfix></Clearfix>
										</Row>
									)
								})}
							</Box>
							<HR />
							<Box>
								<BoxHeader>Security Details</BoxHeader>
								<Row>
									<Cell30><MRModalLabel>Name</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{module_config.security_register[security_id.toString()].security_name}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Identifier</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{identifier_values}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Identifier Type</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{identifier_types}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
							</Box>
							<HR />
							<Box>
								<BoxHeader>Trade Details</BoxHeader>
								<Row>
									<Cell30><MRModalLabel>Volume</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.trade_details.volume}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Price</MRModalLabel></Cell30>
									<Cell70><MRModalLabel><span dangerouslySetInnerHTML={{ __html: currency_symbols[currency.title] }}></span>{this.state.task.parenttask.task_json.object_data.trade_details.price}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Cash Consideration</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>00</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								{/* <Row>
									<Cell30><MRModalLabel>Counterparty</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{module_config.counterparties[this.state.task.parenttask.task_json.object_data.trade_details.counterparty_id.toString()].title}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row> */}
								{
									Store.getStoreData("module_config").general.enable_employee_broker_ac === true &&
									<Row>
										<Cell30><MRModalLabel>Broker</MRModalLabel></Cell30>
										<Cell70><MRModalLabel>
											{
												(()=>{
													let broker_name = "N/A"
													if("broker_id" in this.state.task.parenttask.task_json.object_data.trade_details && this.state.task.parenttask.task_json.object_data.trade_details.broker_id !==0){
														let broker_obj = this.state.broker_list.find(item => item.id === this.state.task.parenttask.task_json.object_data.trade_details.broker_id)
														broker_name = broker_obj.broker_name;
														if(broker_obj.is_active === 0) broker_name += " (Inactive)";
													}
													return broker_name;
												})()
											}
										</MRModalLabel></Cell70>
										<Clearfix></Clearfix>
									</Row>
								}
								<Row>
									<Cell30><MRModalLabel>Beneficial Owner</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{module_config.beneficiaries[this.state.task.parenttask.task_json.object_data.trade_details.beneficiary_id.toString()].title}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<Row>
									<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
									<Cell70><MRModalLabel>{this.state.task.parenttask.task_json.object_data.requester_comments}</MRModalLabel></Cell70>
									<Clearfix></Clearfix>
								</Row>
								<div style={{ width: "100%" }}>
								  <div style={{ float: "left", width: "30%" }}>
								    <MRModalLabel>Attachments</MRModalLabel>
								  </div>
								  <div style={{ float: "left", width: "70%", paddingLeft : "30px", boxSizing : "border-box"}}>
								    <MRModalLabel>
								      {attachments.map(file => {
								        return (
								          <Row>
								            {file.name}
								            <div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
								              <FiDownload /> Download
								            </div>
								          </Row>
								        )
								      })}
								    </MRModalLabel>
								  </div>
								  <div style={{ clear: "both" }}></div>
								</div>
								{
									this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_short === true &&
									<Row style={{width: "100%", boxSizing: "border-box", marginBottom: "22px", marginTop: "20px"}}>
										<div style={{float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
											{this.state.task.parenttask.task_json.object_data.not_short === true && <FaRegCheckSquare style={{cursor: "pointer"}} />}
											{this.state.task.parenttask.task_json.object_data.not_short === false && <FaRegSquare style={{cursor: "pointer"}} />}
										</div>
										<div style={{float: "left", width: "95%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
											I confirm that this trade does not take the beneficial owner "Short"
										</div>
										<Clearfix></Clearfix>
									</Row>
								}
								{
									this.state.task.parenttask.task_json.object_data.module_config.general.checkbox_breach === true &&
									<Row style={{width: "100%", boxSizing: "border-box", marginBottom: "20px"}}>
										<div style={{float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#030303"}}>
											{this.state.task.parenttask.task_json.object_data.not_breach === true && <FaRegCheckSquare style={{cursor: "pointer"}} />}
											{this.state.task.parenttask.task_json.object_data.not_breach === false && <FaRegSquare style={{cursor: "pointer"}} />}
										</div>
										<div style={{float: "left", width: "95%", boxSizing: "border-box", fontWeight: "600", color: "#030303"}}>
											I confirm that this trade does not breach Regulator or Company rules or policies
										</div>
										<Clearfix></Clearfix>
									</Row>
								}
							</Box>
							{
								approval_data.map((item, index) => {
									return (
										<div key={index}>
											<HR />
											<Box>
												<Row>
													<Cell30><MRModalLabel>Reviewer</MRModalLabel></Cell30>
													<Cell70><MRModalLabel>{this.state.company_users_obj[item.performed_by.toString()].name}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												<Row>
													<Cell30><MRModalLabel>Response</MRModalLabel></Cell30>
													<Cell70><MRModalLabel style={{textTransform: "capitalize", color: "#37ADA7"}}>{item.status}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												<Row>
													<Cell30><MRModalLabel>Reviewed</MRModalLabel></Cell30>
													<Cell70><MRModalLabel>{moment.unix(item.action_time).format('DD/MM/YYYY hh:mm a')}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												<Row>
													<Cell30><MRModalLabel>Comments</MRModalLabel></Cell30>
													<Cell70><MRModalLabel>{item.comments}</MRModalLabel></Cell70>
													<Clearfix></Clearfix>
												</Row>
												{
													(() => {
														if (Store.getStoreData("role") !== "team" && module_config.general.internal_approver_comments === true) {
															return (
																<Row>
																	<Cell30><MRModalLabel>Internal Comments</MRModalLabel></Cell30>
																	<Cell70><MRModalLabel>{item.internal_approver_comments}</MRModalLabel></Cell70>
																	<Clearfix></Clearfix>
																</Row>
															)
														}
													})()
												}
												<div style={{ width: "100%" }}>
												<div style={{ float: "left", width: "30%" }}>
													<MRModalLabel>Attachments</MRModalLabel>
												</div>
												<div style={{ float: "left", width: "70%", paddingLeft : "30px", boxSizing : "border-box" }}>
													<MRModalLabel>
													{item.attachments.map(file => {
														return (
														<Row key={file.uid}>
															{file.name}
															<div style={{ cursor: "pointer",color:"#37ADA7" }} onClick={() => this.handleDownload(file.uid, this.state.task.parenttask.id)}>
															<FiDownload /> Download
															</div>
														</Row>
														)
													})}
													</MRModalLabel>
												</div>
												<div style={{ clear: "both" }}></div>
												</div>
											</Box>
										</div>
									)
								})
							}
						</InfoBox>
						<MRModalLabel style={{fontSize: "13px", marginTop: "20px"}}>Trade Details</MRModalLabel>
						{/* change arnab */}
						<Rowx>
							<TradeDetailsContainer>
								<MRModalLabel>Volume</MRModalLabel>
								<MRModalLabel>Price</MRModalLabel>
								<MRModalLabel>Cash Consideration</MRModalLabel>
								<TextBox type="text" value={this.state.task.parenttask.task_json.object_data.actual_trade_details.volume} onChange={this.handleChange("volume")} />
								<div style={{display : "flex", flexDirection : "row"}}>
									<div style={{width : "30%"}}><CslSimpleDropDown options={this.state.currencies} selected={this.state.selected_currency} setSelection={this.changeCurrency} /></div>
									<TextBox style={{width: "83%"}} type="text" value={this.state.task.parenttask.task_json.object_data.actual_trade_details.price} onChange={this.handleChange("price")} />
								</div>
								<TextBox type="text" value={this.state.task.parenttask.task_json.object_data.actual_trade_details.cash_consideration} onChange={this.changeCashConsideration} />
							</TradeDetailsContainer>
						</Rowx> 
						{/* change arnab */}
						{/* <Rowx>
							<Section25>
								<Rowx><MRModalLabel>Volume</MRModalLabel></Rowx>
								<TextBox type="text" value={this.state.task.parenttask.task_json.object_data.actual_trade_details.volume} onChange={this.handleChange("volume")} />
							</Section25>
							<Section50 style={{paddingLeft: "6%"}}>
								<Rowx><MRModalLabel>Price</MRModalLabel></Rowx>
								<div style={{width: "100%", boxSizing: "border-box"}}>
									<Section30>
										<CslSimpleDropDown options={this.state.currencies} selected={this.state.selected_currency} setSelection={this.changeCurrency} />
									</Section30>
									<Section70>
										<TextBox style={{width: "83%"}} type="text" value={this.state.task.parenttask.task_json.object_data.actual_trade_details.price} onChange={this.handleChange("price")} />
									</Section70>
									<Clearfix></Clearfix>
								</div>
							</Section50>
							<Section25>
								<Rowx><MRModalLabel>Cash Consideration</MRModalLabel></Rowx>
								<div><TextBox type="text" value={this.state.task.parenttask.task_json.object_data.actual_trade_details.cash_consideration} onChange={this.changeCashConsideration} /></div>
							</Section25>
							<Clearfix></Clearfix>
						</Rowx> */}
						<MRModalLabel style={{marginTop: "15px", marginBottom: "10px"}}>Execution Date and Time</MRModalLabel>
						<div style={{marginTop: "10px", marginBottom: "10px"}}>
							<DateTimePicker
								onChange={this.changeDate}
								value={this.state.execdate}
								clearIcon={null}
								format="dd/MM/y h:mm a"
								minDate={new Date(0)}
							/>
						</div>
						{/* <Rowx>
							<MRModalLabel style={{marginBottom: "10px"}}>Counterparty</MRModalLabel>
							<CslSimpleDropDown options={this.state.counterparties} selected={this.state.selected_counterparty} setSelection={this.changeCounterparty} />
						</Rowx> */}
						<Rowx>
							<MRModalLabel style={{marginBottom: "10px"}}>Beneficial Owner</MRModalLabel>
							<CslSimpleDropDown options={this.state.beneficiaries} selected={this.state.selected_beneficiary} setSelection={this.changeBeneficiary} />
						</Rowx>
						<Rowx>
							  <MRModalLabel>Attachments</MRModalLabel>
							  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} buttontxt="Add Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />
						</Rowx>
						{
							this.state.cancelling === 2 &&
							<Rowx>
							  <MRModalLabel>Please provide a reason for cancelling this trade<span style={{color: "red", fontWeight: "600", fontSize: "21px", marginLeft: "5px"}}>*</span></MRModalLabel>
							  <TextArea rows="5" cols="120" value={this.state.comments} onChange={this.changeComments}></TextArea>
							</Rowx>
						}
						
					</MRModalBody>

					<MRModalFooter>
						<MRModalNextBtn
							onClick={this.submitTask}
							disabled={this.state.submitting}
							style={{
								cursor: this.state.submitting === true ? "not-allowed" : "pointer",
								filter: this.state.submitting === true ? "grayscale(1)" : "none"
							}}
						>
							SUBMIT
						</MRModalNextBtn>
						<MRModalNextBtn
							onClick={this.cancelTask}
							disabled={this.state.cancelling}
							style={{
								cursor: this.state.cancelling === 1 || this.state.cancelling === 2 ? "not-allowed" : "pointer",
								filter: this.state.cancelling === 1 || this.state.cancelling === 2 ? "grayscale(1)" : "none",
								backgroundColor : "#D7504C",
								borderColor : "#A63E3B"
							}}
						>
							CANCEL
						</MRModalNextBtn>
						<Clearfix></Clearfix>
					</MRModalFooter>

				</MRModalContainer>
			</ModalWraper>
		);
	}

}

export default ActualTradeModal;