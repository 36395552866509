import React from "react";
import styled from "styled-components";
import CSLToggle from "./CSLToggle";
import CSLNumberTextBox from "../../Common/CSLNumberTextBox";
import CSLNumberTimesTextBox from "../../Common/CSLNumberTimesTextBox";

const GeneralContainer = styled.div`
  width: 90%;
`;
const GeneralInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const SubLabel = styled.div`
  font-weight: 400;
  color: #868686;
  margin-top: 5px;
  font-size: 12px;
`;
const Gensublebel = styled.p`
    font-weight: 600;
    font-size: 12px;
    color: #868686;
`;
const Clearfix = styled.div`
  clear: both;
`;
const Line = styled.hr`
  border: 1px solid #ebeff2;
  margin-top: 15px;
  float: left;
  width: 80%;
`;
class Sla extends React.Component {
  state = { sla: null};

  Automatic_Rejection_advise = [
    {
      header: "",
      text: "",
    },
    {
      header: "",
      text: "",
    },
  ];

  componentDidMount() {
    this.initialSetup();
  }

  initialSetup = () => {
    let timespans = {};

     
    let employee_notification_close_days_list = {};

    let reminder_email_before_sla_hour = {};
    let reminder_email_after_sla_hour = {};
    let repeated_followup_notfication_after_sla_days_list={};
   
    
    let repeate_after_sla_days_list={};

    let request_type = {};

    request_type["Approval"] = "Approval";
    request_type["Notification"] = "Notification";

     

    for (let i = 0; i <= 30; i++) {
      i = i.toString();
      employee_notification_close_days_list[i] = i;
    }

    repeated_followup_notfication_after_sla_days_list = employee_notification_close_days_list;

    for (let i = 24; i >= 0; i--) {
      i = i.toString();
      reminder_email_before_sla_hour[i] = i;
    }
    for (let i = 24; i >= 0; i--) {
      i = i.toString();
      reminder_email_after_sla_hour[i] = i;
    }
    repeate_after_sla_days_list =employee_notification_close_days_list
    this.setState({ sla: this.props.sla, timespans: timespans, request_type: request_type, employee_notification_close_days_list: employee_notification_close_days_list, reminder_email_before_sla_hour: reminder_email_before_sla_hour, reminder_email_after_sla_hour: reminder_email_after_sla_hour,repeated_followup_notfication_after_sla_days_list:repeated_followup_notfication_after_sla_days_list, repeate_after_sla_days_list:repeate_after_sla_days_list });
    // this.setState({ sla: sla, timespans: timespans, request_type: request_type, employee_notification_close_days_list: employee_notification_close_days_list, reminder_email_before_sla_hour: reminder_email_before_sla_hour, reminder_email_after_sla_hour: reminder_email_after_sla_hour,repeated_followup_notfication_after_sla_days_list:repeated_followup_notfication_after_sla_days_list, repeate_after_sla_days_list:repeate_after_sla_days_list });
  };

  switchCheck = (key) => (event) => {
    //event.preventDefault();
    let { sla } = this.state;
    sla[key] = sla[key] ? false : true;
    this.props.updateSla(sla);
  };

  changeDropResponse = (event, field) => {
    event.preventDefault();
    let  sla  = JSON.parse(JSON.stringify(this.state.sla));
    console.log("action_value", event.target);
    console.log("field", field);
    sla[field] = event.target.value;
    this.props.updateSla(sla);
  };

  handleChange = (event) => {
    let  sla  = JSON.parse(JSON.stringify(this.state.sla));
   sla[event.target.name] = event.target.value;
   this.props.updateSla(sla);
   this.setState({sla});
  }
  
  beforeSlaToogle = (status) => {
    // event.preventDefault();
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.email_before_sla_exceeded.enabled = sla.email_before_sla_exceeded.enabled === true ? false : true;
    this.props.updateSla(sla);
  }
  
  afterSlaToogle = (status) => {
    // event.preventDefault();
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.email_after_sla_exceeded.enabled = sla.email_after_sla_exceeded.enabled === true ? false : true;
    this.props.updateSla(sla);
  }
  
  repeatedFollowupToogle = (status) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.repeat_email_after_sla_exceeded.enabled = sla.repeat_email_after_sla_exceeded.enabled === true ? false : true;
    this.props.updateSla(sla);
    this.setState({sla});
  }
  
  changeValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.email_before_sla_exceeded.hours_before = parseInt(number);
    this.props.updateSla(sla);
  }
  
  changeCloseValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.days_to_close_notification = parseInt(number);
    this.props.updateSla(sla);
  }

  changeApprovalValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.hours_to_close_approval = parseInt(number);
    this.props.updateSla(sla);
  }

  changeTradeDetailsValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.days_to_provide_trade_details = parseInt(number);
    this.props.updateSla(sla);
  }
  
  changeExceededHoursValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.email_after_sla_exceeded.hours_after = parseInt(number);
    this.props.updateSla(sla);
  }
  
  changeFrequencyValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.repeat_email_after_sla_exceeded.frequency_in_days = parseInt(number);
    this.props.updateSla(sla);
  }
  
  changeRepetitionValue = (number) => {
    let sla = JSON.parse(JSON.stringify(this.props.sla));
    sla.repeat_email_after_sla_exceeded.repetition_times = parseInt(number);
    this.props.updateSla(sla);
  }

  render() {
    // if (this.state.sla === null) {
    //   return <div>Loading...</div>;
    // }
    
    console.log("this.props.sla:", this.props.sla);

    return (
      <GeneralContainer>
        <GeneralInnerContainer>
          <GeneralLabel>Days for managers to close an employee's notification </GeneralLabel>
            <SubLabel>Leave as '0' for no SLA </SubLabel>
          <div style={{ paddingTop: "10px", width: "20%" }}>
            {/*<TextBox style={{width:"35%"}} type = "number" value={this.state.sla.days_to_close_notification} name="days_to_close_notification" onChange={this.handleChange} max="30" min="0"/>*/}
            <CSLNumberTextBox value={this.props.sla.days_to_close_notification} changeValue={this.changeCloseValue} />
          </div>
          <Line />
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <GeneralLabel>Hours for managers to approve/reject an employee's approval request </GeneralLabel>
            <SubLabel>Leave as '0' for no SLA </SubLabel>
          <div style={{ paddingTop: "10px", width: "20%" }}>
            {/*<TextBox style={{width:"35%"}} type = "number" value={this.state.sla.days_to_close_notification} name="days_to_close_notification" onChange={this.handleChange} max="30" min="0"/>*/}
            <CSLNumberTextBox value={this.props.sla.hours_to_close_approval} changeValue={this.changeApprovalValue} />
          </div>
          <Line />
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <GeneralLabel>Days for Requester to provide trade details </GeneralLabel>
            <SubLabel>Leave as '0' for no SLA </SubLabel>
          <div style={{ paddingTop: "10px", width: "20%" }}>
            {/*<TextBox style={{width:"35%"}} type = "number" value={this.state.sla.days_to_close_notification} name="days_to_close_notification" onChange={this.handleChange} max="30" min="0"/>*/}
            <CSLNumberTextBox value={this.props.sla.days_to_provide_trade_details} changeValue={this.changeTradeDetailsValue} />
          </div>
          <Line />
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <GeneralLabel>Reminder Emails</GeneralLabel>
          <Gensublebel>Send a reminder email before a SLA is exceeded</Gensublebel>
          <CSLToggle checked={this.props.sla.email_before_sla_exceeded.enabled} onClick={this.beforeSlaToogle} />
          <Clearfix />
          {
            (() => {
              if(this.props.sla.email_before_sla_exceeded.enabled === true){
                return(<div>
                  <Gensublebel>Hours before SLA is exceeded</Gensublebel>
                  <div style={{ paddingTop: "10px"}}>
                    <CSLNumberTextBox value={this.props.sla.email_before_sla_exceeded.hours_before} changeValue={this.changeValue} />
                    {/*<TextBox style={{width:"35%"}} type = "number" value={this.state.sla.email_before_sla_exceeded.hours_before} name="send_email_before_sla" onChange={this.handleChange} max="24" min="0"/>*/}
                  </div>
                </div>)
              }

            })()
          }
          <Line />
        </GeneralInnerContainer>

        <GeneralInnerContainer>
          <Gensublebel>Send an email when a SLA is exceeded</Gensublebel>
          <CSLToggle checked={this.props.sla.email_after_sla_exceeded.enabled} onClick={this.afterSlaToogle} />
          <Clearfix />
          {
            (() => {
              if(this.props.sla.email_after_sla_exceeded.enabled === true){
                return(<div>
                  <Gensublebel>Hours after SLA is exceeded</Gensublebel>
                  <SubLabel>Leave as '0' to send immediately after SLA is exceeded</SubLabel>
                  <div style={{ paddingTop: "10px", width: "20%" }}>
                    {/*<TextBox style={{width:"35%"}} type = "number" value={this.state.sla.email_after_sla_exceeded.hours_after} name="send_email_after_sla" onChange={this.handleChange} max="24" min="0"/>*/}
                    <CSLNumberTextBox value={this.props.sla.email_after_sla_exceeded.hours_after} changeValue={this.changeExceededHoursValue} />
                  </div>
                </div>)
              }
            })()
          }
          <Line />
        </GeneralInnerContainer>
      </GeneralContainer>
    );
  }
}

export default Sla;
