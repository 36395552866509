/* eslint-disable */
import Store from './Store.js';
import { trackPromise } from 'react-promise-tracker';
import EventBus from './EventBus.js';
import http from 'mcc-front-aux/dist/httpCommon';

class APICall {
  constructor() {
    this.url = Store.getStoreData('api_url');
    this.is_admin = Store.getStoreData('is_admin');
    this.module_name = Store.getStoreData('module_name');
  }

  command(postData, callback, incremental_callback) {
    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    let incremental = (typeof incremental_callback !== 'undefined') ? true : false
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--')
                                   .replace(/'+/g, '--quote--')
                                   .replace(/’+/g, '--ap--')
                                   .replace(/‘+/g, '--rev_ap--')
                                   .replace(/\n/g, '')
                                   .replace(/\r/g, '');


    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }


    http.post(postURL, { jsondata: jsonDataString}).then(result => {
      result = result.data.body;
      result = result.replace(/(--plus--)/g, "+");
      result = result.replace(/(--quote--)/g, "'");
      result = result.replace(/--ap--+/g, "’");
      result = result.replace(/--rev_ap--+/g, "‘");
      result = JSON.parse(result);
      if(incremental && incremental_callback){
        incremental_callback(result);
      } else {
        if(typeof(result) === "object" && 'error_code' in result && result.error_code !== 0){
          EventBus.raiseEvent('system_err', result.error_msg)
        } else {
          if(callback) callback(result);
        }
      }
    }).catch(error => {
      console.error('FETCH ERROR',error);
      EventBus.raiseEvent('system_err', 'An error has occured. Please contact your System Administrator for further assistance.');
    })

    // trackPromise(fetch(this.url, httpVerb)
    //   .then(res => res.json() )
    //   // .then(res => {
    //   //     if(!res.ok) throw res
    //   //     try { return res.json() }
    //   //     catch { console.log('DATA RECEIVED',res) } })
    //   .then(result => {
    //     // console.log('FETCHED',result)
    //     result = JSON.stringify(result);
    //     result = result.replace(/--quote--+/g,"'").replace(/'+/g,"'");
    //     result = JSON.parse(result);
    //     if(incremental && incremental_callback){
    //       incremental_callback(result);
    //     } else {
    //       if(typeof(result) === "object" && 'error_code' in result && result.error_code !== 0){
    //         EventBus.raiseEvent('system_err', result.error_msg)
    //       } else {
    //         if(callback) callback(result);
    //       }
    //     }
    //
    //   }).catch(error => {
    //     console.log('FETCH ERROR',error);
    //     EventBus.raiseEvent('system_err', 'An error has occured. Please contact your System Administrator for further assistance.');
    //     return(null)
    //   }));
  }

  async commandWithoutCallback(postData) {
    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
    jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
    jsonDataString = jsonDataString.replace(/\n/g, '--nl--');
    jsonDataString = jsonDataString.replace(/’/g, '--ap--');
    jsonDataString = jsonDataString.replace(/‘/g, '--rev_ap--');
    jsonDataString = jsonDataString.replace(/\r/g, '');

    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }


    try {
      let result = await http.post(postURL, { jsondata: jsonDataString});
      result = result.data.body
      result = result.replace(/(--plus--)/g, "+");
      result = result.replace(/(--quote--)/g, "'");
      result = result.replace(/(--and--)/g, "&");
      result = result.replace(/--ap--+/g, "’");
      result = result.replace(/--rev_ap--+/g, "‘");
      result = result.replace(/(--nl--)/g, "\n");
      result = JSON.parse(result);
      return result;
    } catch (error) {
        console.error('FETCH ERROR',error);
        EventBus.raiseEvent('system_err', 'An error has occured. Please contact your System Administrator for further assistance.');
        return null
    }
  }
}

export default APICall;
