import React from 'react';
import styled from "styled-components";
import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall';
import { FaTimes, FaRegCheckSquare, FaRegSquare, FaEllipsisV } from "react-icons/fa";
import moment from 'moment';
import "./BrokerListTable.css";
import { Dvr } from '@mui/icons-material';
import { faL } from '@fortawesome/pro-solid-svg-icons';
import CSLToggle from '../module_config/CSLToggle';
import CSLTable from '../../Common/CSLTable';
import EllipsisMenu from '../../Common/EllipsisMenu';
import CslSimpleDropDown from '../../Common/CslSimpleDropDown';
import AddNewBrokerModal from './AddNewBrokerModal';
import YesNoBox from '../../Common/YesNoBox';
import ToggleSwitch from '../../Common/ToggleSwitch';

class BrokerListTable extends React.Component{
    state={
        show_main_context_menu : false,
        show_table_context_menu : true,
        requester_obj: {},
        contacts: null,
        broker_list : [],
        broker_list_backup : [],
        ready : false,
        show_add_new_broker_modal : false,
        broker_data : {
            id : 0,
            contact_id : 0,
            broker_name : "",
            account_type : "",
            account_name : "",
            account_id : "",
            open_date : moment().format("DD/MM/YYYY"),
            close_date : moment().format("DD/MM/YYYY"),
            active: true
        },
        show_alert : false,
        alertParam : null
    }

    async componentDidMount() {
        const company_users = Store.getStoreData("company_users");
		const my_users = Store.getStoreData("my_users");
		const loggedin_contact_id = Store.getStoreData("loggedin_contact_id");
		let company_users_obj = {};
		for (let user of company_users) {
			company_users_obj[user.user_id.toString()] = user;
		}
		let contacts = [];
		let requester_obj = {};
		for (let user_id of my_users) {
			if (user_id.toString() in company_users_obj) {
				if (company_users_obj[user_id.toString()].role !== "no_access") {
					if(company_users_obj[user_id.toString()].name !== "Inactive User"){
						contacts.push({id: company_users_obj[user_id.toString()].user_id, name: company_users_obj[user_id.toString()].name});
					} else {
						contacts.push({id: company_users_obj[user_id.toString()].user_id, name: company_users_obj[user_id.toString()].name});
					}	
				}
			}
		}
		requester_obj = {id: company_users_obj[loggedin_contact_id.toString()].user_id, name: company_users_obj[loggedin_contact_id.toString()].name};
		//requester_obj = {id: company_users_obj[task.task_json.object_data.requester_id.toString()].user_id, name: company_users_obj[task.task_json.object_data.requester_id.toString()].name};
		const sorted_contacts = [...contacts].sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        
        console.log("requester_obj:", requester_obj);
        await this.getBrokerList(requester_obj.id);
        // const postData = {command: "list_brokers", requester_id: requester_obj.id};
		// const api = new APICall();
		// let result = await api.commandWithoutCallback(postData);
        // console.log("Broker Result : ", result);
        // let broker_list = result.result;
        // console.log("Broker list : ", broker_list);
        this.setState({ contacts : sorted_contacts, requester_obj, ready : true })
    }

    getBrokerList = async (contact_id) => {
        console.log("Contact_id : ", contact_id)
        const postData = {command: "list_brokers", requester_id: contact_id};
		const api = new APICall();
		let result = await api.commandWithoutCallback(postData);
        console.log("Broker Result : ", result);
        let broker_list = result.result;
        this.setState({ broker_list, broker_list_backup : broker_list })
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    showMainContextMenu = (event) => {
        event.preventDefault();
        this.setState({show_main_context_menu : this.state.show_main_context_menu === true ? false : true })
    }

    hideMainContextMenu = (event) => {
        event.preventDefault();
        this.setState({show_main_context_menu : false })
    }

    toggleStatus = async (id) => {
        console.log("ID : ", id);
        let value = 0;
        let broker_list = JSON.parse(JSON.stringify(this.state.broker_list));
        for(let i = 0; i <= broker_list.length -1 ; i++){
            if(broker_list[i].id === id) broker_list[i].is_active = broker_list[i].is_active === 1 ? 0 : 1;
            value = broker_list[i].is_active;
        }  
        console.log("Broker List Toggle : ", broker_list)
        this.setState({ broker_list });
        const postData = {command: "activate_deactivate_broker", broker_id: id, value : value};
		const api = new APICall();
		let result = await api.commandWithoutCallback(postData);
        if(result.error_code !== 0){
            alert("Something went wrong. Couldn't update broker data. Please try again");
            this.setState({ broker_list : this.state.broker_list_backup })
        }  
    }

    changeRequester = async (data) => {
        console.log("Change Requester : ", data);
		let requester_obj = this.state.contacts.find(item => item.id === data.toString());
		//this.requester_obj = requester_obj;
        console.log("Requester Obj : ", requester_obj);
        await this.getBrokerList(requester_obj.id);
		// const postData = {command: "list_brokers", requester_id: parseInt(requester_obj.id), contact_id : parseInt(data)};
		// const api = new APICall();
		// let result = await api.commandWithoutCallback(postData);
        // console.log("Broker Result : ", result);
        // let broker_list = result.result;
		this.setState({requester_obj});
	}

    performAction = async (id, action) =>{
        let broker_obj = this.state.broker_list.find(item => item.id === id)
        console.log("Broker Obj : ", broker_obj);
        if(action === 'edit'){
            
            let broker_data = JSON.parse(JSON.stringify(this.state.broker_data))
            broker_data.id = broker_obj.id;
            broker_data.contact_id = broker_obj.contact_id;
            broker_data.broker_name = broker_obj.broker_name;
            broker_data.account_type = broker_obj.account_type;
            broker_data.account_name = broker_obj.account_name;
            broker_data.account_id = broker_obj.account_id;
            broker_data.open_date = moment.unix(broker_obj.ac_opening_date).format("DD/MM/YYYY");
            broker_data.close_date = broker_obj.ac_close_date === -1 ? null : moment.unix(broker_obj.ac_close_date).format("DD/MM/YYYY");
            broker_data.active = broker_obj.is_active === 1 ? true : false;

            this.setState({broker_data, show_add_new_broker_modal : true});
        }
        if (action === "delete") {
            const api = new APICall();
            if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
              const postData = {
                command: "delete_broker",
                broker_id: id,
                contact_id : broker_obj.contact_id
              };
              console.log("postData:", postData);
              let command_result = await api.commandWithoutCallback(postData);
              if(command_result.result === 'success'){
                await this.getBrokerList(broker_obj.contact_id);
                let alertParam = {
                    "header" : "Broker Data Deleted",
                    "subheader" : "",
                    "msg" : "Broker successfully deleted",
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                this.setState({ show_alert : true, alertParam});
              }
              else if(command_result.error_code !== 0) {
                let alertParam = {
                    "header" : "System Alert",
                    "subheader" : "",
                    "msg" : "Something went wrong. Please try again or contact your system administrator",
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                this.setState({ show_alert : true, alertParam});
              }
              else if(command_result.error_msg === "exists"){
                let alertParam = {
                    "header" : "Broker Delete Alert",
                    "subheader" : "",
                    "msg" : "There are PAD Requests registered to this broker so it cannot be removed. Please mark it as inactive to remove it from your broker selection menu on your PAD Entry form.",
                    "closefunc" : this.closeAlert,
                    "buttons" : {
                        "1" : {
                            "caption" : "OK",
                            "bgcolor" : "#4472C4",
                            "color" : "white",
                            "callback" : this.closeAlert
                        },
                    }
                }
                this.setState({ show_alert : true, alertParam});
              }
            }
        }
    }

    showAddNewBroker = (id) => (event) => {
        event.preventDefault();
        let broker_data = {
            id : id,
            contact_id : 0,
            broker_name : "",
            account_type : "",
            account_name : "",
            account_id : "",
            open_date : moment().format("DD/MM/YYYY"),
            close_date : null,
            active: true
        };
        broker_data.contact_id = parseInt(this.state.requester_obj.id)
        this.setState({ broker_data, show_add_new_broker_modal : true });
    }

    closeAddNewBroker = () =>{
        this.setState({ show_add_new_broker_modal : false });
    }

    closeAlert = () => {
		//console.log("Save and Close Alert Closed for Registration")
		this.setState({ show_alert : false, alertParam : null })
	}

    saveBrokerData = async (broker_data,action) => {
        console.log("Broker data from Add new broker : ", broker_data);
        if(action !== "no change"){
            const postData = { command : 'save_broker', broker_data : broker_data}
            const api = new APICall();
            let result = await api.commandWithoutCallback(postData);
            console.log("Broker Save Result : ", result);
            if(result.error_code === 0){
                await this.getBrokerList(broker_data.contact_id);
                if(action === 'insert')
                    alert("Broker Added succesfully");    
                else
                    alert("Broker Updated successfully");
            }
            else{
                alert("Something went wrong. Please try agaain or contact your system administrator");
            }
        }
        this.setState({show_add_new_broker_modal : false});
    }

    processBrokerTable = () =>{
        let ret = { data: [], columns: [] };
        const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}];
        ret.columns = [
        { Header: "Broker", accessor: "broker_name", width: 220, filterable: true, headerStyle: { textAlign: "left", lineHeight: "24px" }}, //, Cell: (row) => <div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}></div> },
        { Header: "Account Type", accessor: "account_type", width: 220, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
        { Header: "Account Name", accessor: "account_name", width: 220, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
        { Header: "Account ID", accessor: "account_id", width: 220, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
        { Header: "Open Date", accessor: "open_date", width: 100, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { textAlign: "center", lineHeight: "24px" }}, 
        // sortMethod: (a, b) => {
        //     if (a === b) {
        //         return 0;
        //     }
        //     let adue = a.split("/");
        //     const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        //     let  bbdue = b.split("/");
        //     const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        //     return aReverse > bReverse ? 1 : -1;
        // } },
        { Header: "Close Date", accessor: "close_date", width: 100, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { textAlign: "center", lineHeight: "24px" }},
        // {
        //     Header: "",
        //     Cell: (row) => {
        //     let show = {view: 1, delete: 1};
        //     if (Store.getStoreData("role") === "team") show.delete = 0;
        //     if (row.original.status === "COMPLETE") show.delete = 0;
        //     return <div style={{marginTop : "-6px"}}><EllipsisMenu show={show} entity_id={row.original.id} actions={actions} performAction={this.performFollowupAction} /></div>
        //     },
        //     width: 55,
        //     headerStyle: { textAlign: "center" },
        //     filterable: false
        // }
        { Header: "Active", width: 90, filterable: false, headerStyle: { textAlign: "center", lineHeight: "24px" }, style : { display : "flex", justifyContent : "center"},
            Cell: (row) => (
                    <div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}>
                        <ToggleSwitch id={row.original.id} checked={row.original.active} onClick={this.toggleStatus} />
                        {/* <CSLToggle id={row.original.id} checked={row.original.active} onClick={this.toggleStatus} /> */}
                    </div>
            )
            
        },
        { Header: "", width: 50, filterable: false, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px", },
            Cell: (row) => {
                let show = {edit: 1, delete: 1};
                return(
                    <div style={{ marginTop: "-5px"}}>
                        <EllipsisMenu show={show}  entity_id={row.original.id} actions={actions} performAction={this.performAction} />
                    </div>
                )
            }
        }
        ];
        for (let data of this.state.broker_list) {
            ret.data.push({
                id : data.id,
                broker_name : data.broker_name,
                account_type : data.account_type,
                account_name : data.account_name,
                account_id : data.account_id,
                open_date : moment.unix(data.ac_opening_date).format("DD/MM/YYYY"),
                close_date : data.ac_close_date === -1 ? "--/--/----" : moment.unix(data.ac_close_date).format("DD/MM/YYYY"),
                active: data.is_active === 1 ? true : false
            });
        }
        return ret;
    }

    exportBrokerList = (event) =>{
        event.preventDefault();
        let headerCSV = {
            broker_name : "Broker Name", 
            account_type : "Account Type", 
            account_name: "Account Name at Broker", 
            account_id: "Account ID at Broker", 
            open_date: "Account Opening Date", 
            close_date: "Account Close Date", 
            active : "Active"
        };
        let dataCSV = [];
        let broker_list = JSON.parse(JSON.stringify(this.state.broker_list))
        for(let broker of broker_list){
            let data= {
                broker_name : broker.broker_name, 
                account_type : broker.account_type, 
                account_name: broker.account_name, 
                account_id: broker.account_id, 
                open_date: moment.unix(broker.ac_opening_date).format("DD/MM/YYYY"), 
                close_date: broker.ac_close_date === -1 ? "" : moment.unix(broker.ac_close_date).format("DD/MM/YYYY"), 
                active : broker.is_active === 1 ? "Yes" : "No"
            }
            dataCSV.push(data);
        }
        console.log("Headers : ", headerCSV, "Data : ", dataCSV);
        this.exportToCSV(headerCSV, dataCSV);
    }

    exportToCSV = (headerCSV, dataCSV) => {
        //event.preventDefault();
        //let tableData = this.processDataTable();
        // if (Store.getStoreData("role") === "team") {
        //   delete headerCSV.requester;
        // }
        let data= dataCSV;
        let csv_data_arr = [];
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
          console.log("Data in Export : ", data[k]);
          let cont = {};
          Object.entries(headerCSV).forEach(([key, value]) => {
            if(data[k][key] !== null) {
              console.log("Data key", data[k][key]);
              cont[key] = data[k][key].toString().replace(/,/g, '  ').replace(/-/g, '-');
            }
          })            
          csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Broker List.csv");
        document.body.appendChild(link); 
        link.click();
    }

    render() {
        if(this.state.ready === false) return(<div></div>);
        return(
            <div className='broker-list-wrapper'>
                {this.state.alertParam !== null && this.state.show_alert === true && <YesNoBox alertParam={this.state.alertParam}/>}
                <div className='broker-list-header'>
                    <div className='broker-list-heading'>
                        Broker List
                    </div>
                    <div className='broker-list-close'>
                        <FaTimes style={{ cursor : "pointer" }} onClick={this.closeModal}/>
                    </div>
                </div>
                <div className='broker-list-body'>
                    {
                        Store.getStoreData("role") === "admin_manager" &&
                        <div className='broker-list-row'>
                            <div className='broker-list-label'>Employee Name</div>
                            <CslSimpleDropDown options={this.state.contacts} selected={this.state.requester_obj} setSelection={this.changeRequester} />
                        </div>
					}
                    <div className='broker-list-button-container'>
                            <button className='broker-list-add-button' onClick={this.showAddNewBroker(0)}>Add New Record</button>
                            <div className='broker-list-main-ellipsis'>
                                <FaEllipsisV onClick={this.showMainContextMenu}/>
                                {
                                    this.state.show_main_context_menu &&
                                    <div className='broker-list-main-context-menu' onMouseLeave={this.hideMainContextMenu}>
                                        <div className='broker-list-main-context-menu-item' onClick={this.exportBrokerList}>Export</div>
                                    </div>
                                }
                            </div>
                    </div>
                    <div className='broker-list-table-container'>
                        <CSLTable add={false} processData={this.processBrokerTable} zebra={false} tableRows={5}/>
                    </div>
                    <div className='broker-list-footer-button-container'>
                        <button className='broker-list-footer-button broker-list-close-button' onClick={this.closeModal} >Close</button>
                    </div>
                </div>
                {
                    this.state.show_add_new_broker_modal === true &&
                    <div className='interactive-overlay-broker-modal'>
                        <AddNewBrokerModal saveBrokerData={this.saveBrokerData} broker_data={this.state.broker_data} closeModal={this.closeAddNewBroker} />
                    </div>
                }
            </div>
        )
    }
}

export default BrokerListTable;