import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination"
import "./table_styles.css";
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import Store from '../../Common/Store'
import { ThreeSixty } from '@mui/icons-material'


const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #1A3552;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;
const TableContainer = styled.div`
    background-color: #ffffff;
`

/**
 * This component wraps the *[react.table](https://www.npmjs.com/package/react-table)*, adds headers and pagination
 */
class CSLTable extends React.Component
{
    static defaultProps = {
        tableRows: 5,
        headerText: null,
        zebra: false,
    }

    state = { toExport : false, current_record_ids : [] }

    static propTypes = {
         /** Add + sign to fire this.showAddAskModal -- needs to change to pass function dynamically */
        add: PropTypes.bool,
        /**  Works together with Add -- needs to change to pass function dynamically */
        addTitle: PropTypes.string,
        /** Function that should return an object in format {data:[], colums:[]}*/
        processData: PropTypes.func,
        /** Number of rows */
        tableRows: PropTypes.number,
        /** Text for header */
        headerText: PropTypes.string,
        /** Alternated row background-colour */
        zebra: PropTypes.bool
    }

    // this prop is lost  needs to cross check
    // refreshCallback: undefined

    componentDidMount() {
        const currentRecords = this.selectTable.getResolvedState().sortedData;
        //console.log("currentRecords:", currentRecords);
        let current_record_ids = [];
        if(currentRecords.lenght!=0 && "from" in this.props && this.props.from === "list_task"){
            for(let record of currentRecords){
                current_record_ids.push(record._original.id);
            }
            Store.updateStore("list_task_current_record_ids", current_record_ids);
            this.setState({current_record_ids});
            //console.log("Current record ids component did mount : ", current_record_ids);
        }
        if(currentRecords.lenght!=0 && "from" in this.props && this.props.from === "mng_confirm"){
            for(let record of currentRecords){
                current_record_ids.push(record._original.id);
            }
            Store.updateStore("mng_confirm_current_record_ids", current_record_ids);
            //this.setState({current_record_ids});
            //console.log("Current record ids component did mount : ", current_record_ids);
        }
        /*exportClicked in this.props === true && */ this.setState({toExport : this.props.exportClicked})
        //console.log(this.props);
    }

    componentDidUpdate(prevProps) {
        //console.log("props from holding : ", this.props, prevProps)
        if(this.props !== prevProps){
            this.setState({toExport : this.props.exportClicked})
            const currentRecords = this.selectTable.getResolvedState().sortedData;
            //console.log("currentRecords:", currentRecords);
            let current_record_ids = [];
            if(currentRecords.lenght!=0 && "from" in this.props && this.props.from === "list_task"){
                for(let record of currentRecords){
                    current_record_ids.push(record._original.id);
                }
                Store.updateStore("list_task_current_record_ids", current_record_ids);
                this.setState({current_record_ids});
                //console.log("Current record ids component did mount : ", current_record_ids);
            }
            if(currentRecords.lenght!=0 && "from" in this.props && this.props.from === "mng_confirm"){
                for(let record of currentRecords){
                    current_record_ids.push(record._original.id);
                }
                Store.updateStore("mng_confirm_current_record_ids", current_record_ids);
                //this.setState({current_record_ids});
               //console.log("Current record ids component did mount : ", current_record_ids);
            }
            if(this.props.exportClicked === true && "from" in this.props === false) this.export();
        }
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }

    handleFilterChange = () => {
        const currentRecords = this.selectTable.getResolvedState().sortedData;
        let current_record_ids = [];
        // if(currentRecords.lenght!=0){
        //     for(let record of currentRecords){
        //         current_record_ids.push(record._original.id);
        //     }
        //     Store.updateStore("list_task_current_record_ids", current_record_ids);
        // }
        if(currentRecords.lenght!=0 && "from" in this.props && this.props.from === "list_task"){
            for(let record of currentRecords){
                current_record_ids.push(record._original.id);
            }
            Store.updateStore("list_task_current_record_ids", current_record_ids);
            //this.setState({current_record_ids});
            //console.log("Current record ids component did mount : ", current_record_ids);
        }
        if(currentRecords.lenght!=0 && "from" in this.props && this.props.from === "mng_confirm"){
            for(let record of currentRecords){
                current_record_ids.push(record._original.id);
            }
            Store.updateStore("mng_confirm_current_record_ids", current_record_ids);
            //this.setState({current_record_ids});
            //console.log("Current record ids component did mount : ", current_record_ids);
        }
        //this.setState({current_record_ids});
        //console.log("currentRecords:", currentRecords, "ids", current_record_ids);
    }


    //Following function is not ready
    export = () =>{
        const currentRecords = this.selectTable.getResolvedState().sortedData;
        console.log("currentRecords from export : ", currentRecords);
        // let rows = currentRecords;
        let rows = [];
        for (let item of currentRecords) {
            rows.push({"Security Name": item._original.name, "Security Type": item._original.type, "Security Identifiers": item._original.identifier, "Holding": item._original.holding});
        }
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Holdings");
        // XLSX.utils.sheet_add_aoa(worksheet, [["Security Name", "Security Type", "Security Identifiers", "User Holding"]], { origin: "A1" });
        // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
        // worksheet["!cols"] = [ { wch: max_width } ];
        XLSX.writeFile(workbook, `PA Dealing Employee Holdings ${moment().format('DD-MM-YYYY')}.xlsx`);
        this.props.preventExport();
    } 


	render() {
        let tableData = this.props.processData();

		return (
			<TableContainer>
			    <HeaderText style={{ display: this.props.headerText ? 'inherit' : 'none'}}>{this.props.headerText}</HeaderText>                    
                    { this.props.add &&
                        <TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>
                    }          
                    <div style={{width:'100%'}}>
	                    <ReactTable
                            ref={(r) => {this.selectTable = r;}}
                            className={ this.props.zebra ? "-striped" : "" }
                            PaginationComponent={ Pagination }
                            data={ tableData.data }
                            columns={ tableData.columns }
                            defaultPageSize={ parseInt(this.props.tableRows) }
                            filterable={ true }
                            defaultFilterMethod={ this.filterMethod }
                            onFilteredChange={this.handleFilterChange}
						/>
					</div>
			</TableContainer>
		)
	}
}

export default CSLTable;
