import React from "react";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import CSLTable from "../CSLTable/CSLTable";
import CSLToggle from "../CSLToggle";
// import Utils from "../../../../Common/Utils";
// import Store from "../../../../Common/Store";
import APICall from "../../../../Common/APICall";
import MangeInstructionType from "./modals/MangeInstructionType";
//import Dropdown from "../../../Common/Dropdown";
import EllipsisMenuModConfig from "../../../Common/EllipsisMenuModConfig";
import SpinningLoaderSmall from "../../../Common/SpinningLoaderSmall";
import Store from "../../../../Common/Store";


// const SearchInput = styled.input`
//   height: 30px;
//   width: 98%;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   padding: 5px;
//   color: #222222;
//   font-size: 15px;
//   font-family: "Montserrat", sans-serif;
// `;
// const MRModalLabel = styled.div`
//   color: #212121;
//   font-weight: 600;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   display: inline-block;
// `;
const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const HelpTextDiv = styled.div`
  background-color: #E7F0F7;
  border: 1px solid #d3d5d7;
  border-radius: 4px;
  padding: 17px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #6e6e6e;
  line-height: 20px;
`;

const LoaderContainer = styled.div`
  width : 70%;
  height : 62%;
  display : flex;
  align-items : center;
  justify-content : center;
  position : absolute;
`

const TransDateSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;

  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;

class InstructionTypes extends React.Component {

  state = {
    role_types: null,
    prefered_columns: null,
    show_modal: null,
    type:{},
    selected_transaction_date_type : null,
    active_section_not_ready : true,
  };

  constructor() {
    super();
    this.api = new APICall();
  }

  componentDidMount() {
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    console.log("postData", postData)
    this.api.command(postData, this.getroleTypes);
  }

  getroleTypes = (result) => {
    console.log(result.result)
    let prefered_columns = {};
    prefered_columns['name'] = 'Name';
    prefered_columns['description'] = 'Description';
    prefered_columns['transaction_date_type'] = 'Transaction Date';
    prefered_columns['impact'] = 'Impact';
    prefered_columns['status'] = 'Status';
    this.setState({role_types: result.result, prefered_columns: prefered_columns, active_section_not_ready : false})
  }

  toggleStatus = (is_active, id) => {
    let role_types = JSON.parse(JSON.stringify(this.state.role_types));
    // console.log("role_types", role_types)
    role_types[id.toString()].is_active = is_active ? 1 : 0;
    const postData = { command: "toggle_status"};
    postData['lookup_id'] = this.props.lookup_id;
    postData['is_active'] = is_active ? 1 : 0;
    postData['id'] = id;
    this.role_types = role_types;
    this.api.command(postData, this.toggleroleType);
  }

  toggleroleType = (result) => {
    alert(result.error_msg);
    this.setState({role_types: this.role_types});
  }

  addType = (event) => {
    event.preventDefault();
    let type = {};
    type['id'] = 0;
    type['status'] = 1;
    type['name'] = "";
    type['transaction_date_type'] = 'any_date';
    type['desc'] = "";
    type['impact'] = "None";
    this.setState({show_modal:'roletype', type: type});
  };

  openModal = (id) => {
    // event.preventDefault();
    let type = this.state.role_types[id.toString()];
    let lookup_json = JSON.parse(type.lookup_json)
    let t = {};
    t['id'] = id;
    t['status'] = type.is_active;
    t['name'] = type.name;
    t['transaction_date_type'] = type.transaction_date_type;
    t['desc'] = type.desc;
    t['impact'] = lookup_json.impact;
    this.setState({ show_modal: 'roletype', type: t });
  };

  deleteType = (id) => (event) => {
    let type = this.state.role_types[id.toString()];
    if (window.confirm(`Are you sure you want to delete this ${type['name']} from the list?`)) {
     const postData = { command: "delete_lookup"};
     postData['lookup_id'] = this.props.lookup_id;
     postData['id'] = id;
     this.api.command(postData, this.removeroleType);
    }
  };

  removeroleType = (result) => {
    let msg = "Instruction type successfully deleted";
    alert(msg);
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    this.api.command(postData, this.getroleTypes);
  }

  closeModal = (event) => {
    this.setState({ show_modal: null });
  };
  refreshParent = () => {
    this.setState({ show_modal: null });
    const postData = { command: "list_lookups"};
    postData['lookup_id'] = this.props.lookup_id;
    console.log("postData", postData)
    this.api.command(postData, this.getroleTypes);
  }

  handleTransDateChange = (id) => (e) =>{
    e.preventDefault()
    let role_types = this.state.role_types;
    console.log("instruction_types", role_types);
    console.log("VALUE", id);
    
    role_types[id].transaction_date_type = e.target.value;
    this.setState({role_types})
    console.log("instruction_types", role_types);
    const postData = { command: "save_lookup"};
    let role_json = JSON.parse(role_types[id].lookup_json);
    let lookup_json = {};
    lookup_json['transaction_date_type'] = role_types[id].transaction_date_type;
    lookup_json['name'] = role_types[id]["name"];
    lookup_json['description'] = role_json.description;
    lookup_json['impact'] = role_json.impact;

    
    postData['lookup_json'] = lookup_json;
    postData['name'] = role_types[id]["name"];
    postData['status'] = role_types[id]["is_active"];
    
    postData['description'] = role_json.description;
    postData['lookup_id'] = 'instruction_types';
    postData['id'] = id;
    console.log("postData", postData)
    this.api.command(postData, this.saveroleTypes);
  }
  
  saveroleTypes = (result) => {
    alert('Transaction Date Type has been successfully updated.')
  }


  tableData = () => {
    let table = { data: [], columns: [] };
    const actions = [{name: "Edit", action: "edit"}, {name: "Remove", action: "delete"}];
    table.columns = [
      { Header: "#", accessor: "index", width: 30, headerStyle: { textAlign: "center" }, style: { fontFamily: "monospace", fontSize: "14px", textAlign: "right", lineHeight : "30px" } },
      { Header: "Name", accessor: "name",width: 150, headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset", lineHeight : "30px" } },
      { Header: "Description", accessor: "description", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset", lineHeight : "30px" } },
      {
        Header: "Transaction Date",
        accessor: "transaction_date_type",
        headerStyle: { textAlign: "left" },
        filterable: true,
        style: { whiteSpace: "unset" },
        Cell: (row) => {
          console.log("row:", row);
          //console.log("instruction_date_types",Store.getStoreData('module_config').transaction_date_types);
          return <TransDateSelect style={{width: "98%"}} value={row.original.transaction_date_type} selected={this.state.selected_transaction_date_type} key={row.original.index} onChange={this.handleTransDateChange(row.original.key)}>
            {Store.getStoreData('module_config').transaction_date_types.map((dtype) => (
              <option key={dtype.id} value={dtype.name}>
                {dtype.nickname}
              </option>
            ))}
          </TransDateSelect>
          },
        width: 130,
        // headerStyle: { textAlign: "center" },
        // filterable: false
      },
      { Header: "Impact", accessor: "impact",width: 80, headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset", lineHeight : "30px" } },
      { Header: "Status", accessor: "status", width: 80, filterable: false, headerStyle: { textAlign: "center" }, style: { marginTop : "6px" }, Cell: (row) => <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} /> },
      {
        Header: "",
        style: { marginTop : "6px" },
        Cell: (row) => (
          // <Dropdown entity_id={row.original.id} actions={actions} performAction={this.performAction} />
          <div style={{marginTop : "-10px"}}><EllipsisMenuModConfig entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
        ),
        width: 55,
        headerStyle: { textAlign: "center" },
        filterable: false
      },
    ];
    let data = this.state.role_types;
    let i = 0;
    for(let key in data){
      i++;
      let lookup_json = JSON.parse(data[key].lookup_json)
      let impact = lookup_json.impact === "Negetive" ? "Negative" : lookup_json.impact;
      let elem = {'index' : i, 
              'key' : key,
              'name': data[key].name,
              'description': data[key].desc,
              'transaction_date_type' : data[key].transaction_date_type,
              'impact': impact,
              'id': data[key].id,
              'status': data[key].is_active,
              'status_value' : data[key].is_active === 1 ? "Active" : "In-Active"
             };
      table.data.push(elem);
    }
    return table;
  }
  
  performAction = (role_id, action_type) => {
    console.log("role_id:", role_id);
    console.log("action_type:", action_type);
    if (action_type === "edit") {
      this.openModal(role_id);
    }
    if (action_type === "delete") {
      if (window.confirm(`Are you sure you want to delete this item from the list?`)) {
        const postData = {
          command: "delete_lookup",
          lookup_id: this.props.lookup_id,
          check_key: "instruction_type_id",
          id: role_id
        };
        console.log("postData:", postData);
        this.api.command(postData, this.afterRemove);
      }
    }
  }

  afterRemove = (result) => {
    console.log(result);
    if (result.error_msg === "exists") {
      alert("This item cannot be removed as it is associated with tasks");
    } else {
      alert("This item is successfully removed");
      let role_types = JSON.parse(JSON.stringify(this.state.role_types));
      delete role_types[result.result.toString()];
      this.setState({role_types});
    }
  }

  render() {
    console.log("this.state.show_modal:", this.state.show_modal);
    
    if(this.state.active_section_not_ready === true) {
      return( <div><LoaderContainer><SpinningLoaderSmall /></LoaderContainer></div> );
    }

    return (
      <div>
        <div style={{ width: "90%" }}>
          <TypesContainer>
            {
              (() => {
                if(this.state.show_modal){
                  return <MangeInstructionType closeModal={this.closeModal} type = {this.state.type} refreshParent={this.refreshParent}/>
                }
              })()
            }
            <HelpTextDiv>
              Instruction types identify the reason for the trade, be it a simple purchase of an asset, a fund switch or an adjustment due to a corporate action. The first entry in the table is given as the default on the PAD request entry screen.
            </HelpTextDiv>
            <TypesInnerContainer>
              <div style={{ display: "flow-root", paddingBottom: "16px" }}>
                <div style={{ float: "left" }}><TypesBoldLabel style={{ display: "inline-block" }}>{this.props.sectionName}</TypesBoldLabel></div>
                <div onClick={this.addType} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                  <FaPlusCircle />
                </div>
              </div>
              <CSLTable add={false} processData={this.tableData} tableRows={10} />
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
    );
  }
}

export default InstructionTypes;
