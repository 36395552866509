import React from "react";

import styled from "styled-components";

import CSLToggle from "../../Components/PAD/module_config/CSLToggle";
import { FaTimes, FaCalendarAlt, FaPlusCircle, FaRegCheckSquare, FaRegSquare, FaExclamationTriangle } from "react-icons/fa";
// import AddPadModal from '../../Modals/AddPadModal';
//react ,components , PAD, modals
// const SearchInput = styled.input`
//   height: 30px;
//   width: 98%;
//   border: 1px solid #ffffff;
//   border-radius: 3px;
//   box-shadow: 0 0 4px #c4c4c4;
//   padding: 5px;
//   color: #222222;
//   font-size: 15px;
//   font-family: "Montserrat", sans-serif;
// `;
// const MRModalLabel = styled.div`
//   color: #212121;
//   font-weight: 600;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   display: inline-block;
// `;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #f1f6f7;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
`;

const ModalWraper = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 70%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  margin-left: 15%;
  padding-bottom: 100px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  // width: 40%;
  // position: absolute;
  // top: 38px;
  // z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  // margin-bottom: 10px;
  // margin-left: 30%;
`;
const MRModalHeader = styled.div`
background-color: white;
display: block;
padding-top: 5px;
font-size: 14px;
font-weight: 700;
height: 50px;
width: calc(100% - 10px);
border-width: 1px 1px 1px 10px;
border-left: 9px solid #04ada8;
border-bottom: 1px solid #dfdfdf;
`;
const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const MRModalHeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
`;
const ClearBoth = styled.div`
  clear: both;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 21px;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 200;
  color: #656565;
`;
class SecurityRestrictedRegisters extends React.Component {
  state = {
    ready: false,
    filters: null,
    security_register: null,
    show_modal: null,
    type: {},
    show_restricted_security_information: true,
    module_config: null,
    resctricted_list_toggle: true,
  };

 

  componentDidMount() {}
  render() {
    // if (!this.state.ready) return <div>Loading...</div>;
    // console.log("this.state.ready",this.state.ready)
    console.log("this.props", this.props);
    return (

      <ModalWraper>
    
      
        <MRModalContainer>
        <MRModalHeader>
            
            <MRModalHeaderCloseBtn>
              <FaTimes />
            </MRModalHeaderCloseBtn>
            <ClearBoth />
          
          </MRModalHeader>
         

          <MRModalBody>

      <div>
        <div style={{ width: "90%" }}>
          <TypesContainer>
            <TypesInnerContainer>
              <div style={{ display: "flow-root", paddingBottom: "16px" }}>
                <div style={{ float: "left" }}>
                  <TypesBoldLabel style={{ display: "inline-block" }}>Restricted List</TypesBoldLabel>
                </div>
                <div style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                  <div style={{ float: "left",paddingTop:"5px", paddingRight: "10px", color: "#595959", fontSize: "13px", fontWeight: "600" }}>
                    Show Restricted Security Information (included)
                    <div style={{ paddingLeft: "5px", float: "right" }}>
                      <CSLToggle checked={this.props} />
                    </div>
                  </div>
                </div>
              </div>
            </TypesInnerContainer>
          </TypesContainer>
        </div>
      </div>
          </MRModalBody>
     
      </MRModalContainer>
      </ModalWraper>
    );
  }
}

export default SecurityRestrictedRegisters;
