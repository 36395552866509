import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {chevronDown} from 'react-icons-kit/ionicons/chevronDown';
import {chevronUp} from 'react-icons-kit/ionicons/chevronUp';

const DropContainer = styled.div`
	min-height: 40px;
	width: 100%;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 8px 10px;
`;
const Section85 = styled.div`
	float: left;
	width: 85%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section15 = styled.div`
	float: left;
	width: 15%;
	box-sizing: border-box;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`;
const SingleOption = styled.div`
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #DBDBDB;
	cursor: pointer;
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;

class CSLSimpleDropDown extends React.Component
{
	// Demo data structure
	// state = {
	// 	data: ["Alpha", "Calendar", "Numeric", "Dropdown"],
	// 	selected: "Alpha",
	// 	show_options: false
	// };

	static propTypes = {
        /** Initial Data */
        options: PropTypes.array,
        /** Initial Data Selection*/
        selected: PropTypes.string,
        /** callback: value selected*/
		changeFieldType: PropTypes.func,
    };

	state = {data: null, selected: null, show_options: false};

	componentDidMount() {
	    document.addEventListener('mousedown', this.handleClickOutside);
	    this.setState({data: this.props.options, selected: this.props.selected});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({data: this.props.options, selected: this.props.selected, show_options: false});
		}
	}

	componentWillUnmount() {
	    document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = (node) => {
	    this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		if (this.state.show_options && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({show_options: false});
		}
	}

	toggleOptions = (event) => {
		event.preventDefault();
		const show_options = this.state.show_options ? false : true;
		this.setState({show_options});
	}

	makeSelected = (data) => (event) => {
		event.preventDefault();
		this.props.changeFieldType(data);
		// this.setState({selected: data, show_options: false});
	}

	render()
	{
		return (
			<div style={{position: "relative"}} ref={this.setWrapperRef}>
				<DropContainer>
					<Section85 style={{paddingTop: "3px"}}>{this.state.selected}</Section85>
					<Section15 style={{textAlign: "right", paddingTop: "3px"}}>
						{
							(() => {
								if (this.state.show_options) {
									return <Icon icon={chevronUp} onClick={this.toggleOptions} style={{cursor: "pointer"}} />
								} else {
									return <Icon icon={chevronDown} onClick={this.toggleOptions} style={{cursor: "pointer"}} />
								}
							})()
						}
					</Section15>
					<div style={{clear: "both"}}></div>
				</DropContainer>
				{
					(() => {
						if (this.state.show_options) {
							return (
								<OptionsContainer>
									{
										this.state.data.map((item) => {
											return (<SingleOption key={item} onClick={this.makeSelected(item)}>{item}</SingleOption>);
										})
									}
								</OptionsContainer>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default CSLSimpleDropDown;