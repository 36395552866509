import React from "react";
import { FaTimes, FaRegCheckSquare, FaRegSquare, FaEllipsisV } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import CSLHeader from "../../Common/CSLHeader";
import Store from "../../../Common/Store";
import SpinningLoader from "../../Common/SpinningLoader";
import "./ManageConfirmation.css"
import CSLTable from "../../Common/CSLTable";
import EllipsisMenu from "../../Common/EllipsisMenu";
import APICall from '../../../Common/APICall';
import moment from "moment";
import SendConfirmationModal from "./SendConfirmationModal";
import ConfirmationReportModal from "./ConfirmationReportModal";
import TransactionReportViewModal from "./TransactionReportViewModal";
import HoldingReportViewModal from "./HoldingReportViewModal";
import HoldingConfirmationReportModal from "./HoldingConfirmationReportModal";
import AlertBox from "../../Common/AlertBox";
//import PdfGen from "./ConfirmationReport";


class Pill extends React.Component{

    state = {
        active : false,
        text : "",
        count : 0
    }

    componentDidMount(){
        this.setState({ id : this.props.id, active : this.props.active, text : this.props.pilltext, count : this.props.pillcount });
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({ id : this.props.id, active : this.props.active, text : this.props.pilltext, count : this.props.pillcount });
        }
    }

    itemClicked = (event) => {
        event.preventDefault();
        this.props.changePill(this.state.id);
    }

    render(){
        //console.log("Pill Props : ", this.props);
        return(
            //<div className={"pill " + this.props.active === true ? "pill-active" : "pill-inactive"}>
            <div className={`${this.state.active === true ? "pill pill-active" : "pill pill-inactive"}`} onClick={this.itemClicked}>
                <div className="pill-text-container">{this.state.text}</div>
                <div className="pill-count-container">{this.state.count}</div>
            </div>
        )
    }
}

class ManageConfirmation extends React.Component{
    state = {
        is_debug: Store.getStoreData("is_debug"),
        show_spinner : false,
        pills: {
           all : { text : "All", count : 100, active : true },
           new: { text : "New", count : 2, active : false, color : "#ffc34d" },
           pending : { text : "Pending", count : 1, active : false, color : "#ffc34d" },
           rejected : { text : "Rejected", count : 20, active : false, color : "#d95656" },
           confirmed : { text : "Confirmed", count : 100, active : false, color : "#37ada7" },
        },
        report_types : {
            1 : {text : "TRANSACTION"},
            2 : {text : "HOLDING"}
        },
        report_list : [],
        show_list : [],
        inactive_users : [],
        show_main_context_menu : false,
        select_all : false,
        multi_select : 0,
        hide_inactive : true,
        show_confirmation_modal : false,
        show_confirmation_report_modal : false,
        show_report_view_modal : false,
        selected_records : { "transaction" : [], "holding" : []},
        selected_report : { "transaction" : {}, "holding" : {}},
        render_key : null,
        tran_report_id : null,
        hold_report_id : null,
        url_requester : null,
        ready : false,
        alert_param : null
    }

    async componentDidMount() {
        //this.setState({ show_spinner : true });
        let tran_report_id = null;
        let hold_report_id = null;
        let url_requester = null;
        if (this.props.match) {
            console.log("this.props.match.params.Id:", this.props.match.params.Id);
            let Id = this.props.match.params.Id;
            let sp_ar = []
            if(Id.startsWith('TR-')){
                sp_ar = Id.split('-');
                tran_report_id = parseInt(sp_ar[1]);
            }else if(Id.startsWith('HOLD-')){
                sp_ar = Id.split('-');
                hold_report_id = parseInt(sp_ar[1]);
            }else if(isNaN(Id) === false){
                url_requester = parseInt(Id);
            }

        }
        //    this.showreport(parseInt(this.props.match.params.Id));
        this.setState({ tran_report_id, hold_report_id, url_requester})
        this.intializeState();
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps) {
            let tran_report_id = null;
            let hold_report_id = null;
            let url_requester = null;
            if (this.props.match) {
                console.log("this.props.match.params.Id:", this.props.match.params.Id);
                let Id = this.props.match.params.Id;
                let sp_ar = []
                if(Id.startsWith('TR-')){
                    sp_ar = Id.split('-');
                    tran_report_id = parseInt(sp_ar[1]);
                }else if(Id.startsWith('HOLD-')){
                    sp_ar = Id.split('-');
                    hold_report_id = parseInt(sp_ar[1]);
                }else if(isNaN(Id) === false){
                    url_requester = parseInt(Id);
                }

            }
            //    this.showreport(parseInt(this.props.match.params.Id));
            this.setState({ tran_report_id, hold_report_id, url_requester})
            this.intializeState();
        }
    }

    intializeState = async () => {
        let report_list = [];
        let inactive_user_ids = [];
        let all_users = Store.getStoreData("my_all_users");
        let company_users = Store.getStoreData("company_users");
        const postData = { command: "list_confirmation_data" };
        const api = new APICall();
        const api_result = await api.commandWithoutCallback(postData);
        let non_new_record_ids = [];
        //check for existing records are to be implemented later
        //Creating only new record
        //let i=0;
        for(let id of all_users){
            //console.log("ID record : ", id);
            let user = company_users.find(item => item.user_id.toString() === id.toString());
            if(user !== undefined && user.role !== "no_access"){
                if(user.name === "Inactive User") inactive_user_ids.push(parseInt(user.user_id));
                //console.log("INACTIVE USERs : ", inactive_users);
                if(api_result.result.ids.includes(parseInt(id)) === false){
                    const commonData = {
                        record_id : 0,
                        checked : false,
                        status : "new",
                        contact_id : id,
                        date_sent : null,
                        due_date : null,
                        resp_date : null,
                        resp_time : null,
                        issued_by : null,
                        inactive_contact : user.name === "Inactive User",
                        last_action_time : 0
                    }
                    let dataTran = {...commonData, id : this.makeid(8), report_type : "1"};
                    let dataHold = {...commonData, id : this.makeid(8), report_type : "2"};
                    report_list.push(dataTran)
                    report_list.push(dataHold);
                }else{
                    let pads = 0;
                    let holds = 0;
                    for(let r of api_result.result.records){
                        if(r.contact_id === parseInt(id)){
                            let status = r.cur_lane.split('_')
                            let data = {
                                id : this.makeid(8),
                                record_id : r.id,
                                checked : false,
                                status : status[2].toLowerCase(),
                                report_type : status[0] === 'PAD' ? "1" : "2",
                                report_title : r.report_title,
                                contact_id : id,
                                from_date : r.from_date,
                                to_date : r. to_date,
                                date_sent : r.date_sent,
                                due_date : r.due_date,
                                resp_date : r.response_date,
                                resp_time : r.response_time,
                                issued_by : r.issued_by.toString(),
                                inactive_contact : user.name === "Inactive User" ? true : false,
                                last_action_time : r.last_action_time
                            }
                            if(status[0] === 'PAD'){ 
                                pads++;
                            }else if(status[0] === 'HOLD'){ 
                                holds++;
                            }
                            non_new_record_ids.push(r.id);
                            report_list.push(data);
                        }
                    }
                    if(pads === 0 || holds === 0) {
                        let data = {
                            id : this.makeid(8),
                            record_id : 0,
                            checked : false,
                            status : "new",
                            report_type : pads === 0 ? "1" : "2",
                            contact_id : id,
                            date_sent : null,
                            due_date : null,
                            resp_date : null,
                            resp_time : null,
                            issued_by : null,
                            inactive_contact : user.name === "Inactive User" ? true : false,
                            last_action_time : 0
                        }
                        report_list.push(data);
                    }
                }
            }
            //i++;
        }
        let inactive_users = [];
        if(inactive_user_ids.length !== 0){
            const postData1 = { command: "fetch_inactive_users", ids : inactive_user_ids };
            //api = new APICall();
            const api_result1 = await api.commandWithoutCallback(postData1);


            if(api_result1.result.length > 0){
                for(let record of api_result1.result){
                    inactive_users.push(
                        {
                            user_id : record.ID.toString(),
                            name : `${record.ContactName} (Inactive User)`,
                            role : (company_users.find(item => item.user_id === record.ID.toString())).role
                        }
                    )
                }
            }
        }
        console.log("Inactives : ",inactive_users);
        let show_list_active = [];
        if(this.state.hide_inactive === false) show_list_active = report_list;
        else show_list_active = await this.makeShowListActive(report_list);
        await this.calculatePillCount(show_list_active);
        console.log("LIST ACTIVE : ", show_list_active);
        let pills = JSON.parse(JSON.stringify(this.state.pills));
        for(let keys in pills){
            if(keys !== "all") pills[keys].active = false;
            //else pills[keys].active = false;
        }
        pills["all"].active = true;
        this.setState({ report_list, ready : true, show_list : report_list, show_list_active, inactive_users, pills, select_all : false, multi_select : 0});
        if(this.state.tran_report_id !== null){
            if(non_new_record_ids.includes(this.state.tran_report_id) === true) {
                let tran_report_id = this.state.tran_report_id;
                let report = report_list?.find(item => item.record_id === tran_report_id);
                if(report?.report_type !== "1"){
                    let alertParam = {
                        title: 'Error', message: `The Report ID TR-${this.state.tran_report_id?.toString()} is invalid`, ok_text: 'Ok', confirm: false,
                        alertHandler: this.closeAlertSmall, stack: {}
                    }
                    this.setState({ alert_param : alertParam})
                }else{
                    this.performAction(tran_report_id, 'view', true);
                }
                this.setState({tran_report_id : null});
            }
            else{
                let alert_param = {
                    title: 'Error', message: `You are not authorized to view Transaction Report with ID TR-${this.state.tran_report_id.toString()} or the Report ID is invalid`, ok_text: 'Ok', confirm: false,
                    alertHandler: this.closeAlertSmall, stack: {}
                }
                this.setState({ alert_param })
            }
            if(this.state.hold_report_id !== null){
                if(non_new_record_ids.includes(this.state.hold_report_id) === true) {
                    let holdReportId = this.state.hold_report_id;
                    let report = report_list?.find(item => item.record_id === holdReportId);
                    if(report?.report_type !== "2"){
                        let alertParam = {
                            title: 'Error', message: `The Report ID HOLD-${this.state.hold_report_id?.toString()} is invalid`, ok_text: 'Ok', confirm: false,
                            alertHandler: this.closeAlertSmall, stack: {}
                        }
                        this.setState({ alert_param : alertParam})
                    }else{
                        this.performAction(holdReportId, 'view', true);
                    }
                    this.setState({hold_report_id : null});
                }
                else{
                    let alertParam = {
                        title: 'Error', message: `You are not authorised to view Holding Report with ID HOLD-${this.state.hold_report_id.toString()} or the Report ID is invalid`, ok_text: 'Ok', confirm: false,
                        alertHandler: this.closeAlertSmall, stack: {}
                    }
                    this.setState({ alert_param : alertParam});
                }
            }
        }
    }

    makeShowListActive = async (show_list) => {
        let show_list_active = [];
        for(let item of show_list){
            if(item.inactive_contact === false) show_list_active.push(item);
        }
        return show_list_active;
    }

    makeid = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

    calculatePillCount = async (list)=>{
        let all = 0;
        let newrec = 0;
        let pending = 0;
        let rejected = 0;
        let confirmed = 0;
        let pills = JSON.parse(JSON.stringify(this.state.pills));
        for (let record of list){
            if(record.status === 'new') newrec++;
            else if(record.status === 'pending') pending++;
            else if(record.status === 'rejected') rejected++;
            else if(record.status === 'confirmed') confirmed++;
            all++;
        }
        pills.all.count = all;
        pills.new.count = newrec;
        pills.pending.count = pending;
        pills.rejected.count = rejected;
        pills.confirmed.count = confirmed;
        this.setState({ pills });
    }

    changePill = async (key) => {
        let pills = JSON.parse(JSON.stringify(this.state.pills));
        let active_pills = [];
        if(key === "all"){
            for(let keys in pills){
                if(keys !== key) pills[keys].active = false;
                //else pills[keys].active = false;
            }
            pills[key].active = true;
        }else{
            for(let keys in pills){
                if(keys === key) pills[keys].active = pills[keys].active === true ? false : true;
                pills["all"].active = false;
            }
            for(let keys in pills){
                if(pills[keys].active === true) active_pills.push(keys);
            }
            if(active_pills.length === 0) pills["all"].active = true;
        }
        console.log("Active Pills : ",active_pills);
        let report_list = await this.makeAllUnchecked(JSON.parse(JSON.stringify(this.state.report_list)));
        let show_list = [];
        for(let record of report_list){
            if(active_pills.includes(record.status))
                show_list.push(record);
        }
        if(pills["all"].active) show_list = report_list;
        //this.setState({ pills, show_list });
        let show_list_active = await this.makeShowListActive(show_list);
        //await this.calculatePillCount(show_list_active);
        this.setState({ show_list_active, pills, show_list, select_all : false, multi_select : 0 });
    }

    toggleCheck = (checked, id) => async (e) => {
        e.preventDefault();
        let table_ids = Store.getStoreData("mng_confirm_current_record_ids");
        let multi_select = 0;
        let report_list = JSON.parse(JSON.stringify(this.state.report_list));
        let show_list_active = JSON.parse(JSON.stringify(this.state.show_list_active));
        for(let i=0; i<=report_list.length-1; i++){
            if(id === report_list[i].id) report_list[i].checked = !checked;
            if(report_list[i].checked === true) multi_select++;
        }
        for(let i=0; i<=show_list_active.length-1; i++){
            if(id === show_list_active[i].id) show_list_active[i].checked = !checked;
        }
        //let selected_records = await this.makeSelectedRecords(report_list);
        this.setState({ report_list, show_list_active, select_all : multi_select >= table_ids.length ? true : false, multi_select });
    }

    toggleSelectAll = async (e) =>{
        e.preventDefault();
        //let table_ids = Store.getStoreData("mng_confirm_current_record_ids");
        //console.log("CURRENT TABLE IDS : ",table_ids);
        let report_list = JSON.parse(JSON.stringify(this.state.report_list));
        let show_list_active = JSON.parse(JSON.stringify(this.state.show_list_active));
        for(let i=0; i<=report_list.length-1; i++) report_list[i].checked = !this.state.select_all;
        for(let i=0; i<=show_list_active.length-1; i++) show_list_active[i].checked = !this.state.select_all;
        let multi_select = !this.state.select_all === true ? show_list_active.length : 0;
        this.setState({ report_list, show_list_active, select_all : !this.state.select_all, multi_select});
    }

    makeAllUnchecked = async (report_list) =>{
        for(let i=0; i<=report_list.length-1; i++) report_list[i].checked = false;
        return report_list;
    }

    showHideInactives = async (e) => {
        e.preventDefault();
        let show_list_active = [];
        let show_list = JSON.parse(JSON.stringify(this.state.report_list));
        if(this.state.hide_inactive === true) {
            show_list_active = show_list;
        }
        else{
            show_list_active = await this.makeShowListActive(show_list);
        }
        await this.calculatePillCount(show_list_active);
        this.setState({ show_list_active, hide_inactive : this.state.hide_inactive === true ? false : true})
    }

    showMainContextMenu = (event) => {
        event.preventDefault();
        this.setState({show_main_context_menu : this.state.show_main_context_menu === true ? false : true })
    }

    hideMainContextMenu = (event) => {
        event.preventDefault();
        this.setState({show_main_context_menu : false })
    }

    showConfirmationModal = (event) =>{
        event.preventDefault();
        let report_list = JSON.parse(JSON.stringify(this.state.show_list_active));
        let table_ids = Store.getStoreData("mng_confirm_current_record_ids");
        let selected_records = { "transaction" : [], "holding" : []};
        for(let i=0; i< report_list.length; i++){
            if(report_list[i].checked === true && report_list[i].inactive_contact !== true) {
                if(report_list[i].report_type === "1" && table_ids.includes(report_list[i].id) === true) {
                    if(selected_records.transaction.includes(parseInt(report_list[i].contact_id)) === false) selected_records.transaction.push(parseInt(report_list[i].contact_id));
                }
                else if(report_list[i].report_type === "2" && table_ids.includes(report_list[i].id) === true){
                    if(selected_records.holding.includes(parseInt(report_list[i].contact_id)) === false) selected_records.holding.push(parseInt(report_list[i].contact_id));
                }
            }
        }
        let show_confirmation_modal = (selected_records.transaction.length > 0 || selected_records.holding.length > 0) ? true : false;
        this.setState({ show_confirmation_modal, selected_records });
    }

    closeConfirmationModal = async (status = null) =>{
        this.setState( { show_confirmation_modal : false, selected_records : {} });
        if(status === 'new') await this.intializeState();
    }

    closeConfirmationReportModal = async () =>{
        this.setState( { show_confirmation_report_modal : false, selected_report : null });
    }

    closeTransactionReportViewModal = () => {
        this.setState( { show_report_view_modal : false, selected_report : null });
    }

    closeAlertSmall = (value, stack) => {
        this.setState({ alert_param : null});
    }

    performAction = (id, action, from_url = false) => {
        const report_list = JSON.parse(JSON.stringify(this.state.report_list));
        if(action === "issue") {
            let selected_records = { "transaction" : [], "holding" : []};
            let record = report_list.find(item => item.id === id);
            if(record.report_type === "1" && selected_records.transaction.includes(parseInt(record.contact_id)) === false) selected_records.transaction.push(record.contact_id);
            else if(record.report_type === "2" && selected_records.holding.includes(parseInt(record.contact_id)) === false) selected_records.holding.push(parseInt(record.contact_id));
            this.setState({ show_confirmation_modal : true, selected_records });
        }else if(action === "view") {
            let selected_report = { "transaction" : {}, "holding" : {}};
            let record = from_url === false ? report_list.find(item => item.id === id) : report_list.find(item => item.record_id === id);
            if(record?.report_type === "1") {
                selected_report.transaction = record;
                selected_report.holding = null;
            }
            else if(record?.report_type === "2") {
                selected_report.holding = record;
                selected_report.transaction = null;
            }
            if(record.status === 'pending')
                this.setState({ show_confirmation_report_modal : true, selected_report });
            else
                this.setState({ show_report_view_modal : true, selected_report });
        }
    }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";

        window.location.replace(url);
    };

    processDataTable = () => {
        const ret = { data: [], columns: [] };
        const actions = [{name: "View", action: "view"}, {name: "Issue", action: "issue"}];
        let is_all_selected = true;
        let table_data = [];
        //debugger;
        for(let record of this.state.show_list_active){
            if(record.checked === false) is_all_selected = false;
            let data = {
                id : record.id,
                record_id : record.record_id,
                checked : record.checked,
                status : this.state.pills[record.status].text.toUpperCase(),
                status_color : this.state.pills[record.status].color,
                report_type : this.state.report_types[record.report_type].text.toUpperCase(),
                contact_name : (Store.getStoreData("company_users").find(item => item.user_id.toString() === record.contact_id.toString())).name === "Inactive User" ? (this.state.inactive_users.find(item => item.user_id === record.contact_id)).name : (Store.getStoreData("company_users").find(item => item.user_id.toString() === record.contact_id.toString())).name,
                date_sent : record.date_sent === null ? "--/--/----" : moment(moment(record.date_sent.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                due_date : record.due_date === null ? "--/--/----" : moment(moment(record.due_date.toString())).format("DD/MM/YYYY"),
                resp_date : record.resp_date === null ? "--/--/----" : moment(moment(record.resp_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                issued_by : record.issued_by !== null ? Store.getStoreData("company_users").find(item => item.user_id === record.issued_by).name : "",
                is_inactive_user : record.inactive_contact,
                last_action_time : record?.last_action_time
            }
            table_data.push(data);
        }

        console.log("Table Data : ", table_data);
        table_data.sort((a, b) => b.last_action_time - a.last_action_time);
        ret.data = table_data;
        ret.columns = [
            { Header: (
                <div className="select-all-container">
                    {
                        (()=>{
                            if(this.state.select_all === false || is_all_selected === false) return <div style={{fontSize : "20px", color : "#888", paddingTop : "3px"}} onClick={this.toggleSelectAll}><FaRegSquare /></div>;
                            else return <div style={{fontSize : "20px", color : "#888", paddingTop : "3px"}} onClick={this.toggleSelectAll}><FaRegCheckSquare /></div>;
                        })()

                    }
                </div>
            ), width: 40, filterable: false, sortable: false, headerStyle: { textAlign: "center", lineHeight: "24px"}, Cell: (row) =>{
                    //console.log("ROW",row.original.checked);
                    if(row.original.is_inactive_user === true) return <div style={{fontSize : "20px", color : "#ddd", paddingTop : "3px", cursor : "auto"}}><FaRegSquare /></div>;
                    if(row.original.checked === false) return <div style={{fontSize : "20px", color : "#888", paddingTop : "3px", cursor : "pointer"}} onClick={this.toggleCheck(row.original.checked, row.original.id)}><FaRegSquare /></div>;
                    return <div style={{fontSize : "20px", color : "#888", paddingTop : "3px", cursor : "pointer"}} onClick={this.toggleCheck(row.original.checked, row.original.id)}><FaRegCheckSquare /></div>;
            }},
            { Header: "", accessor: "status_color", width: 40, filterable: false, headerStyle: { textAlign: "center", lineHeight: "24px" }, Cell: (row) =><div style={{ backgroundColor: `${row.original.status_color}`, height: "15px", width: "15px", marginTop: "5px" }}></div>},
            { Header: "Status", accessor: "status", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Report Type", accessor: "report_type", width: 200, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Employee", accessor: "contact_name", width: 250, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Date Sent", accessor: "date_sent", width: 150, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Due Date", accessor: "due_date", width: 150, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Response Date", accessor: "resp_date", width: 150, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            { Header: "Issued By", accessor: "issued_by", width: 250, headerStyle: { textAlign: "left", lineHeight: "24px" }, style: { lineHeight: "24px" } },
            {
                Header: "Action", Width: 55, headerStyle: { textAlign: "center", lineHeight: "24px" }, style: { lineHeight: "24px", textAlign: "center", height : "50px" },
                Cell: (row) => {
                    if(row.original.is_inactive_user) return <div style={{color : "#ddd", fontSize : "18px", paddingTop : "5px"}}><FaEllipsisV /></div>
                    let show = {view: 1, issue: 1};
                    if(row.original.status === 'NEW') show.view = 0;
                    return <div style={{marginTop : "-6px"}}><EllipsisMenu show={show} entity_id={row.original.id} actions={actions} performAction={this.performAction} /></div>
                },
                filterable: false
            }
        ]

        return ret;
    }

    exportConfirmationList = (event) =>{
        event.preventDefault();
        let headerCSV = {
            status : "Status",
            report_type : "Report Type",
            employee : "Employee",
            date_sent : "Date Sent",
            due_date: "Due Date",
            resp_date: "Response Date",
            resp_time: "Response Time",
            issued_by : "Issued by"
        };
        let dataCSV = [];
        let report_list = JSON.parse(JSON.stringify(this.state.report_list))
        let export_ids = Store.getStoreData("mng_confirm_current_record_ids")
        for(let id of export_ids){
            let record = report_list.find(item => item.id === id)
            let data = {
                status : this.state.pills[record.status].text.toUpperCase(),
                status_color : this.state.pills[record.status].color,
                report_type : this.state.report_types[record.report_type].text.toUpperCase(),
                employee : (Store.getStoreData("company_users").find(item => item.user_id === record.contact_id)).name === "Inactive User" ? (this.state.inactive_users.find(item => item.user_id === record.contact_id)).name : (Store.getStoreData("company_users").find(item => item.user_id === record.contact_id)).name,
                date_sent : record.date_sent === null ? "--/--/----" : moment(moment(record.date_sent.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                due_date : record.due_date === null ? "--/--/----" : moment(moment(record.due_date.toString())).format("DD/MM/YYYY"),
                resp_date : record.resp_date === null ? "--/--/----" : moment(moment(record.resp_date.toString(),"YYYYMMDD")).format("DD/MM/YYYY"),
                resp_time : record?.resp_time === null ? "--:--:--" : record?.resp_time,
                issued_by : record.issued_by !== null ? Store.getStoreData("company_users").find(item => item.user_id === record.issued_by).name : ""
            }
            dataCSV.push(data);
        }
        //console.log("Headers : ", headerCSV, "Data : ", dataCSV);
        this.exportToCSV(headerCSV, dataCSV);
    }

    exportToCSV = (headerCSV, dataCSV) => {
        //event.preventDefault();
        //let tableData = this.processDataTable();
        // if (Store.getStoreData("role") === "team") {
        //   delete headerCSV.requester;
        // }
        let data= dataCSV;
        let csv_data_arr = [];
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
          //console.log("Data in Export : ", data[k]);
          let cont = {};
          Object.entries(headerCSV).forEach(([key, value]) => {
            if(data[k][key] !== null) {
              //console.log("Data key", data[k][key]);
              cont[key] = data[k][key].toString().replace(/,/g, '  ').replace(/-/g, '-');
            }
          })
          csv_data_arr.push(cont);
        }
        //console.log("csv_data_arr",csv_data_arr)
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0);
          csv_data_final_arr.push(container)
        }
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Confirmation List.csv");
        document.body.appendChild(link);
        link.click();
    }

    render(){
        if (Store.getStoreData("role") !== "admin_manager" && Store.getStoreData("role") !== "padm") {
            return (
                <div className="error-bar">
                    <div className="error-msg-div">You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div className="error-bar-close" onClick={this.closePage}><FaTimes /></div>
                </div>
            );
        }
        if(this.state.ready !==true) return <SpinningLoader />
        console.log("This.STate ==>", this.state);
        let render_key = this.makeid(8);
        //let table_ids = Store.getStoreData("mng_confirm_current_record_ids");
        //console.log("RENDER CURRENT TABLE IDS : ",table_ids);
        return(
            <>
                {this.state.show_spinner === true && <SpinningLoader />}
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <CSLHeader
                        isAdmin={(this.state.cur_view === 'manager_index')}
                        isDebug={Store.getStoreData('is_debug')}
                        taskHeader={false} module={"padreg"}
                        headerText={'Manage Reports'}
                    />
                    <div className="container">
                        <div className="header-container">
                            <div className="header-text-container">Build and Send Reports</div>
                        </div>
                        <div className="pill-bar">
                            <div className="pill-container">
                                {
                                    (()=>{
                                        let pills = [];
                                        for(let key in this.state.pills){
                                            pills.push(<Pill key={key} id={key} active={this.state.pills[key].active} pilltext={this.state.pills[key].text} pillcount={this.state.pills[key].count} changePill={this.changePill}/>)
                                       };
                                       return pills;
                                    })()
                                }
                            </div>
                            <div className='main-ellipsis'>
                                <FaEllipsisV onClick={this.showMainContextMenu}/>
                                {
                                    this.state.show_main_context_menu &&
                                    <div className='main-context-menu' onMouseLeave={this.hideMainContextMenu}>
                                        <div className='main-context-menu-item' onClick={this.exportConfirmationList}>Export</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="option-bar">
                            { (this.state.multi_select > 0) && <div className="multi-select-option-link" onClick={this.showConfirmationModal}>Send Report Request</div>}
                        </div>
                        <div className="table-container">
                            <CSLTable add={false} processData={this.processDataTable} zebra={false} tableRows={10} exportClicked = {this.state.export_clicked} from={"mng_confirm"} render_key={render_key}/>
                            {/* <div className="select-all-container">
                                {
                                    (()=>{
                                        if(this.state.select_all === false) return <div style={{fontSize : "20px", color : "#888", paddingTop : "3px"}} onClick={this.toggleSelectAll}><FaRegSquare /></div>;
                                        else return <div style={{fontSize : "20px", color : "#888", paddingTop : "3px"}} onClick={this.toggleSelectAll}><FaRegCheckSquare /></div>;
                                    })()

                                }
                            </div> */}
                        </div>
                        <div className="option-bar-bottom">

                            { this.state.hide_inactive === true && <div>Showing Active Employees. <span className="multi-select-option-link" onClick={this.showHideInactives}>Show Inactive Employees</span></div>}
                            { this.state.hide_inactive === false && <div>Showing All Employees. <span className="multi-select-option-link" onClick={this.showHideInactives}>Hide Inactive Employees</span></div>}
                        </div>
                        {
                            this.state.show_confirmation_modal === true && <div className="inactive-overlay"><SendConfirmationModal selected_records={this.state.selected_records} closeModal={this.closeConfirmationModal} /></div>
                        }
                        {
                            this.state.show_confirmation_report_modal === true && this.state.selected_report?.transaction !== null && this.state.selected_report?.holding === null && <div className="inactive-overlay"><ConfirmationReportModal selected_report={this.state.selected_report} view={"admin"} closeModal={this.closeConfirmationReportModal} /></div>
                        }
                        {
                            this.state.show_confirmation_report_modal === true && this.state.selected_report?.transaction === null && this.state.selected_report?.holding !== null && <div className="inactive-overlay"><HoldingConfirmationReportModal selected_report={this.state.selected_report} view={"admin"} closeModal={this.closeConfirmationReportModal} /></div>
                        }
                        {
                            this.state.show_report_view_modal === true && this.state.selected_report?.transaction !== null && this.state.selected_report?.holding === null && <div className="inactive-overlay"><TransactionReportViewModal selected_report={this.state.selected_report} view={"admin"} closeModal={this.closeTransactionReportViewModal} /></div>
                        }
                        {
                            this.state.show_report_view_modal === true && this.state.selected_report?.transaction === null && this.state.selected_report?.holding !== null && <div className="inactive-overlay"><HoldingReportViewModal selected_report={this.state.selected_report} view={"admin"} closeModal={this.closeTransactionReportViewModal} /></div>
                        }
                    </div>
                </div>
                <AlertBox alertParam={this.state.alert_param} />
            </>
        )
    }
}

export default ManageConfirmation;
